import React, { useState, useEffect, useContext } from 'react';
import AssignVehicles from './AssignVehicles';
import { setVehicleShiftLog, subscribeToVehiclesWithUsersAssigned, getVehiclesWithUsersAssigned} from '../../services/VehiclesService';
import { subscribeToVehicleChecklistsInReview } from '../../services/VehiclesChecklistService'
import { subscribeToMaintenanceJobsComplete } from '../../services/MaintenanceJobsService'
import {getChecklists} from "../../services/ChecklistsService";
import { getNewsItems, subscribeToNewsItems,subscribeToVehicleIssues} from '../../services/newsItemsService';
import { getTeams } from '../../services/TeamsService';
import { getUsers, subscribeToUsers, updateUser } from '../../services/UsersService';
import { getIncidents } from '../../services/IncidentService';
import { UserContext } from '../../context/userContext';
import ModalError from '../../components/ModalError';
import SignOffMaintenance from './SignOffMaintenance';
import { navigate, Router } from '@reach/router';
import SharedLayout from '../../components/SharedLayout';

import { CModal } from '@coreui/react';
import UserTypes  from '../../enums/UserTypes';
import Incidents from './Incidents';
import Dashboard from './Dashboard';
import HandoverReport from './HandoverReport';
import ChecklistReview from './ChecklistReview';
import ReportedIssuesReview from './ReportedIssuesReview';
import VehicleHistory from './VehiclesHistory';
import Reports from './Reports';
import { APPROVED, REJECTED } from '../../enums/ReportedProblemStatuses';
import Checklist from '../../components/Checklist';
import checklistData from '../../data/checklistdata';
import SideBarItems from '../../components/SideBarItems';
import VehicleEngineHours from './VehicleEngineHours';
import ChecklistHistory from "./VehiclesHistory/ChecklistHistory";
import InspectionHistory from "./VehiclesHistory/ChecklistHistory/InspectionHistory";
import IssuesHistory from "./VehiclesHistory/IssuesHistory";
import AssignmentHistory from "./VehiclesHistory/AssignmentHistory";
import IndividualIssue from "./VehiclesHistory/IssuesHistory/IndividualIssue";
import IndividualAssignment from "./VehiclesHistory/AssignmentHistory/IndividualAssignment";

///// BUGS /////
// 1. When you click back on checkout vehicle, it takes you to an empty page (doesn't take you back to supervisor)
// 2. When you close the add load modal, page goes blank
// 3. When you approve a load, the load does not dissapear on users screen (but does update on firestore)f
// 4> (2022) - Still some bugs to sort on reassign vehicle
////////////////

export const Supervisor = () => {
  const { user, updateActiveUser, vehicle } = useContext(UserContext);
  const [, setIsShiftStart] = useState(false);

  const {SUPERVISOR} = UserTypes;

  const getUniqueTeams = (teamsArr) => [
    ...teamsArr
      .map((teamObj) => teamObj.teamName)
      .filter((team, i, teams) => !teams.slice(i + 1).includes(team)),
    'All'
  ];

  const [vehiclesArr, setVehiclesArr] = useState([]);
  const [filteredVehiclesArr, setFilteredVehiclesArr] = useState([]);
  const [usersArr, setUsersArr] = useState([]);
  const [filteredUsersArr, setFilteredUsersArr] = useState([]);
  const [filteredChecklistCompleteItemsArr, setFilteredChecklistCompleteItemsArr] = useState([]);
  const [checklistsPendingApprovalArr, setChecklistsPendingApprovalArr] = useState([]);
  const [reportedIssuesPendingApprovalArr, setReportedIssuesPendingApprovalArr] = useState([]);
  const [newsItemsArr, setNewsItemsArr] = useState([]);
  const [filteredNewsItemsArr, setFilteredNewsItemsArr] = useState([]);
  const [incidentsArr, setIncidentsArr] = useState([]);
  const [teamToView, setTeamView] = useState(user.userType === SUPERVISOR ? 'All' : user.currentTeam || 'All');
  const [teamsAvailableToView, setTeamsAvailableToView] = useState([]);
  const [filteredVehicleAdditionalTextArr, setfilteredVehicleAdditionalTextArray] = useState([]);
  const [maintenanceJobsArr, setMaintenanceJobsArr] = useState([]);
  const [checklistData, setChecklistData] = useState({});
  const [currentVehicleIssues, setCurrenVehicleIssues] = useState({});
  const [vehicleChecklistsPendingReview, setVehicleChecklistsPendingReview] = useState([]);
  //minimum working hours
  const workingHours = 12;

  const updateSupervisorShiftProperty = () => {
    user.isOnShift = !user.isOnShift;

    if (user.isOnShift) {
      let todayDate = new Date();
      let estEndDate = new Date();
      estEndDate.setHours(estEndDate.getHours() + workingHours);

      user.startShiftTime = todayDate;
      user.endShiftTime = estEndDate;
    } else {
      user.startShiftTime = user.startShiftTime ? user.startShiftTime : new Date();
      user.endShiftTime = new Date();

      setVehicleShiftLog(user);
    }

    setIsShiftStart(user.isOnShift);

    updateUser(user).then((response) => {
      updateActiveUser(user);
      return user;
    });
  };

  const supervisorBarrier = () => {
    if (!user.isOnShift) {
      let message = 'Please begin your shift to proceed';
      setModalContent(
        <ModalError title={'Start shift'} message={message} showModal={setShowModal} />
      );
      toggle();
      return false;
    }

    return true;
  };

  //page load effects
  useEffect(() => {
    let mounted = true;
    //get vehicles
    getVehiclesWithUsersAssigned().then(setVehiclesArr);
    getUsers().then(setUsersArr);
    getIncidents().then(setIncidentsArr);
    //get news items
    getNewsItems(teamToView).then((dataArr) => setNewsItemsArr(dataArr));
    //set teams that can be selected
    getTeams().then((res) => {
      if (mounted) {
        setTeamsAvailableToView(getUniqueTeams(res));
      }
    });

    //subscribing to all vehicles as this doesnt have a teams filter on the service
    const unsubscribeVehicles = subscribeToVehiclesWithUsersAssigned(setVehiclesArr);
    const unsubscribeUsers = subscribeToUsers(setUsersArr);
    const unsubscribeToMaintenanceJobsComplete = subscribeToMaintenanceJobsComplete(setMaintenanceJobsArr);
    const unsubscribetoVehicleChecklistsPendingReview = subscribeToVehicleChecklistsInReview(setVehicleChecklistsPendingReview);
    const unsubscribeVehicleIssues = subscribeToVehicleIssues((issues) => {
      const pendingApprovalIssues = issues.filter((issue) => {
        return issue.info && ![APPROVED, REJECTED].includes(issue.info.status);
      });
      setReportedIssuesPendingApprovalArr(pendingApprovalIssues);
    });

    //Use effect clean ups
    return () => {
      mounted = false;
      unsubscribeVehicles();
      unsubscribetoVehicleChecklistsPendingReview();
      unsubscribeUsers();
      unsubscribeToMaintenanceJobsComplete();
      unsubscribeVehicleIssues();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(()=>{
 
    if(vehicle && vehicle.vehicleID ){

      if (vehicle.vehicleType) {
        getChecklists(vehicle.vehicleType)
          .then(response => {
            setChecklistData(response)
          })
      }

      let unsubscribeLatestVehicleIssue = subscribeToVehicleIssues(setCurrenVehicleIssues,vehicle.vehicleID);

      return () => {
        unsubscribeLatestVehicleIssue();
      };

    }
  },[vehicle]);

  useEffect(() => {
    //refilter when teamToView Changes
    setFilteredVehiclesArr(
      vehiclesArr.filter(
        teamToView === 'All' ? () => true : (vehicle) => vehicle.currentTeam === teamToView
      )
    );
    setFilteredUsersArr(
      usersArr.filter(teamToView === 'All' ? () => true : (user) => user.currentTeam === teamToView)
    );

    // subscribe to collections
    let unsubscribeNews = subscribeToNewsItems(setNewsItemsArr, teamToView);
    return () => {
      unsubscribeNews();
    };
  }, [teamToView, vehiclesArr, usersArr]);

  useEffect(() => {
    // filter out incidents news since those are rendered on their own section
    setFilteredNewsItemsArr(
      newsItemsArr.filter((newsItem) => newsItem.type !== 'supervisorIncident')
    );
  }, [newsItemsArr]);

  useEffect(() => {
    setChecklistsPendingApprovalArr(
      filteredChecklistCompleteItemsArr.filter(
        (newsItem) => newsItem.type === 'vehicleCheckListComplete'
      )
    );
  }, [filteredChecklistCompleteItemsArr]);
  //handle change team dropdown
  const handleTeamChange = (e) => {
    setTeamView(e.target.value);
  };

  // modal
  const [isShowing, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const toggle = () => {
    setShowModal(!isShowing);
  };

  const sectionPageTitle = 'Supervisor';

  const sidebarItems = (
    <SideBarItems
      role={SUPERVISOR}
      user={user}
      numChecklistApproval={vehicleChecklistsPendingReview.length}
      numReportedIsssuesApproval={reportedIssuesPendingApprovalArr.length}
      numMaintenanceIssues={maintenanceJobsArr.length}
      numIncidents={incidentsArr !== undefined ? incidentsArr.filter(v => v.status !== "Archived").length : 0}
      handleShiftState={updateSupervisorShiftProperty}
      handleReassignVehicles={() => {
        if (supervisorBarrier()) {
          setModalContent(
            <AssignVehicles
              usersArr={filteredUsersArr}
              vehiclesArr={filteredVehiclesArr}
              showModal={setShowModal}
            />
          );
          toggle();
        }
      }}
      handleCheckOutVehicle={() => {
        if (supervisorBarrier()) {
          if (user.assignedVehicle) {
            navigate('/supervisor/checklist');
          } else {
            alert('No vehicle assigned, click Reassign Vehicles to assign yourself a vehicle');
          }
        }
      }}
    />
  );

  return (
    <SharedLayout role={SUPERVISOR} sideNav={sidebarItems} pageHeading={sectionPageTitle}>
      <Router>
        <Dashboard
          default
          path="/dashboard"
          teamsAvailableToView={teamsAvailableToView}
          handleTeamChange={handleTeamChange}
          teamToView={teamToView}
          vehiclesArr={vehiclesArr}
          user={user}
          filteredVehiclesArr={filteredVehiclesArr}
          filteredUsersArr={filteredUsersArr}
          filteredNewsItemsArr={filteredNewsItemsArr}
          setModalContent={setModalContent}
          setShowModal={setShowModal}
          filteredVehicleAdditionalTextArr={filteredVehicleAdditionalTextArr}
          numChecklistApproval={vehicleChecklistsPendingReview.length}
          numReportedIssuesApproval={reportedIssuesPendingApprovalArr.length}
          numMaintenanceIssues={maintenanceJobsArr.length}
        />
        <Incidents
          path="/incidents"
          incidentsArr={incidentsArr}
          setModalContent={setModalContent}
          setShowModal={setShowModal}
          toggleModal={toggle}
          user={user}

        />
        <HandoverReport path="/handover-report" user={user} />
        <ChecklistReview
          path="/checklist-review"
          vehicleChecklists={vehicleChecklistsPendingReview}
          user={user}
          vehiclesArr={vehiclesArr}
          setModalContent={setModalContent}
          setShowModal={setShowModal}
          toggleModal={toggle}
        />
        <ReportedIssuesReview
          path="/reported-issues"
          issuesPendingApproval={reportedIssuesPendingApprovalArr}
          vehiclesArr={vehiclesArr}
          user={user}
          setModalContent={setModalContent}
          setShowModal={setShowModal}
        />

        <Reports path="/reports" user={user} />

        <SignOffMaintenance
          user={user}
          maintenanceJobsArr={maintenanceJobsArr}
          showModal={setShowModal}
          path="/sign-off-maintenance"
        />

        <Checklist vehicle={vehicle} path="/checklist" currentVehicleIssues={currentVehicleIssues} checklistData={checklistData} />

        <VehicleEngineHours path="/vehicle-engine-hours" />

        <VehicleHistory path="/vehicleHistory" />
        <ChecklistHistory path="/vehicleHistory/checklistHistory/:vehicleID" />
        <IssuesHistory path="/vehicleHistory/issuesHistory/:vehicleID" />
        <AssignmentHistory path="/vehicleHistory/assignmentHistory/:vehicleID" />

      </Router>

      <CModal
        show={isShowing}
        size="lg"
        onClose={() => {
          setShowModal(false);
        }}
      >
        {modalContent}
      </CModal>
    </SharedLayout>
  );
};

export default Supervisor;
