import {checklistIcon} from "./checklist-icon";
import {clockIcon} from "./clock-icon";
import {checkList} from "./checklist";
import {exclamationCircle} from "./exclamation-circle";
import {exclamationMark} from "./exclamation-mark";
import {maintananceApproveIcon} from "./maintanance-approve-icon";
import {reportAnIssue} from "./report-an-issue";
import {supervisorHandover} from "./supervisor-handover";
import {supervisorReassignVehicle} from "./supervisor-reassign-vehicle";
import {truckicon} from './truckIcon'

export const opsIcons = Object.assign({}, {
  clockIcon, truckicon, exclamationMark, checklistIcon, maintananceApproveIcon, supervisorReassignVehicle, supervisorHandover, reportAnIssue,exclamationCircle,checkList
})
