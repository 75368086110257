import React from "react";
import Styles from "../Checklist.module.scss";
import Confirmation from "../Confirmation";

const ClassCChecks = (props) => {
    const { extraTextFields, setExtraTextFields, getTextFieldElements, setFailedElements, failedElements, failObject, checklistData, vehicleType, nextHandler, backHandler, vehiclePreselected, vehicleStepCheckOptions } = props.propsMethods;
    const { user, vehicle} = props;
    const checkboxArr = checklistData.classC ? Object.keys(checklistData.classC): [];
    checkboxArr.sort();

    const getChecklist = item => {

        let isSelected = !!vehiclePreselected(item) 
        let isPassedSelection = vehicleStepCheckOptions(item, "classC")

        return (
            <div key={item} className={ isSelected ? Styles.inActive  : Styles.active}>
                <img src={checklistData.classC[item]?.iconUrl} alt={item} />
                <label htmlFor={item}>{item}</label>

                { !isSelected && !isPassedSelection &&
                <input type="checkbox" id={item} name={item} value={item}/>
                }

                { !isSelected && isPassedSelection &&
                <input type="checkbox" id={item}  name={item} defaultValue={item}  defaultChecked  />
                }

                { isSelected &&
                <input type="checkbox" id={item}  name={item} defaultValue={item} readOnly checked={false} className={Styles.inActive}/>
                }

            </div>
        )

    }

    return (
        <>
            {checkboxArr.length > 0 ?
                <>
                    <h4>Class C Checks</h4>
                    <article className={Styles.classChecks}>
                        <form id="class-c-checks">
                            <div className={Styles.responses}>
                                {checkboxArr.map(getChecklist)}
                            </div>
                            <div className="mt-5 mb-3 d-flex">
                                <textarea className="form-control border-primary" name="class-c-comment"
                                          id="additional-details"
                                          placeholder="Please enter any additional details here..."
                                          defaultValue={extraTextFields.classC.additional_details}/>
                            </div>
                            <section className={Styles.navigation}>
                                <button onClick={() => {
                                    backHandler();
                                    setFailedElements(failObject(vehicleType, "classC"));
                                    setExtraTextFields(getTextFieldElements('classC'));
                                }} className={Styles.btnSecondary}>Back
                                </button>
                                <button type="button" className={Styles.btnPrimary} onClick={() => {
                                    nextHandler();
                                    setFailedElements(failObject(vehicleType, "classC"));
                                    setExtraTextFields(getTextFieldElements('classC'));
                                }}>Next
                                </button>
                            </section>
                        </form>
                    </article>
                </>
                :
                <Confirmation extraTextFields={extraTextFields} checklistData={checklistData} failedElements={failedElements} user={user} vehicle={vehicle} backHandler={backHandler}/>}
        </>
    )

}

export default ClassCChecks;