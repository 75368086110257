import React, {useEffect, useState, Fragment, useContext} from "react";
import Styles from "./IssuesHistory.module.scss";
import {Card, Tag, Table, Button, Divider, Spin, Descriptions} from 'antd'
import {subscribeVehicleByVehicleID} from "../../../../services/VehiclesService";
import {subscribeVehicleIssuesByVehicleID} from "../../../../services/MaintenanceJobsService";
import VehiclePageHeader from "../VehiclePageHeader";
import {UserContext} from "../../../../context/userContext";
import IndividualIssue from "./IndividualIssue";
import {AVAILABLE} from "../../../../enums/VehicleStatuses";
import {getUsers} from "../../../../services/ShiftsService";
import {getTeams} from "../../../../services/TeamsService";
import {getUser} from "../../../../services/UsersService";

const IssuesHistory = (props) => {

    const {vehicleID} = props
    const [vehicleInfo, setVehicleInfo] = useState({})
    const [issuesList, setIssuesList] = useState([])
    const [userList, setUserList] = useState([])
    const [vehicleInfoLoading, setVehicleInfoLoading] = useState(false)
    const [issuesListLoading, setIssuesListLoading] = useState(false)
    const [issuesDetail, setIssuesDetail] = useState({
        detail: {},
        visible: false
    })

    const getUserList = async( userID ) =>{
        return await getUsers().then(users => {
            return users;
        });
    }

    useEffect(() => {
        setVehicleInfoLoading(true)
        setIssuesListLoading(true)

        getUserList().then(users=>{
            setUserList(users)
        });

        subscribeVehicleByVehicleID(vehicleID, (result) => {
            if(result.length > 0){
                setVehicleInfo(result[0])
            }
            setVehicleInfoLoading(false)
        })

        subscribeVehicleIssuesByVehicleID(vehicleID, null , (items)=>{
            setIssuesList(items)
            setIssuesListLoading(false)
        })
    }, [])

    const issuesListFormat = (issues) => {
        let userName = ''
        userList.forEach(user => {
            if(user.userID === issues.maintenanceSignoff.completedBy)
                userName = (user && user.fullNameStr) ? user.fullNameStr : user.userID
        })
        return {
            ...issues,
            assigned: userName
        }
    }

    const issueListField = [
        {
            key: 'issue',
            dataIndex: 'issue',
            title: 'Issue',
        },
        {
            key: 'dateCreatedStr',
            dataIndex: 'dateCreatedStr',
            title: 'Submission Date',
            sorter: (a, b) => a.dateCreated - b.dateCreated
        },
        {
            key: 'status',
            dataIndex: 'status',
            title: 'Issue Status',
            render: (text) => {
                if(text === 'Open'){
                    return <Tag color={'green'}>{text}</Tag>
                }
                else{
                    return <Tag>{text}</Tag>
                }
            }
        },
        {
            key: 'operatorFullName',
            dataIndex: 'operatorFullName',
            title: 'Reported By',
        },
        {
            key: 'assigned',
            dataIndex: 'assigned',
            title: 'Assigned'
        },
        {
            key: 'action',
            dataIndex: 'action',
            title: 'action',
            render: (text, record) => <Button onClick={() => {
                setIssuesDetail({
                    detail: record,
                    visible: true
                })
            }}>{'View Info'}</Button>
        },
    ]

    return (
        <Fragment>
            {
                !issuesDetail.visible && (
                    <Fragment><Card bodyStyle={{padding: 0}}>
                        <VehiclePageHeader
                            title="Issues History"
                            vehicleID={vehicleID}
                        />
                        <Divider className={Styles.headerDivider}/>
                        <Spin spinning={vehicleInfoLoading}>
                            <div className={Styles.vehicleInfo}>
                                <Descriptions title={vehicleInfo.vehicleID} column={1}>
                                    <Descriptions.Item label="Type">{vehicleInfo.vehicleType}</Descriptions.Item>
                                    <Descriptions.Item label="Current Team">{vehicleInfo.currentTeam}</Descriptions.Item>
                                    {vehicleInfo.userIsOnShift && <Descriptions.Item label="Current User">{vehicleInfo.currentUser}</Descriptions.Item>}
                                    <Descriptions.Item label="Current Status">{vehicleInfo.currentStatus === "Parked" && vehicleInfo.userIsOnShift ? "On Shift" : vehicleInfo.currentStatus}</Descriptions.Item>
                                    <Descriptions.Item label="Vehicle Status">{vehicleInfo?.vehicleStatus?.toLocaleLowerCase() === AVAILABLE.toLocaleLowerCase() ?
                                        <Tag color={"green"}>{vehicleInfo.vehicleStatus}</Tag> :
                                        <Tag color={"gray"}>{vehicleInfo.vehicleStatus}</Tag>}
                                    </Descriptions.Item>
                                </Descriptions>
                            </div>
                        </Spin>
                    </Card>
                        <Card title={'Issues History'} bodyStyle={{padding: 0}}>
                            <Table
                                rowKey={'id'}
                                loading={issuesListLoading}
                                columns={issueListField}
                                sortDirections={['ascend', 'descend', 'ascend']}
                                dataSource={issuesList.map(issuesListFormat)}
                                pagination={{size: 'small', defaultPageSize: 30}}
                                scroll={{x:true}}
                            />
                        </Card>
                    </Fragment>
                )
            }
            {
                issuesDetail.visible &&
                    <IndividualIssue
                        issuesDetail={issuesDetail.detail}
                        vehicleInfo={vehicleInfo}
                        onClose={() => {
                            setIssuesDetail({
                                visible: false
                            })}
                        }
                    />
            }
        </Fragment>
    );
};

export default IssuesHistory;
