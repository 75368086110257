import React, { useState } from 'react';
import EditUserForm from '../EditUserForm';
import useModal from '../../../../components/Modal/useModal';
import { deleteUser, resetPassword } from '../../../../services/UsersService';
import { CModal } from "@coreui/react";
import { CIcon } from "@coreui/icons-react";
import { freeSet } from "@coreui/icons";

const UserItem = (props) => {
  const { isShowing, toggle } = useModal();
  const user = props.user;

  const [isOpen, setIsOpen] = useState(false);
  const [optionsType, setOptionsType] = useState('')
  const toggleStyles = isOpen ? "d-block" : "d-none";
  const toggleParentClass = isOpen ? "d-none" : "d-block";

  const UpdateUserPassword = (users) => {
    const defaultPassword = 'fanhongyu'
    resetPassword({ userID: users.userID, newPass: defaultPassword })
  }


  return (
    <>
      <tr key={user.userID} className="">
        <td>{user.userID}</td>
        <td>{user.fullNameStr}</td>
        <td>{user.dateOfBirth}</td>
        <td>{user.isOnShift}</td>
        <td>{user.currentTeam}</td>
        <td>{user.currentSubTeam}</td>
        <td width={120}>
          <div className="position-relative">

            <div className={toggleParentClass}>
              <span className="d-inline p-2" onClick={toggle}><CIcon content={freeSet.cilPencil} /></span>
              <span className="d-inline p-2" onClick={() => {
                setIsOpen(!isOpen)
                setOptionsType('RESET')
              }}><CIcon content={freeSet.cilFingerprint} title='reset password' /></span>
              <span className="d-inline p-2" onClick={() => {
                setIsOpen(!isOpen)
                setOptionsType('DELETE')
              }}><CIcon content={freeSet.cilTrash} /></span>
            </div>

            <div className={`${toggleStyles}`}>
              <button
                className="btn btn-secondary mx-2 my-1"
                onClick={() => setIsOpen(!isOpen)}
              >Cancel
              </button>
              <button
                className="btn btn-primary mx-2 my-1"
                onClick={() => {
                  optionsType === 'RESET' ? UpdateUserPassword(user) : deleteUser(user);
                  setIsOpen(!isOpen);
                }}
              >Confirm
              </button>
            </div>
          </div>
        </td>

      </tr>

      <CModal show={isShowing} size={'lg'} >
        {<EditUserForm user={user} hide={toggle} />}
      </CModal>

    </>

  )
}

export default UserItem;