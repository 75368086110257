import { firestore } from "../firebase";

const getReportedIssues  = () => {
    return firestore.collection("reportedIssueList")
        .get()
        .then(response => response.docs.map(document => ({...document.data(), id: document.id})));
}

const subscribeReportedIssues = (setState) => {
    return firestore.collection("reportedIssueList")
        .onSnapshot(snapshot =>
            {
    
                let resultDoc = snapshot.docs.map(document => ({...document.data(), id: document.id}) );
                setState(resultDoc);
    
            }
        );
}

const createReportedIssue  = (issue) => {
    return firestore.collection("reportedIssueList").add({...issue});
}

const updateReportedIssue = (document, issue) => {
    return firestore.collection("reportedIssueList").doc(document).update({...issue});
}

const deleteReportedIssue  = ( issue ) => {
    return firestore.collection("reportedIssueList").doc(issue.id).delete();
}

export { getReportedIssues, subscribeReportedIssues, createReportedIssue, updateReportedIssue, deleteReportedIssue }
