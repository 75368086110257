import React, {useEffect, useState, Fragment, useContext} from "react";
import Styles from "./IndividualAssignment.module.scss";
import {PageHeader, Card, Tag, Divider, Descriptions, Row, Col, Spin} from 'antd'
import {AVAILABLE} from "../../../../../enums/VehicleStatuses";
import {subscribeVehicleChecklistsByVehicleId} from "../../../../../services/VehiclesChecklistService";

const IndividualAssignment = (props) => {

    const {onClose, assignmentDetail, vehicleInfo} = props

    const [approvedBy, setApprovedBy] = useState('')
    const [completedBy, setCompletedBy] = useState('')
    const [issuesStr, setIssuesStr] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        subscribeVehicleChecklistsByVehicleId( vehicleInfo.vehicleID,  (items)=>{
            let issueList = []
            let approvedBy = null
            let completedBy = null
            items.forEach(item => {
                if(item.usershiftId === assignmentDetail.userShiftId) {
                    if (!approvedBy) {
                        approvedBy = item.actionedBy?.fullName
                    }
                    if (!completedBy) {
                        completedBy = item.addedByDriver?.fullName
                    }
                    if (item.checkItems && item.checkItems.length > 0) {
                        item.checkItems.forEach(checkItem => {
                            if (checkItem.supervisorCheckItemApproved) {
                                issueList.push({
                                    ...checkItem,
                                    reportedBy: item.addedByDriver.fullName
                                })
                            }
                        })
                    }
                }
            })
            setApprovedBy(approvedBy)
            setCompletedBy(completedBy)
            let issuesStrList = []
            issueList.forEach(item =>
                issuesStrList.push(item.issue)
            )
            setIssuesStr(issuesStrList.join(', '))

            setLoading(false)
        })
    }, [])

    return (
        <Fragment>
            <Card bodyStyle={{padding: 0}}>
                <PageHeader
                    onBack={onClose}
                    title={'Individual Assignment'}
                />
                <Divider className={Styles.headerDivider}/>
                <div className={Styles.vehicleInfo}>
                    <Spin spinning={loading}>
                        <Descriptions title={vehicleInfo.id} column={1}>
                            <Descriptions.Item label="Vehicle">{vehicleInfo.vehicleID}</Descriptions.Item>
                            <Descriptions.Item label="Type">{vehicleInfo.vehicleType}</Descriptions.Item>
                            <Descriptions.Item label="Current Team">{vehicleInfo.currentTeam}</Descriptions.Item>
                            <Descriptions.Item label="Current Status">{vehicleInfo.currentStatus}</Descriptions.Item>
                            <Descriptions.Item label="Vehicle Status">{vehicleInfo?.vehicleStatus?.toLocaleLowerCase() === AVAILABLE.toLocaleLowerCase() ?
                                <Tag color={"green"}>{vehicleInfo.vehicleStatus}</Tag> :
                                <Tag color={"gray"}>{vehicleInfo.vehicleStatus}</Tag>}</Descriptions.Item>
                        </Descriptions>
                    </Spin>
                </div>
            </Card>
            <Card>
                <Row>
                    <Col span={12}>
                        <Spin spinning={loading}>
                            <Descriptions title={'Details'} column={1}>
                                <Descriptions.Item label="Start">{assignmentDetail.startTimeStr}</Descriptions.Item>
                                <Descriptions.Item label="End">{assignmentDetail.endTimeStr}</Descriptions.Item>
                                <Descriptions.Item label="Checklist completed by">{completedBy}</Descriptions.Item>
                                <Descriptions.Item label="Approved by">{approvedBy}</Descriptions.Item>
                                <Descriptions.Item label="Checklist issues">{issuesStr}</Descriptions.Item>
                                <Descriptions.Item label="Reported issues">{}</Descriptions.Item>
                            </Descriptions>
                        </Spin>
                    </Col>
                    <Col span={12}>
                        <Descriptions title={'GPS'} column={1}>
                            <Descriptions.Item label="Distance Traveled">{}</Descriptions.Item>
                            <Descriptions.Item label="Alarms">{}</Descriptions.Item>
                            <Descriptions.Item label="Loads Completed">{}</Descriptions.Item>
                            <Descriptions.Item label="Productivity">{}</Descriptions.Item>
                            <Descriptions.Item label="Fuel">{}</Descriptions.Item>
                            <Descriptions.Item label="ACC">{}</Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>

            </Card>
        </Fragment>
    );
};

export default IndividualAssignment;
