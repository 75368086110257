import { firestore } from "../firebase";

const getWorkareas  = async () => {
    const response = await firestore.collection("workareas").get();
    return response.docs.map(document => ({ ...document.data(), id: document.id }));
}

const subscribeToWorkareas = async (handleSnapshot) => {
    return firestore.collection("workareas").onSnapshot(snapshot => {
        let resultDoc = snapshot.docs.map(document => ({ ...document.data(), id: document.id }));
        handleSnapshot(resultDoc);
        return resultDoc;
    });
}

const createWorkarea  = (area) => {
    return firestore.collection("workareas")
        .add({...area});
}

const updateWorkarea = (area) => {
    return firestore.collection("workareas").doc(area.id).update({...area});
}

const deleteWorkarea = (area) => {
    return firestore.collection("workareas").doc(area.id).delete();
}

export { getWorkareas, subscribeToWorkareas, createWorkarea, updateWorkarea, deleteWorkarea }
