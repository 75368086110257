import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { UserContext } from "../../context/userContext";
import { updateIncident } from "../../services/IncidentService";
import { CModalBody, CModalHeader, CModalTitle, CModalFooter } from "@coreui/react";
import { DateUtil } from "../../utilities/DateUtil";
const IncidentClosingInfo = (props) => {
  const { handleSubmit: handleSubmitArchive, reset: resetInfo } = useForm();
  const { user, showModal } = props;
  const { incident } = props;
  const { toggleFullPageLoader } = useContext(UserContext);

  const ArchiveIncident = async (data) => {

    try {
      toggleFullPageLoader(true);
      //Added the original incident as it's own child element on the reformatted incident from the cdatatable - the alternative is fetching the single incident collection with the ID 
      let updatedincident = incident.oincident;
      updatedincident.status = "Archived";
      updatedincident.dateArchived = new Date();
      updatedincident.dateArchivedStr = DateUtil(new Date());
      updatedincident.archivedByUser = {
        fullName: user.fullNameStr,
        userID: user.userID,
        userType: user.userType
      };
      await updateIncident(updatedincident);
      showModal(false);
      resetInfo({});
    } catch (error) {
      console.error(error);
    }
    finally {
      toggleFullPageLoader(false);
    }

    return
  }

  return (
    <>
      <CModalHeader closeButton>
        <CModalTitle>Incident Closing Information</CModalTitle>
      </CModalHeader>
      <form className="" onSubmit={handleSubmitArchive(ArchiveIncident)}>
        <CModalBody>

          <div className="d-flex flex-column">
            <div className="row">
              <div className="col-md-3"><label>Name</label></div>
              <div className="col-12 col-md-9"><label>{incident.closeInfo.name} </label></div>
            </div>
            <div className="row">
              <div className="col-md-3"><label>Date Closed</label></div>
              <div className="col-12 col-md-9"><label>{incident.closeInfo.dateClosedStr}</label></div>
            </div>
            <div className="row">
              <div className="col-md-3"><label>Closed By</label></div>
              <div className="col-12 col-md-9"><label>{incident.closeInfo.closedByUser.fullName}</label></div>
            </div>
            <div className="row">
              <div className="col-md-3"><label>Classification</label></div>
              <div className="col-12 col-md-9"><label>{incident.oincident.closeInfo.classification.join(",")}</label></div>
            </div>
            <div className="row">
              <div className="col-md-3"><label>Investigation Complete</label></div>
              <div className="col-12 col-md-9"><label>{incident.closeInfo.investigationComplete}</label></div>
            </div>
            <div className="row">
              <div className="col-md-3"><label>Severity</label></div>
              <div className="col-12 col-md-9"><label>{incident.closeInfo.severity}</label></div>
            </div>
            <div className="row">
              <div className="col-md-3"><label>Repeat Probability</label></div>
              <div className="col-12 col-md-9"><label>{incident.closeInfo.repeatProbability}</label></div>
            </div>
            <div className="row">
              <div className="col-md-3"><label>Root Cause</label></div>
              <div className="col-12 col-md-9"><label>{incident.closeInfo.rootCause}</label></div>
            </div>
            <div className="row">
              <div className="col-md-3"><label>Action Taken</label></div>
              <div className="col-12 col-md-9"><label>{incident.closeInfo.actionTaken}</label></div>
            </div>

          </div>

        </CModalBody>
        <CModalFooter className="d-flex justify-content-between">
          <button className="btn btn-danger" type="button" data-dismiss='modal' onClick={() => showModal(false)}>Cancel</button>
          <button className="btn btn-warning text-white" type="submit">Archive</button>
        </CModalFooter>

      </form>
    </>
  );
};
/*
 *  Near Miss
2. FAC - First aid case
3. MTC- Medical treatment case
4. LTI - Lost time Injury
5. FAT- Fatality
6. Machine Damage
 * 
 * 
 * */
export default IncidentClosingInfo;
