import { CCard, CCardBody, CCardTitle, CCardText } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import React, { useState, useContext, useEffect } from "react";
import underscore from "underscore";
import Styles from "../Operator.module.scss";
import DashStyles from "./Dashboard.module.scss";
import SubmitLoad from "../SubmitLoad";
import { opsIcons } from '../../../assets/icons';
import { UserContext } from "../../../context/userContext";
import { getUser } from "../../../services/UsersService";
import Vehicle from '../../../assets/images/vehicle.svg';
import { freeSet } from '@coreui/icons'
import {DateUtil} from "../../../utilities/DateUtil";
import TotalDurationTime from "./TotalDurationTime";
import { NOGO_STATUS,GO_STATUS,GO_BUT_STATUS } from "../../../enums/VehicleStatuses";
import { APPROVED,PENDING,REJECTED } from "../../../enums/VehicleChecklistStatuses";
import { OUT_FOR_MAINTENANCE,PARKED } from "../../../enums/VehicleStatuses";

const Dashboard = (props) => {
    const { user } = useContext(UserContext);
    const {changeStart, updateShiftProperty, checklistBarrier, reportBarrier, setModalContent, showModal,vehicleStatuses
        ,vehicleHasCriticalIssues, currentVehicleChecklist, isCurrentUserChecklist, currentVehicleIssues,vehicle} = props;
    const [supervisorDisplayName, setSupervisorDisplayName] = useState('-');

    const getVehicleStatus = (vehicle,currentVehicleChecklist, user) => {
        let details = {goStatus: NOGO_STATUS, vehicleStatusText: "", vehicleIssuesText: "", vehicleAvailableStatus: ""}

        if(vehicle && vehicle.vehicleID)
        {
        }else{
            return details;  
        }

        if(user)
        {
        }else{
            return details;  
        }

        if(vehicle && vehicle.currentStatus === OUT_FOR_MAINTENANCE)
            details.vehicleAvailableStatus = "Out for maintenance";

        if(vehicle && vehicle.currentStatus === PARKED)
            details.vehicleAvailableStatus = "Available";

        if(vehicle && vehicle.currentStatus === "On Shift")
            details.vehicleAvailableStatus = "Available";

        if (vehicleHasCriticalIssues) {
            details.goStatus = NOGO_STATUS;
            details.vehicleIssuesText = "Vehicle has critical issues";
            details.vehicleAvailableStatus = "Not Available";
            return details;    
        }

        if(!vehicleHasCriticalIssues && currentVehicleIssues && currentVehicleIssues.length){
            //classB + classC issues
            details.vehicleIssuesText = "Vehicle has issues";
        } 
        
        if(user.isOnShift && vehicle && vehicle.vehicleID){   

            if(currentVehicleChecklist && currentVehicleChecklist.vehicleID && isCurrentUserChecklist){

                if(currentVehicleChecklist.checkItemStatus === PENDING){
                    details.goStatus = NOGO_STATUS;
                    details.vehicleStatusText = "Waiting for Supervisor to approve checklist";  
                    return details;
                }

                if(currentVehicleChecklist.checkItemStatus === REJECTED){
                    details.goStatus = NOGO_STATUS;
                    details.vehicleStatusText = "Vehicle checklist rejected, please contact supervisor";
                    return details;
                }

                if(currentVehicleChecklist.checkItemStatus === APPROVED)
                {
                    if(currentVehicleIssues && currentVehicleIssues.length){
                        details.goStatus = GO_BUT_STATUS;
                        details.vehicleIssuesText = "Vehicle has issues";
                        return details;
                    }                
                    if(currentVehicleIssues && currentVehicleIssues.length === 0){
                        details.goStatus = GO_STATUS;
                        details.vehicleStatusText = "";
                        return details;
                    }                 
                    details.goStatus = NOGO_STATUS;
                    details.vehicleStatusText = "Please contact supervisor";
                    return details;
                }        
            }            
            if(currentVehicleChecklist && currentVehicleChecklist.vehicleID){
                if(!isCurrentUserChecklist){
                    details.goStatus = NOGO_STATUS;
                    details.vehicleStatusText = "Vehicle is currently in use";
                    details.vehicleAvailableStatus = "Not Available";
                    return details; 
                }
            }
            else{
                details.goStatus = NOGO_STATUS;
                details.vehicleStatusText = "Please complete Checklist"; 
                return details;
            }
        }
        
        if(user.isOnShift && currentVehicleChecklist && currentVehicleChecklist.vehicleID && !isCurrentUserChecklist){
            details.vehicleStatusText = "Vehicle is currently in use";
            details.vehicleAvailableStatus = "Not Available";
        }

        if(!user.isOnShift && vehicle.currentUser && vehicle.currentUser !== '' && vehicle.currentUser !== user.userID){
            details.vehicleAvailableStatus = "Not Available";
        }

        return details; 
    }

    const vehicleIssues = (vehicle, user, currentVehicleChecklist )=>{

        let valueList = [];
        if(currentVehicleIssues && currentVehicleIssues.length){
            let vehicleClassIssues = [];
            currentVehicleIssues.forEach(issue => {
                vehicleClassIssues.push(issue.classType);
            });     
            underscore.each(underscore.countBy(vehicleClassIssues), (count, idx)=>{
                valueList.push( `${idx} (${count})`)
            });
        }

        return (
            <>
            {valueList && valueList.length > 0 &&
                <CCardTitle tag='div' className={DashStyles.vSep}></CCardTitle>
            }
            {valueList && valueList.length > 0 &&
                <CCardTitle tag='div' className='pb-2 pt-2 pl-4 pr-4'>
                    <CCardTitle className={""}>{vehicleHasCriticalIssues ? "Critical Issues" : "Issues"}</CCardTitle>
                    <CCardText tab='div' className="font-weight-bold text-dark" style={{ color: 'black' }} >                    
                        {valueList.join(', ')}                                                        
                    </CCardText>
                </CCardTitle>
            }
            </>
        )     
    }

    const getVehicleAvailability = (vehicle, user, currentVehicleChecklist )=>{

        let valueList = [];
        if(currentVehicleIssues && currentVehicleIssues.length){
            let vehicleClassIssues = [];
            currentVehicleIssues.forEach(issue => {
                vehicleClassIssues.push(issue.classType);
            });     
            underscore.each(underscore.countBy(vehicleClassIssues), (count, idx)=>{
                valueList.push( `${idx} (${count})`)
            });
        }
        let details = getVehicleStatus(vehicle,currentVehicleChecklist, user);
        return (
            <>
                {details.vehicleAvailableStatus}
            </>
        )
    }

    const getVehicleStatusText = (vehicle, user, currentVehicleChecklist )=>{

        let details = getVehicleStatus(vehicle,currentVehicleChecklist, user);

        if(user && !user.isOnShift)
            return "";
        if(currentVehicleIssues && currentVehicleIssues.length){
            return (
                <>
                    <h4 className="float-right">{details.vehicleIssuesText}</h4>
                </>
            )
        }else{
            return (
                <>
                    <h4 className="float-right">{details.vehicleStatusText}</h4>
                </>
            )
        }
    
    }

    const operatorVehicleGoStatus = (vehicle, user, currentVehicleChecklist ) => {

        if(vehicle)
        {}
        else
            return "";

        if(user && user.isOnShift)
        {}
        else
            return "";


        let details = getVehicleStatus(vehicle,currentVehicleChecklist, user);
        if(details.goStatus === GO_BUT_STATUS){
            return (
                <>
                    <CCardTitle className={DashStyles.vehicleStatusText} tag='div'><div className="float-right"><div><div><div className={DashStyles.vehicleGoStatus + " " + DashStyles.go}>Go, but</div></div></div></div></CCardTitle>                    
                </>
            )
        }
        else if(details.goStatus === GO_STATUS)
        {
            return (
                <>
                    <CCardTitle className={DashStyles.vehicleStatusText} tag='div'><div className="float-right"><div><div><div className={DashStyles.vehicleGoStatus + " " + DashStyles.go}>Go</div></div></div></div></CCardTitle>                    
                </>
            )
        }
        else if(details.goStatus === NOGO_STATUS) {
            return (
                <>
                    <CCardTitle className={DashStyles.vehicleStatusText} tag='div'><div className="float-right"><div className="m-0 text-white"><div className=""><div className={DashStyles.vehicleGoStatus + " " + DashStyles.nogo}>No Go</div></div></div></div></CCardTitle>                    
                </>
            )
        }
       return "";
    };

    useEffect(()=>{
        if(currentVehicleChecklist && currentVehicleChecklist.actionedBy){

            getUser(currentVehicleChecklist.actionedBy.userID).then(user => {
                setSupervisorDisplayName( (user && user.fullNameStr) ? user.fullNameStr : vehicle.actionedBy );
            });

        }

    },[currentVehicleChecklist])

    return (
        <>

            <section className={`container-fluid mx-lg-n2 mb-1 pr-0 pl-0`}>
                <div className={'row'}>
                    <div className={'col-12 card-deck ml-0'} >
                        <div className="mb-3" style={{ backgroundColor: "#DEEEFC", borderRadius: '4px' }}>
                            <CCardBody className={""} >
                                <CCardText tag='div' className='d-flex'>
                                    <CCardText tag='div'>
                                        <CCardTitle className="h3">Good Day!</CCardTitle>
                                        <CCardText className='d-flex flex-column'>Welcome back {user.fullNameStr}.<span>{user.isOnShift ? "You are currently On Shift" : "You have not yet started your shift"}</span></CCardText>
                                    </CCardText>
                                    <CCardText tag='div' className=''>
                                        <img src='/clock.jpg' width='100rem' height='100rem' className='ml-2 rounded-circle' alt='' />
                                    </CCardText>
                                </CCardText>
                                <CCardText tag='div'>
                                    <CCardText >
                                    </CCardText>
                                    <CCardText tag='div' style={{ color: '#1C86B4' }}>
                                        <span className='mr-2'><CIcon content={freeSet.cilCalendar} size="lg" /></span>
                                        <span style={{ marginRight: '5px' }}>{DateUtil(new Date())}</span><span style={{ borderRight: '1.5px solid #1C86B4' }}> </span> <span style={{ marginLeft: '5px' }}>{DateUtil(new Date(), {weekday:'long'})}</span>
                                    </CCardText>
                                </CCardText>
                            </CCardBody>
                        </div>

                        <CCard className="card border-secondary mb-3" style={{ flex: '2', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.075)' }}>
                            <CCardBody className={""}>
                                <CCardTitle tag='div' style={{ color: '#1C86B4', fontWeight: '600' }}>
                                    <CIcon content={opsIcons.clockIcon} className="c-icon-xl c-icon mr-1" /> Shift Details
                                </CCardTitle>
                                <CCardTitle tag='div' className='shiftDuration'>

                                    <CCardText tag='div' >
                                        <CCardTitle className={"fs-6"}>Total Duration</CCardTitle>
                                        <TotalDurationTime startShiftTime={user.startShiftTime?.seconds ? user.startShiftTime.toDate() : user.startShiftTime} isOnShift={user.isOnShift} />                                  
                                    </CCardText>
                                    <CCardText className='shift-hr'/>

                                    <CCardText tag='div' className={''} style={{}}>
                                        <CCardTitle className={"fs-6"}>Shift Opening Hour</CCardTitle>
                                        <CCardText tag='div' className='' style={{ fontSize: '1.4rem', fontWeight: '600' }}>
                                            {(user.isOnShift) &&
                                                DateUtil( user.startShiftTime?.seconds ? user.startShiftTime.toDate() : new Date() , {  hour: 'numeric', minute: 'numeric', second:'numeric'})
                                            }
                                        </CCardText>
                                        <CCardText tag='div' className=''>
                                            <button className={`${DashStyles.opd_button_gen} ${user.isOnShift ? DashStyles.endshift : DashStyles.startshift}`} onClick={() => updateShiftProperty()}>{changeStart}</button>
                                        </CCardText>
                                    </CCardText>
                                </CCardTitle>
                            </CCardBody>

                        </CCard>

                    </div>
                    <div className={'col-12 card-deck ml-0'}>
                        <CCard className="card  border-secondary ml-0 mb-3 mr-0" style={{ flex: '2', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.075)' }}>
                            <CCardBody className={"flex-sm-column"}>
                                <CCardTitle tag='div' style={{ display: 'flex', justifyContent: 'space-between',marginBottom: '0px' }}>
                                    <CCardTitle tag='div' style={{ color: '#1C86B4', fontWeight: '600' }}>
                                        <CIcon src={Vehicle} className="c-icon-xl c-icon mr-1" /> Vehicle Details
                                    </CCardTitle>
                                    <CCardTitle tag='div' className="mb-0"> { operatorVehicleGoStatus(vehicle, user,currentVehicleChecklist) }</CCardTitle>
                                </CCardTitle>                             
                                {getVehicleStatusText(vehicle, user,currentVehicleChecklist) }
                                <CCardTitle tag='div' style={{ display: 'flex',marginTop:'2em' }}>
                                    <CCardTitle tag='div' className='pt-2 pr-4 pb-2'>
                                        <CCardTitle className={""}>Assigned Vehicle</CCardTitle>
                                        <CCardText className={DashStyles.lbVehicleStatus}>{vehicle && vehicle.vehicleID ? `${vehicle.vehicleID}-${vehicle.vehicleType}` : "You currently have no assigned Vehicle"}</CCardText>
                                    </CCardTitle>
                                    <CCardTitle tag='div' className={DashStyles.vSep}></CCardTitle>
                                    <CCardTitle tag='div' className='pb-2 pt-2 pl-4 pr-4'>
                                        <CCardTitle className={""}>Vehicle Status</CCardTitle>
                                        <CCardText className={DashStyles.lbVehicleStatus}> {getVehicleAvailability(vehicle, user,currentVehicleChecklist)} </CCardText>
                                    </CCardTitle>                            
                                        {vehicleIssues(vehicle, user,currentVehicleChecklist)}
                                </CCardTitle>
                            </CCardBody>
                        </CCard>

                        <CCard className="card border-secondary mb-3" style={{boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.075)'}}>
                            <CCardBody className={""}>
                                <CCardTitle tag='div' style={{ color: '#1C86B4', fontWeight: '600' }}>
                                    <CIcon content={freeSet.cilPeople} className="c-icon-xl c-icon mr-1" /> Team Details
                                </CCardTitle>
                                <CCardTitle tag='div' className='p-1'>
                                    <CCardTitle>
                                        <CCardTitle>
                                            Name
                                        </CCardTitle>
                                        <b style={{ color: 'black' }}>
                                            {user.currentTeam}
                                        </b>
                                    </CCardTitle>
                                    <hr style={{ border: '1px, solid, gray', width: '1' }} />
                                    <CCardTitle>
                                        <CCardTitle>
                                            Supervisor
                                        </CCardTitle>
                                        <b style={{ color: 'black' }}>
                                            {supervisorDisplayName}
                                        </b>
                                    </CCardTitle>
                                </CCardTitle>
                            </CCardBody>
                        </CCard>
                    </div>
                </div>
            </section>

            <section className="cta-container container-fluid mx-lg-n2">
                <div className="row">
                    <div className={'col-12 card-deck ml-0 pl-0 pr-0'}>

                        <button className={`listbtn , mr-3`} style={{
                            width: '238px', border: 'none',
                            background: '#3A9139',
                            color: 'white',
                            borderRadius: '4px', display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '6px 10px',
                            marginBottom: '10px'
                        }} onClick={() => checklistBarrier()}>
                            <CIcon content={opsIcons.checkList} className="c-icon-2xl mt-3 c-icon mb-3 mr-2" /> <div class="p-1">Accept Checklist</div>
                        </button>
                        <button className={`bg-white , mr-3`} style={{
                            width: '238px', color: '#F2C800', border: '1.4px solid #F2C800',
                            borderRadius: '4px', display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '6px 10px',
                            marginBottom: '10px'
                        }} onClick={() => reportBarrier()}>
                            <CIcon content={opsIcons.exclamationCircle} className="c-icon-2xl c-icon mt-3 mb-3 mr-2" />  <div class="p-1">Report an Issue</div>
                        </button>

                        {/* TODO : the feature to be enabled or disabled via client config */}
                        <CCard className={`d-none text-white bg-primary faux-link ${Styles.cardMed}`} onClick={() => { showModal(true); setModalContent(<SubmitLoad showModal={showModal} />) }}>
                            <CCardBody className={"d-flex justify-content-center align-items-center"}>
                                <CCardTitle className={"text-white h5 text-center"}>Submit a Load</CCardTitle>
                            </CCardBody>
                        </CCard>

                    </div>

                </div>

            </section>

        </>
    )
}

export default Dashboard;
