const defaultSettings = {
    firebase : {
        apiKey: "AIzaSyCeAGCENnCRoXeBgT3hfW2d0sw9UZd35lo",
        authDomain: "shift-reporter-b2437.firebaseapp.com",
        databaseURL: "https://shift-reporter-b2437.firebaseio.com",
        projectId: "shift-reporter-b2437",
        storageBucket: "shift-reporter-b2437.appspot.com",
        messagingSenderId: "739524536450",
        appId: "1:739524536450:web:51c24c95283a8474a8ac9b",
        measurementId: "G-1JRNC88WSF",
    },
    brand : {
        logo : "opscore-logo.png",
        height: 120,
        metaTitle : 'OpsCORE'
    }
};

const exaroInstance = {
    ...defaultSettings,
    brand : {
        logo : "exxaro-logo.svg",
        height: 95,
        class : 'my-4',
        metaTitle : 'Exxaro - powered by OpsCore'
    }
};


const masimbaInstance = {
    ...defaultSettings,
    firebase: {
        apiKey: "AIzaSyC9Wb5txBT7kjs0OuTpv0iOaAiJcu84BbQ",
        authDomain: "masimba-416c5.firebaseapp.com",
        projectId: "masimba-416c5",
        storageBucket: "masimba-416c5.appspot.com",
        messagingSenderId: "388449939910",
        appId: "1:388449939910:web:f60919a5b83fff111f42c7"
    },
    brand : {
        logo : "opscore-logo.png",
        height: 120,
        metaTitle : 'Masimba - powered by OpsCore'
    }
};

const constructoDemoInstance = {
    ...defaultSettings,
    firebase: {
        apiKey: "AIzaSyDl-q1CszyaFRBBnmvjQWqxpG89F4Myx8A",
        authDomain: "constructo-f4b4e.firebaseapp.com",
        projectId: "constructo-f4b4e",
        messagingSenderId: "915102997934",
        storageBucket: "constructo-f4b4e.appspot.com",
        appId: "1:915102997934:web:da342b1b09e02761897c3f",
        measurementId: "G-22CC66ZWJW"
    },
    brand : {
        logo : "constructo-logo.png",
        height: 70,
        class : 'my-4',
        metaTitle : 'Constructo - powered by OpsCore'
    }
};
const baubaDemoInstance = {
    ...defaultSettings,
    firebase: {
        apiKey: "AIzaSyDsPJDIvwv7oDg3uL7nhZBkytJjAVyfWxA",
        authDomain: "bauba-41133.firebaseapp.com",
        projectId: "bauba-41133",
        storageBucket: "bauba-41133.appspot.com",
        messagingSenderId: "448531199149",
        appId: "1:448531199149:web:eb555aa05a79c4a2940224"
    },
    brand : {
        logo : "constructo-logo.png",
        height: 70,
        class : 'my-4',
        metaTitle : 'Bauba - powered by OpsCore'
    }
};

const constructoInstance = {
    ...defaultSettings,
    firebase: {
        apiKey: "AIzaSyBIu4-G0bfxvQoZT2eOAY8koZwq6kVupOc",
        authDomain: "constructo-live.firebaseapp.com",
        projectId: "constructo-live",
        storageBucket: "constructo-live.appspot.com",
        messagingSenderId: "175881201124",
        appId: "1:175881201124:web:123403b8200e22d96113f1",
        measurementId: "G-22CC66ZWJW"
    },
    brand : {
        logo : "constructo-logo.png",
        height: 70,
        class : 'my-4',
        metaTitle : 'Constructo - powered by OpsCore'
    }
};

const opsCoreInstance = {
    ...defaultSettings
};

const fb_client_config = {
    default: {
        ...defaultSettings
    },
    localhost : {
        ...defaultSettings
    },
    'www.opscore.co.za' : {
        ...opsCoreInstance
    },
    'app.opscore.co.za' : {
        ...opsCoreInstance
    },
    'shift-reporter-b2437.web.app' : {
        ...defaultSettings
    },
    'shift-reporter-b2437.firebaseapp.com' : {
        ...defaultSettings
    },
    'shift-reporter-b2437--exxaro-xgkucpqi.web.app' : {
        ...defaultSettings
    },
    'constructo.opscore.co.za' : {
        ...constructoInstance
    },
    'constructo-live.firebaseapp.com' : {
        ...constructoInstance
    },
    'constructo-live.web.app' : {
        ...constructoInstance
    },
    'demo-constructo.opscore.co.za' : {
        ...constructoDemoInstance
    },
    'constructo-f4b4e.web.app' : {
        ...constructoDemoInstance
    },
    'exxaro.opscore.co.za' : {
        ...exaroInstance
    },
    'exxaro.web.app' : {
        ...exaroInstance
    },
    'masimba-416c5.web.app' : {
        ...masimbaInstance
    },
    'masimba.opscore.co.za' : {
        ...masimbaInstance
    },
    'bauba.opscore.co.za' : {
        ...baubaDemoInstance
    },
}

export default fb_client_config;
