import React from "react"
import { Router, Link } from "@reach/router"
import Styles from "./Admin.module.scss";
import ManageUsers from './ManageUsers'
import ManageVehicles from "./ManageVehicles";
import ManageTeams from './ManageTeams'
import ManageSites from './ManageSites'
import ManageParts from './ManageParts'
import ManageChecklists from "./ManageChecklists";
import CIcon from "@coreui/icons-react";
import {cilCarAlt, freeSet} from '@coreui/icons';
import SharedLayout from "../../components/SharedLayout";
import ManageWorkareas from "./ManageWorkareas";
import ManageCustomlists from "./ManageCustomlists";
import TeamsScheduling from "../TeamsScheduling";
import UserTypes from "../../enums/UserTypes";
import Incidents from "../Management/Incidents/Incidents";
import VehicleHistory from "../Supervisor/VehiclesHistory";
import ChecklistHistory from "../Supervisor/VehiclesHistory/ChecklistHistory";
import IssuesHistory from "../Supervisor/VehiclesHistory/IssuesHistory";
import AssignmentHistory from "../Supervisor/VehiclesHistory/AssignmentHistory";
import SideBarStyles from "../../components/SideBarItems/SideBarItems.module.scss";
import AccessControl from "../../components/AccessControl";

const Admin = () => {

    const {ADMIN} = UserTypes;

  const sectionPageTitle = "Administrator";

  const sidebarItems = (
    <>
      <Link to="users">
        <button className={`${Styles.btnLink} pl-0`}><CIcon name={''} content={freeSet.cilUser} className={"mx-2"} /> Users </button>
      </Link>
      <Link to="vehicles">
        <button className={`${Styles.btnLink} pl-0`}><CIcon name={''} content={freeSet.cilTruck} className={"mx-2"} /> Vehicles </button>
      </Link>
      <Link to="teams">
        <button className={`${Styles.btnLink} pl-0`}><CIcon name={''} content={freeSet.cilPeople} className={"mx-2"} /> Teams </button>
      </Link>
      <Link to="sites">
        <button className={`${Styles.btnLink} pl-0`}><CIcon name={''} content={freeSet.cilHome} className={"mx-2"} /> Sites </button>
      </Link>
      <Link to="parts">
        <button className={`${Styles.btnLink} pl-0`}><CIcon name={''} content={freeSet.cilSettings} className={"mx-2"} /> Parts </button>
      </Link>
      <Link to="workareas">
        <button className={`${Styles.btnLink} pl-0`}><CIcon name={''} content={freeSet.cilObjectGroup} className={"mx-2"} /> Workareas </button>
      </Link>
      <Link to="customlists">
        <button className={`${Styles.btnLink} pl-0`}><CIcon name={''} content={freeSet.cilDescription} className={"mx-2"} /> Customlists </button>
      </Link>
      <Link to="checklists">
        <button className={`${Styles.btnLink} pl-0`}><CIcon name={''} content={freeSet.cilTask} className={"mx-2"} /> Checklists </button>
      </Link>
      <Link to="teams-scheduling">
        <button className={`${Styles.btnLink} pl-0`}><CIcon name={''} content={freeSet.cilPuzzle} className={"mx-2"} /> Team Scheduler</button>
      </Link>
        <Link to="vehicleHistory">
            <button className={`${SideBarStyles.btnNavLink} pl-0 btn-ghost btn text-left text-white`}>
                <CIcon name="" content={cilCarAlt} className="mx-2" />
                {'Vehicle History'}
            </button>
        </Link>
    </>
  )

  return (

    <SharedLayout role={ADMIN} sideNav={sidebarItems} pageHeading={sectionPageTitle}>

      <Router>
        <ManageUsers default path="users" />
        <ManageVehicles path="vehicles" />
        <ManageTeams path="teams" />
        <ManageSites path="sites" />
        <ManageParts path="parts" />
        <ManageChecklists path="checklists" />
        <ManageWorkareas path="workareas" />
        <ManageCustomlists path="customlists" />
        <TeamsScheduling path="teams-scheduling" />

        <VehicleHistory path="/vehicleHistory" />
        <ChecklistHistory path="/vehicleHistory/checklistHistory/:vehicleID" />
        <IssuesHistory path="/vehicleHistory/issuesHistory/:vehicleID" />
        <AssignmentHistory path="/vehicleHistory/assignmentHistory/:vehicleID" />
      </Router>

    </SharedLayout>

  )
}

export default Admin;
