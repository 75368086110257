import React, {useState} from 'react'
import ChecklistViewItem from "../ChecklistViewItem";
import {CCard, CCardBody, CCardHeader} from "@coreui/react";
import {deleteChecklist } from "../../../../services/ChecklistsService";
import CIcon from "@coreui/icons-react";
import {freeSet} from "@coreui/icons";

const ChecklistView = ( props ) => {

    const { checkItem, checklistCollection } = props
    const [isDeleteOpen, setDeleteOpen] = useState(false);
    const toggleStyles = isDeleteOpen ? "d-block alert-warning my-2" : "d-none";

    return (

            <CCard>
                <CCardHeader>
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="heading-title">
                            <strong>{ checkItem.id }</strong>
                        </div>

                        <span className="d-inline p-2 faux-link" onClick={ ()=>{ setDeleteOpen(!isDeleteOpen);  } }><CIcon  content={freeSet.cilTrash} /></span>

                    </div>
                    <div className={`${toggleStyles}`}>
                        <div className="d-flex align-items-center">

                            <div className="mx-2 strong px-2 flex-fill"> Are you sure you want to delete the entire vehicle type / checklist set </div>
                            <div className="bg-white">

                                <button className="btn btn-secondary mx-1" onClick={()=>{ setDeleteOpen(!isDeleteOpen); }}> Cancel </button>
                                <button className="btn btn-danger mx-1" onClick={()=>{ deleteChecklist( checkItem.id ); setDeleteOpen(!isDeleteOpen);}}> Confirm </button>

                            </div>

                        </div>
                    </div>

                </CCardHeader>
                <CCardBody>
                    <table className="table table-sm">
                        <thead>
                            <tr>
                                {/*<th className="border-top-0">Vehicle Type</th>*/}
                                <th className="border-top-0">Class</th>
                                <th className="border-top-0">Detail</th>
                                <th className="border-top-0">Responsible</th>
                                <th className="border-top-0 text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>

                        {
                            checkItem.classA && Object.keys( checkItem.classA ).map( (item, key) => { 
                                 return <ChecklistViewItem checklistCollection={checklistCollection} vehicleType={checkItem.id} issueClass={"classA"} item={item} key={key} itemDetails={checkItem.classA[item]} />
                            })

                        }

                        {
                            checkItem.classB && Object.keys( checkItem.classB ).map( (item, key) => {
                                return <ChecklistViewItem checklistCollection={checklistCollection} vehicleType={checkItem.id} issueClass={"classB"} item={item} key={key} itemDetails={checkItem.classB[item]} />
                            })

                        }

                        {
                            checkItem.classC && Object.keys( checkItem.classC ).map( (item, key) => {
                                return <ChecklistViewItem checklistCollection={checklistCollection} vehicleType={checkItem.id} issueClass={"classC"} item={item} key={key} itemDetails={checkItem.classC[item]} />
                            })

                        }
                        </tbody>
                    </table>
                </CCardBody>

            </CCard>


    )
}
export default ChecklistView
