import React from "react";
import { Router } from "@reach/router";
import MaintenanceList from "./MaintenanceList";
import SharedLayout from "../../components/SharedLayout";
import ManageVehicles from '../Admin/ManageVehicles';
import SideBarItems from '../../components/SideBarItems';
import UserTypes from '../../enums/UserTypes';


const {MAINTENANCE} = UserTypes;
const sidebarItems = <SideBarItems role={MAINTENANCE} />;

const sectionPageTitle = "Maintenance";


const Maintenance = () => {
  return (
    <div>
      <SharedLayout role={MAINTENANCE} sideNav={sidebarItems} pageHeading={sectionPageTitle}>
          <Router>
            <MaintenanceList default path="dashboard" />
            <ManageVehicles path="vehicles" />
          </Router>
      </SharedLayout>
    </div>
  )
}

export default Maintenance;
