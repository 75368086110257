import React, { useState, useEffect } from "react";
import { CCardTitle, CCardText } from "@coreui/react";


const TotalDurationTime = (props) => {

    const [totalDuration, setTotalDuration] = useState({days: "--", hours: "--", minutes: "--", seconds: "--"});
    const { startShiftTime, isOnShift } = props;

    useEffect(() => {
        
        const timer = setTimeout(() => {
            if(isOnShift){
                setTotalDuration(calculateTotalDuration());
            }else{
                setTotalDuration({days: "--", hours: "--", minutes: "--", seconds: "--"});
            }
        }, 1000);
      
        return () => clearTimeout(timer);
        
      });

      const calculateTotalDuration = () => {
        
        let totalDuration = {
            days: "00",
            hours: "00",
            minutes: "00",
            seconds: "00"
          };

        if(isOnShift){
            let difference = +new Date() - +startShiftTime;           
            if (difference > 0) {
                let Days = Math.floor(difference / (1000 * 60 * 60 * 24));
                let Hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
                let Minutes = Math.floor((difference / 1000 / 60) % 60);
                let Seconds = Math.floor((difference / 1000) % 60);
                totalDuration = {
                  days: Days < 10 ? "0"+Days : Days,
                  hours: Hours < 10 ? "0"+Hours : Hours,
                  minutes: Minutes < 10 ? "0"+Minutes : Minutes,
                  seconds: Seconds < 10 ? "0"+Seconds : Seconds
                };
              }
        }else{
            totalDuration = {
                days: "--",
                hours: "--",
                minutes: "--",
                seconds: "--"
              };
        }
        return totalDuration;
    };

    return (
        <>
        <CCardTitle tag='div' style={{ fontSize: '1rem', display: 'flex' }}>
                <div>
                    <button type="button" className="btn fs-4" style={{ background: '#F7F7FB', width: '70px', height: '72.05px', borderRadius: '10px', fontSize: '1.4rem', fontWeight: '600' }}>
                    {(totalDuration) && totalDuration.hours} </button> <b className='mr-2 ml-1'>:</b>
                    <CCardText style={{ marginLeft: '1rem' }}>Hours</CCardText>
                </div>
                <div>
                    <button type="button" className="btn " style={{ background: '#F7F7FB', width: '70px', height: '72.05px', borderRadius: '10px', fontSize: '1.4rem', fontWeight: '600' }}>
                     {(totalDuration) && totalDuration.minutes}</button> <b className='mr-2 ml-1'>:</b>
                    <CCardText style={{ marginLeft: '1rem' }}>Minutes</CCardText>
                </div>
                <div>
                     <button type="button" className="btn " style={{ background: '#F7F7FB', width: '70px', height: '72.05px', borderRadius: '10px', fontSize: '1.4rem', fontWeight: '600' }}>
                     {(totalDuration) && totalDuration.seconds}</button>
                    <CCardText style={{ marginLeft: '1rem' }}>Second</CCardText>
                </div>
        </CCardTitle>
    </>
    )
};

export default TotalDurationTime;
