'use strict';

module.exports = Object.freeze({
  //status
  OPEN : 'Open',
  CLOSED : 'Closed',
  ACTIVE : 'Active',

  //checkItemStatus
  PENDING: 'Pending',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
});
