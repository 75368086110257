import {navigate} from "@reach/router";
import React, {useEffect, useState, useContext} from "react";
import {UserContext} from "../../../context/userContext";
import Styles from "./Confirmation.module.scss";
import toastService from "../../../services/toastService";
import {DateUtil} from "../../../utilities/DateUtil";
import {updateVehicle} from "../../../services/VehiclesService";
import {createNewsItem} from "../../../services/newsItemsService";
import {createChecklistJobs} from "../../../services/MaintenanceJobsService";
import {createVehicleChecklist} from "../../../services/VehiclesChecklistService";
import { NOGO_STATUS, NOT_AVAILABLE } from "../../../enums/VehicleStatuses";
import { PENDING, OPEN,CLOSED,APPROVED } from "../../../enums/VehicleChecklistStatuses";

const Confirmation = (props) => {
  const { vehicle, backHandler, failedElements, user, extraTextFields,checklistData } = props;
  const [issuesArr, setIssuesArr] = useState([]);
  const [passedCheckItemsArr, setPassedCheckItems] = useState([]);
  const [checklistDeclared, setChecklistDeclaration] = useState(false);
  const { toggleFullPageLoader } = useContext(UserContext);

  const getFailedElementJsx = (classType) => {

    const failedItemList = Object.keys(failedElements[classType]).sort();
    return failedItemList.map(elem => {
      if (failedElements[classType][elem].issue) {
        return (
          <li key={failedElements[classType][elem].issue} className={Styles.errorItem}>
            <p>{failedElements[classType][elem].classType}</p>
            <p>{failedElements[classType][elem].issue}</p>
            <p>{failedElements[classType][elem].vehicleID}</p>
            <p>{failedElements[classType][elem].supervisor}</p>
            <p>{failedElements[classType][elem].operator}</p>
          </li>
        )
      }
      return <></>
    });
  }

  const getMessageElementJsx = (classType) => {

    const message = Object.keys(extraTextFields[classType]).sort();

    return message.map(elem => {

      if (extraTextFields[classType][elem]) {
        return (
          <li key={elem} className={`d-flex align-items-center ${Styles.msgItem}`}>
            <p className="col p-0 m-0">{classType}</p>
            <p className="col p-0 m-0">{elem}</p>
            <p className="col p-0 m-0">{extraTextFields[classType][elem]}</p>
          </li>
        )
      }
      return <></>
    });

  } 

  const submitHandler = async () => {
        try {

            toggleFullPageLoader(true);

            if(user && user.userID){

            const prepareVehicleData ={
              goStatus: NOGO_STATUS,
              status: NOT_AVAILABLE
            }

            const prepareVehicleChecklist = {
              goStatus: NOGO_STATUS,
              status: OPEN,
              checkItemStatus: PENDING,
              checkItems: issuesArr,
              checkItemAdditionalDetails: {...extraTextFields},
              usershiftId: user.currentSID,
              vehicleID: vehicle.vehicleID,
              dateCreated: new Date(),
              dateCreatedStr: DateUtil(new Date()),
              currentTeam: vehicle.currentTeam,
              vehicleType: vehicle.vehicleType,
              passedCheckItems: passedCheckItemsArr,
              addedByDriver: {
                    fullName: user.fullNameStr,
                    userID: user.userID,
                    userType: user.userType
                }
            }

            if(user.userType && user.userType === "supervisor"){

              prepareVehicleChecklist.checkItemStatus = APPROVED;
              prepareVehicleChecklist.status = CLOSED;
              prepareVehicleChecklist.actionedBy = {
                fullName: user.fullNameStr,
                userID: user.userID,
                userType: user.userType,
                dateActioned: new Date(),
                dateActionedStr: DateUtil(new Date())
              }   
              const vehicleChecklistID = await createVehicleChecklist( prepareVehicleChecklist );

              await createChecklistJobs(prepareVehicleChecklist);
              

            }else{

              const vehicleChecklistID = await createVehicleChecklist( prepareVehicleChecklist );
 
              await updateVehicle( {...vehicle,  ...prepareVehicleData} );
            
              await createNewsItem({
                dateCreated: new Date(),
                dateCreatedStr: DateUtil( new Date() ),
                title: `Vehicle Checklist Complete`,
                message: `${vehicle.vehicleID} - checklist complete`,
                team: vehicle.currentTeam,
                type: "vehicleCheckListComplete",
                info: {
                    driver: user.userID,
                    vehicle: `${vehicle.vehicleType}-${vehicle.vehicleID}`,
                    vehicleID: vehicle.vehicleID,
                    status: NOGO_STATUS,
                    driverShiftStart: user.startShiftTime || new Date(),
                    driverShiftEnd : user.endShiftTime || new Date(),
                    userShiftId : user.currentSID,
                    checklistId : vehicleChecklistID,
                },
                seenBy: [],
                isImportant: false,
              });

            }

          toastService("Thank you, this has been submitted for approval", 5000);

          setTimeout(() => {
              toggleFullPageLoader(false);
              navigate(`/${user.userType}`);
          }, 5000)

          }else{
            toggleFullPageLoader(false);
            toastService("Something went wrong while submitting checklist, please try again.", 5000);
          }
        } catch (error) {
            toggleFullPageLoader(false);
            toastService("Something went wrong while submitting checklist, please try again.", 5000);
        }
    };

  const updatedDeclaration = (e) => {
    const checked = e.target.checked;
    setChecklistDeclaration(checked);
  };


  useEffect(() => {
    const issues = [];
    Object.keys(failedElements)
      .sort()
      .forEach(function (classType) {
        Object.keys(failedElements[classType])
        .sort()
        .forEach(function (elem) {
          if (failedElements[classType][elem]["issue"]) {
          issues.push(failedElements[classType][elem]);
         }
        });
    });

    const passedCheckItems = [];
    
    if(checklistData){

    Object.keys(checklistData.classA).forEach( (item, key) => {
      let mappedItem = {classType: "classA", issue: item};
      passedCheckItems.push(mappedItem);
    })

    Object.keys(checklistData.classB).forEach( (item, key) => {
      let mappedItem = {classType: "classB", issue: item};
      passedCheckItems.push(mappedItem);
    })

    Object.keys(checklistData.classB).forEach( (item, key) => {
      let mappedItem = {classType: "classC", issue: item};
      passedCheckItems.push(mappedItem);
    })
  }

  setPassedCheckItems(passedCheckItems);
    setIssuesArr(issues);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <article className={Styles.errorListTable}>
      <ul className={Styles.errorList}>
        <li className={Styles.columnTitles}>
          <h4>Class of issue</h4>
          <h4>Issue</h4>
          <h4>Vehicle id</h4>
          <h4>Supervisor</h4>
          <h4>Operator</h4>
        </li>
        {Object.keys(failedElements).map(getFailedElementJsx)}
      </ul>

      <div className="container p-0 mx-0">
        <div className="card-header  px-0 pb-md-2 mb-md-2"> Additional Details</div>
        <div className="card-body p-0">
          <ul className="d-flex flex-column">
            <li className="d-flex align-items-center">
              <h4 className="col p-0 m-0">Class of issue</h4>
              <h4 className="col p-0 m-0">Item</h4>
              <h4 className="col p-0 m-0">Message</h4>
            </li>
            {Object.keys(extraTextFields).map(getMessageElementJsx)}
          </ul>
        </div>
      </div>

      <div className={Styles.declaration}>
        <input type="checkbox" id="checklist-declaration" name="checklist-declaration" onChange={updatedDeclaration} />
        <label htmlFor="checklist-declaration">I hereby declare that the check sheet is true and correct to the best of my knowledge.</label>
      </div>
      <div className={Styles.navigation}>
        <button onClick={backHandler} className={Styles.btnSecondary}>Back</button>
        <button onClick={submitHandler} className={Styles.btnPrimary} disabled={!checklistDeclared}>Confirm checklist</button>
      </div>

    </article>
  )
}

export default Confirmation;