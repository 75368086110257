import React, { useState } from 'react';
import { Badge } from 'antd';
import EditVehicleForm from '../EditVehicleForm';
import useModal from '../../../../components/Modal/useModal';
import { deleteVehicle } from '../../../../services/VehiclesService';
import { CModal } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { freeSet } from "@coreui/icons";


const VehicleItem = (props) => {
  const { userType } = props
  const { isShowing, toggle } = useModal();
  const vehicle = props.vehicle;
  const [isOpen, setIsOpen] = useState(false);
  const toggleStyles = isOpen ? "d-block" : "d-none";
  const toggleParentClass = isOpen ? "d-none" : "d-block";

  return (
    <tr key={vehicle.vehicleID} className="">
      <td>{vehicle.vehicleID}</td>
      <td>{vehicle.vehicleType}</td>
      <td>{
        vehicle.vehicleStatus === 'available'
          ? (<><Badge status="processing" color="green" />Available</>)
          : (<><Badge status="error" />Not Available</>)}
      </td>
      <td>{vehicle.currentStatus}</td>
      <td>{vehicle.currentTeam}</td>
      <td>{vehicle.user ? vehicle.user.fullNameStr : vehicle.currentUser}</td>

      {userType !== "supervisor"
        ? (<td width={100}>
          <div className="position-relative">
            <div className={`${toggleParentClass}`}>
              <span className="d-inline p-2" onClick={toggle}><CIcon content={freeSet.cilPencil} /></span>
              {['maintenance'].includes(userType) ? <></> : <span className="d-inline p-2" onClick={() => setIsOpen(!isOpen)}><CIcon content={freeSet.cilTrash} /></span>}
            </div>
            <div className={`${toggleStyles}`}>
              <button
                className="btn btn-secondary mx-2 my-1"
                onClick={() => setIsOpen(!isOpen)}
              >Cancel
              </button>
              <button
                className="btn btn-primary mx-2 my-1"
                onClick={() => {
                  deleteVehicle(vehicle);
                  setIsOpen(!isOpen);
                }}
              >Confirm
              </button>
            </div>
            <CModal show={isShowing} size={'lg'} >
              {<EditVehicleForm vehicle={vehicle} hide={toggle} userType={userType} />}
            </CModal>
          </div>
        </td>)
        : null
      }

    </tr>
  )
}

export default VehicleItem;
