import React from 'react';
import { Link } from '@reach/router';
import { opsIcons } from '../../assets/icons';
import { CBadge } from '@coreui/react';
import { cilGraph, freeSet, cilCarAlt  } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import SideBarStyles from './SideBarItems.module.scss';
import AccessControl from '../AccessControl';
import UserTypes from '../../enums/UserTypes';
export default ({
  role = 'anonymous',
  user = {},
  numChecklistApproval = 0,
  numReportedIsssuesApproval = 0,
  numMaintenanceIssues = 0,
  numIncidents = 0,
  handleShiftState,
  handleAcceptChecklist,
  handleReportAnIssue,
  handleReassignVehicles,
  handleCheckOutVehicle,
  handleSubmitLoad
}) => {

  const { MAINTENANCE, MANAGEMENT, SUPERVISOR, OPERATOR, ADMIN } = UserTypes;

  return (
    <>
      {/* TODO: both "Dashboard" and "Reports" go to same navigation link */}
      <AccessControl allowed={[MANAGEMENT]} role={role}>
        <Link to={"management"}>
          <button className={`${SideBarStyles.btnNavLink} pl-0 btn-ghost btn text-left text-white`}>
            <CIcon name={''} content={freeSet.cilSpeedometer} className={"mx-2"} />
            {'Dashboard'}
          </button>
        </Link>
      </AccessControl>

      <AccessControl allowed={[OPERATOR]} role={role}>
        <button className={`${SideBarStyles.btnNavLink} pl-0 btn-ghost btn text-left text-white`} onClick={handleShiftState}>
          <CIcon name={''} content={freeSet.cilPuzzle} className="mx-2" />
          {user.isOnShift ? 'End shift' : 'Start shift'}
        </button>
      </AccessControl>

      <AccessControl allowed={[MANAGEMENT, SUPERVISOR]} role={role}>
        <Link to={role.toLowerCase() !== MANAGEMENT ? `/${user.userType}` : 'newsfeed'}>
          <button className={`${SideBarStyles.btnNavLink} pl-0 btn-ghost btn text-left text-white`}>
            <CIcon name={''} content={freeSet.cilSpeedometer} className={'mx-2'} />
            {'Shift Information'}
          </button>
        </Link>
      </AccessControl>

      <AccessControl allowed={[OPERATOR]} role={role}>
        <Link to={`/${user.userType}`}>
          <div className={`${SideBarStyles.btnNavLink} pl-0 btn-ghost btn text-left text-white`}>
            <CIcon name={''} content={freeSet.cilSpeedometer} className={'mx-2'} />
            {'Dashboard'}
          </div>
        </Link>
      </AccessControl>

      <AccessControl allowed={[OPERATOR]} role={role}>
        <button className={`${SideBarStyles.btnNavLink} pl-0 btn-ghost btn text-left text-white`} onClick={handleAcceptChecklist}>
          <CIcon name={''} content={freeSet.cilPuzzle} className={'mx-2'} />
          {'Accept Checklist'}
        </button>
      </AccessControl>

      <AccessControl allowed={[SUPERVISOR]} role={role}>
        <Link to={'checklist-review'}>
          <button className={`${SideBarStyles.btnNavLink} pl-0 btn-ghost btn text-left text-white col-12`}>
            <CIcon name={''} content={freeSet.cilTask} className="mx-2" />
            {'Checklists'}
            {numChecklistApproval > 0 && (
              <CBadge className={`badge-warning badge-pill float-right`}>
                {numChecklistApproval}
              </CBadge>
            )}
          </button>
        </Link>
      </AccessControl>

      <AccessControl allowed={[OPERATOR]} role={role}>
        <button className={`${SideBarStyles.btnNavLink} pl-0 btn-ghost btn text-left text-white`} onClick={handleReportAnIssue}>
          <CIcon name={''} content={opsIcons.truckicon} className={'mx-2'} />
          {'Report an Issue'}
        </button>
      </AccessControl>

      <AccessControl allowed={[SUPERVISOR]} role={role}>
        <Link to="reported-issues">
          <button className={`${SideBarStyles.btnNavLink} pl-0 btn-ghost btn text-left text-white col-12`}>
            <CIcon name="" content={opsIcons.truckicon} className="mx-2" />
            {'Reported Issues'}
            <CBadge className={`badge-warning badge-pill float-right`}>
              {numReportedIsssuesApproval}
            </CBadge>
          </button>
        </Link>
      </AccessControl>

      <AccessControl allowed={[SUPERVISOR]} role={role}>
        <Link to="sign-off-maintenance">
          <button className={`${SideBarStyles.btnNavLink} pl-0 btn-ghost btn text-left text-white position-relative`}>
            <CIcon name={''} content={opsIcons.maintananceApproveIcon} className="mx-2" />
            {'Approve Maintenance'}
            {numMaintenanceIssues > 0 && (
              <CBadge className="badge-danger badge-pill position-absolute mx-2 my-1 ">
                {numMaintenanceIssues}
              </CBadge>
            )}
          </button>
        </Link>
      </AccessControl>

      <AccessControl allowed={[SUPERVISOR]} role={role}>
        <button className={`${SideBarStyles.btnNavLink} pl-0 btn-ghost btn text-left text-white`} onClick={handleReassignVehicles}>
          <CIcon name={''} content={opsIcons.supervisorReassignVehicle} className="mx-2" />
          {'Reassign Vehicles'}
        </button>
      </AccessControl>

      <AccessControl allowed={[OPERATOR]} role={role}>
        {/* TODO: this feature to be enabled or disabled via client config */}
        <button className={`${SideBarStyles.btnNavLink} d-none pl-0 btn-ghost btn text-left text-white`} onClick={handleSubmitLoad}>
          <CIcon name={''} content={freeSet.cilPuzzle} className={"mx-2"} />
          {'Submit Load'}
        </button>
      </AccessControl>

      <AccessControl allowed={[SUPERVISOR]} role={role}>
        <Link to="vehicle-engine-hours">
          <button className={`${SideBarStyles.btnNavLink} pl-0 btn-ghost btn text-left text-white`}>
            <CIcon name="" content={cilGraph} className="mx-2" />
            {'Vehicle Engine Hours'}
          </button>
        </Link>
      </AccessControl>

      <AccessControl allowed={[SUPERVISOR]} role={role}>
        <Link to="handover-report">
          <button className={`${SideBarStyles.btnNavLink} pl-0 btn-ghost btn text-left text-white`}>
            <CIcon name={''} content={opsIcons.supervisorHandover} className="mx-2" />
            {'Handover Report'}
          </button>
        </Link>
      </AccessControl>

        <AccessControl allowed={[MAINTENANCE]} role={role}>
        <Link to="dashboard">
          <button className={`${SideBarStyles.btnNavLink} pl-0 btn-ghost btn text-left text-white`}>
            <CIcon name="" content={freeSet.cilSpeedometer} className="mx-2" />
            {'Dashboard'}
          </button>
        </Link>

      </AccessControl>

      <AccessControl allowed={[MAINTENANCE]} role={role}>
        <Link to="vehicles">
          <button className={`${SideBarStyles.btnNavLink} pl-0 btn-ghost btn text-left text-white`}>
            <CIcon name="" content={cilCarAlt} className="mx-2" />
            {'Vehicles'}
          </button>
        </Link>
      </AccessControl>

      <AccessControl allowed={[MANAGEMENT, SUPERVISOR]} role={role}>
        <Link to={'incidents'}>
          <button className={`${SideBarStyles.btnNavLink} pl-0 btn-ghost btn text-left text-white col-12`}>
            <CIcon name={''} content={freeSet.cilPuzzle} className="mx-2" />
            {'Incidents'}
            {numIncidents > 0 && (
              <CBadge className={`badge-danger badge-pill float-right`}>{numIncidents}</CBadge>
            )}
          </button>
        </Link>
      </AccessControl>


      <AccessControl allowed={[ADMIN, MANAGEMENT, SUPERVISOR]} role={role}>
        <Link to="vehicleHistory">
          <button className={`${SideBarStyles.btnNavLink} pl-0 btn-ghost btn text-left text-white`}>
            <CIcon name="" content={cilCarAlt} className="mx-2" />
            {'Vehicle History'}
          </button>
        </Link>
      </AccessControl>
    </>
  );
};
