import React, {Fragment, useEffect, useRef,useState} from "react";
import Styles from "./InspectionHistory.module.scss";
import {Card, Table, Divider, Descriptions, PageHeader} from 'antd'
import moment from "moment";
import {useReactToPrint} from "react-to-print";

const InspectionHistory = (props) => {

    const {vehicleCheck, onClose, isPrint} = props
    const [unifiedCheckItems, setUnifiedCheckItems] = useState([]);
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    useEffect(() => {
        let list = [];
   
        vehicleCheck.checkItems.forEach(checkItem => {
            list.push({className: checkItem.classType, issue: checkItem.issue, status: 'Fail'});
        });

        vehicleCheck.passedCheckItems.forEach(checkItem => {
            list.push({className: checkItem.classType, issue: checkItem.issue, status: 'Pass'});
        });
        setUnifiedCheckItems(list);
    
    }, [])

    useEffect(() => {
        if (isPrint) {
            handlePrint()
        }
    }, [unifiedCheckItems])

    useEffect(() => {
      
    }, [isPrint])

    const itemCheckListField = [
        {
            key: 'className',
            dataIndex: 'className',
            title: 'Class Name',
        },
        {
            key: 'issue',
            dataIndex: 'issue',
            title: 'Issue',
        },
        {
            key: 'status',
            dataIndex: 'status',
            title: 'Pass/Fail',
            render: item => (<span style={{color: (item === 'Pass' ? 'green' : 'red')}}>{item}</span>)
        },
    ]

    const itemCheckListReformat = (checkItem) => {
            return ({
                className: checkItem.className,
                issue: checkItem.issue,
                status: checkItem.status
            })
    }

    const duration = (vehicleCheck) => {
        if (vehicleCheck.actionedBy) {
            const du = moment.duration(vehicleCheck.actionedBy.dateActioned.seconds - vehicleCheck.dateCreated, 'seconds')
            return <span>{`${du.get('hours')}h:${du.get("minutes")}m:${du.get("seconds")}s`}</span>
        } else {
            return <span>{'-'}</span>
        }
    }

    return (
        <Fragment>
            <div ref={componentRef}>
                <Card bodyStyle={{padding: 0}}>
                    <PageHeader
                        onBack={onClose}
                        title={'Inspection History'}
                    />
                    <Divider className={Styles.headerDivider}/>
                    <div className={Styles.vehicleInfo}>
                        <Descriptions title={`Submission #${vehicleCheck.id}`} column={1}>
                            <Descriptions.Item
                                label="Inspection From">{vehicleCheck.addedByDriver?.fullName}</Descriptions.Item>
                            <Descriptions.Item label="Started From">{vehicleCheck.dateCreatedStr}</Descriptions.Item>
                            <Descriptions.Item
                                label="Submitted On">{vehicleCheck.actionedBy?.dateActionedStr}</Descriptions.Item>
                            <Descriptions.Item label="Duration">{duration(vehicleCheck)}</Descriptions.Item>
                            <Descriptions.Item
                                label="Approved By">{vehicleCheck.actionedBy?.fullName}</Descriptions.Item>
                            <Descriptions.Item
                                label="Submitted By">{vehicleCheck.addedByDriver?.fullName}</Descriptions.Item>
                        </Descriptions>
                    </div>
                </Card>
                <Card title={'Item Checklist'} bodyStyle={{padding: 0}}>
                    <Table
                        columns={itemCheckListField}
                        dataSource={unifiedCheckItems && unifiedCheckItems.map(itemCheckListReformat)}
                        pagination={{size: 'small', defaultPageSize: 30}}
                        scroll={{x: true}}
                    />
                </Card>
            </div>
        </Fragment>
    );
};

export default InspectionHistory;
