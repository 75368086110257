import fb_client_config from "./client_instance_config";
import firebase from "firebase/app";
import "firebase/firestore";
import 'firebase/auth';
import "firebase/functions";
import "firebase/analytics";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = ( fb_client_config[document.domain] && fb_client_config[document.domain].firebase ) ? fb_client_config[document.domain].firebase :  fb_client_config.default.firebase;

firebase.initializeApp(firebaseConfig);
const firebaseAuth = firebase.initializeApp( firebaseConfig, 'AuthService');

// const analytics = firebase.analytics();
const firestore = firebase.firestore();
firestore.settings({
  ignoreUndefinedProperties: true
});

export {firestore, firebaseAuth }

export default firebase
