export const checklistIcon = [
    `512 512`,
    `<title>checklist icon</title>
    <path d="m32 16h352c17.671875 0 32 14.328125 32 32v432c0 17.671875-14.328125 32-32 32h-352c-17.671875 0-32-14.328125-32-32v-432c0-17.671875 14.328125-32 32-32zm0 0"
          fill="#ecce93"/>
    <path d="m32 374.625v-326.625h352v432h-246.625zm0 0" fill="#efefef"/>
    <path d="m137.375 374.625v105.375l-105.375-105.375zm0 0" fill="#e76e54"/>
    <path d="m128 64v-64h160v64c0 17.671875-14.328125 32-32 32h-96c-17.671875 0-32-14.328125-32-32zm0 0"
          fill="#e76e54"/>
    <path d="m512 368c0 79.527344-64.472656 144-144 144s-144-64.472656-144-144 64.472656-144 144-144 144 64.472656 144 144zm0 0" class="checkIconColor" />
    <path d="m335.007812 429.648438-31.007812-31.023438c-6.246094-6.25-6.246094-16.378906 0-22.625s16.375-6.246094 22.625 0l10.367188 10.351562 72.464843-60.398437c6.789063-5.664063 16.886719-4.746094 22.542969 2.046875 5.664062 6.789062 4.746094 16.886719-2.046875 22.542969zm0 0"
          fill="#fff"/>
    <g fill="#000">
        <path d="m176 128h128c8.835938 0 16 7.164062 16 16s-7.164062 16-16 16h-128c-8.835938 0-16-7.164062-16-16s7.164062-16 16-16zm0 0"/>
        <path d="m96 128h32v32h-32zm0 0"/>
        <path d="m96 192h32v32h-32zm0 0"/>
        <path d="m96 256h32v32h-32zm0 0"/>
        <path d="m96 320h32v32h-32zm0 0"/>
        <path d="m176 192h96c8.835938 0 16 7.164062 16 16s-7.164062 16-16 16h-96c-8.835938 0-16-7.164062-16-16s7.164062-16 16-16zm0 0"/>
        <path d="m176 256h32c8.835938 0 16 7.164062 16 16s-7.164062 16-16 16h-32c-8.835938 0-16-7.164062-16-16s7.164062-16 16-16zm0 0"/>
    </g>`
]