import { firestore } from "./../firebase";

const allowedTypes = [
    "vehicleCheckListComplete",
    "maintenanceSuperviorSignoff",
    "maintenanceComplete",
    "supervisorReport",
    "vehicleAssigned",
    "vehicleIssueReported",
    "vehicleClosingHourReported",
    "vehicleOpeningHourReported",
    "vehicleChecklistApproved",
    "vehicleChecklistRejected"
];

// used for filtering results
const daysOffset = 5;

const getNewsItems  = (team) => {
    // console.log( 'get news items team',  team )

    let filterDate = new Date();
    filterDate.setDate( filterDate.getDate()  - daysOffset );

    if(team === "All"){
        return firestore.collection("newsItems")
          .where("type", "in", allowedTypes )
          .where("dateCreated", ">=", filterDate )
          .orderBy("dateCreated", 'desc' )
          .get()
          .then(response => response.docs.map(document => {

                  let responseDoc = document.data();
                  responseDoc.id = document.id;
                  return responseDoc;
              }

          ));

    }else{
        return firestore.collection("newsItems")
          .where("team","==", team)
          .where("type", "in", allowedTypes )
          .where("dateCreated", ">=", filterDate )
          .orderBy("dateCreated" , 'desc')
          .get()
          .then(response => response.docs.map(document =>
              {
                  let responseDoc = document.data();
                  responseDoc.id = document.id;
                  return responseDoc;
              }
          ));
    }
}

const subscribeToNewsItems = (setState,team) => {

  let filterDate = new Date();
  filterDate.setDate( filterDate.getDate()  - daysOffset );

    if(team === "All"){

      return firestore.collection("newsItems")
        .where("dateCreated", ">=", filterDate )
        .where("type", "in", allowedTypes )
        .orderBy("dateCreated",'desc')
        .onSnapshot(snapshot => setState(snapshot.docs.map(document =>
        {
            let responseDoc = document.data();
            responseDoc.id = document.id;
            return responseDoc;
        }
        )))

    }else{

      return firestore.collection("newsItems")
        .where("team","==", team)
        .where("type", "in", allowedTypes )
        .where("dateCreated", ">=", filterDate )
        .orderBy("dateCreated" , 'desc')
        .onSnapshot(snapshot => setState(snapshot.docs.map(document => {
            let responseDoc = document.data();
            responseDoc.id = document.id;
            return responseDoc;
        })));

    }

}

const createNewsItem  = (newsItem) => {
  //  console.log('createNewsItem', newsItem );
    return firestore.collection("newsItems").add({...newsItem}).catch(err => console.error('createNewsItem', err));
}

const updateOrhpanedNewsItems = ( vehicleId, newsItem )=>{
  
    const { type, status }  = newsItem;

   // console.log('orphaned items', newsItem, {vehicleId, type, status } );

     return firestore
        .collection("newsItems")
        .where("status", "==", 'Pending')
        .where("info.vehicleID", "==", vehicleId )
        .where("type", "==", type )
        .get()
        .then(async res => {

          res.forEach(newsItem => {
            return firestore
                .collection("newsItems")
                .doc(newsItem.id)
                .set({status:status}, {merge: true})
          })

        })

}

const updateNewsItem  = ( newsItem ) => {
   return firestore.collection("newsItems").doc(newsItem.id).update({...newsItem});
}

const getIncidents = () => {
    return firestore.collection("newsItems")
        .where("type", "==", 'supervisorIncident' )
        .orderBy("dateCreated", 'desc' )
        .get()
        .then(response => response.docs.map(document => {
                let responseDoc = document.data();
                responseDoc.id = document.id;
                return responseDoc;
            }
        ));
}

const subscribeToIncidents = (setState) => {
  return firestore.collection("newsItems")
    .where("type", "==", 'supervisorIncident' )
    .orderBy("dateCreated", 'desc' )
    .onSnapshot(snapshot => {
      setState(snapshot.docs.map(document => {
        let responseDoc = document.data();
        responseDoc.id = document.id;
        return responseDoc;
      }))
    });
};

const subscribeToVehicleIssues = (setState) => {
  return firestore.collection("newsItems")
    .where("type", "==", 'vehicleIssueReported')
    .orderBy("dateCreated", 'desc' )
    .onSnapshot(snapshot => {
      setState(snapshot.docs.map(document => {
        let responseDoc = document.data();
        responseDoc.id = document.id;
        return responseDoc;
      }))
    });
};


const getNewsItemsByVehicleId = ( vehicleId , status)=>{

    // console.log('orphaned items', newsItem, {vehicleId, type, status } );

    return firestore
        .collection("newsItems")
        .where("info.vehicleID", "==", vehicleId )
        .where("status", "==", status )
        .get()
        .then(response => response.docs.map(document => {
                let responseDoc = document.data();
                responseDoc.id = document.id;
                return responseDoc;
            }
        ));

}

export { getNewsItems, updateOrhpanedNewsItems, subscribeToNewsItems, createNewsItem, updateNewsItem, getIncidents, subscribeToIncidents, subscribeToVehicleIssues , getNewsItemsByVehicleId};


//News items should conform to this:
// }
//     title: null,                  //
//     message: null,                //
//     team: "Managment",            //
//     type:"Supervisor Report",     //
//     isImportant: true,            //
//     seenBy: [],                   //Array of users who have viewed this
//     info: {}                      //additional info based on type. eg report numbers, vehicles
// }
