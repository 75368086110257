import React, {forwardRef} from 'react';
import FormattedDate from './FormattedDate';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner, faExclamation, faCheck, faCalendarAlt} from '@fortawesome/free-solid-svg-icons';

export default forwardRef(({
  status = 'inert',
  week,
  year,
  startOfWeek,
  endOfWeek,
  onClick,
  minWidth = '300px',
  mapAttributesToStatus = {
    inert: {icon: faCalendarAlt, spin: false}, 
    active: {icon: faSpinner, spin: true}, 
    success: {icon: faCheck, spin: false}, 
    warning: {icon: faExclamation, spin: false}, 
    error: {icon: faExclamation, spin: false}
  },
}, ref) => {

  return (
    <button 
      className={'btn btn-outline-primary d-inline-flex flex-row justify-content-between'} 
      onClick={onClick} 
      ref={ref}
      style={{minWidth}} 
      disabled={status === 'active'}
    >
      <FontAwesomeIcon className={`${status} mt-1 mr-1`} {...mapAttributesToStatus[status]} size="1x" />
      {`Week ${week}, `}
      <FormattedDate date={startOfWeek} className={'ml-1'} dayOfWeek={false} />
      &nbsp;{'to'}&nbsp;
      <FormattedDate date={endOfWeek} dayOfWeek={false} />
    </button>
  );
});

