import React, { useState, useEffect } from 'react';
import Styles from './ManagementSummary.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faTruckPickup, faTruckLoading } from '@fortawesome/free-solid-svg-icons'
import { getVehicles } from "../../../services/VehiclesService";
import { getLoads } from "../../../services/LoadsService";
import { getTeams } from "../../../services/TeamsService";
import { CCard, CCardBody, CCardHeader, CProgress, CProgressBar } from "@coreui/react";
import { CChartBar, CChartPie } from "@coreui/react-chartjs";

import { getIncidents } from "../../../services/newsItemsService";
import underscore from "underscore";
import { DateUtil } from "../../../utilities/DateUtil";
import { getOperators, subscribeToOperators } from "../../../services/UsersService";


const ManagementSummary = () => {

  const [vehiclesArr, setVehiclesArr] = useState([]);
  const [loadsArr, setLoadsArr] = useState([]);
  const [teamsArr, setTeamsArr] = useState([]);
  const [membersArr, setMembersArr] = useState([]);
  const [incidentArr, setIncidentArr] = useState([]);
  const [filteredIncidentArr, setFilteredIncidentArr] = useState([]);
  const [incidentFreeDays, setIncidentFreeDays] = useState(null);

  const [faultyCategoryList, setFaultyCategoryList] = useState(null);
  const [inOpsList, setInOpsList] = useState(null);
  const [vehicleTypeAvail, setVehicleTypeAvail] = useState(null);

  const [teamMembersBar, setTeamMembersBar] = useState(null);
  const [teamMembersOnShiftBar, setTeamMembersOnShiftBar] = useState(null);

  const [teamVehicles, setTeamVehicles] = useState(null);

  const barGraphOptions = {
    // tooltips: {
    //   enabled: false,
    //   custom: customTooltips
    // },
    legend: {
      display: false
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            // drawBorder: false,
            color: "rgba(0, 0, 0, 0)",
            // display: false
          },
        }
      ],
      yAxes: [
        {
          gridLines: {
            // drawBorder: false,
            color: "rgba(0, 0, 0, 0)",
            // display: false

          },
          ticks: {
            beginAtZero: true,
            userCallback: function (label) {
              // when the floored value is the same as the value we have a whole number
              if (Math.floor(label) === label) {
                return label;
              }

            },
          }
        }
      ],
    },
    maintainAspectRatio: true
  }

  const pieGraphOptions = {
    legend: {
      display: true,
    }
  };

  const defaultGraphColors = [
    '#9e6666',
    '#3d756f',
    '#7d5d29',
    '#99ff99',
    '#FF6384',
    '#36A2EB',
    '#FFCE56',
    '#ffa64d'
  ]

  const defaultGraphHoverColors = [...defaultGraphColors]

  useEffect(() => {
    const promises = [getVehicles(), getLoads(), getTeams(), getIncidents(), getOperators()];

    Promise.all(promises).then(response => {

      const [vehicles, loads, teams, incidents, members] = response;
      setVehiclesArr(vehicles);
      setLoadsArr(loads);
      setTeamsArr(teams);
      setIncidentArr(incidents);
      setMembersArr(members);

    })


    const subscribeOperators = subscribeToOperators(setMembersArr);

    return () => {
      subscribeOperators()
    }

  }, []);

  useEffect(() => {

    const incidentsGroupedByTitle = underscore.groupBy(incidentArr, 'title');
    const cleanIncidentList = Object.keys(incidentsGroupedByTitle).map((incidentTitle) => {
      const incidents = incidentsGroupedByTitle[incidentTitle];
      return {
        count: incidents.length,
        title: incidentTitle,
        date: DateUtil(incidents[0].dateCreated.toDate()),
        date_sort: parseInt(incidents[0].dateCreated.toDate().getTime())
      }

    })

    //Only show 5 incidents
    setFilteredIncidentArr(cleanIncidentList.slice(0, 5));

  }, [incidentArr])

  useEffect(() => {

    const today = new Date();
    const incidentDays = underscore.max(filteredIncidentArr, 'date_sort');

    let daysDiff = Math.round((today - new Date(incidentDays.date)) / (1000 * 60 * 60 * 24));

    if (!underscore.isNaN(daysDiff) && !underscore.isUndefined(daysDiff)) {
      setIncidentFreeDays(daysDiff);
    }

  }, [filteredIncidentArr])

  useEffect(() => {

    if (membersArr && membersArr.length > 0) {

      let teamMemberList = underscore.filter(underscore.groupBy(membersArr, 'currentTeam'));
      let teamMemberCount = [];
      let teamMemberLabels = [];
      let teamMemberDataList = [];

      let teamMemberonShiftCount = [];
      let teamMemberonShiftLabels = [];
      let teamMemberonShiftList = [];
      let teamMemberonShiftDataList = [];

      teamMemberList.forEach((teamMember) => {

        if (teamMember[0].currentTeam) {

          teamMemberLabels.push(teamMember[0].currentTeam)
          teamMemberCount.push(teamMember.length)
          teamMemberDataList.push(
            {
              team: teamMember[0].currentTeam,
              count: teamMember.length
            }
          )

        }

        teamMember.forEach((tmem) => {

          if (tmem.currentTeam && tmem.isOnShift) {

            teamMemberonShiftList.push(tmem)

          }

        })


      })

      let updateTeamBarProps = {
        labels: teamMemberLabels,
        datasets: [
          {
            label: 'Team Members',
            borderWidth: 0,
            borderColor: 'rgb(255, 255, 255)',
            hoverBorderColor: 'rgb(255, 255, 255)',
            backgroundColor: defaultGraphColors,
            hoverBackgroundColor: defaultGraphHoverColors,
            data: teamMemberCount,
          }
        ],
        collection: teamMemberDataList
      };

      teamMemberonShiftList = underscore.filter(underscore.groupBy(teamMemberonShiftList, 'currentTeam'))

      teamMemberonShiftList.forEach((tmOnShift) => {

        if (tmOnShift[0].currentTeam) {

          teamMemberonShiftLabels.push(tmOnShift[0].currentTeam)
          teamMemberonShiftCount.push(tmOnShift.length)

          teamMemberonShiftDataList.push(
            {
              team: tmOnShift[0].currentTeam,
              count: tmOnShift.length
            }
          )

        }

      })

      let updateTeamShiftBarProps = {
        labels: teamMemberonShiftLabels,
        datasets: [
          {
            label: 'Team Members On Shift',
            borderWidth: 0,
            borderColor: 'rgb(255, 255, 255)',
            hoverBorderColor: 'rgba(255, 255, 255,1)',
            backgroundColor: defaultGraphColors,
            hoverBackgroundColor: defaultGraphHoverColors,
            data: teamMemberonShiftCount,
          }
        ],
        collection: teamMemberonShiftDataList
      };

      setTeamMembersOnShiftBar(updateTeamShiftBarProps);
      setTeamMembersBar(updateTeamBarProps);

    }


  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membersArr])

  useEffect(() => {

    let jobsArr = []
    let jobIssueTypeLabelsArr = []
    let jobIssueTypesArr = []
    let jobIssueTypesDatalistArr = []

    if (vehiclesArr && vehiclesArr.length) {

      vehiclesArr.forEach(vehicle => {

        if (vehicle.checkItems) {
          vehicle.checkItems.forEach(checkItem => {
            if (checkItem.maintenanceSignoff === false && checkItem.supervisorCheckItemApproved === true) {
              jobsArr.push(checkItem)
            }
          });
        }
      });

      const issuesGroupedByType = underscore.groupBy(jobsArr, 'classType');
      Object.keys(issuesGroupedByType).forEach(issueType => {
        const issues = issuesGroupedByType[issueType]
        jobIssueTypeLabelsArr.push(issueType)
        jobIssueTypesArr.push(issues.length)

        jobIssueTypesDatalistArr.push({
          type: issueType,
          count: issues.length
        })
      })

      let faultyIssueItems = {
        labels: jobIssueTypeLabelsArr,
        datasets: [
          {
            data: jobIssueTypesArr,
            backgroundColor: defaultGraphColors,
            hoverBackgroundColor: defaultGraphHoverColors,
            borderWidth: 3
          }],
        collection: jobIssueTypesDatalistArr
      };

      setFaultyCategoryList(faultyIssueItems);

      // Prepare data for vehicles on shift
      let vehiclesByStatusLabelsArr = []
      let vehiclesByStatusArr = []
      let vehiclesByStatusDatalistArr = []
      const vehiclesOnShift = vehiclesArr.filter((vehicle) => vehicle.currentUser && vehicle.userIsOnShift);
      vehiclesByStatusLabelsArr.push('OnShift');
      vehiclesByStatusArr.push(vehiclesOnShift.length);
      vehiclesByStatusDatalistArr.push({ status: 'OnShift', count: vehiclesOnShift.length });

      // Prepare data for vehicles grouped by vehicle status
      const vehiclesGroupedByStatus = underscore.groupBy(vehiclesArr, (vehicle) => vehicle.status || 'Parked');
      Object.keys(vehiclesGroupedByStatus).forEach((vehicleStatus) => {
        const vehicles = vehiclesGroupedByStatus[vehicleStatus];
        vehiclesByStatusLabelsArr.push(vehicleStatus)
        vehiclesByStatusArr.push(vehicles.length)

        vehiclesByStatusDatalistArr.push(
          {
            status: vehicleStatus,
            count: vehicles.length
          }
        )
      });

      let vehiclesByClassType = {
        labels: vehiclesByStatusLabelsArr,
        datasets: [
          {
            data: vehiclesByStatusArr,
            backgroundColor: defaultGraphColors,
            hoverBackgroundColor: defaultGraphHoverColors,
            borderWidth: 3
          }],
        collection: vehiclesByStatusDatalistArr
      };

      setInOpsList(vehiclesByClassType);


      // Prepare data for vehicles grouped by vehicle type
      let vehicleAvailableList = [];
      const vehiclesGroupedByType = underscore.groupBy(vehiclesArr, 'vehicleType');
      Object.keys(vehiclesGroupedByType).forEach(vehicleType => {
        const vehicles = vehiclesGroupedByType[vehicleType];
        let inService = underscore.countBy(vehicles, function (veh) {
          return veh.userIsOnShift === true ? 'inServiceCount' : 'notInServiceCount';
        });

        let isAvailable = underscore.countBy(vehicles, function (veh) {
          return veh.status === 'Available' ? 'availableCount' : 'notAvailableCount';
        });

        let isParked = underscore.countBy(vehicles, function (veh) {
          return veh.userIsOnShift === true ? 'notParkedCount' : 'parkedCount';
        });

        vehicleAvailableList.push({
          'title': vehicleType,
          'count': vehicles.length,
          'in_service': inService.inServiceCount ? inService.inServiceCount : 0,
          'available': isAvailable.availableCount ? isAvailable.availableCount : 0,
          'parked': isParked.parkedCount ? isParked.parkedCount : 0
        })
      })

      // vehicleTypeAvail
      setVehicleTypeAvail(vehicleAvailableList.slice(0, 5))

      // Prepare data for vehicles grouped by current team assigned
      let teamVehiclesCount = [];
      let teamVehiclesLabels = [];
      let teamVehiclesDatalist = [];
      const vehiclesGroupedByTeam = underscore.groupBy(vehiclesArr, 'currentTeam');
      Object.keys(vehiclesGroupedByTeam).forEach(vehicleTeam => {
        const vehicles = vehiclesGroupedByTeam[vehicleTeam];
        if (vehicles[0].currentTeam) {

          teamVehiclesLabels.push(vehicleTeam)
          teamVehiclesCount.push(vehicles.length)
          teamVehiclesDatalist.push({
            team: vehicleTeam,
            count: vehicles.length
          })

        }

      })

      setTeamVehicles({
        labels: teamVehiclesLabels,
        datasets: [
          {
            data: teamVehiclesCount,
            borderWidth: 0,
            backgroundColor: defaultGraphColors,
            hoverBackgroundColor: defaultGraphHoverColors,
          }
        ],
        collection: teamVehiclesDatalist
      })

    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehiclesArr])

  return (
    <>

      <CCard className={``}>

        <CCardBody className={""}>
          <div className={'row'}>
            <div className={'col-12 card-deck'}>

              <CCard className={``}>
                <CCardBody className={""}>
                  <div className="h1 text-muted text-right mb-4">
                    <FontAwesomeIcon className={`${Styles.sectionIcon} ${Styles.sectionIconOne}`} icon={faUsers} />
                  </div>
                  <div className="h1 m-0">{teamsArr.length}</div>
                  <small className="text-muted text-uppercase font-weight-bold">Teams</small>
                  <CProgress size={"xs"} className="my-3 mb-0" color={"warning"}>
                    <CProgressBar value={25} max={100} ></CProgressBar>
                  </CProgress>
                </CCardBody>
              </CCard>

              <CCard className={``}>
                <CCardBody className={""}>
                  <div className="h1 text-muted text-right mb-4">
                    <FontAwesomeIcon className={`${Styles.sectionIcon} ${Styles.sectionIconTwo}`} icon={faTruckPickup} />
                  </div>
                  <div className="h1 m-0">{vehiclesArr.length}</div>
                  <small className="text-muted text-uppercase font-weight-bold">Vehicles</small>
                  <CProgress size={"xs"} className="my-3 mb-0">
                    <CProgressBar value={25} max={100} ></CProgressBar>
                  </CProgress>
                </CCardBody>
              </CCard>

              <CCard className={``}>
                <CCardBody className={""}>
                  <div className="h1 text-muted text-right mb-4">
                    <FontAwesomeIcon className={`${Styles.sectionIcon} ${Styles.sectionIconThree}`} icon={faTruckLoading} />
                  </div>
                  <div className="h1 m-0">{loadsArr.length}</div>
                  <small className="text-muted text-uppercase font-weight-bold">Loads</small>

                  <CProgress size={"xs"} className="my-3 mb-0" color={"success"}>
                    <CProgressBar value={25} max={100} ></CProgressBar>
                  </CProgress>

                </CCardBody>
              </CCard>

            </div>
          </div>
        </CCardBody>


        <CCardBody className={'card-deck'}>


          <CCard className={``}>
            <CCardHeader className="">
              Incidents
            </CCardHeader>
            <CCardBody className={""}>
              <div className="d-flex">
                <div className="table-responsive">
                  <table className="table-hover table table-sm">
                    <thead>
                      <tr>
                        <th>Incident</th>
                        <th>Date</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>

                      {
                        (filteredIncidentArr && filteredIncidentArr.length) ? filteredIncidentArr.map((incident, idx) => (
                          <tr key={idx}>
                            <td>{incident.title}</td>
                            <td>{incident.date}</td>
                            <td>Open</td>
                          </tr>
                        )) : <tr><td></td></tr>
                      }

                    </tbody>
                  </table>

                </div>
              </div>
            </CCardBody>
          </CCard>


          {/*
          <CCard className="">
            <CCardBody className="d-flex justify-content-center align-items-center">
              <div className="h1 text-center">
                Incident Free days {incidentFreeDays}
              </div>
            </CCardBody>
          </CCard>
          */}

        </CCardBody>

        {/*
        <CCardBody className={'card-deck'}>

          <CCard className={``}>
            <CCardHeader>Team Members</CCardHeader>
            <CCardBody className="row justify-content-center d-flex align-items-center flex-md-column-reverse flex-sm-column-reverse flex-lg-row">

              {teamMembersBar &&

                <>
                  <div className="col d-flex flex-column col col-lg-4 small pt-4 pt-lg-0">
                    {teamMembersBar.collection.map((item, idx) => (

                      <div className="row" key={idx}>
                        <div className="col">{item.team}</div>
                        <div className="col">{item.count}</div>
                      </div>

                    ))}
                  </div>

                  <div className="col">
                    <CChartBar datasets={teamMembersBar.datasets} labels={teamMembersBar.labels} options={barGraphOptions}></CChartBar>
                  </div>
                </>


              }
            </CCardBody>
          </CCard>

          {teamMembersOnShiftBar &&
            <CCard className={``}>
              <CCardHeader>Team Members on shift</CCardHeader>
              <CCardBody className="">
                <CChartBar datasets={teamMembersOnShiftBar.datasets} labels={teamMembersOnShiftBar.labels} options={barGraphOptions}></CChartBar>
              </CCardBody>
            </CCard>
          }

          <CCard className={`d-none`}>
            <CCardHeader>Team Members Table</CCardHeader>
            <CCardBody className="">

              <div className="table-responsive">
                <table className="table-hover table table-sm">
                  <thead>
                    <tr>
                      <th>Shifts worked</th>
                      <th>Hours worked</th>
                      <th>TTL Breakdowns</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      (filteredIncidentArr && filteredIncidentArr.length) ? filteredIncidentArr.map((incident, idx) => (
                        <tr key={idx}>
                          <td>{incident.title}</td>
                          <td>{incident.date}</td>
                          <td>Open</td>
                        </tr>
                      )) : <tr><td></td></tr>
                    }
                  </tbody>
                </table>

              </div>

            </CCardBody>
          </CCard>

        </CCardBody>

        <CCardBody className={'card-deck'}>

          <CCard className="">
            <CCardHeader>Active Faults</CCardHeader>
            <CCardBody className="justify-content-center align-items-center d-flex flex-md-column-reverse flex-sm-column-reverse flex-lg-row">
              {faultyCategoryList &&
                <>
                  <div className="col d-flex flex-column  col col-lg-4 small pt-4 pt-lg-0">
                    {faultyCategoryList.collection.map((item, idx) => (

                      <div className="row" key={idx}>
                        <div className="col">{item.type}</div>
                        <div className="col col-4">{item.count}</div>
                      </div>

                    ))}
                  </div>

                  <div className="col">
                    <CChartPie datasets={faultyCategoryList.datasets} labels={faultyCategoryList.labels} options={pieGraphOptions}></CChartPie>
                  </div>
                </>
              }
            </CCardBody>
          </CCard>

          <CCard className="">
            <CCardHeader>Vehicles in Operation</CCardHeader>
            <CCardBody className={"justify-content-center align-items-center d-flex flex-md-column-reverse flex-sm-column-reverse flex-lg-row"}>
              {inOpsList &&
                <>
                  <div className="col d-flex flex-column col col-lg-4 small pt-4 pt-lg-0" key={'v-ops-summary'}>
                    {inOpsList.collection.map((item, idx) => (

                      <div className="row" key={idx}>
                        <div className="col">{item.status}</div>
                        <div className="col col-4">{item.count}</div>
                      </div>

                    ))}
                  </div>

                  <div className="col" key={'v-ops-detail'}>
                    <CChartPie datasets={inOpsList.datasets} labels={inOpsList.labels} options={pieGraphOptions}></CChartPie>
                  </div>
                </>
              }
            </CCardBody>
          </CCard>

        </CCardBody>

        <CCardBody className={'card-deck'}>

          <CCard className="">
            <CCardHeader>Team Vehicles</CCardHeader>
            <CCardBody className={"justify-content-center align-items-center d-flex flex-md-column-reverse flex-sm-column-reverse flex-lg-row"}>
              {teamVehicles && teamVehicles.labels.length > 0 &&
                <>
                  <div className="col d-flex flex-column col col-lg-4 small pt-4 pt-lg-0" key={'team-v-summary'}>
                    {teamVehicles.collection.map((item, idx) => (

                      <div className="row" key={idx}>
                        <div className="col">{item.team}</div>
                        <div className="col col-4">{item.count}</div>
                      </div>

                    ))}
                  </div>

                  <div className="col" key={'team-v-detail'}>
                    <CChartBar datasets={teamVehicles.datasets} labels={teamVehicles.labels} options={barGraphOptions}></CChartBar>
                  </div>
                </>
              }
            </CCardBody>
          </CCard>

          <CCard className={``}>
            <CCardHeader>Vehicles Types Available</CCardHeader>
            <CCardBody className={"justify-content-center align-items-center d-flex "}>
              {vehicleTypeAvail &&

                <div className="table-responsive">
                  <table className="table-hover table table-sm">
                    <thead>
                      <tr>
                        <th className="border-top-0"></th>
                        <th className="border-top-0">Total</th>
                        <th className="border-top-0">In service</th>
                        <th className="border-top-0">Available</th>
                        <th className="border-top-0">Parked</th>
                      </tr>
                    </thead>
                    <tbody>

                      {
                        vehicleTypeAvail && vehicleTypeAvail.length && vehicleTypeAvail.map((vehicleType, idx) => (

                          <tr key={idx}>
                            <td>{vehicleType.title}</td>
                            <td>{vehicleType.count}</td>
                            <td>{vehicleType.in_service}</td>
                            <td>{vehicleType.available}</td>
                            <td>{vehicleType.parked}</td>
                          </tr>

                        ))
                      }

                    </tbody>
                  </table>

                </div>

              }
            </CCardBody>
          </CCard>


        </CCardBody>
        */}

      </CCard>


    </>
  )
}

export default ManagementSummary;