import styles from './Checklist.module.scss';
import React, { useState, useContext} from 'react'
import ClassAChecks from './ClassAChecks';
import ClassBChecks from './ClassBChecks';
import ClassCChecks from './ClassCChecks';
import Confirmation from './Confirmation';
import { v4 as uuidv4 } from "uuid";
import { UserContext } from "../../context/userContext";
import { CCard, CCardBody, CCardHeader } from "@coreui/react";
import {isEmpty} from 'lodash';
import FullPageLoader from "../FullPageLoader";

const Checklist = (props) => {
    const {currentVehicleIssues,checklistData,vehicle} = props;
    const { user } = useContext(UserContext);
    const [step, setStep] = useState(1);
    const [backNavStatus, setBackNavStatus] = useState(false);
    const [failedElements, setFailedElements] = useState({classA: {}, classB: {}, classC: {}});
    const [extraTextFields, setExtraTextFields] = useState({classA: {additional_details : ''}, classB: {}, classC: {}});

    const failObject = (vehicleType, classType) => {
        return Object.keys(checklistData[classType]).reduce((acc, val) => {
            if (!document.getElementById(val).checked) {

                let dateCreated = new Date().toUTCString();
                let issueID = uuidv4();

                if( vehicleCheckItems(val) ){
                    const existingIssueDetails = getVehicleCheckItemDetails(val)
                    issueID = existingIssueDetails.issueID || issueID;
                }

                acc[classType][val] = {
                    classType: classType,
                    issue: val,
                    dateCreated,
                    issueID
                };

                } else{
                    acc[classType][val] = {};
                } 
            return acc;
        }, failedElements);
    }

    const getTextFieldElements = ( classType ) =>{

        let outputResult = {...extraTextFields};

        // eslint-disable-next-line default-case
        switch ( classType ) {

            case 'classA':
                outputResult.classA =  {
                    additional_details:document.getElementById('additional-details') ? document.getElementById('additional-details').value : '',
                }
            break;

            case 'classB':
                outputResult.classB =  {
                    additional_details:document.getElementById('additional-details') ? document.getElementById('additional-details').value : '',
                }
            break;

            case 'classC':
                outputResult.classC =  {
                    additional_details:document.getElementById('additional-details') ? document.getElementById('additional-details').value : '',
                }
            break;

        }

        return outputResult;

    }

    const vehicleCheckItems = ( check ) => {

        if ( currentVehicleIssues && currentVehicleIssues.length ) {

            const selected =  currentVehicleIssues.find( issue => {
                return issue.issue === check
            })

            return !!selected

        }
        return false;

    }
    
    const getVehicleCheckItemDetails = ( issueItem ) => {

        if ( currentVehicleIssues && currentVehicleIssues.length ) {

            return currentVehicleIssues.find( issue => {
                return issue.issue === issueItem
            })

        }

        return false;

    }

    const vehicleStepCheckOptions = ( item, classType ) => {

        if( backNavStatus ){
            return !(failedElements && failedElements[classType] && failedElements[classType][item] && failedElements[classType][item].issue)
        }

        return false

    }

    const nextHandler = ()  => {
        setStep(step + 1);
    }

    const backHandler = ()  => {
        setBackNavStatus(true);
        setStep(step - 1);
    }

    const propsMethods = {
        extraTextFields: extraTextFields,
        setExtraTextFields: setExtraTextFields,
        getTextFieldElements: getTextFieldElements,
        failedElements: failedElements,
        setFailedElements: setFailedElements,
        failObject: failObject,
        checklistData: checklistData, 
        vehicleType: vehicle.vehicleType,
        vehiclePreselected : vehicleCheckItems,
        nextHandler: nextHandler,
        backHandler: backHandler,
        backNavStatus:backNavStatus,
        vehicleStepCheckOptions:vehicleStepCheckOptions        
    }

    let navigation = () => {
        if (isEmpty(vehicle)) {
            return (
              <>
                  <div className={styles.vehicleDisplay}>
                      <p>Please confirm with your supervisor that a vehicle is assigned.</p>
                  </div>
                  <div className={styles.navigation}>
                      <button className={styles.btnSecondary} onClick={ () => window.history.back()}>Back</button>
                  </div>
              </>
            )
        }
        if (isEmpty(checklistData)) {
            return (
              <>
                  <div className={styles.vehicleDisplay}>
                      <p>Cannot fill checklist for a vehicle with no type. Contact admin for assistance.</p>
                  </div>
                  <div className={styles.navigation}>
                      <button className={styles.btnSecondary} onClick={ () => window.history.back()}>Back</button>
                  </div>
              </>
            )
        }

        switch (step) {
                case 1: return (
                    <>
                        <div className={styles.vehicleDisplay}>
                            <p>Vehicle type:</p>
                            <p>{vehicle.vehicleType}</p>
                        </div>
                        <div className={styles.navigation}>
                            <button className={styles.btnSecondary} onClick={ () => window.history.back()}>Back</button>
                            <button className={styles.btnPrimary} onClick={nextHandler}>Next</button>
                        </div>
                    </>
                    )
                case 2: return <ClassAChecks propsMethods={propsMethods} vehicle={vehicle} />
                case 3: return <ClassBChecks propsMethods={propsMethods} vehicle={vehicle} />

                case 4: return <ClassCChecks propsMethods={propsMethods} vehicle={vehicle} user={user} />

                case 5: return <Confirmation extraTextFields={extraTextFields} vehicle={vehicle} backHandler={backHandler} checklistData={checklistData} failedElements={failedElements} user={user} />
                default: return "error page"
            }
        }

    return (

        <CCard className={'checklist'}>
            <CCardHeader>Prestart Checklist</CCardHeader>
            <CCardBody>
                {navigation()}
            </CCardBody>
            <FullPageLoader />
        </CCard>

    )
}

export default Checklist;