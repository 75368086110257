import React, { useState, useEffect, useContext } from 'react';
import { useForm } from "react-hook-form";
import Styles from './EditTeamForm.module.scss';
import { updateTeam } from '../../../../services/TeamsService'
import { getSites } from '../../../../services/SitesService'
import {CModalBody, CModalFooter, CModalHeader, CModalTitle} from "@coreui/react";
import { UserContext } from "../../../../context/userContext";

const EditTeamForm = (props) => {
    const {
        site: currentSite,
        teamName, 
        subTeamName,
        teamID
    } = props.team;

    const { register, handleSubmit, errors, reset } = useForm();
    const [sitesArr, setSitesArr] = useState([currentSite]);
    const { toggleFullPageLoader } = useContext(UserContext);

    const updateCurrentTeam = async (data) => {
        try {
            toggleFullPageLoader(true);
            const updatedTeam = {
                site : data.site,
                teamName : data.teamName,
                subTeamName : data.subTeamName,
                teamID: teamID
            };
            props.hide();
            await updateTeam(updatedTeam);
        } finally {
            toggleFullPageLoader(false);
        }
    };

    useEffect(() => {
        getSites().then(response => {
            setSitesArr(response.map(site => site.siteName));
            reset({
                site: currentSite
            });
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <CModalHeader closeButton>
                <CModalTitle>Edit Team</CModalTitle>
            </CModalHeader>

            <form className="d-flex flex-column" onSubmit={handleSubmit(updateCurrentTeam)}>

                <CModalBody>
                    <div className={Styles.teamForm}>
                        <label htmlFor="site">Select Site :</label>
                        <select
                            className={Styles.selectPrimary}
                            defaultValue={currentSite}
                            name="site"
                            id="site"
                            ref={register({ required: true })}>
                            <option value="">Select site :</option>
                            {sitesArr.map((site, index) => <option key={index} value={site}>{site}</option>)}
                        </select>
                        {errors.site && <p>Site is required.</p>}

                        <label htmlFor="teamName">Team Name :</label>
                        <input
                            className={Styles.inputPrimary}
                            defaultValue={teamName}
                            type="text"
                            id="teamName"
                            name="teamName"
                            placeholder="enter the teamName"
                            ref={register({ required: true })} />
                            {errors.teamName && <p> Team Name is required.</p>}

                        {subTeamName &&
                            <>
                                <label htmlFor="subTeamName">Sub Team Name:</label>
                                    <input
                                    className={Styles.inputPrimary}
                                    defaultValue={subTeamName}
                                    type="text"
                                    id="subTeamName"
                                    name="subTeamName"
                                    placeholder="enter the Sub Team"
                                    ref={register({required: true})} />
                                {errors.subTeamName && <p>Sub Team Name is required.</p>}
                            </>

                        }
                    </div>
                </CModalBody>

                <CModalFooter>
                    <button className="btn btn-secondary" data-dismiss="modal" aria-label="Close" type="button" onClick={()=>(props.hide())}>Cancel</button>
                    <button className="btn btn-primary" type="submit">Update</button>
                </CModalFooter>

            </form>
        </>
    )
}

export default EditTeamForm;
