import React, {useContext, useState, useEffect} from "react";
import { useForm , Controller } from "react-hook-form";
import { createNewsItem } from "../../../services/newsItemsService";
import { UserContext } from "../../../context/userContext";
import { CCardHeader, CCard, CCardBody, CCardFooter, CAlert, CListGroup, CListGroupItem, CCardText } from "@coreui/react";
import { Link } from '@reach/router';
import {DateUtil} from "../../../utilities/DateUtil"
import CreatableSelect from "react-select";
import { subscribeToWorkareas } from "../../../services/WorkareasService";
import { getVehiclesByIds, subscribeToVehicles } from "../../../services/VehiclesService";
import { groupBy } from "lodash";

export const DailyReport = ( props ) => {

    const { user, toggleFullPageLoader } = useContext(UserContext);
    const [ isShowing, setShowNotification ] = useState(false);
    const [ workplaces, setWorkplaces ] = useState([]);
    const [ missingClosingHourVehicles, setMissingClosingHoursVehicles ] = useState([]);
    const [ workplaceFilteredOptions, setWorkplaceOptions] = useState([])

    const [ vehicles, setVehicleList ] = useState([]);
    const [ vehicleGroupedOptions, setVehicleGroupedOptions] = useState([]);

    const { register, handleSubmit , reset, control, errors } = useForm();

    const toLowerCaseUnderscore = (str) => {
        return str.split(" ").map(word => {
            return word.toLowerCase()
        }).join("_")
    };

    const handleVehicleSelection = async( idsSelected ) =>{

        if(idsSelected.length){
            
            const vehicleList = await checkVehicleClosingHours( idsSelected );
            setMissingClosingHoursVehicles( vehicleList );
            return !!vehicleList.length

        }

        return false

    }

    const checkVehicleClosingHours = async( list )=>{

        const filterClosingVehicles = getVehiclesByIds( list );

        const filterVehiclelist = (await filterClosingVehicles).filter(vehicle => (vehicle.engineHours && vehicle.engineHours.closingHours ));

        return filterVehiclelist;

    }
    
    /////////////
    class Input {
        constructor( type, title, label, placeholder='', value='', readonly=false, isMulti=false, onChangeFunction={} ){
            this.type = type;
            this.label = label;
            this.title = toLowerCaseUnderscore(title);
            this.id  = toLowerCaseUnderscore(this.title);
            this.value = value;
            this.placeholder = placeholder;
            this.readonly = readonly;
            this.isMulti = isMulti;
            this.onchange = onChangeFunction;
        }
    }

    const inputs = [
        new Input("text","Date", "Date", '', DateUtil( new Date(), {}, 'en-GB' ), true),
        new Input("select","Workplace","Workplace", '', workplaceFilteredOptions, false, true ),
        new Input("select_grouped","Machine Conditions","Machine Conditions", '', vehicleGroupedOptions, false, true, handleVehicleSelection ),
        new Input("select","Dust Suppression","Dust Suppression", '', [ "Done", "Not Done"] ),
        new Input("select","Road Conditions","Conditions on the Haul Roads", '', [ "Okay", "Dusty",  "Wet", "Bumpy",  "Bad light", " Not ok" ]),
        new Input("select","Dumping Stockpile", "Dumping/Stockpile position", '', ["Stable" , "Unstable"]),
        new Input("select","Loading Conditions", "Loading Conditions", '', ["Okay", "Not okay"]),
        new Input("time","First Load Time", "Time for First Load"),
        new Input("time","Last Load Time", "Time for Last Load"),
        new Input("textarea","Comments", "Comments and Instructions for oncoming shift"),
    ]
    /////////////////

    const submitForm = async (data) => {

        try {

            let workplaces = [];
            data.workplace.forEach((item)=>{
                workplaces.push( item.value );
            });
        
            let stockpile = [];
            stockpile.push( data.dumping_stockpile.value );
        
            let dust_suppression = [];
            dust_suppression.push( data.dust_suppression.value );
        
            let load_conditions = [];
            load_conditions.push( data.loading_conditions.value );
        
            let machine_conditions = [];
            data.machine_conditions.forEach((item)=>{
                machine_conditions.push( item.value );
            });

            let road_conditions = [];
            road_conditions.push( data.road_conditions.value );
            
            let reformatData = {
                ...data, 
                workplace : workplaces,
                date : new Date(),
                'dumping_stockpile' : stockpile,
                'dust_suppression' : dust_suppression,
                'loading_conditions' : load_conditions,
                'machine_conditions' : machine_conditions,
                'road_conditions' : road_conditions,
            }
         
            toggleFullPageLoader(true);

            const hasMissingClosingHours = await handleVehicleSelection( machine_conditions );

            if ( hasMissingClosingHours === false ){

                let newsItemToSubmit = {
                    title: "Supervisor Report",
                    message: `New supervisor report by ${user.fullNameStr}`,
                    team: user.currentTeam,
                    submittedBy: {
                        fullNameStr: user.fullNameStr,
                        userID: user.userID,
                        userType: user.userType,
                        currentTeam: user.currentTeam
                    },
                    type: "supervisorReport",
                    isImportant: true,
                    seenBy: [],
                    info: reformatData,
                    dateCreated: new Date()
                };
    
                // console.log('newsItemToSubmit', newsItemToSubmit)
                await createNewsItem(newsItemToSubmit);
                setShowNotification(true);
                reset({});

            }

        } catch(error) {
            console.error('DailyReport submitForm ', error );
        }finally{
            toggleFullPageLoader(false);
        }

    };
      
    const formatGroupLabel = data => (
        <div className="d-flex align-items-center justify-content-between">
            <span>{data.label}</span>
            <span className="badge-info badge-pill badge">{data.options.length}</span>
        </div>
    );

    useEffect(()=>{

        const vehicleByStatus = groupBy(vehicles, 'status');

        let vehicleGroupedOptions =[
            {
              label: 'Available',
              options: vehicleByStatus['Available'] && vehicleByStatus['Available'].map(opt=>({id: opt.id, vehicleID : opt.vehicleID , value : opt.vehicleID , label: opt.vehicleID })),
            },
            {
              label: 'Not Available',
              options: vehicleByStatus['Not Available'] && vehicleByStatus['Not Available'].map(opt=>({id: opt.id, vehicleID : opt.vehicleID , value : opt.vehicleID , label: opt.vehicleID })),
            },
          ];

          setVehicleGroupedOptions( vehicleGroupedOptions );

    },[vehicles])

    useEffect(()=>{
        
        let mounted = true;
        if(mounted){
            subscribeToWorkareas( setWorkplaces );
            subscribeToVehicles( setVehicleList );
        }
        

        //Use effect clean ups
        return () => {
            mounted = false;
        }

    },[])

    useEffect(  ()=>{
        let workplaceOptions = [];
        workplaces.map(workplace => (workplaceOptions.push(workplace.title)))

        setWorkplaceOptions( workplaceOptions );

    },[workplaces])

    return (
        <div>
            <CCard className={``}>
                <CCardHeader className={'d-flex align-items-center justify-content-between'}>
                    Supervisor Handover Notes
                </CCardHeader>
                <form onSubmit={handleSubmit(submitForm)}>

                    <CCardBody>

                        { isShowing &&
                            <div className="">
                                <CAlert className={'fade show alert-success'}>
                                    Form successfully submitted
                                </CAlert>
                            </div>
                        }

                        { !isShowing &&
                            <div className={"d-flex flex-column"}>
                                <>

                                

                                {inputs.map(input => (
                                    <React.Fragment key={`${input.id}label`}>
                                        <label className="my-2" htmlFor={input.label}>{input.label}</label>
                                        { input.type === "textarea" && <textarea className="form-control my-2" type={input.type}  name={input.title}  ref={register} id={input.label} rows="5" placeholder={input.placeholder}  defaultValue={input.value} /> }
                                        { (input.type === "text" || input.type === "time") && <input className="form-control my-2" type={input.type}  name={input.title}  ref={register} id={input.label} placeholder={input.placeholder}  defaultValue={input.value} disabled={input.readonly}  />  }
                                        { (input.type === "select") && 

                                                <Controller 
                                                    isMulti={input.isMulti}
                                                    closeMenuOnSelect={ input.isMulti ? false : true }
                                                    className="col col-12 p-0 my-2"
                                                    name={input.title}
                                                    id={input.id}
                                                    options={input.value.map(optVal => ({label : optVal, value: optVal}) )}
                                                    as={CreatableSelect}
                                                    control={control}
                                                    rules={{ required: true }}
                                                    defaultValue=''
                                                />

                                        }

                                        { (input.type === "select_grouped") && 
                                                <Controller 
                                                isMulti={input.isMulti}
                                                closeMenuOnSelect={ input.isMulti ? false : true }
                                                className="col col-12 p-0 my-2"
                                                name={input.title}
                                                id={input.id}
                                                options={input.value} 
                                                as={CreatableSelect}
                                                control={control}
                                                rules={{ required: true }}
                                                defaultValue=''
                                                formatGroupLabel={formatGroupLabel}
                                                isClearable={true} 
                                            />
                                            
                                        }

                                        {errors[input.id] && <div className="col-12 error py-2 px-0">{input.label} field is required.</div>}
                                
                                    </React.Fragment>
                                ))}

                                { missingClosingHourVehicles && missingClosingHourVehicles.length > 0 && 
                                    <>
                                        <CCard color="" className="card-accent-danger mt-3 bg-danger text-white">
                                                    
                                            <CCardHeader className="" color=""> 
                                                <h3 className="alert-heading"> Form submission failed. Resolve the errors and resubmit. </h3>
                                            </CCardHeader>
                                            <CCardBody>
                                                <CCardText tag="div"> 
                                                    <CAlert className="fade show alert-light m-0">
                                                        <h3>The below listed vehicles have no closing hours</h3>
                                                        
                                                        <CListGroup flush className="mt-2">
                                                        { 
                                                            missingClosingHourVehicles.map( mVehicle => (
                                                                <CListGroupItem className="pl-2" key={mVehicle.id}>VehicleID : {mVehicle.vehicleID}</CListGroupItem>
                                                            ))
                                                        }
                                                        </CListGroup>
                                                        
                                                    </CAlert>
                                                    
                                                </CCardText>
                                            </CCardBody>

                                        </CCard>
                                    </>
                                }
                                    
                                </>
                            </div>
                        }

                    </CCardBody>

                    { !isShowing &&
                        <CCardFooter className="d-flex justify-content-between">
                            <Link to="../">
                                <button className="btn btn-danger" type="button">Cancel</button>
                            </Link>
                            <button className="btn btn-primary" type="submit" >Submit</button>
                        </CCardFooter>
                    }
                    
                </form>
            </CCard>
        </div>
    )
}
