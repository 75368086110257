
import { firestore } from '../firebase';

const getTeams = () => {
  return firestore.collection("teams").get().then(response => response.docs.map(document => document.data()));
};

//watches teams collection and updates the state whenever the db changes
const subscribeToTeams = (setState) => {
  return firestore.collection("teams").onSnapshot(snapshot => setState(snapshot.docs.map(document => document.data())))
};

const subscribeToSiteTeams = (setState) => {
  return firestore.collection("teams").onSnapshot(snapshot => {
    let resultDoc = snapshot.docs.map(document => document.data());
    resultDoc = resultDoc.length ? resultDoc[0] : null;
    if (resultDoc) setState(resultDoc);
  })
};


const createTeam = (newTeam) => {
  const newTeamRef = firestore.collection('teams').doc();
  return newTeamRef.set({
    ...newTeam,
    teamID: newTeamRef.id
  })
};

const updateTeam = (updatedTeam) => firestore.collection("teams").doc(updatedTeam.teamID).update({ ...updatedTeam });

const deleteTeam = (team) => firestore.collection("teams").doc(team.teamID).delete();

const getTeamSiteName = (operatorTeam, operatorSubTeam) => {
  return firestore
    .collection("teams")
    .where("teamName", "==", `${operatorTeam}`)
    .where("subTeamName", "==", `${operatorSubTeam}`)
    .get()
    .then(querySnapshot => querySnapshot.docs.map(doc => doc.data()));
};

export { getTeams, createTeam, subscribeToTeams, updateTeam, deleteTeam, getTeamSiteName, subscribeToSiteTeams };
