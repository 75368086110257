import React from 'react'
import CIcon from "@coreui/icons-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";

// import maintananceApproveIcon from "../../assets/images/approve-maintenance-alt.svg";
import supervisorReassignVehicle from "../../assets/images/reassign-vehicle.svg";
import reportedIssue from "../../assets/images/truckicon.svg";
import checklistIcon from "../../assets/images/completed-checklist.svg";
import reportAnIssue from "../../assets/images/report-an-issue.svg";

const iconSets = {
    // maintenancecomplete : maintananceApproveIcon,
    vehicleissuereported :  reportedIssue,
    vehiclechecklistcomplete : checklistIcon,
    vehicleassigned :  supervisorReassignVehicle,
    supervisorincident : reportAnIssue,
}

const OpsIcon = (props) => {

    const { type, useClass, item } = props;

    // console.log('props',item , item.message);

    if( ( (item.status && ['approved', 'rejected'].includes(item.status.toLowerCase())) || ( item.message && (item.message.toLowerCase().indexOf('rejected') > 1 ||  item.message.toLowerCase().indexOf('approved') > 1) ) ) ){
        return (<FontAwesomeIcon size={'2x'} icon={faInfoCircle} className={`${useClass} link`} />)
    }

    if(Object.keys(iconSets).includes(type.toLowerCase())){
        return (<CIcon src={iconSets[type.toLowerCase()]} className={`link c-icon-2xl c-icon ${useClass} ${type}`} alt={type} />)
    }

    return (<FontAwesomeIcon size={'2x'} icon={faInfoCircle} className={`${useClass} link`} />)

}

export default OpsIcon
