import React from "react";
import {Card, Tag, Table} from 'antd'
import {DateUtil} from "../../../utilities/DateUtil";
import {getColumnSearchProps} from "../../../components/TableColumnSearch/TableColumnSearch";

const NewsTicker = (props) => {

    const isIncidentsOnly = props.incidentsListing || false;
    const sectionHeading = props.title || false;
    const {newsItems,  extra } = props;

    const vehicleFieldSettings = [
        {
            key: 'timestamp',
            dataIndex: 'timestamp',
            title: 'Timestamp',
            sorter: (a, b) => a.timestamp - b.timestamp,
            defaultSortOrder: 'descend',
            render: (value, record) => DateUtil(record.dateCreated ? record.dateCreated.toDate().toString().substring(0, 21) : new Date().toString().substring(0, 21))
        },
        {
            key: 'message',
            dataIndex: 'message',
            title: 'Title',
            ...getColumnSearchProps("message", "Title")
        },
        {
            key: 'title',
            dataIndex: 'title',
            title: 'Category',
            ...getColumnSearchProps("title", "Category")
        },
        {
            key: 'type',
            dataIndex: 'type',
            title: 'Message',
            render: (text) => {
                switch (text) {
                    case 'maintenanceSuperviorSignoff':
                        return <Tag color={'purple'}>Supervisor Sign off</Tag>;

                    case 'vehicleClosingHourReported':
                        return <Tag color={'red'}>Closing Hour Reported</Tag>;

                    case 'maintenanceComplete':
                        return <Tag color={'magenta'}>Maintenance Complete</Tag>;

                    case 'vehicleCheckListComplete':
                        return <Tag color={'green'} >CheckList Complete</Tag>;

                    case 'vehicleChecklistApproved':
                        return <Tag color={'green'} >CheckList Approved</Tag>;

                    case 'vehicleChecklistRejected':
                        return <Tag color={'red'} >CheckList Rejected</Tag>;

                    case 'vehicleOpeningHourReported':
                        return <Tag color={'orange'}>Opening Hour Reported</Tag>;

                    case 'vehicleIssueReported':
                        return <Tag color={'blue'}>Issue Reported</Tag>;

                    case 'vehicleAssigned':
                        return <Tag color={'cyan'}>Vehicle Assigned</Tag>;

                    default:  return <Tag>{text}</Tag>
                }
            }
        },
        // {
        //     key: 'action',
        //     dataIndex: 'action',
        //     title: 'action',
        //     render: (_, record) => <Button type='link' onClick={() => toggleDetails(record)}>View</Button>
        // },
    ]

    const reformatNewsItem =  ( newsItem ) => {

        return {
            ...newsItem,
            timestamp: (newsItem.dateCreated ? newsItem.dateCreated.toDate().getTime() : new Date().getTime() ),
            message: newsItem.message,
            title: newsItem.title,
            _classes : ''
        }

    }


    return (
        <Card title={isIncidentsOnly ? 'Incidents' : sectionHeading ? sectionHeading :'Shift Information Feed'} bodyStyle={{padding: 0}} extra={extra}>
            <Table
                columns={vehicleFieldSettings}
                sortDirections={['ascend', 'descend', 'ascend']}
                dataSource={newsItems.map(reformatNewsItem)}
                pagination={{size: 'small'}}
                scroll={{x:true}}
            />

        </Card>
    );
};

export default NewsTicker;
