import React, {useRef, useState, useEffect} from 'react';
import Measure from 'react-measure';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import shutter from '../../assets/sounds/camera-shutter.mp3';
import {faSpinner, faExclamation, faCheck, faVideo, faImage} from '@fortawesome/free-solid-svg-icons';

export default ({handleDataUri = async () => null, ...props}) => {
  const videoRef = useRef();
  const canvasRef = useRef();
  const flashingRef = useRef();
  const overlayRef = useRef();
  const shutterAudio = new Audio(shutter);
  const constraints = {audio: false, video: {facingMode: 'environment'}};
  const [container, setContainer] = useState({width: 0, height: 0});
  const [isFlashing, setIsFlashing] = useState(false);
  const [isHiding, setIsHiding] = useState(false);
  const [windowResized, setWindowResized] = useState(true);
  const [status, setStatus] = useState('warning');

  const buttonAttributeMap = {
    inert: {icon: faExclamation},
    active: {icon: faSpinner, spin: true},
    preview: {icon: faImage},
    video: {icon: faVideo},
    success: {icon: faCheck},
    warning: {icon: faExclamation},
    error: {icon: faExclamation},
  };

  const initVideo = () => {
    setStatus('active');
    navigator.mediaDevices.getUserMedia({...constraints, ...container}).then((stream) => (videoRef.current.srcObject = stream));
  };

  const startVideo = () => {
    videoRef.current.play();
    setStatus('video');
  };

  const stopVideo = () => videoRef.current && videoRef.current.srcObject && videoRef.current.srcObject.getTracks().forEach((track) => track.stop());

  const getPhoto = () => canvasRef.current.toDataURL('image/jpeg', 1.0);

  const showPreview = () => {
    canvasRef.current.getContext('2d').drawImage(videoRef.current, 0, 0, container.width, container.height);
  };

  const clearPreview = () => canvasRef.current.getContext('2d').clearRect(0, 0, container.width, container.height);

  const handleResize = (contentRect) => setContainer({width: contentRect.bounds.width, height: contentRect.bounds.height});

  const handleWindowResize = () => () => setWindowResized(true);

  const handleContainerResize = (contentRect) => windowResized && handleResize(contentRect) && setWindowResized(false);

  const handleCapturePreview = async () => {
    try {
      showPreview();
      setStatus('active');
      shutterAudio.play();
      setIsFlashing(true);
      const dataURL = getPhoto();
      handleDataUri(dataURL)
        .then(() => setStatus('preview'))
        .catch(() => {
          setStatus('error');
          throw new Error('Failed retrieving photo.');
        });
    } catch (error) {
      setStatus('error');
      throw new Error('Failed retrieving photo from video.');
    }
  };

  const handleClearPreview = () => {
    setStatus('video');
    clearPreview();
  }

  const handleCanvasAnimationEnd = () => {
    clearPreview();
    setIsHiding(false);
  };

  const handleFlashingAnimationEnd = () => setIsFlashing(false);

  // const handleClearVideo = () => {
  //   setStatus('active');
  //   stopVideo();
  // };

  useEffect(() => {
    if (canvasRef.current) {
      canvasRef.current.style.width = `${container.width}px`;
      canvasRef.current.style.height = `${container.height}px`;
      canvasRef.current.width = container.width;
      canvasRef.current.height = container.height;
    }
    if (flashingRef.current) {
      flashingRef.current.style.width = `${container.width}px`;
      flashingRef.current.style.height = `${container.height}px`;
    }
    if (overlayRef.current) {
      overlayRef.current.style.width = `${container.width - 40}px`;
      overlayRef.current.style.height = `${container.height - 40}px`;
    }
  }, [container]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    initVideo();
    return () => {
      stopVideo();
      setStatus('warning');
      window.removeEventListener('resize', handleWindowResize);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Measure bounds onResize={handleContainerResize}>
      {({measureRef}) => (
        <div id={'preview-camera'} style={{display: status === 'inert' ? 'none' : 'flex'}} ref={measureRef} {...props}>
          <video ref={videoRef} className={'video'} onCanPlay={startVideo} onLoadedData={() => setWindowResized(true)} autoPlay playsInline muted />
          <canvas ref={canvasRef} className={`canvas ${isHiding ? 'hiding' : ''}`} onAnimationEnd={handleCanvasAnimationEnd} />
          <div ref={flashingRef} className={`flashing ${isFlashing ? 'active' : ''}`} onAnimationEnd={handleFlashingAnimationEnd} />
          <div ref={overlayRef} className={'overlay'}>
            <button onClick={status === 'preview' ? handleClearPreview : handleCapturePreview} disabled={status === 'active' || isHiding}>
              <div className={status}>
                <FontAwesomeIcon {...buttonAttributeMap[status]} />
              </div>
            </button>
          </div>
        </div>
      )}
    </Measure>
  );
};
