import React, {useContext} from 'react';
import { useForm } from "react-hook-form";
import Styles from './CreatePartForm.module.scss';
import { createPart } from '../../../../services/PartsService'
import {CModalBody, CModalFooter, CModalHeader, CModalTitle} from "@coreui/react";
import { UserContext } from "../../../../context/userContext";

// ------ CLASSES ----- //

class Part {
    constructor(partID, partName) {
        this.partID = partID;
        this.partName = partName;
    }
}

// ------ JSX ----- //

const CreatePartForm = (props) => {
    const { register, handleSubmit, errors, reset } = useForm();
    const { toggleFullPageLoader } = useContext(UserContext);

    const createNewPart = async (data) => {
        try {
            toggleFullPageLoader(true);
            props.hide();
            reset();
            await createPart(new Part(data.partID, data.partName));
        } finally {
            toggleFullPageLoader(false);
        }
    }
    
    return (
        <>
            <CModalHeader closeButton>
                <CModalTitle>Create Part</CModalTitle>
            </CModalHeader>

            <form className="d-flex flex-column" onSubmit={handleSubmit(createNewPart)}>
                <CModalBody>

                    <div className={Styles.partForm}>
                        <label htmlFor="partID">Part ID:</label>
                        <input className={Styles.inputPrimary}
                               type="text"
                               id="partID"
                               name="partID"
                               placeholder="enter the part ID"
                               ref={register({required: true})}/>
                        {errors.partID && <p>Part ID is required.</p>}

                        <label htmlFor="partName">Part Name:</label>
                        <input className={Styles.inputPrimary}
                               type="text"
                               id="partName"
                               name="partName"
                               placeholder="enter the part name"
                               ref={register({required: true})}/>
                        {errors.partName && <p>Part Name is required.</p>}
                    </div>

                </CModalBody>
                <CModalFooter>
                    <button className="btn btn-secondary" data-dismiss="modal" aria-label="Close" type="button" onClick={()=>(props.hide())}>Cancel</button>
                    <button className="btn btn-primary" type="submit">Update</button>
                </CModalFooter>
            </form>
        </>
    );
}

export default CreatePartForm;