import React, { useState, useEffect } from 'react';
import useModal from '../../../components/Modal/useModal';
import CreateSiteForm from './CreateSiteForm';
import SiteItem from './SiteItem';
import { getSites, subscribeToSites } from '../../../services/SitesService';
import {CCard, CCardBody, CCardHeader, CCardText, CModal} from "@coreui/react";

const ManageSites = () => {
    const {isShowing, toggle} = useModal();
    const [sitesArr, setSitesArr] = useState([]);
    const [siteNamesArr, setSiteNamesArr] = useState([null]);
    const [filteredSitesArr, setFilteredSitesArr] = useState([]);
        
    useEffect(() => {
        getSites().then(response => {
            setFilteredSitesArr(response);
            setSitesArr(response);
            setSiteNamesArr([...new Set(response.map(site => site.siteName))]);
        });
        return subscribeToSites(setSitesArr);
    }, [])

    useEffect(() => {
        if (sitesArr) {
            setFilteredSitesArr(sitesArr);
            setSiteNamesArr([...new Set(sitesArr.map(site => site.siteName))])
        }
    }, [sitesArr])

    const filterSites = (siteName) => {
        if (siteName) {
        setFilteredSitesArr(sitesArr.filter((each) => each.siteName === siteName))
        } else {
        setFilteredSitesArr(sitesArr)
        }
    };

    return (
        <>
            <CCard>
                <CCardHeader className={'d-flex align-items-center justify-content-between'}>
                    Sites
                    <div className={'card-header-actions align-items-center justify-content-center d-flex'}>
                        <div className={'p-2'}>
                            <button className="btn-primary btn" onClick={toggle}>Create New Site</button>
                            <CModal show={isShowing} size={'lg'} >
                                {<CreateSiteForm hide={toggle}/>}
                            </CModal>
                        </div>
                        <div className={'p-2'}>
                            <select className="selectPrimary custom-select" onChange={(event) => filterSites(event.target.value)}>
                                <option value="">All Sites</option>
                                {siteNamesArr ? siteNamesArr.map((site) => <option key={site} value={site}>{site}</option>) : null}
                            </select>
                        </div>
                    </div>

                </CCardHeader>

                <CCardBody className="">
                    <CCardText tag={'div'}>
                        <div className={`position-relative table-responsive`}>
                            <table className={`table table-hover`} >
                                <thead key="titles" className="">
                                <tr>
                                    <th className="border-top-0">ID</th>
                                    <th className="border-top-0">Site Name</th>
                                    <th className="border-top-0">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {filteredSitesArr.map((site) => <SiteItem key={site.siteID} site={site} />)}
                                </tbody>
                            </table>
                        </div>
                    </CCardText>
                </CCardBody>
            </CCard>

        </>

    )
}

export default ManageSites;