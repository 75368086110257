import React from 'react';
import {daysOfWeek, monthsOfYear} from '../utilities/DateUtil';

export default ({date = new Date(), dayOfMonth = true, dayOfWeek = true, monthOfYear = true, year = true, className = '', ...props}) => {
  return (
    <span className={`formatted-date ${className}`} {...props}>
      {dayOfWeek && <span className={'day-of-week'}>{daysOfWeek[date.getDay()]}</span>}
      {monthOfYear && <span className={'month-of-year'}>{monthsOfYear[date.getMonth()]}</span>}
      &nbsp;
      {dayOfMonth && <span className={'day-of-month'}>{date.getDate()}</span>}
    </span>
  );
}
