import React from "react";
import NewsTicker from "../NewsTickerByAnt";
import VehicleTable from "../VehicleTableByAnt";
import UserTable from "../UserTableByAnt";
import Styles from './Dashboard.module.scss'
import {Select} from "antd";

const Dashboard = (props) => {

    const {
        teamsAvailableToView,
        handleTeamChange,
        teamToView,
        vehiclesArr,
        user,
        filteredVehiclesArr,
        filteredUsersArr,
        filteredNewsItemsArr,
        filteredVehicleAdditionalTextArr,
        setModalContent,
        setShowModal,
        numChecklistApproval,
        numReportedIssuesApproval,
        numMaintenanceIssues
    } = props;

    const selectChange = (value) => {
        handleTeamChange({
            target: {
                value
            }
        })
    }

    const selectRender =
        <Select value={teamToView} onChange={selectChange}>
            {teamsAvailableToView.map(team => (
                <Select.Option key={team} value={team}>{team}</Select.Option>
            ))}
        </Select>

  return (
    <>
      {!user.isOnShift &&
        <section>
          <div className="alert alert-danger fade show">
            Please begin your shift to proceed
          </div>
        </section>
      }

      {user.isOnShift &&
        <>
            { !user.isOnShift &&
                <section>
                    <div className="alert alert-danger fade show">
                        Please begin your shift to proceed
                    </div>
                </section>
            }

            { user.isOnShift &&
                <>
                    <section>
                        <div className={Styles.info}>
                            <div className={Styles.helloLabel}>
                                Hello!
                            </div>
                            <div>
                                <span>Welcome back {user.fullNameStr}. You have</span>
                                <span className={Styles.countNumber}>{numChecklistApproval}</span>
                                <span>Checklists To Confirm, </span>
                                <span className={Styles.countNumber}>{numReportedIssuesApproval}</span>
                                <span>Reported Issues And</span>
                                <span className={Styles.countNumber}>{numMaintenanceIssues}</span>
                                <span>Maintenance To Approve</span>
                            </div>
                        </div>

                        <NewsTicker
                            teamsAvailableToView={teamsAvailableToView}
                            teamToView={teamToView}
                            incidentsListing={false}
                            newsItems={filteredNewsItemsArr}
                            setModalContent={setModalContent}
                            toggle={setShowModal}
                            user={user}
                            vehicleItems={vehiclesArr}
                            filteredVehicleAdditionalTextArr={filteredVehicleAdditionalTextArr}
                            extra={selectRender}
                        />
                    </section>

                    <section>
                        <VehicleTable filteredVehiclesArr={filteredVehiclesArr} />
                    </section>

                    <section>
                        <UserTable filteredUsersArr={filteredUsersArr}/>
                    </section>
                </>
            }
        </>
      }
    </>
  )
}

export default Dashboard;
