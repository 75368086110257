import React, { useState, useEffect, useContext } from 'react';
import { useForm } from "react-hook-form";
import Styles from './CreateTeamForm.module.scss';
import {createTeam, getTeams} from '../../../../services/TeamsService'
import { getSites } from '../../../../services/SitesService'
import {CModalBody, CModalFooter, CModalHeader, CModalTitle} from "@coreui/react";
import { UserContext } from "../../../../context/userContext";

// ------ CLASSES ----- //

class Team {
    constructor(site, teamName, subTeamName) {
      this.site = site;
      this.teamName = teamName;
      this.subTeamName = subTeamName;
    }
}

// ------ JSX ----- //

const CreateTeamForm = (props) => {
    const { register, handleSubmit, errors, reset } = useForm();
    const { hide, subteam } = props;
    const [sitesArr, setSitesArr] = useState([]);
    const [teamsArr, setTeamsArr] = useState([]);
    const [teamOptsArr, setTeamOptsArr] = useState([]);
    const { toggleFullPageLoader } = useContext(UserContext);

    const createNewTeam = async (data) => {
        try {
            toggleFullPageLoader(true);
            hide();
            reset();
            await createTeam(new Team(data.site, data.teamName || '', data.subTeamName || ''));
        } finally {
            toggleFullPageLoader(false);
        }
    };

    const filterTeamsBySite = ( site ) => {
        setTeamOptsArr(teamsArr.filter(team => team.site === site));
    };
    
    useEffect(() => {
        getSites().then(response => {
            setSitesArr(response.map(site => site));
        });
    }, []);

    useEffect(() => {

        // If we are creating sub team fetch list of teams and build up a list of parent teams
        if( subteam ){

            getTeams().then(response => {
                const parentTeams = response.filter(team => !team.subTeamName);
                setTeamsArr(parentTeams);
                setTeamOptsArr(parentTeams);
            });
        }

    },  [ subteam ]);

    
    return (
        <>

        <CModalHeader closeButton>
            <CModalTitle>{subteam ? 'Create SubTeam' :   'Create Team'}</CModalTitle>
        </CModalHeader>

        <form className="d-flex flex-column" onSubmit={handleSubmit(createNewTeam)}>
            <CModalBody>
                <div className={Styles.teamForm}>
                        <label htmlFor="site">Select Site :</label>
                        <select
                            onChange={(e)=>(filterTeamsBySite(e.target.value))}
                            className={Styles.selectPrimary}
                            name="site"
                            id="site"
                            ref={register({ required: true })}>
                            <option value="">Select site :</option>
                            {sitesArr.map((site) => <option key={site.siteID}>{site.siteName}</option>)}
                        </select>
                        {errors.site && <p>Site is required.</p>}

                    {!subteam &&
                        <>
                            <label htmlFor="teamName">Team Name:</label>
                                <input
                                className={Styles.inputPrimary}
                                type="text"
                                id="teamName"
                                name="teamName"
                                placeholder="enter the team name"
                                ref={register({required: true})} />
                            {errors.teamName && <p>Team Name is required.</p>}
                        </>
                    }

                    { subteam && teamOptsArr.length > 0 &&

                        <>

                            <label htmlFor="teamName">Select Team :</label>
                            <select
                                className={Styles.selectPrimary}
                                name="teamName"
                                id="teamName"
                                ref={register({ required: true })}>
                                <option value="">Select parent team :</option>
                                {teamOptsArr.map((pTeam) => <option key={pTeam.teamID}>{pTeam.teamName}</option>)}
                            </select>
                            {errors.teamName && <p>Parent Team is required.</p>}
                        </>
                    }

                    { subteam &&

                        <>
                        <label htmlFor="subTeamName">Sub Team Name :</label>
                        <input
                          className={Styles.inputPrimary}
                          type="text"
                          id="subTeamName"
                          name="subTeamName"
                          placeholder="enter the Sub Team Name"
                          ref={register({required: true})} />
                        {errors.subTeamName && <p>Sub Team Name is required.</p>}
                        </>
                    }

                    </div>
            </CModalBody>

            <CModalFooter>
                <button className="btn btn-secondary" data-dismiss="modal" aria-label="Close" type="button" onClick={()=>( hide() )}>Cancel</button>
                <button className="btn btn-primary" type="submit">Submit</button>
            </CModalFooter>

        </form>


        </>

    );
}

export default CreateTeamForm;