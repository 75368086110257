import React, { useContext } from 'react';
import { UserContext } from "../../../context/userContext";
import {useForm} from "react-hook-form";
import { updateUser } from "../../../services/UsersService";
import { updateVehicle } from "../../../services/VehiclesService";
import showToast from "../../../services/toastService";
import {createNewsItem} from "../../../services/newsItemsService";
import {CModalBody, CModalFooter, CModalHeader, CModalTitle} from "@coreui/react";

export const AssignVehicles = (props) => {

    const { usersArr, vehiclesArr, showModal } = props;

    const { register, handleSubmit} = useForm();
    const { toggleFullPageLoader } = useContext(UserContext);
    console.log("f");
    const onSubmit = async (data) => {
        try {
         
            const updatedUser = usersArr.filter(user => user.userID === data.driver)[0];
            const updatedVehicle = vehiclesArr.filter(vehicle => vehicle.vehicleID === data.vehicle)[0];

            if(updatedUser && updatedUser.userID && updatedVehicle && updatedVehicle.vehicleID){
                
                if(updatedUser.isOnShift){
                    showToast(`${updatedUser.fullNameStr}(${updatedUser.userID}) is currently on shift, end shift before you can reassign vehicle`, 5000);
                    return false;
                }

                // if(updatedVehicle.userIsOnShift){
                //     showToast(`${updatedVehicle.vehicleType}-${updatedVehicle.vehicleID} is currently in use by ${updatedVehicle.currentUser}`, 5000);
                //     return false;
                // }
                
            }

            toggleFullPageLoader(true);

            // Keep reassign vehicle logic simple - a user must end their shift before they can be reassigned a new vehicle
            await updateUser({
                  ...updatedUser,
                  assignedVehicle: data.vehicle
              }
            );
            
            //No longer add the user to the currentUser field on the vehicle - this code can be removed once business has finalized rules on this functionality
            //remove operator from current vehicle
            // if(updatedUser.assignedVehicle && updatedUser.assignedVehicle !== ''){
            //     await updateVehicle({
            //         vehicleID : updatedUser.assignedVehicle,
            //         currentUser: null,
            //         userIsOnShift : false                    
            //     });
            // }
            // add operator to assigned vehicle
            // await updateVehicle({
            //     ...updatedVehicle,
             //    currentUser: data.driver, 
            //     userIsOnShift : updatedUser.isOnShift,
            //     userEndShiftTime : updatedUser.endShiftTime,
            //     userStartShiftTime : updatedUser.startShiftTime,
            //     checkItemStatus : '',
            //     actionedBy : '',
            //     goStatus : "No go"
            // });

            await createNewsItem({
                dateCreated: new Date(),
                title: `Vehicle Assigned`,
                message: `User ${updatedUser.userID} assigned to vehicle ${updatedVehicle.vehicleType}-${updatedVehicle.vehicleID}`,
                team: updatedUser.currentTeam,
                type: "vehicleAssigned",
                info: {},
                seenBy: [],
                isImportant: false
            });

            toggleFullPageLoader(false);
            showToast(`${updatedUser.fullNameStr} assigned to ${updatedVehicle.vehicleType}-${updatedVehicle.vehicleID}`, 5000);
            showModal( false );
        } catch (error) {
            toggleFullPageLoader(false);
            console.error('Supervisor : Reassignment error onSubmit', error );
            showToast('Something went wrong while trying to assign a vehicle to a user, please try again.', 5000);
        }
    };

    return (

        <section>
            <CModalHeader closeButton>
                <CModalTitle>Assign Vehicles</CModalTitle>
            </CModalHeader>

            <form className={''} action="" onSubmit={handleSubmit(onSubmit)}>
                <CModalBody>
                    <div className="d-flex flex-column">

                        <label>Operator</label>
                        <select className="" name="driver" ref={register({required: true})}>
                            {usersArr.map(employee => <option key={employee.userID} value={employee.userID}>{`${employee.userID}-${employee.fullNameStr}`}</option>)}
                        </select>

                        <label>Vehicle</label>
                        <select className="" name="vehicle" ref={register({required: true})}>
                            {vehiclesArr.map(vehicle => <option key={vehicle.vehicleID} value={vehicle.vehicleID}>{`${vehicle.vehicleType}-${vehicle.vehicleID}`}</option>)}
                        </select>

                    </div>

                </CModalBody>
                <CModalFooter className="d-flex justify-content-between">
                    <button className="btn btn-danger" type="button" data-dismiss='modal' onClick={()=>{showModal(false)}}>Cancel</button>
                    <button className={`btn btn-primary`} type="submit">Submit</button>
                </CModalFooter>
            </form>

        </section>
    )
}

export default AssignVehicles;