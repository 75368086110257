import React, { useContext } from "react";
import fb_client_config from "../../client_instance_config";
import { UserContext } from "../../context/userContext";
import CIcon from "@coreui/icons-react";
import { Link } from "@reach/router";
import { CSidebar, CSidebarBrand, CSidebarClose, CSidebarNav, CSidebarFooter } from "@coreui/react";


const SideNav = (props) => {

  const { isDocked, setIsDocked, isMobileDocked, setMobileIsDocked } = props;
  const { user } = useContext(UserContext);

  const logoList = (fb_client_config[document.domain] && fb_client_config[document.domain].brand) ? fb_client_config[document.domain].brand : fb_client_config.default.brand;
  const activeSiteLogo = logoList.logo;
  const footerDefaultLogo = fb_client_config.default.brand.logo;
  const activeSiteLogoHeight = 60;
  const activeSiteLogoClass = logoList.class || '';

  document.title = logoList.metaTitle || '';

  return (
    <>
      <CSidebar show={false} className={`${isDocked ? 'c-sidebar' : 'c-sidebar-lg-show'}`}>
        <CSidebarClose onClick={() => { setIsDocked(!isDocked) }} className={`${isDocked ? '' : 'd-none'}`} ></CSidebarClose>
        <CSidebarBrand tag={"div"} className={"align-middle"}>
          <Link to={`/${user.userType}`}>
            <CIcon name={"logo"} src={require(`../../assets/images/${activeSiteLogo}`)} className={`c-sidebar-brand-full ${activeSiteLogoClass}`} height={activeSiteLogoHeight} ></CIcon>
          </Link>
        </CSidebarBrand>

        <CSidebarNav className={"px-3 py-4"}>
          {props.children}
        </CSidebarNav>

        <CSidebarFooter>
          <div className="d-flex align-items-center flex-column justify-content-center">
            {/* <div className="col-12 text-center">
              <CIcon name={"logo"} src={require(`../../assets/images/${footerDefaultLogo}`)} className={``} height={50}></CIcon>
            </div> */}
            <div className="col-12 text-center text-muted font-italic">Powered by &copy; OpsCORE</div>
          </div>
        </CSidebarFooter>
      </CSidebar>

      <CSidebar show={false} className={`${isMobileDocked ? 'c-sidebar-show' : 'd-lg-none'}`}>

        <CSidebarClose onClick={() => { setMobileIsDocked(!isMobileDocked) }} className={"d-lg-none d-xl-none"}></CSidebarClose>

        <CSidebarBrand tag={"div"} className={"align-middle"}>
          <Link to={`/${user.userType}`}>
            <CIcon name={"logo"} src={require(`../../assets/images/${activeSiteLogo}`)} className={`c-sidebar-brand-full ${activeSiteLogoClass}`} height={activeSiteLogoHeight} ></CIcon>
          </Link>
        </CSidebarBrand>

        <CSidebarNav className={"px-3 py-4"}>
          {props.children}
        </CSidebarNav>

        <CSidebarFooter>
          <div className="d-flex align-items-center flex-column justify-content-center">
            <div className="col-12 text-center">
              <CIcon name={"logo"} src={require(`../../assets/images/${footerDefaultLogo}`)} height={50}></CIcon>
            </div>
            <div className="col-12 text-center text-muted font-italic">Powered by &copy; OpsCORE</div>
          </div>
        </CSidebarFooter>
      </CSidebar>
    </>

  )
}

export default SideNav
