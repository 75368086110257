import React, {useContext} from 'react'
import {Dropdown, PageHeader, Menu, Button} from "antd";
import {DownOutlined} from '@ant-design/icons'
import {navigate} from "@reach/router";
import {UserContext} from "../../../../context/userContext";

const CHECKLIST = 'Checklist'
const ISSUES = 'Issues'
const ASSIGNMENT = 'Assignment'

const VehiclePageHeader = (props) => {

    const {title, vehicleID} = props;
    const { user } = useContext(UserContext);

    const menuItemClick = (item) => {
        if(item.key === CHECKLIST){
            navigate(`/${user.userType}/vehicleHistory/checklistHistory/${vehicleID}`)
        }
        else if(item.key === ISSUES){
            navigate(`/${user.userType}/vehicleHistory/issuesHistory/${vehicleID}`)
        }
        else if(item.key === ASSIGNMENT){
            navigate(`/${user.userType}/vehicleHistory/assignmentHistory/${vehicleID}`)
        }
    }

    const menu = (
        <Menu onClick={menuItemClick}>
            <Menu.Item key={CHECKLIST}>Checklist History</Menu.Item>
            <Menu.Item key={ISSUES}>Issues History</Menu.Item>
            <Menu.Item key={ASSIGNMENT}>Assignment History</Menu.Item>
        </Menu>
    )

    return (
        <PageHeader
            onBack={() => window.history.back()}
            subTitle={(
                <Dropdown overlay={menu} trigger={'click'}>
                    <Button size={"large"} style={{fontWeight: 'bold'}}>
                        {title}<DownOutlined />
                    </Button>
                </Dropdown>
            )}
        />
    )
}

export default VehiclePageHeader;