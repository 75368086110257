export const supervisorHandover = ['64 64',`
  <title>supervisor handover icon</title> 
    <g>    
        <path d="m21 62h-9c-.553 0-1-.447-1-1v-4.655l-8.787-11.229c-.138-.176-.213-.392-.213-.616v-22c0-1.93 1.57-3.5 3.5-3.5s3.5 1.57 3.5 3.5v12.086l12.707 12.707c.188.187.293.441.293.707v13c0 .553-.447 1-1 1zm-8-2h7v-11.586l-8.883-8.882c-.34-.339-.809-.532-1.289-.532-1.008 0-1.828.82-1.828 1.828 0 .481.195.953.535 1.293l6.172 6.172-1.414 1.414-6.172-6.172c-.723-.723-1.121-1.684-1.121-2.707 0-1.733 1.159-3.202 2.742-3.671l-1.449-1.45c-.188-.187-.293-.441-.293-.707v-12.5c0-.827-.673-1.5-1.5-1.5s-1.5.673-1.5 1.5v21.655l8.787 11.229c.138.176.213.392.213.616z"/>
        <path d="m52 62h-9c-.553 0-1-.447-1-1v-13c0-.266.105-.52.293-.707l12.707-12.707v-12.086c0-1.93 1.57-3.5 3.5-3.5s3.5 1.57 3.5 3.5v22c0 .224-.075.44-.213.616l-8.787 11.229v4.655c0 .553-.447 1-1 1zm-8-2h7v-4c0-.224.075-.44.213-.616l8.787-11.229v-21.655c0-.827-.673-1.5-1.5-1.5s-1.5.673-1.5 1.5v12.5c0 .266-.105.52-.293.707l-1.449 1.45c1.583.469 2.742 1.938 2.742 3.671 0 1.022-.398 1.984-1.121 2.707l-6.172 6.172-1.414-1.414 6.172-6.172c.34-.34.535-.811.535-1.293 0-1.008-.82-1.828-1.828-1.828-.479 0-.949.193-1.289.532l-8.883 8.882z"/>
        <path d="m43 24c-.158 0-.316-.037-.462-.113-.331-.172-.538-.514-.538-.887v-3h-10v-2h11c.553 0 1 .447 1 1v2.079l7.256-5.079-7.256-5.079v2.079c0 .553-.447 1-1 1h-21v3c0 .373-.207.715-.538.887-.331.173-.729.147-1.035-.067l-10-7c-.268-.188-.427-.494-.427-.82s.159-.632.427-.819l10-7c.306-.215.704-.24 1.035-.067.331.171.538.513.538.886v3h10v2h-11c-.553 0-1-.447-1-1v-2.079l-7.256 5.079 7.256 5.079v-2.079c0-.553.447-1 1-1h21v-3c0-.373.207-.715.538-.887.331-.174.729-.147 1.035.067l10 7c.268.188.427.494.427.82s-.159.632-.427.819l-10 7c-.171.12-.372.181-.573.181z"/>
        <path d="m34 6h2v2h-2z"/>
        <path d="m38 6h2v2h-2z"/>
        <path d="m24 18h2v2h-2z"/>
        <path d="m28 18h2v2h-2z"/>
        <path d="m43 42c-.158 0-.316-.037-.462-.113-.331-.172-.538-.514-.538-.887v-3h-10v-2h11c.553 0 1 .447 1 1v2.079l7.256-5.079-7.256-5.079v2.079c0 .553-.447 1-1 1h-21v3c0 .373-.207.715-.538.887-.331.173-.729.147-1.035-.067l-10-7c-.268-.188-.427-.494-.427-.82s.159-.632.427-.819l10-7c.306-.215.704-.24 1.035-.067.331.171.538.513.538.886v3h10v2h-11c-.553 0-1-.447-1-1v-2.079l-7.256 5.079 7.256 5.079v-2.079c0-.553.447-1 1-1h21v-3c0-.373.207-.715.538-.887.331-.173.729-.147 1.035.067l10 7c.268.188.427.494.427.82s-.159.632-.427.819l-10 7c-.171.12-.372.181-.573.181z"/>
        <path d="m34 24h2v2h-2z"/>
        <path d="m38 24h2v2h-2z"/>
        <path d="m24 36h2v2h-2z"/>
        <path d="m28 36h2v2h-2z"/>
    </g>

`]
