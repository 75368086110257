import React, { useState, useEffect, useContext } from 'react';
import { useForm, Controller } from "react-hook-form";
// eslint-disable-next-line no-unused-vars
import Styles from "../../../assets/styles/shift-reporter/_shiftui.scss";
import showToast from "../../../services/toastService";
import { UserContext } from "../../../context/userContext";

import { subscribeToChecklistCollection, mergeUpdateCheckList } from "../../../services/ChecklistsService";
import ChecklistView from "./ChecklistView";
import { CCard, CCardBody, CCardHeader, CCardText } from "@coreui/react";
import Creatable  from "react-select/creatable";
import { SUPERVISOR, MAINTENANCE, MANAGEMENT } from '../../../enums/UserTypes';

const ManageChecklists = () => {

    const [vehicleTypesArr, setVehicleTypesArr] = useState([]);
    const [items, setItems] = useState(1);
    const [checklistItems, setChecklistItems] = useState([]);
    const [vehicleTypeOptions, setVehicleTypeOptions] = useState([]);
    const { register, handleSubmit, errors, reset, control } = useForm();
    const { toggleFullPageLoader } = useContext(UserContext);

  const addChecklistItem = (e) => {
        e.preventDefault();
        setItems(items+1);
    };

    const removeChecklistItem = (e) => {
        e.preventDefault();
        setItems(items-1);
    };

    const onSubmit = async (data) => {
      try {
        toggleFullPageLoader(true);
        let checkObj = {};
        data.checkItems.forEach((item) => {
          if (checkObj[item.class]) {
            checkObj[item.class][item.check] = {
              iconUrl: item.image,
              assignedRole: item?.role ?? MAINTENANCE
            };
          } else {
            checkObj[item.class] = {
              [item.check]: {
                iconUrl: item.image,
                assignedRole: item?.role ?? MAINTENANCE
              }
            };
          }
        });


        for (let i = 0; i < data.vehicleNames.length; i++) {
          await mergeUpdateCheckList( data.vehicleNames[i].value , {...checkObj})
        }

        reset({vehicleNames: ''});
        setItems(1);
        toggleFullPageLoader(false);
        showToast("Vehicle checklist was added successfully.", 2000);

      } catch(error) {
        toggleFullPageLoader(false);
      }
    };

    const itemsRender = () => {
        let indexes = [];
        for (let i=0; i<items; i++) {
          indexes.push(i)
        }
      return (
        indexes.map(index => {
          const fieldName = `checkItems[${index}]`;
          return (
            <div className="d-flex py-2 flex-column flex-sm-row align-items-start row" key={fieldName}>

                <div className="d-flex flex-sm-wrap col my-2 m-sm-0 align-items-center">

                  <label htmlFor="class" className="col-5 m-0">Class of check:</label>
                  <select className="custom-select col-7" name={`${fieldName}.class`} ref={register({required: true})} id="class">
                    <option value="classA">classA</option>
                    <option value="classB">classB</option>
                    <option value="classC">classC</option>
                  </select>

                { errors['checkItems'] && errors['checkItems'][index] && errors['checkItems'][index]['class'] && <div className="error py-2 text-center col-7 offset-5">Class selection is required.</div> }

                </div>

                <div className="d-flex flex-sm-wrap col my-2 m-sm-0 align-items-center">

                    <label htmlFor="role" className="col-5 m-0">Responsible Role:</label>
                    <select className="custom-select col-7 text-capitalize" name={`${fieldName}.role`} ref={register({required: true})} id="role">
                      <option value={MAINTENANCE}>{MAINTENANCE}</option>
                      <option value={MANAGEMENT}>{MANAGEMENT}</option>
                      <option value={SUPERVISOR}>{SUPERVISOR}</option>
                  </select>

                    { errors['checkItems'] && errors['checkItems'][index] && errors['checkItems'][index]['role'] && <div className="error py-2 text-center col-7 offset-5">Role selection is required.</div> }

                </div>

                <div className="d-flex flex-sm-wrap col my-2 m-sm-0 align-items-center">

                    <label htmlFor="check" className="col-5 m-0">Check:</label>
                    <input className="form-control col-7" type="text" name={`${fieldName}.check`} ref={register({required: true})} id="check"/>
                    { errors['checkItems'] && errors['checkItems'][index] && errors['checkItems'][index]['check'] && <div className="error py-2 text-center col-7 offset-5">Parts to check is required.</div> }

                </div>

                <div className="d-flex flex-sm-wrap col my-2 m-sm-0 align-items-center">

                    <label htmlFor="image" className="col-5 m-0">Icon URL address:</label>
                    <input className="form-control col-7" type="text" name={`${fieldName}.image`} ref={register({required: true})} id="image"/>
                    { errors['checkItems'] && errors['checkItems'][index] && errors['checkItems'][index]['image'] && <div className="error py-2 text-center col-7 offset-5">Icon url is required.</div> }

                </div>

            </div>
          );
        })
      );
    }

    useEffect(() => {
      subscribeToChecklistCollection( setChecklistItems );
    }, [])

    useEffect(() => {
        setVehicleTypesArr( checklistItems.map(checklist => checklist.id) )
    }, [checklistItems])

    useEffect(() => {
        itemsRender()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items])

    useEffect(() => {
       setVehicleTypeOptions( vehicleTypesArr.map((vehicleType) => ({value : vehicleType, label : vehicleType})) )
    }, [vehicleTypesArr])

    return (
        <>
                <CCard>
                    <CCardHeader className={'d-flex align-items-center justify-content-between'}>
                        Checklists
                        <div className={'card-header-actions align-items-center justify-content-center d-flex'}>
                        </div>
                    </CCardHeader>

                </CCard>

                <CCard>
                    <CCardHeader className={'d-flex align-items-center justify-content-between'}>
                        Add a new checklist
                        <div className={'card-header-actions align-items-center justify-content-center d-flex'}></div>
                    </CCardHeader>
                    <CCardBody className="">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <CCardText tag={'div'}>
                          <div className="d-flex align-items-center py-2 row col-12 col-sm-10">

                              <div className="d-flex flex-wrap col m-0 align-items-center row p-0">

                                <label htmlFor="vehicleNames" className="col-sm-2 col-12 m-0">Vehicle type name:</label>

                                  <Controller isMulti
                                          closeMenuOnSelect={false}
                                          className="col col-12 col-sm-9 py-3 p-sm-0 p-sm-2"
                                          name="vehicleNames"
                                          id="vehicleNames"
                                          options={vehicleTypeOptions}
                                          as={Creatable}
                                          control={control}
                                          rules={{ required: true }}
                                  />

                                  {errors['vehicleNames'] && <div className="col-sm-9 col-12 offset-5 error py-3">Vehicle name is required.</div>}

                              </div>

                          </div>
                          {itemsRender()}
                          <div className="d-flex justify-content-between align-items-center py-2">
                            <div>
                              <button className="btn btn-secondary mx-1" onClick={removeChecklistItem} disabled={items === 1}>-</button>
                              <button className="btn btn-secondary mx-1" onClick={addChecklistItem}>+</button>
                            </div>
                            <div>
                              <button className="btn btn-primary" type="submit">Submit</button>
                            </div>
                          </div>
                        </CCardText>
                      </form>
                    </CCardBody>
                </CCard>

                <CCard>
                    <CCardHeader className={'d-flex align-items-center justify-content-between'}>
                        Checklists
                    </CCardHeader>
                    <CCardBody className="">

                        {checklistItems.map((item) => <ChecklistView key={item.id} checkItem={item} checklistCollection={checklistItems} />)}

                    </CCardBody>

                </CCard>

        </>
    )
}

export default ManageChecklists
