import React from "react";
import Styles from "./UserTable.module.scss";
import {DateUtil} from "../../../utilities/DateUtil";
import {CCard, CCardBody, CCardHeader, CCardText, CDataTable} from "@coreui/react";

const UserTable = (props) => {

    const userData = props.filteredUsersArr;

    const userFieldSettings = [
        { key: 'name', label: 'Name', sorter: false},
        { key: 'vehicle', label: 'Assigned Vehicle', sorter: false},
        { key: 'type', label: 'User Type', _classes: 'sorter-left'},
        { key: 'userID', label: 'User ID', sorter: false},
        { key: 'status', label: 'Status', _classes: 'sorter-left'},
        { key: 'shiftStart', label: 'Shift Start', sorter: false},
        { key: 'shiftEnd', label: 'Shift End', sorter: false },
      ]

    const userSorting = {column: 'status', desc: 'true'}

    const userReformat = (user) => {

        return (
          {
            _classes : user.isOnShift ? Styles.onshift : Styles.unavailable,
            name : user.fullNameStr,
            vehicle : user.assignedVehicle ? user.assignedVehicle : '',
            type : user.userType,
            userID : user.userID,
            status : user.isOnShift ? "On Shift" : "Not On Shift",
            shiftStart : user.startShiftTime ? DateUtil( user.startShiftTime.toDate() ) : '',
            shiftEnd :  user.endShiftTime  && user.endShiftTime >  user.startShiftTime ? DateUtil( user.endShiftTime.toDate() ) : '',
          }
        )

    }
  
  let list;
  return (
    <section className={Styles.userListSection}>

      <CCard className={``}>
        <CCardHeader className={'d-flex align-items-center justify-content-between'}>
          Team Members
          <div className={'card-header-actions align-items-center justify-content-center d-flex'}>
            <div className={'p-2'}>Total</div>
            <div className={'badge badge-info p-3'}>{userData && userData.length}</div>
            <div className={'p-2'}>On Shift</div>
            <div className={'badge badge-info p-3'}>{ userData && userData.filter(user => user.isOnShift).length}</div>
            <div className={'p-2'}>Away</div>
            <div className={'badge badge-info p-3'}>{userData && userData.filter(user => !user.isOnShift).length}</div>
          </div>
        </CCardHeader>
        <CCardBody className={""}>
          <CCardText tag={'div'}>
            <div className={`position-relative table-responsive`}>

              <CDataTable
                  items={userData.map(userReformat)}
                  fields={userFieldSettings}
                  addTableClasses={Styles.tableContainer}
                  itemsPerPage={10}
                  pagination={{ addListClass: Styles.paginationContainer }}
                  sorter
                  sorterValue={userSorting}
                  responsive={true}
                  hover={true} />

          </div>
          </CCardText>
        </CCardBody>
      </CCard>


    </section>
  );
};

export default UserTable;
