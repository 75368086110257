import React, { useState, useContext } from "react";
import styles from "./NewsItemModal.module.scss";
import { sortBy } from "lodash";
import showToast from "../../../../services/toastService";
import {updateVehicle, updateVehicleIssues, getVehicleByID} from "../../../../services/VehiclesService";
import {updateNewsItem, createNewsItem, updateOrhpanedNewsItems } from "../../../../services/newsItemsService";
import {DateUtil} from "../../../../utilities/DateUtil";
import {CCardText, CModalHeader, CModalTitle, CModalBody, CModalFooter } from "@coreui/react";
import { UserContext } from "../../../../context/userContext";
import { getUser } from "../../../../services/UsersService";
import {createJobFromReportedIssue} from "../../../../services/MaintenanceJobsService";
import { createVehicleEngineHours, getShiftVehicleEngineHours } from "../../../../services/VehicleEnginehoursService";
import CIcon from "@coreui/icons-react";
import { freeSet } from '@coreui/icons';
import { downloadReportUtil } from "../../../../utilities/DownloadReportUtil";
import {useForm} from "react-hook-form";
import { GO_BUT_STATUS, GO_STATUS, NOGO_STATUS,NOT_AVAILABLE, AVAILABLE } from "../../../../enums/VehicleStatuses";
import { APPROVED, REJECTED,MAINTENANCE } from "../../../../enums/VehicleChecklistStatuses";
import { CLASS_A, CLASS_B, CLASS_C } from "../../../../enums/VehicleIssueClasses";
import { updateVehicleChecklist } from "../../../../services/VehiclesChecklistService";

const NewsItemModal = (props) => {
    const { toggleFullPageLoader } = useContext(UserContext);
    const { vehicleItems, user, item, toggle , filteredVehicleAdditionalTextArr,vehicleChecklist, vehicleChecklistId } = props;
    const {time, title, message, info, type, errorDetail, status } = item;
    const vehicleID = info.vehicleID;
    // eslint-disable-next-line no-unused-vars
    const [isActive, setIsActive] = useState(true);
    const [isClosingHourEdit, setClosingHourEdit] = useState(false);
    const [driverName, setDriverName] = useState('');
    const [openingHour, setOpeningHour] = useState(0);
    const [closingHour, setClosingHour] = useState(0);
    let additionalInfo, filteredIssues, vehicleDetail, filteredTextArr ;

    const { register, handleSubmit , reset } = useForm();

    if( vehicleID ){

        vehicleDetail = vehicleItems.filter( item => (item.vehicleID === vehicleID) ) //Todo: Can be optomized - might be expensive once lots of vehicles added

        vehicleDetail = vehicleDetail[0];
     
        if(type.toLowerCase() === "vehiclechecklistcomplete"){
          if(vehicleChecklist && vehicleChecklist.checkItems.length){
            filteredIssues = vehicleChecklist.checkItems;
            filteredIssues = sortBy( filteredIssues , ['classType' ,'issue']);
          }else if( vehicleItems && vehicleItems.length ){
            //Todo: Can remove once we fully refactored vehicle checklist - this is to display older newsfeed item issues
            filteredIssues = vehicleDetail.checkItems;
            filteredIssues = sortBy( filteredIssues , ['classType' ,'issue']);
          }
        }

        if( filteredVehicleAdditionalTextArr ) {
              filteredTextArr = filteredVehicleAdditionalTextArr.filter(textItem => (textItem.vehicleID === vehicleID && textItem.detail))
              filteredTextArr = sortBy(filteredTextArr, 'classType')
        }

    }

      if( item && item.userShiftId ){
        const getShiftHours = getShiftVehicleEngineHours( item.userShiftId )

        getShiftHours.then(result =>{

          const openingHourResult = result.filter( item => item.type === 'openingHours' );
          const closingHourResult = result.filter( item => item.type === 'closingHours' );

          openingHourResult.map((engineHour)=>(setOpeningHour(engineHour.openingHours)))
          closingHourResult.map((engineHour)=>(setClosingHour(engineHour.closingHours)))

        })

      }

    const approvalChecklist = ( data ) => {
    return (

          <tr key={data.issueID} className={styles.issueItem}>
              <td>{data.classType}</td>
              <td>{data.issue}</td>
              <td>{data.additionalDetails}</td>
              <td width={'12%'}>{ data.photo ? <img className={'border rounded'} alt="" src={data.photo} style={{width:'100%', height: 'auto'}} /> : ''}</td>
          </tr>

    )

      }

    const additionalData = ( data ) => {

           if( !['opening_hours'].includes(data.label) ){

             return (
              <tr key={data.key} className={data.key}>
                  <td>{ ['opening_hours'].includes(data.label) ? ' - ':data.classType}</td>
                  <td>{data.label}</td>
                  <td>{data.detail}</td>
              </tr>
             )

           }

  }

    const preventChecklistDuplicateAction = async (vehicleID) => {
      const vehicleData = await getVehicleByID(vehicleID);
      if (vehicleData && ['Approved', 'Rejected'].indexOf(vehicleData.checkItemStatus) > -1) {
        toggle();
        showToast("Cannot action this checklist because it was already actioned.", 5000, 'background-color: red; color: white');
        return true;
      }
    };

    const approveVehicleChecklist = async () => {

        try {
          let legacyVehicleID = info.vehicle;
          legacyVehicleID = legacyVehicleID.split('-');

          let vehicleID = info.vehicleID ? info.vehicleID : legacyVehicleID[ legacyVehicleID.length - 1 ];
          if (await preventChecklistDuplicateAction(vehicleID)) {
            return;
          }

          toggleFullPageLoader(true);
          setIsActive(false );

          let goStatus = GO_STATUS;
          let status = AVAILABLE;
          let checkItemStatus = APPROVED;
          let supervisorCheckItemApproved = true;
          let actionedBy = user.userID;
          let driver = info.driver;
          let vehicle = info.vehicle;
          let usershiftId = (vehicleChecklist && vehicleChecklist.usershiftId) ? vehicleChecklist.usershiftId : "";
          let hasCheckItemsInMaintenance = false;

          if (filteredIssues.some(item => item.classType === CLASS_A ) ) {
            goStatus = NOGO_STATUS;
            status = NOT_AVAILABLE;
            hasCheckItemsInMaintenance = true;
          } else if (filteredIssues.some(item => item.classType === CLASS_B ) || filteredIssues.some(item => item.classType === CLASS_C )) {
            goStatus = GO_BUT_STATUS;
            hasCheckItemsInMaintenance = true;
          }


          let updatedNewsItem = { ...item };
          updatedNewsItem.message = `${vehicleID} - checklist approved by - ${user.fullNameStr} - ${goStatus}`;
          updatedNewsItem.info['status'] = goStatus;
          updatedNewsItem.status = checkItemStatus;

          let checkItems = vehicleChecklist.checkItems.map( item =>{
            return { ...item, supervisorCheckItemApproved }
          });

          let checkItemAdditionalDetails = {};
          
          const driverDetails = await getUserDetails( driver );

          let engineHours = {
            //openingHours : vehicleDetail.checkItemAdditionalDetails.classA.opening_hours ?? '', Todo: this line was bugged - review
            openingHours : vehicleDetail.engineHours.openingHours ?? '',
            closingHours : '',
            closingHoursDetail : {},
            openingHoursDetail : {
              //openingHours : vehicleDetail.checkItemAdditionalDetails.classA.opening_hours ?? '', Todo: this line was bugged - review
              openingHours : vehicleDetail.engineHours.openingHours ?? '',
              dateCreated : new Date(),
              addedByDriver : {
                userID : driverDetails.userID,
                fullName : driverDetails.fullNameStr,
                userType : driverDetails.userType,
              },
              approvedBy : {
                userID : actionedBy,
                fullName: user.fullNameStr,
                userType: user.userType,
              },
              type : 'openingHours'
            }
          };
          
          // console.log('engineHours', engineHours)
          const vehicleUpdateData = { vehicleID, goStatus , checkItemStatus, actionedBy, engineHours };// Todo: Review which information we update on the vehicle 
          
          /*
          const vehicleChecklistUpdateData = {
            ...vehicleUpdateData,
             vehicleID, 
             goStatus, 
             status: checkItemStatus, 
             checkItems, 
             actionedBy, 
             checkItemAdditionalDetails,
             id : checklistId, 
             userShiftId : userShiftId
             };

            delete vehicleChecklistUpdateData.engineHours;
          */

            let updatedVehicleChecklist = {...vehicleChecklist}
            updatedVehicleChecklist.checkItemStatus = checkItemStatus;
            updatedVehicleChecklist.status = status;
            updatedVehicleChecklist.goStatus = goStatus;
            updatedVehicleChecklist.checkItems = checkItems;
            updatedVehicleChecklist.hasCheckItemsInMaintenance = hasCheckItemsInMaintenance;//Added this additional property so that we know which vehicleChecklists has issues sent to maintenance
            updatedVehicleChecklist.actionedBy = {
              action: checkItemStatus,
              fullName: user.fullNameStr,
              userID: user.userID,
              userType: user.userType,
              dateActioned: new Date(),
              dateActionedStr: DateUtil(new Date())
            }            

           await updateVehicle( vehicleUpdateData );
           //await updateOrhpanedNewsItems( vehicleID, updatedNewsItem ); Todo: review and add back this functioanality if needed - will need to be refactored           
           await createVehicleEngineHours( vehicleID , engineHours );
           await updateVehicleChecklist( vehicleChecklistId,updatedVehicleChecklist)
           await updateNewsItem( updatedNewsItem );

          for (let i = 0; i<filteredIssues.length; i++) {
            const issue = filteredIssues[i];
            let letters = issue.classType.split("");
            letters[0] = letters[0].toUpperCase();
            letters.splice(5, 0, " ");
            let title = letters.join("");

            await createNewsItem({
              title: `${title} issue raised`,
              message: `${issue.issue} reported on ${vehicle}`,
              team: item.team,
              type: "maintenanceRaised",
              isImportant: false,
              seenBy: [],
              info:{
                vehicle: vehicle,
                driver: driver,
                faultClass: issue.classType,
                issue: issue.issue,
                faultDescription: issue.additionalDetails,
                vehicleChecklistId:vehicleChecklistId,
                usershiftId: usershiftId
              },
              dateCreated: new Date()
            });
          }

          toggle();
        } finally {
          toggleFullPageLoader(false);
        }
    };

    const rejectVehicleChecklist = async () => {
        try {

            let legacyVehicleID = info.vehicle;
            legacyVehicleID = legacyVehicleID.split('-');
            
            let vehicleID = info.vehicleID ? info.vehicleID : legacyVehicleID[ legacyVehicleID.length - 1 ];
            let userShiftId = '';
            let checklistId='';
            
            /* Todo: review and add back this functioanality 
            if (await preventChecklistDuplicateAction(vehicleID)) {
              return;
            }
            */
          toggleFullPageLoader(true);

          let goStatus = info.status;
          let checkItemStatus =  REJECTED;
          let actionedBy = user.userID;

          setIsActive(false );

          let createRejectNewsItem = { ...item };
          createRejectNewsItem.message = `${vehicleID} - checklist rejected by - ${user.fullNameStr} - ${goStatus}`;
          createRejectNewsItem.userType = 'operator';
          createRejectNewsItem.dateCreated =  new Date();
          createRejectNewsItem.dateCreatedStr = DateUtil( new Date() );
          delete createRejectNewsItem.id;
          delete createRejectNewsItem.status;
          createRejectNewsItem.status = checkItemStatus;  
          //update existing newsitem, so that it doesn't keep appearing in checklist-review
          let updateRejectedNewsItem = { ...item };
          updateRejectedNewsItem.message = createRejectNewsItem.message;
          updateRejectedNewsItem.status = checkItemStatus;

          let checkItems = vehicleDetail.checkItems.filter( item =>{
            return (item.supervisorCheckItemApproved !== undefined && item.supervisorCheckItemApproved === true);
          });

          let checkItemAdditionalDetails = {};
          const vehicleUpdateData = { vehicleID, goStatus , checkItemStatus, actionedBy, checkItemAdditionalDetails };
          
          /*
          const vehicleChecklistUpdateData = {
            ...vehicleUpdateData,
             status: checkItemStatus, 
             checkItems,
             id : checklistId, 
             userShiftId : userShiftId
          };
          */
          //delete vehicleChecklistUpdateData.engineHours;

          let updatedVehicleChecklist = {...vehicleChecklist}
          updatedVehicleChecklist.checkItemStatus = checkItemStatus;
          updatedVehicleChecklist.status = NOT_AVAILABLE;
          updatedVehicleChecklist.goStatus = goStatus;
          updatedVehicleChecklist.actionedBy = {
            action: checkItemStatus,
            fullName: user.fullNameStr,
            userID: user.userID,
            userType: user.userType,
            dateActioned: new Date(),
            dateActionedStr: DateUtil(new Date())
          }
          
           await updateVehicle(  vehicleUpdateData );
          // await updateVehicleChecklist(  vehicleUpdateData );
          await updateVehicleChecklist( vehicleChecklistId,updatedVehicleChecklist)
          //await createNewsItem( createRejectNewsItem );
          await updateNewsItem( updateRejectedNewsItem );
          // await updateOrhpanedNewsItems( vehicleID, updateRejectedNewsItem ); Todo: review and add back this functioanality if needed - will need to be refactored
          

          toggle();
        } finally {
          toggleFullPageLoader(false);
        }
    };

    const rejectVehicleReportedIssue = async () => {
        try{

          toggleFullPageLoader(true);
          setIsActive(false);

          let updatedNewsItem = { ...item };
          updatedNewsItem.info['status'] = 'Rejected';
          await updateNewsItem( updatedNewsItem );

          let reportedIssueObj = updatedNewsItem.errorDetail;
          updatedNewsItem.title = `${reportedIssueObj.issue} issue rejected`;    
          updatedNewsItem.message = `Reported issue - ${reportedIssueObj.issue} on ${reportedIssueObj.vehicleID} - rejected by - ${user.fullNameStr}`  ;
          updatedNewsItem.type = "issueRejected";
          updatedNewsItem.dateCreated = new Date();
          updatedNewsItem.info['status'] = 'Rejected';

          await createNewsItem(updatedNewsItem);

          toggle();

        } finally {
          
          toggleFullPageLoader(false);

        }

    };

    const approveVehicleReportedIssue = async () => {
        try {

          toggleFullPageLoader(true);
          setIsActive(false );

          let updatedNewsItem = { ...item, supervisorSignoff: true };
          updatedNewsItem.info['status'] = 'Approved';
          await updateNewsItem( updatedNewsItem );

          let reportedIssueObj = updatedNewsItem.errorDetail;
          reportedIssueObj.supervisor = user.userID;
          reportedIssueObj.supervisorFullName = user.fullNameStr;
          console.log("updatedNewsItem >> ",updatedNewsItem);
          await createJobFromReportedIssue(reportedIssueObj);

          updatedNewsItem.title = `${reportedIssueObj.issue} issue raised`;    
          updatedNewsItem.message = `${reportedIssueObj.issue} reported on ${reportedIssueObj.vehicleID}`  ;
          updatedNewsItem.type = "maintenanceRaised";
          updatedNewsItem.dateCreated = new Date();
        

          await createNewsItem(updatedNewsItem);

          toggle();
        } finally {
          toggleFullPageLoader(false);
        }
    };

    const ctaButtonWording =  filteredIssues && filteredIssues.length > 0  ? "Send to Maintenance": "Approve checklist"
      
    const reformatReportData = ( reportData )=>{
      
      let reform = {...reportData};

      reform.date = DateUtil(reform.date.toDate());
      reform.dumping_stockpile = reform.dumping_stockpile.join(',');
      reform.dust_suppression = reform.dust_suppression.join(',');
      reform.loading_conditions = reform.loading_conditions.join(',');
      reform.machine_conditions = reform.machine_conditions.join(',');
      reform.road_conditions = reform.road_conditions.join(',');
      reform.workplace = reform.workplace.join(',');

      return reform

    }

    const downloadReport = async( reportName, reportData ) => {

      const fields = [
        {
          label: 'DATE',
          value: 'date'
        },
        {
          label: 'DUMPING STOCKPILE',
          value: 'dumping_stockpile'
        },
        {
          label: 'DUST SUPPRESSION',
          value: 'dust_suppression'
        },
        {
          label: 'FIRST LOAD TIME',
          value: 'first_load_time'
        },
        {
          label: 'LAST LOAD TIME',
          value: 'last_load_time'
        },
        {
          label: 'LOADING CONDITIONS',
          value: 'loading_conditions'
        },
        {
          label: 'MACHINE CONDITIONS',
          value: 'machine_conditions'
        },
        {
          label: 'ROAD CONDITIONS',
          value: 'road_conditions'
        }
        ,
        {
          label: 'WORKPLACE',
          value: 'workplace'
        
        },
        {
          label: 'COMMENTS',
          value: 'comments'
        
        },
      ];

      const reformattedData = await reformatReportData(reportData);

      await downloadReportUtil( reportName , reformattedData, fields );

    }

    const getUserDetails = async ( driverId )=>{
      
      const details = await getUser( driverId );
      return details;
    }

    const getUserFullnameStr = ( driver )=>{
      
      getUserDetails( driver )
      .then((driverItem)=>{
        setDriverName( driverItem.fullNameStr ?? driver);
      });

      return driverName;

    }

    const approveClosingHours = async( details, currentUser, newsitem ) =>{

      const { vehicle, closingHours , user } = details;

      try {

        toggleFullPageLoader(true);

        const closingHourSnippet = {
          closingHours : closingHours,
          closingHoursDetail : {
            dateCreated : new Date(),
            addedByDriver : {
              userID : user.userID,
              fullName : user.fullNameStr,
              userType : user.userType,
            },
            approvedBy : currentUser,
            type : 'closingHours'
          }
        }


        const engineHours = {
          ...vehicle.engineHours,
          ...closingHourSnippet
        }

        await updateVehicle({...vehicle, engineHours});

        await updateNewsItem( { ...newsitem,  approvedBy : currentUser.fullNameStr } );

        await createVehicleEngineHours( vehicle.vehicleID, {closingHours, ...closingHourSnippet.closingHoursDetail} );

        toggle();

      }
      catch(error){
        console.error('approveClosingHours', error );
      }
      finally {
        toggleFullPageLoader(false);
      }

    }

    const handleClosingHourEditSubmit = async( formData ) => {

        const { info } = item;

        const updateInfo = {
            ...info,
            closingHours : formData.updateClosingHour,
            changedClosingHoursFrom : info.closingHours
        }

        item.info = updateInfo;

        try{
            await approveClosingHours( updateInfo , user, item );
            setClosingHourEdit(false);
            reset();
        } catch(error){
                console.error('Error  handleClosingHourEditSubmit ', error );
        }

    }

    //Todo: Review item status logic 
    let itemStatus = '';
    if( status ){
      itemStatus = status;
    }else if( vehicleDetail && vehicleDetail.checkItemStatus ){
      itemStatus = vehicleDetail.checkItemStatus ;
    }else if(  message.toLowerCase().indexOf('rejected') > 0 ){
      itemStatus = 'Rejected';
    }else if(  message.toLowerCase().indexOf('approved') > 0 ){
      itemStatus = 'Approved';
    }

    switch (type.toLowerCase()) {

        case "supervisorreport" :
          additionalInfo = (
            <>
              <p>Date: { info["date"].toDate() ? DateUtil(info["date"].toDate()) : ''}</p>
              <p>Workplace: {info["workplace"].join(', ')}</p>
              <p>Machine Conditions: {info["machine_conditions"].join(', ')}</p>
              <p>Dust Suppression: {info["dust_suppression"]}</p>
              <p>Conditions on the Haul Roads: {info["road_conditions"]}</p>
              <p>Dumping/Stockpile Position: {info["dumping_stockpile"]}</p>
              <p>Loading Conditions: {info["loading_conditions"]}</p>
              <p>Time for First Load: {info["first_load_time"]}</p>
              <p>Time for Last Load: {info["last_load_time"]}</p>
              <p>Instructions for Oncoming Shift: {info["comments"]}</p>
            </>
          )
          break;

        case "maintenanceraised" :
          additionalInfo = (
            <>
              <p>Vehicle: {info["vehicle"]}</p>
              <p>Driver: {getUserFullnameStr(info["driver"])}</p>
              <p>Fault Class: {info["faultClass"]}</p>
              <p>Issue: {info["faultName"]}</p>
              <p>Fault Description: {info["faultDescription"]}</p>
            </>
          )
          break;

        case "maintenancecomplete" :
          additionalInfo = (
            <>
              <p>Vehicle: {info["vehicle"]}</p>
              <p>Fault Class: {info["faultClass"]}</p>
              <p>Issue: {info["faultName"]}</p>
              <p>Completed By: {getUserFullnameStr(info["completedBy"])}</p>
              <p>Go to Maintenance SignOff to Approve</p>
              {/* {console.log('info', item)} */}
            </>
          )
          break;

        case "maintenancesuperviorsignoff" :
          additionalInfo = (
            <>
              <p>Vehicle: {info["vehicle"]}</p>
              <p>Fault Class: {info["faultClass"]}</p>
              <p>Issue: {info["faultName"]}</p>
              <p>Completed By: {getUserFullnameStr(info["completedBy"])}</p>
            </>
          )
          break;

        case "vehiclechecklistcomplete" :
          additionalInfo = (
              <>
                <p>Vehicle: {info["vehicle"]}</p>
                <p>Driver: { getUserFullnameStr(info["driver"]) }</p>
                
                { (openingHour > 0 ) &&
                  <p>Opening Hours : { openingHour }</p>
                }
                
                { (closingHour > 0) &&  (closingHour > openingHour) &&
                  <p>Closing Hours : { closingHour }</p>
                }

                <div className="table-responsive">

                    { itemStatus === "Pending" && filteredIssues && filteredIssues.length > 0 &&

                          <table className='table'>
                              <thead key="issue-titles">
                              <tr>
                                  <th>Class Type</th>
                                  <th>Issue</th>
                                  <th>Additional Details</th>
                              </tr>
                              </thead>

                              <tbody>
                                {filteredIssues.map(approvalChecklist )}
                              </tbody>

                          </table>


                    }

                    { itemStatus === "Pending" && filteredVehicleAdditionalTextArr && filteredVehicleAdditionalTextArr.length && filteredTextArr && filteredTextArr.length > 0 &&

                        <div className="table-responsive py-3">
                            <h3 className="mt-2 mb-4"> Additional comments</h3>
                            <table className='table'>
                                <thead key="msg-titles">
                                <tr>
                                    <th>Class Type</th>
                                    <th>Item</th>
                                    <th>Details</th>
                                </tr>
                                </thead>

                                <tbody>
                                    {filteredTextArr.map(additionalData)}
                                </tbody>

                            </table>
                        </div>

                    }



                    { filteredIssues && filteredIssues.length < 1 && info && itemStatus !== 'Approved' && itemStatus !== 'Rejected' && info.status === 'No go' &&

                        <div className="d-flex justify-content-center align-content-center p-2">
                            No issues recorded
                        </div>

                    }

                    {
                        info && itemStatus !== 'Approved' && itemStatus !== 'Rejected' && info.status === 'No go' &&
                        <div className='d-flex justify-content-between'>
                            <button className={styles.btnSecondary} onClick={() => {
                                rejectVehicleChecklist();
                            }}> Reject
                            </button>
                            <button className={styles.btnPrimary} onClick={() => {
                                approveVehicleChecklist();
                            }}> {ctaButtonWording}
                            </button>
                        </div>
                    }

                    { itemStatus === 'Approved' &&

                        <div className="d-flex justify-content-center align-content-center p-2 alert-success">
                            Issue already Approved
                        </div>

                    }

                    { itemStatus === 'Rejected' &&

                        <div className="d-flex justify-content-center align-content-center p-2 alert-danger">
                            Issue Rejected
                        </div>

                    }

                </div>


              </>
            )
          break;

        case "vehicleissuereported" :
          additionalInfo = (
              <>

                <p>Vehicle: {info.vehicle}</p>
                <p>Reported by Driver: {getUserFullnameStr(info["driver"])}</p>

                <div className="table-responsive">

                    { errorDetail &&
                      <table className='table'>
                          <thead>
                              <tr key="issue-titles">
                                  <th>Class Type</th>
                                  <th>Issue</th>
                                  <th>Additional Details</th>
                                  <th>Photo</th>
                              </tr>
                          </thead>

                          <tbody>
                            { approvalChecklist(errorDetail) }
                          </tbody>

                      </table>

                    }

                    { info.status === 'Approved' &&

                        <div className="d-flex justify-content-center align-content-center p-2 alert-success">
                           Issue already Approved
                        </div>

                    }

                    { info.status === 'Rejected' &&

                        <div className="d-flex justify-content-center align-content-center p-2 alert-danger">
                            Issue Rejected
                        </div>

                    }

                    {
                        errorDetail && !info.status &&
                        <div className="d-flex justify-content-between">
                            <button className={styles.btnSecondary} onClick={() => {
                                rejectVehicleReportedIssue();
                            }}> Reject
                            </button>
                            <button className={styles.btnPrimary} onClick={() => {
                                approveVehicleReportedIssue();
                            }}> Send to maintenance
                            </button>
                        </div>
                    }

                </div>


              </>
            )
          break;

        case "vehicleassigned" :
          additionalInfo = (
            <>
            </>
          )
        break;

        case "loadreported" :
          additionalInfo = (
            <>
              <p>Driver: {getUserFullnameStr(info["driver"])}</p>
              <p>Go to Approve Loads to Approve</p>
            </>
          )
          break;

        case "loadapproved" :
          additionalInfo = (
            <>
              <p>Driver: {getUserFullnameStr(info["driver"])}</p>
              <p>Mass: {info["mass"]}</p>
              <p>Material: {info["material"]}</p>
              <p>Approved By: {getUserFullnameStr(info["approvedBy"])}</p>
            </>
          )
          break;

        case "loadcreatedbysupervisor" :
          additionalInfo = (
            <>
              <p>Driver: {getUserFullnameStr(info["driver"])}</p>
              <p>Mass: {info["mass"]}</p>
              <p>Material: {info["material"]}</p>
              <p>Approved By: {getUserFullnameStr(info["approvedBy"])}</p>
            </>
          )
          break;

        case "supervisorincident":
          additionalInfo = (
            <>
              <p>Reported By: {getUserFullnameStr(info["reportedBy"])}</p>
            </>
          )

        break;

        case "vehicleclosinghourreported":
          additionalInfo = (
            <>

                <table className='table'>
                    <thead key="closing-hours-titles">
                    <tr>
                        <th>Vehicle ID</th>
                        <th>Operator</th>
                        <th>Closing Hour</th>
                    </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>{info.vehicleID} </td>
                        <td>{info.user.fullNameStr}</td>
                        <td> {info.closingHours}</td>
                      </tr>
                    </tbody>

                </table>

            </>
          )
        break;

        case "vehicleopeninghourreported":
          additionalInfo = (
            <>

                <table className='table'>
                    <thead key="opening-hours-titles">
                    <tr>
                        <th>Vehicle ID</th>
                        <th>Operator</th>
                        <th>Opening Hour</th>
                    </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>{info.vehicleID} </td>
                        <td>{info.user.fullNameStr}</td>
                        <td> {info.openingHours}</td>
              
                      </tr>
                    </tbody>

                </table>
            </>
          )
        break;

        default:
        break;
  }

  return (
    <>

        <CModalHeader closeButton>
            <CModalTitle>{title}</CModalTitle>
        </CModalHeader>
        <CModalBody>
            <CCardText tag={'div'}>
                <div className={''}>
                    <p className="time">{time}</p>
                    <p className="message">{message}</p>
                    <div className={styles.additionalInfoContainer}>{additionalInfo}</div>
                </div>
            </CCardText>
        </CModalBody>
        <CModalFooter className="d-flex justify-content-between">
            <button className="btn btn-danger" type="button" data-dismiss='modal' onClick={()=>{toggle(false)}}>Cancel</button>
            
            { type && type.toLowerCase() === "supervisorreport"  &&
              <button className="btn btn-success" type="button" data-dismiss='modal' onClick={()=>{downloadReport( type, info)}}>
                <CIcon name="" content={freeSet.cilCloudDownload} className="mx-2"></CIcon>
                Download Report
              </button>
            }
        </CModalFooter>

    </>
  );
};

export default NewsItemModal;