import { MAINTENANCE } from "../enums/UserTypes";
import { firestore } from "../firebase";

const getChecklists  = (vehicleType) => {
        return firestore
            .collection("checklistData")
            .doc(vehicleType)
            .get()
            .then(response => response.data())
}

const getChecklistCollection  = () => {

        return firestore
            .collection("checklistData")
            .get()
            .then(response => response.docs.map(document => {

                    let responseDoc = document.data();
                    responseDoc.id = document.id;
                    return responseDoc;
                }

            ));

}

const subscribeToChecklistCollection  = ( setState ) => {

        return firestore
            .collection("checklistData")
            .onSnapshot(snapshot => setState(snapshot.docs.map(document =>
                {
                    let responseDoc = document.data();
                    responseDoc.id = document.id;
                    return responseDoc;
                }
            )))
}

const subscribeToChecklist = () => {
    console.log("subscibe to checklists here")
}

const createChecklist  = (data) => {
    Object.keys(data).forEach(type => {
        firestore
        .collection("checklistData")
        .doc(type)
        .set(data[type]);
    })
}

const updateChecklist  = ( checklistData ) => {

    checklistData.forEach( ( item ) => {

        let  vehicleTypeObj = {
            classA : item.classA,
            classB : item.classB,
            classC : item.classC ,
        };

        //remove undefined items
        Object.keys(vehicleTypeObj).forEach(key => vehicleTypeObj[key] === undefined ? delete vehicleTypeObj[key] : {});

        firestore.collection("checklistData")
            .doc( item.id )
            .update({...vehicleTypeObj} );

        // console.log('vehicleTypeObj', vehicleTypeObj)

    })

}

const MigrateChecklistData = async () => {
    //Still needs to be tested 
    //Updating schema to allow role's to be added to vehicle checklist data
    const checklistData = await getChecklistCollection();

    checklistData.forEach(async(p_item) => {

        let newChecklistDataItem = {};
        let classAitems = {};
        let classBitems = {};
        let classCitems = {};

        if(p_item.classA) {
            Object.keys(p_item.classA).forEach((item) => {
                classAitems[item] = {assignedRole: MAINTENANCE, iconUrl: p_item.classA[item]};
            })
        }

        if(p_item.classB) {
            Object.keys(p_item.classB).forEach((item) => {
                classBitems[item] =  {assignedRole: MAINTENANCE, iconUrl: p_item.classB[item]};
            })
        }
        
        if(p_item.classC) {
            Object.keys(p_item.classC).forEach((item) => {
                classCitems[item] = {assignedRole: MAINTENANCE, iconUrl: p_item.classC[item]};
            })
        }

        newChecklistDataItem.classA = classAitems ?? {};
        newChecklistDataItem.classB = classBitems ?? {};
        newChecklistDataItem.classC = classCitems ?? {};

        // console.log('newChecklistDataItem >> ', newChecklistDataItem );

        // await firestore.collection("checklistData").doc(p_item.id).set({...newChecklistDataItem});//Migrating to a test collection for testing

        // await firestore.collection("checklistData_BKUP").doc(p_item.id).set({...p_item});//Migrating to a test collection for testing

        // console.log('{...p_item.data}', p_item)
    });
}

const AddChecklistDataItem = async (id,newChecklistDataItem) => {

    await firestore.collection("checklistData").doc(id).set({...newChecklistDataItem});//Migrating to a test collection for testing

}

const mergeUpdateCheckList = ( vehicleName, checklistObj )=>(
    firestore
        .collection("checklistData")
        .doc(vehicleName)
        .set({...checklistObj}, {merge: true})
)

const deleteChecklist  = (vehicleType) => {
    firestore.collection("checklistData").doc(vehicleType).delete();
}

export { getChecklists, subscribeToChecklist, createChecklist, updateChecklist, deleteChecklist, getChecklistCollection, subscribeToChecklistCollection, mergeUpdateCheckList, MigrateChecklistData};