import { firestore } from '../firebase';

const getUsers = () => firestore.collection('users')
  .get()
  .then(resp => resp.docs.map(doc => doc.data()))

const getShifts = () => firestore.collection('shifts')
  .get()
  .then(response => response.docs.map(doc => ({ ...doc.data(), id: doc.id })));

const createShift = (shift) => firestore.collection('shifts')
  .add(shift)
  .then((doc) => doc.id);

const updateShift = (id, shift) => firestore.collection('shifts')
  .doc(id)
  .update(shift);

const deleteShift = (id) => firestore.collection('shifts')
  .doc(id)
  .delete();

export { getUsers, getShifts, createShift, updateShift, deleteShift } 
