import React, { useState, useEffect, useContext } from 'react';
import { useForm, Controller } from "react-hook-form";
import Creatable  from "react-select/creatable";

import { UserContext } from "../../../context/userContext";
import { subscribeToWorkareas, createWorkarea, deleteWorkarea } from '../../../services/WorkareasService';
import {CCard, CCardBody, CCardHeader, CCardText, CDataTable, CCollapse, CButton } from "@coreui/react";

const ManageWorkareas = () => {
    const [filteredWorkareasArr, setFilteredWorkareasArr] = useState([]);
    const [workareasOptions, setWorkareasOptions] = useState([]);
    const { handleSubmit, reset, control } = useForm();
    const { toggleFullPageLoader } = useContext(UserContext);
    const [ details, setDetails ] = useState([]);
    const [ deleteItems, setDeleteItems ] = useState('');

    useEffect(() => {
        subscribeToWorkareas( setFilteredWorkareasArr );
    }, [])

    useEffect(() => {
        setWorkareasOptions( filteredWorkareasArr.map( area => ({value: area.title, label : area.title })) );
    },[filteredWorkareasArr])

    const onSubmit = async (data) => {

        try {
            
          toggleFullPageLoader(true);

          data.workareas.forEach((item) => {

            let newarea = {
                title: item.value 
            };
            
            if( item.__isNew__ ){
                createWorkarea( newarea );
            }

          });
  
          reset({workareas: ''});
          toggleFullPageLoader(false);

        } catch(error) {
          toggleFullPageLoader(false);
        }
      };

      const areaFormat = (area)=>{
        return (
            {
              _classes : '',
              title : area.title,
              id: area.id
            }
          )
      }

      const areaSorting = {column: 'title', desc: 'asc'}

      const areaFieldSettings = [
        { key: 'title', label: 'Workarea Title', sorter: false},
        // { key: 'edit_details', label: '', _style: { width: '1%' }, sorter: false, filter: false },
        { key: 'delete_area', label: '', _style: { width: '1%' }, sorter: false, filter: false }
      ]

      const toggleDetails = (index) => {
        const position = details.indexOf(index)
        let newDetails = details.slice()
        if (position !== -1) {
          newDetails.splice(position, 1)
        } else {
          newDetails = [...details, index]
        }
        setDetails(newDetails)
      }

      const toggleDelete = (item) => {
            setDeleteItems(item.id)
      }

      const processDeleteWorkarea = (item) =>{
          deleteWorkarea( item );
          setDeleteItems('');
      }


    return (
        <>

            <CCard>
                <CCardHeader className="d-flex align-items-center justify-content-between">
                    Workareas
                    <div className="card-header-actions align-items-center justify-content-center d-flex"></div>
                </CCardHeader>
            </CCard>

            <CCard>
                    <CCardHeader className="d-flex align-items-center justify-content-between">
                        Add a new workarea
                        <div className="card-header-actions align-items-center justify-content-center d-flex"></div>
                    </CCardHeader>
                    <CCardBody className="">
                      <form onSubmit={handleSubmit(onSubmit)}>
                            <CCardText tag={'div'}>
                                <div className="d-flex align-items-center py-2 row col-12">
                                    <div className="d-flex flex-wrap col m-0 align-items-center row p-0 col-11">
                                        <Controller isMulti
                                                closeMenuOnSelect={false}
                                                className="col col-12 py-3 p-sm-0 p-sm-2"
                                                name="workareas"
                                                id="workareas"
                                                options={workareasOptions}
                                                as={Creatable}
                                                control={control}
                                                rules={{ required: true }}
                                        />
                                    </div>

                                    <div className="align-items-center justify-content-center text-center">
                                        <button className="btn btn-primary" type="submit">Submit</button>
                                    </div>
                                </div>
                            </CCardText>
                        </form>
                    </CCardBody>
            </CCard>

            <CCard>
                <CCardHeader className="d-flex align-items-center justify-content-between">
                    List of Workareas
                </CCardHeader>
                <CCardBody className="">
                    <CCardText tag={'div'}>
                        <div className="position-relative table-responsive">
                        <CDataTable
                            items={filteredWorkareasArr.map(areaFormat)}
                            fields={areaFieldSettings}
                            sorter
                            sorterValue={areaSorting}
                            responsive={true}
                            hover={true}
                            scopedSlots = {{                        
                                'edit_details':
                                    (item, index)=>{
                                        return (
                                        <td className="py-2">
                                            <CButton
                                            color="primary"
                                            variant="outline"
                                            shape="square"
                                            size="sm"
                                            onClick={()=>{toggleDetails(index)}}
                                            >
                                            {details.includes(index) ? 'Close' : 'Edit'}
                                            </CButton>
                                        </td>
                                        )
                                    },
                                    'details':
                                        (item, index)=>{
                                        return (
                                        <CCollapse show={details.includes(index)}>
                                            <CCardBody>
                                            <CButton size="sm" color="danger" className="ml-1">
                                                Delete
                                            </CButton>
                                            </CCardBody>
                                        </CCollapse>
                                        )
                                    },
                                    'delete_area':
                                    (item, index)=>{
                                        return (
                                        <td className="py-2">
        
                                            { deleteItems !== item.id &&
                                                <CButton className="col-12" color="danger" variant="outline" shape="square" size="sm" onClick={()=>{toggleDelete(item)}}>
                                                    Delete
                                                </CButton>
                                            }

                                            <CCollapse show={deleteItems === item.id} className="warrn">
                                                <CCardBody className="m-0 p-0">
                                                    <CButton size="sm" color="danger" className="mb-2 col-12" onClick={()=>processDeleteWorkarea(item)}> Confirm </CButton>
                                                    <CButton size="sm" color="info" className="mb-2 col-12" onClick={()=>setDeleteItems('')}> Cancel </CButton>
                                                </CCardBody>
                                            </CCollapse>

                                        </td>
                                        )
                                    }
                            }}
                            />
                        </div>
                    </CCardText>
                </CCardBody>
            </CCard>

        </>
    )
}

export default ManageWorkareas;
