import React, { useState, useEffect, useContext } from 'react';
import { useForm, Controller } from "react-hook-form";
import Creatable  from "react-select/creatable";

import { UserContext } from "../../../context/userContext";
import {createReportedIssue, deleteReportedIssue, subscribeReportedIssues} from "../../../services/ReportedIssuesService";
import {CCard, CCardBody, CCardHeader, CCardText, CDataTable, CCollapse, CButton } from "@coreui/react";

const ManageCustomlists = () => {
    const [ filteredIssuesArr, setFilteredIssuesArr ] = useState([]);
    const [ issuesOptions, setIssuesOptions ] = useState([]);
    const { handleSubmit, reset, control } = useForm();
    const { toggleFullPageLoader } = useContext(UserContext);
    const [ details, setDetails ] = useState([]);
    const [ deleteItems, setDeleteItems ] = useState('');

    useEffect(() => {
        subscribeReportedIssues( setFilteredIssuesArr );
    }, [])

    useEffect(() => {
        setIssuesOptions( filteredIssuesArr.map( issue => ({value: issue.title, label : issue.title })) );
    },[filteredIssuesArr])

    const onSubmit = async (data) => {

        try {
            
          toggleFullPageLoader(true);

          data.issues.forEach((item) => {

            let newissue = {
                title: item.value 
            };
            
            if( item.__isNew__ ){
                createReportedIssue( newissue );
            }

          });
  
          reset({ issues: ''});
          toggleFullPageLoader(false);

        } catch(error) {
          toggleFullPageLoader(false);
        }
      };

      const issueFormat = (issue)=>{
        return (
            {
              _classes : '',
              title : issue.title,
              id: issue.id
            }
          )
      }

      const issueSorting = {column: 'title', desc: 'asc'}

      const issueFieldSettings = [
        { key: 'title', label: 'Reported Issue Title', sorter: false},
        // { key: 'edit_details', label: '', _style: { width: '1%' }, sorter: false, filter: false },
        { key: 'delete_issue', label: '', _style: { width: '1%' }, sorter: false, filter: false }
      ]

      const toggleDetails = (index) => {
        const position = details.indexOf(index)
        let newDetails = details.slice()
        if (position !== -1) {
          newDetails.splice(position, 1)
        } else {
          newDetails = [...details, index]
        }
        setDetails(newDetails)
      }

      const toggleDelete = (item) => {
            setDeleteItems(item.id)
      }

      const processDeleteWorkarea = (item) =>{
          deleteReportedIssue( item );
          setDeleteItems('');
      }


    return (
        <>

            <CCard>
                <CCardHeader className="d-flex align-items-center justify-content-between">
                    Custom Lists
                    <div className="card-header-actions align-items-center justify-content-center d-flex"></div>
                </CCardHeader>
            </CCard>

            <div className="col-12 col-sm-6 col-md-6 pl-0">

                <CCard className="card-accent-primary">
                        <CCardHeader className="d-flex align-items-center justify-content-between">
                            Add a new reported issue
                            <div className="card-header-actions align-items-center justify-content-center d-flex"></div>
                        </CCardHeader>
                        <CCardBody className="">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <CCardText tag={'div'}>
                                    <div className="d-flex align-items-center py-2 row col-12 p-0 m-0">
                                        <div className="d-flex flex-wrap col m-0 align-items-center row p-0">
                                            <Controller isMulti
                                                    closeMenuOnSelect={false}
                                                    className="col col-12 py-3 p-sm-0 p-sm-2"
                                                    name="issues"
                                                    id="issues"
                                                    options={issuesOptions}
                                                    as={Creatable}
                                                    control={control}
                                                    rules={{ required: true }}
                                            />
                                        </div>

                                        <div className="align-items-center justify-content-center text-center">
                                            <button className="btn btn-primary" type="submit">Submit</button>
                                        </div>
                                    </div>
                                </CCardText>
                            </form>

                            <CCardText tag={'div'} className="mt-5">
                                <div className="position-relative table-responsive">
                                <CDataTable
                                    items={filteredIssuesArr.map(issueFormat)}
                                    fields={issueFieldSettings}
                                    sorter
                                    sorterValue={issueSorting}
                                    responsive={true}
                                    hover={true}
                                    scopedSlots = {{                        
                                        'edit_details':
                                            (item, index)=>{
                                                return (
                                                <td className="py-2">
                                                    <CButton
                                                    color="primary"
                                                    variant="outline"
                                                    shape="square"
                                                    size="sm"
                                                    onClick={()=>{toggleDetails(index)}}
                                                    >
                                                    {details.includes(index) ? 'Close' : 'Edit'}
                                                    </CButton>
                                                </td>
                                                )
                                            },
                                            'details':
                                                (item, index)=>{
                                                return (
                                                <CCollapse show={details.includes(index)}>
                                                    <CCardBody>
                                                    <CButton size="sm" color="danger" className="ml-1">
                                                        Delete
                                                    </CButton>
                                                    </CCardBody>
                                                </CCollapse>
                                                )
                                            },
                                            'delete_issue':
                                            (item, index)=>{
                                                return (
                                                <td className="py-2">
                
                                                    { deleteItems !== item.id &&
                                                        <CButton className="col-12" color="danger" variant="outline" shape="square" size="sm" onClick={()=>{toggleDelete(item)}}>
                                                            Delete
                                                        </CButton>
                                                    }

                                                    <CCollapse show={deleteItems === item.id} className="warrn">
                                                        <CCardBody className="m-0 p-0">
                                                            <CButton size="sm" color="danger" className="mb-2 col-12" onClick={()=>processDeleteWorkarea(item)}> Confirm </CButton>
                                                            <CButton size="sm" color="info" className="mb-2 col-12" onClick={()=>setDeleteItems('')}> Cancel </CButton>
                                                        </CCardBody>
                                                    </CCollapse>

                                                </td>
                                                )
                                            }
                                    }}
                                    />
                                </div>
                            </CCardText>

                        </CCardBody>

                </CCard>


            </div>

            <div className="col-12 col-sm-6 col-md-6 pr-0">

            </div>
           
        </>
    )
}

export default ManageCustomlists;
