import React from "react";
import Styles from "./VehicleTable.module.scss";
import {Card, Badge, Space, Table} from 'antd'
import underscore from "underscore";
import {AVAILABLE, NOT_AVAILABLE} from "../../../enums/VehicleStatuses";
import {getColumnSearchProps} from "../../../components/TableColumnSearch/TableColumnSearch";
import { GetVehicleIssues } from "../../../services/MaintenanceJobsService";

const VehicleTable = (props) => {

    const { filteredVehiclesArr } = props;
    const badgeColor = '#32323299';

    const vehicleFieldSettings = [
        {
            key: 'vehicleID',
            dataIndex: 'vehicleID',
            title: 'Vehicle ID',
            render: (text, record) => <span className={record._classes}>{text}</span>,
            ...getColumnSearchProps("vehicleID", "Vehicle ID"),

        },
        {
            key: 'vehicleType',
            dataIndex: 'vehicleType',
            title: 'Vehicle Type',
            sorter: (a, b) => a.vehicleType.localeCompare(b.vehicleType),
            render: (text, record) => <span className={record._classes}>{text}</span>,
            ...getColumnSearchProps("vehicleType", "Vehicle Type"),
        },
        {
            key: 'vehicleStatus',
            dataIndex: 'vehicleStatus',
            title: 'Vehicle Status',
            sorter: (a, b) => a.vehicleStatus.localeCompare(b.vehicleStatus),
            render: (text, record) => <span className={record._classes}>{text}</span>
        },
        {
            key: 'status',
            dataIndex: 'status',
            title: 'Current Status',
            sorter: (a, b) => a.status.localeCompare(b.status),
            defaultSortOrder: 'ascend',
            render: (text, record) => <span className={record._classes}>{text}</span>
        },
        {
            key: 'currentTeam',
            dataIndex: 'currentTeam',
            title: 'Current Team',
            render: (text, record) => <span className={record._classes}>{text}</span>
        },
        {
            key: 'currentUser',
            dataIndex: 'currentUser',
            title: 'Current User',
            render: (text, record) => <span className={record._classes}>{text}</span>
        },
        {
            key: 'classA',
            dataIndex: 'classA',
            title: 'Class A',
            render: (text, record) => <span className={record._classes}>{text}</span>
        },
        {
            key: 'classB',
            dataIndex: 'classB',
            title: 'Class B',
            render: (text, record) => <span className={record._classes}>{text}</span>
        },
    ]

    const vehicleReformat = (vehicle) => {

    let _vehicleStatus = "Available";
    let _currentStatus = "Parked";
    let _classACount = 0;
    let _classBCount = 0;

    let vehicleClasses = {};
    let isUserOnShift = vehicle.userIsOnShift ? vehicle.userIsOnShift : false;

    if (vehicle && vehicle.vehicleID) {

      if(vehicle.openIssues){
        _classACount = vehicle.openIssues.filter(v => v.classType === "classA").length;
        _classBCount = vehicle.openIssues.filter(v => v.classType === "classB").length;
      }

      if(vehicle.currentStatus === "Out for Maintenance"){
        _currentStatus = "Out for Maintenance";
        _vehicleStatus = "Not Available";
      }else if(vehicle.currentStatus === "Parked"){
        _currentStatus = isUserOnShift ? "On Shift" : "Parked";
        if(!isUserOnShift){
          if(vehicleClasses && vehicleClasses.classA){
            _vehicleStatus = "Not Available";
          }else{

          }
        }
      }

    }

    let additionalClasses;
    if (_vehicleStatus.toLowerCase() === NOT_AVAILABLE.toLowerCase()) {
      additionalClasses = 'not-available';
    } else if (isUserOnShift && _vehicleStatus.toLowerCase() === AVAILABLE.toLowerCase()) {
      additionalClasses = 'on-shift-available';
    } else {
      additionalClasses = 'default-state'
    }

    if(_classACount > 0){
        _vehicleStatus = "Not Available";
    }

        return ({
            key: vehicle.vehicleID,
            _classes: additionalClasses,
            vehicleID: vehicle.vehicleID,
            vehicleType: vehicle.vehicleType,
            vehicleStatus: _vehicleStatus,
            vehicleClasses: vehicleClasses,
            status: _currentStatus,
            currentTeam: vehicle.currentTeam ? vehicle.currentTeam : "No team",
            currentUser: isUserOnShift ? (vehicle.currentUser ? vehicle.currentUser : '-') : '-',
            classA: _classACount,
            classB: _classBCount
        })

    }

    return (
        <Card title={'Vehicles On Shift'} bodyStyle={{padding: 0}} extra={(
            <Space size={16}>
                <span>
                    <Badge color={badgeColor} text="Total"/>
                    <span className={Styles.countValue}>{filteredVehiclesArr && filteredVehiclesArr.length}</span>
                </span>
                <span>
                    <Badge color={badgeColor} text="Working"/>
                    <span className={Styles.countValue}>{filteredVehiclesArr && filteredVehiclesArr.filter(v => v.status === "Available").length}</span>

                </span>
                <span>
                    <Badge color={badgeColor} text="Break Down"/>
                    <span className={Styles.countValue}>{filteredVehiclesArr && filteredVehiclesArr.filter(v => v.status !== "Available").length}</span>
                </span>
            </Space>
        )}>
            <Table
                columns={vehicleFieldSettings}
                sortDirections={['ascend', 'descend', 'ascend']}
                dataSource={filteredVehiclesArr.map(vehicleReformat)}
                pagination={{size: 'small'}}
                scroll={{x:true}}
            />
        </Card>
    );
};

export default VehicleTable;
