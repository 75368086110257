import { CCard, CCardBody, CCardHeader } from '@coreui/react';
import React from 'react';
import ReportedIssues from "../ReportedIssues";

export const ReportedIssuesReview = (props) => {

  const {user, issuesPendingApproval, setModalContent, setShowModal, vehiclesArr} = props;

  return (
    <>
      { !user.isOnShift &&
        <section>
          <div className="alert alert-danger fade show">
              Please begin your shift to proceed
          </div>
        </section>
      }

      { user.isOnShift && issuesPendingApproval.length < 1 &&
        <CCard className={``}>
            <CCardHeader>
                {'Reported issues Review'}
            </CCardHeader>

            <CCardBody>

                <div className="table-responsive">
                    <h3>There are currently no reported issues to review</h3>
                </div>
            </CCardBody>
        </CCard>

      }
      { user.isOnShift && issuesPendingApproval.length > 0 &&
        <section>
          <ReportedIssues
            title={'Reported issues'}
            incidentsListing={false}
            newsItems={issuesPendingApproval}
            setModalContent={setModalContent}
            toggle={setShowModal}
            user={user}
            vehicleItems={vehiclesArr}
          />
        </section>
      }
    </>
  )

};

export default ReportedIssuesReview;
