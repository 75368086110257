import React, { useState, useEffect } from 'react';
import useModal from '../../../components/Modal/useModal';
import CreatePartForm from './CreatePartForm';
import PartItem from './PartItem';
import { getParts, subscribeToParts } from '../../../services/PartsService';
import {CCard, CCardBody, CCardHeader, CCardText, CModal} from "@coreui/react";

const ManageParts = () => {
    const {isShowing, toggle} = useModal();
    const [partsArr, setPartsArr] = useState([]);
    const [partNamesArr, setPartNamesArr] = useState([null]);
    const [filteredPartsArr, setFilteredPartsArr] = useState([]);
        
    useEffect(() => {
        getParts().then(response => {
            setFilteredPartsArr(response);
            setPartsArr(response);
            setPartNamesArr([...new Set(response.map(part => part.partName))]);
        });
        return subscribeToParts(setPartsArr);
    }, [])

    useEffect(() => {
        if (partsArr) {
            setFilteredPartsArr(partsArr);
            setPartNamesArr([...new Set(partsArr.map(part => part.partName))])
        }
    }, [partsArr])

    const filterParts = (partName) => {
        if (partName) {
        setFilteredPartsArr(partsArr.filter((each) => each.partName === partName))
        } else {
        setFilteredPartsArr(partsArr)
        }
    };

    return (
        <>
            <CCard>
                <CCardHeader className={'d-flex align-items-center justify-content-between'}>
                    Parts
                    <div className={'card-header-actions align-items-center justify-content-center d-flex'}>
                        <div className={'p-2'}>
                            <button className="btn-primary btn" onClick={toggle}>Create New Part</button>
                            <CModal show={isShowing} size={'lg'} >
                                {<CreatePartForm hide={toggle}/>}
                            </CModal>
                        </div>
                        <div className={'p-2'}>
                            <select className="selectPrimary custom-select" onChange={(event) => filterParts(event.target.value)}>
                                <option value="">All Parts</option>
                                {partNamesArr ? partNamesArr.map((part) => <option key={part} value={part}>{part}</option>) : null}
                            </select>
                        </div>
                    </div>
                </CCardHeader>
                <CCardBody className="">
                    <CCardText tag={'div'}>
                        <div className={`position-relative table-responsive`}>
                            <table className={`table table-hover`} >
                                <thead key="titles" className="">
                                <tr>
                                    <th className="border-top-0">ID</th>
                                    <th className="border-top-0">Part Name</th>
                                    <th className="border-top-0">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {filteredPartsArr.map((part) => <PartItem key={part.partID} part={part} />)}
                                </tbody>
                            </table>
                        </div>
                    </CCardText>
                </CCardBody>
            </CCard>

        </>
    )
}

export default ManageParts;
