import React, { useState } from 'react';
import { Form, Input, TimePicker, Select, Button, Tag, message, Switch } from 'antd';

import { updateShift, createShift } from '../services/ShiftsService';
import moment from 'moment'
import { cloneDeep } from 'lodash';

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 7,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailLayout = {
  wrapperCol: { offset: 6, span: 16 },
};

const week = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]

const EditShiftForm = (props) => {
  const { handleOk, usersInfos, teamsInfos, currentShiftParams, getData, status } = props
  const [form] = Form.useForm();
  const [timePacker, setTimePacker] = useState('');
  const [switchFlag, setSwitchFlag] = useState(currentShiftParams.shiftStatus);

  const onReset = () => {
    form.resetFields();
  };

  const onTimePackerChange = (timer) => {
    if (!timer) {
      setTimePacker(null)
      return
    }
    try {
      const [u, v] = cloneDeep(timer)
      const timerDiffMins = moment(u).diff(v, 'm') < 0 ? moment(v).diff(u, 'm') : moment(u).diff(v, 'm')
      setTimePacker((timerDiffMins / 60).toFixed(1))
    } catch (error) {
      setTimePacker(null)
    }
  }

  const onFinish = (values) => {
    try {
      const [start, end] = values?.startAndEnd?.map(el => moment(el).format('HH:mm'))
      // eslint-disable-next-line no-unused-expressions
      values.shiftStatus ? values.shiftStatus : values.shiftStatus = false
      delete values.startAndEnd
      const shiftParams = { ...values, start, end }
      if (status === 'ADD') {
        createShift(shiftParams).then(id => {
          if (id) message.success(`shift created success! id is [${id}]`);
        })
      } else {
        updateShift(currentShiftParams.id, shiftParams);
      }
      form.resetFields();
      getData()
    } catch (error) {
      console.error('[create shift error]', error)
    }
    handleOk()
  };


  return (
    <Form
      {...formItemLayout}
      form={form}
      name="register"
      initialValues={currentShiftParams}
      onFinish={onFinish}
      scrollToFirstError
    >
      <Form.Item name="name" label="Name"
        rules={[
          { required: true, message: 'Name requires 3 to 32 characters!' },
          {
            pattern: /^[a-zA-Z ]{3,30}$/,
            message: 'Name requires 3 to 32 characters!'
          }
        ]}
        
      >
        <Input placeholder="3 to 32 alphabetic characters." />
      </Form.Item>

      <Form.Item name="supervisor" label="Supervisor" rules={[{ required: true, message: 'Please select supervisor!', },]} >
        <Select
          showSearch
          mode="multiple"
          placeholder="Select supervisor"
        >
          {usersInfos.filter(user => user.userType === "supervisor").map((infos, index) => <Select.Option key={infos.userID + index} value={infos.fullNameStr}>{infos.fullNameStr}</Select.Option>)}
        </Select>
      </Form.Item>

      <Form.Item name="users" label="Users" >
        <Select
          showSearch
          mode="multiple"
          placeholder="select your users"
        >
          {usersInfos.map(infos => <Select.Option key={infos.userID} value={infos.fullNameStr}>
            {infos.fullNameStr}
            <Tag size="small" color="lime" style={{ marginLeft: 10 }}>
              {infos.userType}
            </Tag>
          </Select.Option>)}
        </Select>
      </Form.Item>

      <Form.Item
        name="teams"
        label="Teams"
      >
        <Select
          showSearch
          mode="multiple"
          placeholder="select your Teams"
        >
          {teamsInfos.map(infos => <Select.Option key={infos.teamID} value={infos.teamID}>
            {infos.teamName}
            {infos.site
              ? <Tag size="small" color="lime" style={{ marginLeft: 10 }}> {infos.site} </Tag>
              : null}
            {infos.subTeamName
              ? <Tag size="small" color="lime" > {infos.subTeamName} </Tag>
              : null}
          </Select.Option>)}
        </Select>
      </Form.Item>

      <Form.Item
        name="week"
        label="Days of Week"
        rules={[
          {
            type: 'array',
            required: true,
            message: 'Please select your habitual residence!',
          },
        ]}
      >
        <Select
          showSearch
          mode="multiple"
          placeholder="select your Teams"
        >
          {week.map(week => <Select.Option key={week} value={week}>{week}</Select.Option>)}
        </Select>
      </Form.Item>

      <Form.Item
        name="startAndEnd"
        label="Start and End shift"
        tooltip={
          timePacker ? <span>during {timePacker} hours</span> : null
        }
        rules={[
          {
            required: true,
            message: 'Please input start and end time!',
          }
        ]}
      >
        <TimePicker.RangePicker
          style={{ width: '100%' }}
          minuteStep={30}
          order={false}
          format="HH:mm"
          onChange={onTimePackerChange}
        />
      </Form.Item>
      <Form.Item
        name="shiftStatus"
        label="Shift status"
      >
        <Switch
          checkedChildren="on"
          unCheckedChildren="off"
          checked={switchFlag}
          style={{ width: 60 }}
          onChange={(flag) => {
            setSwitchFlag(flag)
          }} />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button htmlType="button" type='default' onClick={onReset}>Reset</Button>
        <Button htmlType="submit" type="primary" style={{ marginLeft: 10 }}>Submit</Button>
      </Form.Item>
    </Form >
  );
};

export default EditShiftForm