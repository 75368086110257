import React, { useState, useEffect } from 'react';
import useModal from '../../../components/Modal/useModal';
import CreateUserForm from './CreateUserForm';
import UserItem from './UserItem';
import { getUsers, subscribeToUsers } from '../../../services/UsersService';
import {CCard, CCardBody, CCardHeader, CCardText, CModal} from "@coreui/react";

const ManageUsers = () => {
    const {isShowing, toggle} = useModal();
    const [usersArr, setUsersArr] = useState([]);
    const [filteredUsersArr, setFilteredUsersArr] = useState([]);
        
    useEffect(() => {
        getUsers().then(response => {
            setFilteredUsersArr(response);
            setUsersArr(response);
        });
        return subscribeToUsers(setUsersArr);
    }, [])

    useEffect(() => {
        if (usersArr) {
            setFilteredUsersArr(usersArr);
        }
    }, [usersArr]);

    const filterUsers = (userType) => {
        if (userType) {
        setFilteredUsersArr(usersArr.filter((each) => each.userType === userType))
        } else {
        setFilteredUsersArr(usersArr)
        }
    };

    return (
        <>
            <CCard className={``}>
                <CCardHeader className={'d-flex align-items-center justify-content-between'}>

                    Users

                    <div className={'card-header-actions align-items-center justify-content-center d-flex'}>
                        <div className={'p-2'}>
                            <button className="btn-primary btn" onClick={toggle}>Create New User</button>
                            {/*<Modal innerComponent={<CreateUserForm hide={toggle}/>} isShowing={isShowing} hide={toggle} />*/}
                            <CModal show={isShowing} size={'lg'} >
                                {<CreateUserForm hide={toggle}/>}
                            </CModal>
                        </div>
                        <div className={'p-2'}>
                            <select className="selectPrimary custom-select" onChange={(event) => filterUsers(event.target.value)}>
                                <option value="">All Users</option>
                                <option value="operator">Operator</option>
                                <option value="supervisor">Supervisor</option>
                                <option value="maintenance">Maintenance</option>
                                <option value="management">Management</option>
                            </select>
                        </div>
                    </div>

                </CCardHeader>
                <CCardBody className="">
                    <CCardText tag={'div'}>

                    <div className={`position-relative table-responsive`}>
                        <table className={`table table-hover`} >
                            <thead key="titles" className="">
                                <tr>
                                    <th className="border-top-0">ID</th>
                                    <th className="border-top-0">Full Name</th>
                                    <th className="border-top-0">Date of Birth</th>
                                    <th className="border-top-0">On Shift?</th>
                                    <th className="border-top-0">Team</th>
                                    <th className="border-top-0">Sub Team</th>
                                    <th className="border-top-0">Action</th>
                                </tr>
                            </thead>
                            <tbody tag='div'>
                                {filteredUsersArr.map((user) => <UserItem key={user.userID} user={user} />)}
                            </tbody>
                        </table>
                    </div>
                    </CCardText>
                </CCardBody>


            </CCard>

        </>
    )
}

export default ManageUsers;