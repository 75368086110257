import React from "react";
import {Card, Table, Button} from 'antd'
import {DateUtil} from "../../../utilities/DateUtil";
import NewsItemModal from "../NewsTicker/NewsItemModal";

const ReportedIssues = (props) => {

    const sectionHeading = props.title || false;

    const {newsItems,  extra, setModalContent, toggle , user, vehicleItems, filteredVehicleAdditionalTextArr  } = props;

    const vehicleFieldSettings = [
        {
            key: 'timestamp',
            dataIndex: 'timestamp',
            title: 'Date Created',
            sorter: (a, b) => a.timestamp - b.timestamp,
            defaultSortOrder: 'descend',
            render: (value, record) => DateUtil(record.dateCreated ? record.dateCreated.toDate().toString().substring(0, 21) : new Date().toString().substring(0, 21))
        },
        {
            key: 'title',
            dataIndex: 'title',
            title: 'Category',
        },
        {
            key: 'message',
            dataIndex: 'message',
            title: 'Title',
        },
        {
            key: 'action',
            dataIndex: 'action',
            title: 'action',
            render: (_, record) => <Button type='primary' onClick={() => toggleDetails(record)}>Open</Button>
        },
    ]

    const toggleDetails = (item) => {
        toggle(true);
        setModalContent(<NewsItemModal item={item} filteredVehicleAdditionalTextArr={filteredVehicleAdditionalTextArr} vehicleItems={vehicleItems} user={user} toggle={toggle} />)
    }

    const reformatNewsItem =  ( newsItem ) => {
        return {
            ...newsItem,
            timestamp: (newsItem.dateCreated ? newsItem.dateCreated.toDate().getTime() : new Date().getTime() ),
            message: newsItem.message,
            title: newsItem.title,
            _classes : ''
        }

    }

    return (
        <Card title={sectionHeading} bodyStyle={{padding: 0}} extra={extra}>
            <Table
                columns={vehicleFieldSettings}
                sortDirections={['ascend', 'descend', 'ascend']}
                dataSource={newsItems.map(reformatNewsItem)}
                pagination={{size: 'small'}}
                scroll={{x:true}}
            />

        </Card>
    );
};

export default ReportedIssues;
