import React, { useState } from 'react';
import EditTeamForm  from '../EditTeamForm';
import useModal from '../../../../components/Modal/useModal';
import { deleteTeam } from '../../../../services/TeamsService';
import {CModal} from "@coreui/react";
import {freeSet} from "@coreui/icons";
import CIcon from "@coreui/icons-react";


const TeamItem = (props) => {
    const {isShowing, toggle} = useModal();
    const { team } = props;

    const [isOpen, setIsOpen] = useState(false);
    const toggleStyles = isOpen ? "d-block" : "d-none";
    const toggleParentClass = isOpen ? "d-none" : "d-block";

    return (
        
        <tr key={team.teamID} className="">
            <td>{team.teamID}</td>
            <td>{team.site}</td>
            <td>{team.teamName}</td>
            <td>{team.subTeamName}</td>
            <td width={100}>
                <div className="position-relative">

                    <div className={toggleParentClass}>
                        <span className="d-inline p-2" onClick={toggle}><CIcon  content={freeSet.cilPencil} /></span>
                        <span className="d-inline p-2" onClick={() => setIsOpen(!isOpen)}><CIcon  content={freeSet.cilTrash} /></span>
                    </div>

                    <div className={`${toggleStyles}`}>
                        <button
                            className="btn btn-secondary mx-2 my-1"
                            onClick={() => setIsOpen(!isOpen)}
                        >Cancel
                        </button>
                        <button
                            className="btn btn-primary mx-2 my-1"
                            onClick={() => {
                                deleteTeam(team);
                                setIsOpen(!isOpen);
                            }}
                        >Confirm
                        </button>
                    </div>
                </div>

                <CModal show={isShowing} size="lg">
                    {<EditTeamForm team={team} hide={toggle}/>}
                </CModal>

            </td>

        </tr>

    )
}

export default TeamItem;
