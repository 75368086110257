const DateUtil = ( strDate, options, dateformat='en-US')=>{

    let setDateStr= new Date( strDate );

    let  dateOptions = options ? options : {

        // weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',

        timeZone : 'Africa/Harare',
        timeZoneName : 'short',

        hour12: false,
        hour: 'numeric',
        minute: 'numeric',
        // second: 'numeric',

    };

    let dateStrFormat =  new Intl.DateTimeFormat(dateformat, dateOptions ).format( setDateStr );

    // console.log('DAte >> ', strDate, dateStrFormat )

    return dateStrFormat

}

/**
 * Check if the given dates are the same excluding the their time
 *
 * @param {Date} first - First date to compare
 * @param {Date} second - Second date to compare
 * @returns {boolean}
 */
const matchDate = (first, second) => {
    return (
        first.getFullYear() === second.getFullYear() &&
        first.getMonth() === second.getMonth() &&
        first.getDate() === second.getDate()
    )
};

//Format the date into year-month-date e.g 2021-January-22
const getFormattedDate = (date) => {
    
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];

    return `${date.getFullYear()}-${months[date.getMonth()]}-${date.getDate()}`

};

/**
 * get the first day of the year for Date object
 */
const getFirstDayOfYear = (date) => (new Date(date.getFullYear(), 0, 1));

/**
 * get total days in year for Date object
 */
const getTotalDaysInYear = (date) => Math.floor((date - getFirstDayOfYear(date)) / (24 * 60 * 60 * 1000));

/**
 * get current week of Date object
 */
const getWeekOfDate = (date) => Math.ceil((date.getDay() + 1 + getTotalDaysInYear(date)) / 7);

/**
 * get Date object of current week
 */
const getDateOfWeek = (week, year = new Date().getFullYear()) => new Date(year, 0, (1 + (week - 1) * 7));

/**
 * get "start of week" date, and "end of week" date, 
 * where "offset" indicative of start of week, ie does the week start on Sunday, or on Monday?
 * offset of 0 is Sunday
 * offset of -1 is Monday
 */
const getStartAndEndDatesForWeek = (date, offset = -1) => {
  const startOfWeek = new Date(date.getTime());
  const endOfWeek = new Date(date.getTime());
  const firstDayOfWeek = date.getDay() + offset;
  startOfWeek.setHours(-24 * firstDayOfWeek);
  endOfWeek.setDate(startOfWeek.getDate() + 6);
  return {startOfWeek, endOfWeek};
}

/**
 * iso days
 * NOTE: order is important as date.getDay() starts on Sunday, do not change order
 */
const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

/**
 * standardised months
 */
const monthsOfYear = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

/**
 * take a string of '00:00:00.000' and parse into an array, [${hours}, ${minutes}, ${seconds}, ${milliseconds}]
 * format '00:00' required, '00:00:00', and '00:00:00.000' optional
 */
const parseTime = (time) => time.match(/^([0-9]{2}):([0-9]{2}):?([0-9]{2})?\.?([0-9]{1,3})?/).slice(1);

/**
 * take milliseconds and generate a time string '00:00:00.000'
 */
const millisecondsToTime = (ms) => (`${('0' + Math.floor((ms / (1000 * 60 * 60)) % 24)).slice(-2)}:${('0' + Math.floor((ms / (1000 * 60)) % 60)).slice(-2)}:${('0' + Math.floor((ms / 1000) % 60)).slice(-2)}.${('00' + ms % 1000).slice(-3)}`);

/**
 * format date's time as '12:30'
 */
const dateToTime = (date) => `${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}`;

/**
 * convert string '12:30:00.000' to Date object, 
 * using either an optional Date object or current Date object to fill in year, month, and day
 */
const timeToDate = (time, date = new Date()) => (new Date(date.getFullYear(), date.getMonth(), date.getDate(), ...parseTime(time).map((unit) => parseInt(unit)).filter((unit) => !isNaN(unit))));

/**
 * array of functions for transforming an array of time units, [${(int)hours}, ${(int)minutes}, ${(int)seconds}, ${(int)milliseconds}], to milliseconds.
 * ie, convert 12:30 to milliseconds ...
 * const totalMilliseconds = [12, 30].reduce((accumulator, unit, index) => (accumulator + mapUnitsToMilliseconds[index](unit)))
 */
const mapUnitsToMilliseconds = [(unit) => (unit * 60), (unit) => (unit * 60 * 60), (unit) => (unit * 60 * 60 * 1000), (unit) => unit];

/**
 * convert [${(int)hours}, ${(int)minutes}, ${(int)seconds}, ${(int)milliseconds}] to milliseconds
 */
const unitsToMilliseconds = ([hours, minutes, seconds = 0, milliseconds = 0]) => (((hours * 60 + minutes) * 60 + seconds) * 1000 + milliseconds);

export { 
  DateUtil, 
  matchDate, 
  getFormattedDate, 
  daysOfWeek, 
  monthsOfYear,
  parseTime, 
  millisecondsToTime, 
  mapUnitsToMilliseconds, 
  dateToTime, 
  unitsToMilliseconds, 
  timeToDate, 
  getFirstDayOfYear,
  getTotalDaysInYear,
  getWeekOfDate,
  getDateOfWeek,
  getStartAndEndDatesForWeek,
}
