import React from 'react';
import {CModalBody, CModalFooter, CModalHeader, CModalTitle} from '@coreui/react';

export default ({
  title, 
  message, 
  hide, 
  status = 'inert'
}) => [
  <CModalHeader key={`${status}-modal-header`} closeButton>
    <CModalTitle>{title}</CModalTitle>
  </CModalHeader>,
  <CModalBody key={`${status}-modal-body`}>
    <div className={`alert alert-${status} fade show`}>{message}</div>
  </CModalBody>,
  <CModalFooter key={`${status}-modal-footer`}>
    <button type={'button'} className={`btn btn-${status}`} data-dismiss={'modal'} onClick={hide}>
      {'Close'}
    </button>
  </CModalFooter>,
];
