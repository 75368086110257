import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { UserContext } from "../../context/userContext";
import Styles from './LogIncidentForm.module.scss';
import { createIncident } from "../../services/IncidentService";
import { CModalBody, CModalHeader, CModalTitle, CModalFooter } from "@coreui/react";
import { DateUtil } from "../../utilities/DateUtil";

const LogIncidentForm = (props) => {
  const { register: registerLog, handleSubmit: handleSubmitLog, errors: errorsLog, reset: resetLog } = useForm();
  const { user, showModal } = props;
  const { toggleFullPageLoader } = useContext(UserContext);

  const createNewIncident = async (data) => {

    try {

      toggleFullPageLoader(true);
      await createIncident({
        title: data.title,
        message: data.message,
        isImportant: data.isImportant,
        classification: data.cbClassification,
        seenBy: [],
        dateCreated: new Date(),
        dateCreatedStr: DateUtil(new Date()),
        status: "Open",
        loggedByUser: {
          fullName: user.fullNameStr,
          userID: user.userID,
          userType: user.userType
        }
      });
      showModal(false);
      resetLog({});
    } catch (error) {
      console.error(error);
    }
    finally {
      toggleFullPageLoader(false);
    }

    return
  }

  useEffect(() => {

  }, [])

  return (
    <>
      <CModalHeader closeButton>
        <CModalTitle>Report An Incident</CModalTitle>
      </CModalHeader>
      <form className="" onSubmit={handleSubmitLog(createNewIncident)}>
        <CModalBody>
          <div className="d-flex flex-column">

            <div className="form-group">
              <label>Title</label>
              <input ref={registerLog({ required: true })} className="form-control" type="text" name="title" placeholder="Title..." />
              {errorsLog.title && <p className={Styles.validation}>Title is required.</p>}
            </div>
            <div className="form-group">
              <label htmlFor="title">Classification</label>
            </div>
            <div className="form-group">
              <div class={"custom-control custom-checkbox custom-control-inline " + Styles.resmodalcheckbox}>
                <input ref={registerLog} class="form-check-input" type="checkbox" name="cbClassification" value="Near Miss" /><label class="form-check-label mx-2" for="Near Miss">Near Miss</label>
              </div>
              <div class={"custom-control custom-checkbox custom-control-inline " + Styles.resmodalcheckbox}>
                <input ref={registerLog} class="form-check-input" type="checkbox" name="cbClassification" value="Machine Incident" /><label class="form-check-label mx-2" for="Machine Incident">Machine Incident</label>
              </div>
              <div class={"custom-control custom-checkbox custom-control-inline " + Styles.resmodalcheckbox}>
                <input ref={registerLog} class="form-check-input" type="checkbox" name="cbClassification" value="Human/Injury" /><label class="form-check-label mx-2" for="inline-checkbox1">Human/Injury</label>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea ref={registerLog} className="form-control" type="textarea" name="message" placeholder="Message..." rows="5" />
            </div>

            <div className="form-group custom-control custom-checkbox custom-control-inline">
              <input ref={registerLog} className="form-check-input" type="checkbox" name="isImportant" />
              <label className="form-check-label mx-2" htmlFor="isImportant">Important?</label>
            </div>

          </div>
        </CModalBody>
        <CModalFooter className="d-flex justify-content-between">
          <button className="btn btn-danger" type="button" data-dismiss='modal' onClick={() => showModal(false)}>Cancel</button>
          <button className="btn btn-primary" type="submit">Submit</button>
        </CModalFooter>

      </form>
    </>
  );
};

export default LogIncidentForm;
