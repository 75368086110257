import React, { useEffect, useState, useContext } from 'react'
import { useForm } from "react-hook-form";
import Styles from './EditVehicleForm.module.scss';
import { updateVehicle, getVehicleTypes } from '../../../../services/VehiclesService'
import { getTeams } from '../../../../services/TeamsService';
import { CModalBody, CModalFooter, CModalHeader, CModalTitle } from "@coreui/react";
import underscore from "underscore";
import { UserContext } from "../../../../context/userContext";

const EditVehicleForm = (props) => {
  const {
    vehicleID,
    vehicleType,
    vehicleStatus,
    currentStatus,
    currentTeam,
    vin: vehicleVIN,
    licence: vehicleLicencePlate,
    year: vehicleYear,
    make: vehicleMake,
    model: vehicleModel,
  } = props.vehicle;

  const { userType } = props

  const { register, handleSubmit, errors, reset } = useForm();
  const [teamNamesArr, setTeamNamesArr] = useState([null]);
  // eslint-disable-next-line no-unused-vars
  const [subTeamNamesArr, setSubTeamNamesArr] = useState([null]);
  const [vehicleTypesArr, setVehicleTypesArr] = useState([]);
  const { toggleFullPageLoader } = useContext(UserContext);

  const updateCurrentVehicle = async (data) => {
    const updatedVehicle = {
      vin: data.vehicleVIN,
      year: data.vehicleYear,
      make: data.vehicleMake,
      model: data.vehicleModel,
      licence: data.vehicleLicencePlate,
      vehicleID: vehicleID,
      vehicleType: data.vehicleType,
      currentTeam: data.currentTeam,
      currentStatus: data.currentStatus,
      vehicleStatus: data.vehicleStatus
    };
    try {
      toggleFullPageLoader(true);
      props.hide();
      await updateVehicle(updatedVehicle);
    } finally {
      toggleFullPageLoader(false);
    }
  }

  const validateVehicleYear = (year) => {
    // Validate that the vehicle year is in the past
    return Number(year) <= new Date().getFullYear();
  };

  useEffect(() => {
    Promise.all([getTeams(), getVehicleTypes()]).then(([teams, vehicleTypes]) => {
      setTeamNamesArr([...new Set(teams.map(team => team.teamName))]);
      setSubTeamNamesArr([...new Set(teams.map(team => team.subTeamName))]);
      setVehicleTypesArr(underscore.uniq(vehicleTypes));
      reset({
        vehicleType,
        currentTeam
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <CModalHeader closeButton>
        <CModalTitle>Edit Vehicle</CModalTitle>
      </CModalHeader>

      <form className="d-flex flex-column" onSubmit={handleSubmit(updateCurrentVehicle)}>

        <CModalBody>

          <div className={Styles.vehicleForm}>
            {
              userType !== 'maintenance' ? (
                <>
                  <label htmlFor="vehicleLicencePlate">Enter the vehicle licence number  :</label>
                  <input className={Styles.inputPrimary}
                    type="text"
                    defaultValue={vehicleLicencePlate}
                    id="vehicleLicencePlate"
                    name="vehicleLicencePlate"
                    placeholder="eg: CY 10"
                    ref={register()}
                  />

                  <label htmlFor="vehicleYear">Enter the vehicle year  :</label>
                  <input className={Styles.inputPrimary}
                    type="text"
                    defaultValue={vehicleYear}
                    id="vehicleYear"
                    name="vehicleYear"
                    placeholder="eg: 2015"
                    ref={register({ pattern: /^(19|20)\d{2}$/, validate: validateVehicleYear })}
                  />
                  {errors.vehicleYear && errors.vehicleYear.type === 'pattern' && <p>Make sure vehicle year is valid.</p>}
                  {errors.vehicleYear && errors.vehicleYear.type === 'validate' && <p>Vehicle year cannot be in the future.</p>}

                  <label htmlFor="vehicleMake">Enter the vehicle make  :</label>
                  <input className={Styles.inputPrimary}
                    type="text"
                    defaultValue={vehicleMake}
                    id="vehicleMake"
                    name="vehicleMake"
                    placeholder="eg: Toyota"
                    ref={register()}
                  />

                  <label htmlFor="vehicleModel">Enter the vehicle model  :</label>
                  <input className={Styles.inputPrimary}
                    type="text"
                    defaultValue={vehicleModel}
                    id="vehicleModel"
                    name="vehicleModel"
                    placeholder="eg: Yaris"
                    ref={register()}
                  />

                  <label htmlFor="vehicleType">Select Vehicle Type :</label>
                  <select className={Styles.selectPrimary}
                    defaultValue={vehicleType}
                    name="vehicleType"
                    id="vehicleType"
                    ref={register({ required: true })}>
                    <option value="">Select Vehicle Type :</option>
                    {vehicleTypesArr.map((vehicleType) => <option key={vehicleType} value={vehicleType}>{vehicleType}</option>)}
                  </select>
                  {errors.vehicleType && <p>Vehicle Type is required.</p>}

                  <label htmlFor="currentTeam">Select Team :</label>
                  <select
                    className={Styles.selectPrimary}
                    defaultValue={currentTeam}
                    name="currentTeam"
                    id="currentTeam"
                    ref={register()}>
                    <option value="">Select Team</option>
                    {teamNamesArr.map((teamName) => <option key={teamName} value={teamName}>{teamName}</option>)}
                  </select>
                </>
              ) : null
            }

            {
              userType !== 'supervisor' ? (
                <>
                  <label htmlFor="vehicleStatus">Select the vehicle status:</label>
                  <select className={Styles.selectPrimary}
                    defaultValue={vehicleStatus}
                    name="vehicleStatus"
                    id="vehicleStatus"
                    ref={register({ required: true })}>
                    <option value="available">Available</option>
                    <option value="Not available">Not Available</option>
                  </select>

                  <label htmlFor="currentStatus">Select the current status:</label>
                  <select className={Styles.selectPrimary}
                    defaultValue={currentStatus}
                    name="currentStatus"
                    id="currentStatus"
                    ref={register({ required: true })}>
                    <option value="Parked">Parked</option>
                    <option value="Out for Maintenance">Out for Maintenance</option>
                  </select>
                </>) : null
            }

          </div>
        </CModalBody>

        <CModalFooter>
          <button className="btn btn-secondary" data-dismiss="modal" aria-label="Close" type="button" onClick={() => (props.hide())}>Cancel</button>
          <button className="btn btn-primary" type="submit">Update</button>
        </CModalFooter>

      </form>
    </>
  )
}

export default EditVehicleForm;
