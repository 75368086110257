import React, { useContext } from "react";
import { createLoad } from "../../../services/LoadsService";
import { UserContext } from "../../../context/userContext";
import showToast from "../../../services/toastService";
import { CModalBody, CModalFooter, CModalHeader, CModalTitle } from "@coreui/react";

const SubmitLoad = (props) => {
  const { showModal } = props;
  const { user, supervisor, teamSiteName } = useContext(UserContext);


  const SubmitHandler = () => {
    const load = {
      driver: user.userID,
      driverName: user.fullNameStr,
      currentDate: new Date(),
      supervisor: supervisor.userID ? supervisor.userID : null,
      supervisorName: supervisor.fullNameStr ? supervisor.fullNameStr : null,
      team: user.currentTeam,
      site: teamSiteName.site ? teamSiteName.site : null,
      isSignedOff: null
    };

    createLoad(load);

    showModal();
    showToast("Your load has been recorded and passed for approval.", 5000)
  }

  return (
    user.isOnShift ? (
      <>
        <CModalHeader closeButton>
          <CModalTitle>Submit Load</CModalTitle>
        </CModalHeader>
        <CModalBody>
          Please confirm you would like to submit a load
        </CModalBody>
        <CModalFooter>
          <button type={'button'} className={'btn btn-danger'} data-dismiss='modal' onClick={() => { showModal(false) }}>Close</button>
          <button className={`btn btn-primary`} type="submit" onClick={SubmitHandler}>Add Load</button>
        </CModalFooter>
      </>
    ) : (
      <>
        <CModalHeader closeButton>
          <CModalTitle>Submit Load</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div className={`alert alert-danger fade show`}> Please start your shift to submit a load </div>
        </CModalBody>
      </>
    )
  )
}

export default SubmitLoad;