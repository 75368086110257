import React, { useContext, useEffect } from "react";
import { UserContext } from "../../../context/userContext";
import { v4 as uuidv4 } from "uuid";
import { useState } from "react";
import firebase from "firebase";
import showToast from "../../../services/toastService";
import {createNewsItem} from "../../../services/newsItemsService";
import {CModalBody, CModalFooter, CModalHeader, CModalTitle} from "@coreui/react";
import { useForm , Controller } from "react-hook-form";
import Creatable  from "react-select";
import { subscribeReportedIssues } from "../../../services/ReportedIssuesService";
import PreviewCamera from '../../../components/PreviewCamera';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faExclamation, faCheck, faShare, faCamera } from '@fortawesome/free-solid-svg-icons';

const ReportAProblem = ({showModal, title, isModalShowing,vehicle, ...props}) => {

    const [isPhotoOpen, setIsPhotoOpen] = useState(false);
    const { user, supervisor } = useContext(UserContext);
    const supervisorProperty = supervisor ? supervisor.userID ? supervisor.userID : null : null;
    const [submitStatus, setSubmitStatus] = useState('inert');
    const { handleSubmit , reset, control, errors, register } = useForm();
    const [ componentOptions, setComponentOptions ] = useState([]);
    const [ reportedIssueList, setreportedIssueList ] = useState([]);
    const [photoDataUri, setPhotoDataUri] = useState(null);
    const photoID = uuidv4();
    const getPhotoUrl = async (dataUri) =>
      fetch(dataUri)
        .then(res => res.blob())
        .then(async blob => {
          const storageRef = firebase.storage().ref();
          return storageRef
            .child(`issueimages/${photoID}.jpg`)
            .put(blob)
            .then(async () => await storageRef.child(`issueimages/${photoID}.jpg`).getDownloadURL())
        });
    const statusAttributeMap = {
      inert: { icon: faShare, spin: false },
      active: { icon: faSpinner, spin: true  },
      success: { icon: faCheck, spin: false },
      warning: { icon: faExclamation, spin: false },
      error: { icon: faExclamation, spin: false }
    };

    const submitHandler = async ( form_data ) => {
        setSubmitStatus('active');

        try {
          const photoUrl = photoDataUri ? await getPhotoUrl(photoDataUri) : null;

            let error =  {
                classType: 'classA',
                issue: form_data.issue_type.value,
                operator: user.userID,
                operatorFullName: user.fullNameStr,
                supervisor: supervisorProperty,
                vehicleID: vehicle.vehicleID,
                vehicleType: vehicle.vehicleType,
                vehicleTeam: vehicle.currentTeam,
                additionalDetails: document.getElementById("additional-details").value,
                dateCreated: new Date().toUTCString(),
                issueID: uuidv4(),
                photo: photoUrl
            };

            await createNewsItem({
                dateCreated: new Date(),
                title: `Vehicle Issue Reported`,
                message: `Reported issue - on ${vehicle.vehicleID} - ${error.issue} - reported`,
                team: vehicle.currentTeam,
                type: "vehicleIssueReported",
                info: {
                    driver: user.userID,
                    vehicle: `${vehicle.vehicleType}-${vehicle.vehicleID}`,
                    vehicleID: vehicle.vehicleID
                },
                errorDetail : error,
                seenBy: [],
                isImportant: false
            });

            setSubmitStatus('success');
            showToast("Your issue has been recorded and sent for review.", 5000);
            showModal( false );

        } catch (error) {
            setSubmitStatus('error');
            console.log('ReportAProblem::submitHandler:error ', error);
            showToast("Something went wrong while reporting an issue, please try again.", 5000);
        } 
    };
    
    const togglePhoto = (e) =>  {
        e.preventDefault()
        setIsPhotoOpen(!isPhotoOpen);
    }

  const resetForm = () => {
    setSubmitStatus('inert');
    setPhotoDataUri(null);
    reset({issue_type: null, additional_details: null});
  }

  const cancelModal = () => {
    showModal(false);
    resetForm();
  }

    useEffect(()=>{

        const subscribeToLists = subscribeReportedIssues( setreportedIssueList );

        return () =>{
            subscribeToLists();
        }

    },[]);

    useEffect(()=>{

        if(reportedIssueList.length){
            setComponentOptions( reportedIssueList.map( issue => ({value: issue.title, label : issue.title })) );
        }
        
    },[reportedIssueList]);

    useEffect(() => {
      if (['success', 'warning', 'error'].includes(submitStatus)) resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitStatus, reset])

    return (
        <>
            <CModalHeader closeButton>
                <CModalTitle>{title}</CModalTitle>
            </CModalHeader>


                <CModalBody>

                  <form className={`form-horizontal`} onSubmit={handleSubmit(submitHandler)}>
                    <div className={`pb-3 h6`}> Please give details of your issue below: </div>


                        <div className={`row form-group`}>
                            <div className={'col-md-3'}>
                                <label htmlFor="issue-type">Component affected</label>
                            </div>

                            <div className={'col-12 col-md-9'}>
                                <Controller 
                                    isMulti={false}
                                    closeMenuOnSelect={true}
                                    className="col-12 px-0"
                                    name="issue_type"
                                    id="issue-type"
                                    options={componentOptions}
                                    as={Creatable}
                                    control={control}
                                    rules={{ required: true }}
                                    defaultValue=''
                                />

                                {errors['issue-type'] && <div className="col-12 error py-2 px-0"> Component affected field is required.</div>}

                            </div>
                        </div>

                        <div className={`row form-group`}>
                            <div className={'col-md-3'}>
                                <label htmlFor="additional-details">Additional details</label>
                            </div>
                            <div className={'col-12 col-md-9'}>
                                <textarea
                                    ref={register()}
                                    className={'form-control'} name="additional_details" id="additional-details"
                                          placeholder="Please enter any additional details" rows={3} defaultValue={''}></textarea>
                            </div>
                        </div>

                  </form>
                  {isModalShowing && isPhotoOpen && <PreviewCamera handleDataUri={async (dataUri) => setPhotoDataUri(dataUri)} className={'mb-2'} />}
                  <br />
                  <button 
                    className={`btn ${isPhotoOpen ? 'btn-danger' : 'btn-secondary'} d-inline-flex flex-row justify-content-between`} 
                    style={{minWidth: '100px'}} 
                    type="button" 
                    onClick={togglePhoto} 
                    disabled={submitStatus === 'active'}
                  >
                    <FontAwesomeIcon className={'mt-1 mr-1'} icon={faCamera} size="1x" />
                    <span>{isPhotoOpen ? 'Cancel photo' : 'Take photo'}</span>
                  </button>

                </CModalBody>

                <CModalFooter>
                  <button className={'btn btn-danger mr-1'} data-dismiss="modal" aria-label="Close" onClick={cancelModal} disabled={submitStatus === 'active'}>{'Cancel'}</button>
                  <button className="btn btn-primary ml-1 d-inline-flex flex-row justify-content-between" type="button" style={{ minWidth: '100px' }} value="Report" disabled={submitStatus === 'active'} onClick={handleSubmit(submitHandler)}>
                    <FontAwesomeIcon className={`${submitStatus} mt-1`} {...statusAttributeMap[submitStatus]} size="1x" />
                    <span>{'Report'}</span>
                  </button>
                </CModalFooter>
            

        </>
    )
}

export default ReportAProblem;
