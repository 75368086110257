import { firestore } from '../firebase';
import { DateUtil } from "../utilities/DateUtil";
import { REJECTED, MAINTENANCE, OPEN, COMPLETE } from "../enums/MaintenanceJobsStatuses";

const createMaintenanceJob  = async(jobData) => { 
  await firestore
      .collection("maintenanceJobs")
      .add(jobData)
      .then((docRef) => {
          console.log("maintenanceJobs doc written with ID: ", docRef.id);         
      })
      .catch((error) => {
          console.error("maintenanceJobs error : ", error );
      });
     
};

const getMaintenanceJobs = async () => {
  const response = await firestore.collection("maintenanceJobs").get();
  return response.docs.map((doc) => doc.data());
};

const getMaintenanceJob  = (jobid) => {
  return firestore.collection('maintenanceJobs').doc(jobid).get()
  .then(snapshot => snapshot.data())
}

const GetJobForIssueID = async (issueID) => {
  return firestore.collection("maintenanceJobs")
  .where("issueID", "==", issueID)
  .limit(1)
  .get()
  .then(result => {
    return result.docs.map(doc => {
        return { ...doc.data(), id: doc.id }
    });
  })
  .then(list => list[0]);
}

const GetVehicleActiveJobForIssue = async (issuename, vehicleID) => {
  
  return firestore.collection("maintenanceJobs")
  .where("issue", "==", issuename)
  .where("vehicleID", "==", vehicleID)
  .where("status","==", OPEN)
  .limit(1)
  .get()
  .then(result => {
    return result.docs.map(doc => {
        return { ...doc.data(), id: doc.id }
    });
  })
  .then(list => list[0]);
}

const updateMaintenanceJob  = (docid, MaintenanceJob) => firestore.collection("maintenanceJobs").doc(docid).set({...MaintenanceJob}, {merge : true});

const subscribeToMaintenanceJobs = (setState ) => {
  //Maintenance - jobs
  return firestore
          .collection("maintenanceJobs")
          .where("status", '==', OPEN)
          .where("jobStatus", 'in', [MAINTENANCE, REJECTED])
          .onSnapshot(snapshot => setState(snapshot.docs.map(document => 
              ({...document.data(), id: document.id}))));
}

const subscribeToMaintenanceJobsComplete = (setState ) => {
  //Supervisor - maintenance sign-off 
  return firestore
          .collection("maintenanceJobs")
          .where("status", '==', OPEN)
          .where("jobStatus", '==', COMPLETE)
          .onSnapshot(snapshot => setState(snapshot.docs.map(document => 
              ({...document.data(), id: document.id}))));
}

const subscribeToVehicleIssues = (setState, vehicleID ) => {
  //Operator - dashboard
  return firestore
          .collection("maintenanceJobs")
          .where("vehicleID",'==',vehicleID)
          .where("status", '==', OPEN)
          .where("jobStatus", 'in', [MAINTENANCE, REJECTED, COMPLETE])
          .onSnapshot(snapshot => setState(snapshot.docs.map(document => 
              ({...document.data(), id: document.id}))));
}

const GetVehicleIssues = async(vehicleID) => {  
  return firestore.collection("maintenanceJobs")
  .where("vehicleID", "==", vehicleID)
  .where("status","==", OPEN)
  .where("jobStatus", 'in', [MAINTENANCE, REJECTED, COMPLETE])
    .get()
    .then(querySnapshot => {
      return querySnapshot.docs.map(doc => doc.data());
    });
}


const subscribeVehicleIssuesByVehicleID = (vehicleID, status, setIssues ) => {
    if(status){
        return firestore
            .collection("maintenanceJobs")
            .where("vehicleID",'==',vehicleID)
            .where("status", '==', status)
            .onSnapshot(response => setIssues(response.docs.map(document => ({...document.data(), id: document.id}))));
    }
    else{
        return firestore
            .collection("maintenanceJobs")
            .where("vehicleID",'==',vehicleID)
            .onSnapshot(response => setIssues(response.docs.map(document => ({...document.data(), id: document.id}))));
    }
}

const createChecklistJobs = async (vehicleChecklist) => {
  if(vehicleChecklist.checkItems && vehicleChecklist.checkItems.length){
    vehicleChecklist.checkItems.forEach((checkItem) => {
      createJobFromVehicleChecklistItem(vehicleChecklist, checkItem);
    });
  }
}

const createJobFromVehicleChecklistItem = async (vehicleChecklist, checkItem) => {
  try {
  if(checkItem && checkItem.issueID){
    const result = await GetJobForIssueID(checkItem.issueID);
    if(result && result.issueID){
      //console.log("Failed to create maintenance job, a job all ready exists for this issueID >> ",checkItem.issueID);
      return false;
    }    
  }

  if(vehicleChecklist && vehicleChecklist.vehicleID && checkItem && checkItem.issue)
  {
    const result = await GetVehicleActiveJobForIssue(checkItem.issue,vehicleChecklist.vehicleID);
    if(result && result.issueID){
      //console.log("Failed to create maintenance job, an active job all ready exists for this vehicleID and issue >> ",vehicleChecklist.vehicleID, ",",checkItem.issue);
      return false;
    }    
  }

  let supervisor = vehicleChecklist.actionedBy && vehicleChecklist.actionedBy.userID ? vehicleChecklist.actionedBy.userID : "";
  let supervisorFullName = vehicleChecklist.actionedBy && vehicleChecklist.actionedBy.fullName ? vehicleChecklist.actionedBy.fullName : "";
  let operator = vehicleChecklist.addedByDriver && vehicleChecklist.addedByDriver.userID ? vehicleChecklist.addedByDriver.userID : "";
  let operatorFullName = vehicleChecklist.addedByDriver && vehicleChecklist.addedByDriver.fullName ? vehicleChecklist.addedByDriver.fullName : "";
  //Discuss: Is it better to denormalize some of the user ane vehicle data? - it may not be necessary to have vehicleType, vehicleTeam or full names 
  const job = {
      jobStatus: MAINTENANCE,
      status: OPEN,
      dateCreated: new Date(),
      dateCreatedStr: DateUtil(new Date()),
      vehicleID: vehicleChecklist.vehicleID,
      vehicleType: vehicleChecklist.vehicleType,
      vehicleTeam: vehicleChecklist.currentTeam,
      classType: checkItem.classType,
      issue: checkItem.issue,
      issueID: checkItem.issueID,
      operator: operator,
      operatorFullName: operatorFullName,
      supervisor: supervisor,
      supervisorFullName: supervisorFullName,
      maintenanceSignoff: false,
      supervisorSignoff: false,
      assignedMaintenance: '',
      type: "checklistItem",
      additionalDetails: '',
      photo: ''
    };

    await firestore
    .collection("maintenanceJobs")
    .add(job)
    .then((docRef) => {
    
    })
    .catch((error) => {
        
    });

  } catch (error) {
    
  }
}

const createJobFromReportedIssue = async (reportedIssueObj) => {
  try {

    if(reportedIssueObj && reportedIssueObj.issueID){
      const result = await GetJobForIssueID(reportedIssueObj.issueID);
      if(result && result.issueID){
        //console.log("Failed to create maintenance job, a job all ready exists for this issueID >> ",checkItem.issueID);
        return false;
      }    
    }
  

  if(reportedIssueObj && reportedIssueObj.vehicleID && reportedIssueObj.issue)
  {
    const result = await GetVehicleActiveJobForIssue(reportedIssueObj.issue,reportedIssueObj.vehicleID);
    if(result && result.issueID){
      //console.log("Failed to create maintenance job, an active job all ready exists for this vehicleID and issue >> ",vehicleChecklist.vehicleID, ",",checkItem.issue);
      return false;
    }    
  }

  let supervisor = reportedIssueObj.supervisor ? reportedIssueObj.supervisor : "";
  let supervisorFullName = reportedIssueObj.supervisorFullName ? reportedIssueObj.supervisorFullName : "";
  let operator = reportedIssueObj.operator ? reportedIssueObj.operator : "";
  let operatorFullName = reportedIssueObj.operatorFullName ? reportedIssueObj.operatorFullName : "";

  const job = {
      jobStatus: MAINTENANCE,
      status: OPEN,
      dateCreated: new Date(),
      dateCreatedStr: DateUtil(new Date()),
      vehicleID: reportedIssueObj.vehicleID,
      vehicleType: reportedIssueObj.vehicleType,
      vehicleTeam: reportedIssueObj.vehicleTeam,
      classType: reportedIssueObj.classType,
      issue: reportedIssueObj.issue,
      issueID: reportedIssueObj.issueID,
      operator: operator,
      operatorFullName: operatorFullName,
      supervisor: supervisor,
      supervisorFullName: supervisorFullName,
      maintenanceSignoff: false,
      supervisorSignoff: false,
      assignedMaintenance: "",
      type: "reportedIssue",
      additionalDetails: reportedIssueObj.additionalDetails,
      photo: reportedIssueObj.photo
    };

    await firestore
    .collection("maintenanceJobs")
    .add(job)
    .then((docRef) => {
    
    })
    .catch((error) => {
        
    });

  } catch (error) {
    
  }
}

const clearMaintenanceJobs = async ()=>{
    firestore
        .collection("maintenanceJobs")
        .where("status", '==', 'pending')
        .get()
        .then((response) => {
            response.forEach(entry => {
                console.log('deleting - maintenanceJobs - ', entry.id);
                firestore.collection('maintenanceJobs').doc(entry.id).delete();
            })
        });
}

export {subscribeVehicleIssuesByVehicleID,GetVehicleIssues, createMaintenanceJob, getMaintenanceJobs,createJobFromVehicleChecklistItem,createChecklistJobs,subscribeToMaintenanceJobs,getMaintenanceJob,updateMaintenanceJob,subscribeToMaintenanceJobsComplete,subscribeToVehicleIssues, clearMaintenanceJobs,createJobFromReportedIssue};