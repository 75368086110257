import React, { useState, useEffect, useContext } from 'react'
import Styles from './Management.module.scss';
import { Router } from "@reach/router";
// import SideNav from "../../components/SideNav";
import { getVehicles } from "../../services/VehiclesService";
import { getIncidents } from '../../services/IncidentService';
import { UserContext } from '../../context/userContext';
import ManagementSummary from './ManagementSummary/ManagementSummary.jsx';
import Incidents from './Incidents/Incidents.jsx';
import ManagementNewsfeed from "./ManagementNewsfeed";
import SharedLayout from "../../components/SharedLayout";
import SideBarItems from '../../components/SideBarItems';
import UserTypes from "../../enums/UserTypes";
import VehicleEngineHours from "../Supervisor/VehicleEngineHours/VehicleEngineHours";
import VehicleHistory from "../Supervisor/VehiclesHistory";
import ChecklistHistory from "../Supervisor/VehiclesHistory/ChecklistHistory";
import IssuesHistory from "../Supervisor/VehiclesHistory/IssuesHistory";
import AssignmentHistory from "../Supervisor/VehiclesHistory/AssignmentHistory";

const Management = () => {
    const {MANAGEMENT} = UserTypes;
    const { user } = useContext(UserContext);
    const [redSignal, setRedSignal] = useState();
    const [amberSignal, setAmberSignal] = useState();
    const [greenSignal, setGreenSignal] = useState();
    const [incidentsArr, setIncidentsArr] = useState();
    const safetySignalStatus = (status) => {

        if (status === "go") {
            setGreenSignal(Styles.greenSignal)
            setAmberSignal()
            setRedSignal()
        } else if (status === "goBut") {
            setRedSignal()
            setAmberSignal(Styles.amberSignal)
            setGreenSignal()
        } else if (status === "noGo") {
            setRedSignal(Styles.redSignal)
            setAmberSignal()
            setGreenSignal()
        }
    }

    useEffect(() => {
        getIncidents().then(setIncidentsArr);
        const promises = [getVehicles()];
        Promise.all(promises).then(response => {

            const classAIssues = [];
            const classBIssues = [];
            // const classCIssues = [];
            const [vehicles] = response;

            // eslint-disable-next-line array-callback-return
            vehicles.map((vehicle) => {

                if (vehicle.checkItems !== null) {
                    for (let i = 0; i < vehicle.checkItems.length; i++) {
                        const checkItem = vehicle.checkItems[i];

                        if (checkItem.classType === "classA") classAIssues.push(checkItem);
                        else if (checkItem.classType === "classB") classBIssues.push(checkItem);
                    }
                }
            })

            if (classAIssues.length > 0) safetySignalStatus("noGo");
            else if (classBIssues.length >= 5) safetySignalStatus("goBut");
            else safetySignalStatus("go");


        })
    }, []);

    const sectionPageTitle = "Management";

    const sidebarItems = (<SideBarItems role={MANAGEMENT} numIncidents={incidentsArr !== undefined ? incidentsArr.filter(v => v.status !== "Archived").length : 0} signalStatus={{ redSignal, amberSignal, greenSignal }} />);

    return (

        <SharedLayout role={MANAGEMENT} sideNav={sidebarItems} pageHeading={sectionPageTitle}>
            <Router>
                <ManagementSummary default path="summary" />
                <ManagementNewsfeed path="newsfeed" />
                <Incidents path="/incidents"
                    incidents={incidentsArr}
                    user={user}
                />

                <VehicleHistory path="/vehicleHistory" />
                <ChecklistHistory path="/vehicleHistory/checklistHistory/:vehicleID" />
                <IssuesHistory path="/vehicleHistory/issuesHistory/:vehicleID" />
                <AssignmentHistory path="/vehicleHistory/assignmentHistory/:vehicleID" />

            </Router>


        </SharedLayout>


    )
}

export default Management;
