'use strict';

module.exports = Object.freeze({
  //status
  OPEN: 'Open',
  CLOSED: 'Closed',
  ARCHIVED: 'Archived',

  //jobStatus
  MAINTENANCE : 'Maintenance',
  COMPLETE: 'Complete',
  REJECTED : 'Rejected',
  APPROVED : 'Approved',
  //MAINTENANCEPROGRESS : 'Maintenance Progress' Todo: Discuss if this is needed and what action will put it in this state - there was a new status added to the vehicle collection which can be set to 'out for maintenance'
});
