import React, {useState, useContext, useEffect} from 'react';
import {CModal} from '@coreui/react';
import {Router, navigate} from '@reach/router';
import {UserContext} from '../../context/userContext';
import {updateUser} from "../../services/UsersService";
import {getChecklists} from "../../services/ChecklistsService";
import {updateVehicle, setVehicleShiftLog, getVehicleUserOnShift,getVehicleByID,subscribeToUserAssignedVehicle,subscribeToUserVehicleStatusa} from "../../services/VehiclesService";
import {updateOrphanedVehicleChecklist,subscribeToLatestVehicleChecklist} from "../../services/VehiclesChecklistService";
import {subscribeToVehicleIssues} from "../../services/MaintenanceJobsService";
import SubmitLoad from './SubmitLoad';
import ReportAProblem from './ReportAProblem';
import Dashboard from './Dashboard';
import Checklist from '../../components/Checklist';
import SharedLayout from '../../components/SharedLayout';
import ModalError from '../../components/ModalError';
import ModalWarning from '../../components/ModalWarning';
import ModalSuccess from '../../components/ModalSuccess';
import SideBarItems from '../../components/SideBarItems';
import ClosingHourModal from "../../components/ClosingHourModal";
import OpeningHourModal from '../../components/OpeningHourModal';
import { v5 as uuidv5 } from "uuid";
import { CLASS_A } from '../../enums/VehicleIssueClasses';
import { OUT_FOR_MAINTENANCE,NOGO_STATUS } from "../../enums/VehicleStatuses";
import { APPROVED, REJECTED, PENDING } from "../../enums/VehicleChecklistStatuses";
import UserTypes from "../../enums/UserTypes";
const uuidNamespace = '2b9459e8-2164-41e6-bfa5-a06232e5d059';

const ModalChildren = ({ component, message, status, ...props }) => {
  switch (status) {
    case 'error': return <ModalError message={message} {...props} />;
    case 'warning': return <ModalWarning message={message} {...props} />;
    case 'success': return <ModalSuccess message={message} {...props} />;
    default: return component && component.props ? { ...component, props: { ...component.props, ...props } } : component;
  }
}

export default () => {
  const {OPERATOR} = UserTypes;
  const {user, /*vehicle,*/ updateActiveUser } = useContext(UserContext);
  const [modalContent, setModalContent] = useState({ component: null });
  const [isModalShowing, setIsModalShowing] = useState(false);
  const [vehicleStatuses, setVehicleStatuses] = useState({});
  const [currentVehicleIssues, setCurrenVehicleIssues] = useState({});
  const [vehicleHasCriticalIssues, setVehicleHasCriticalIssues] = useState(false);
  const [isCurrentUserChecklist, setIsCurrentUserChecklist] = useState(false);
  const [vehicleHasActiveChecklist, setVehicleHasActiveChecklist] = useState(false);
  const [currentVehicleChecklist, setCurrentVehicleChecklist] = useState({});
  const [vehicle, setVehicle] = useState({});
  const [checklistData, setChecklistData] = useState({});
  useEffect(()=>{

    let hasActiveChecklist = false;
    let isCurrentUserChecklist = false;

    if(vehicle && vehicle.vehicleID){   
      if(currentVehicleChecklist && currentVehicleChecklist.length){      
        hasActiveChecklist = true;
        if(currentVehicleChecklist[0].usershiftId === user.currentSID){
          isCurrentUserChecklist = true;
        }
      }
    }

    setVehicleHasActiveChecklist(hasActiveChecklist); 
    setIsCurrentUserChecklist(isCurrentUserChecklist);

  },[currentVehicleChecklist]);

  useEffect(()=>{
    let hascriticalissues = false;
    if(currentVehicleIssues && currentVehicleIssues.length){
      currentVehicleIssues.some(function(issue) {
        if(issue.classType === "classA")
          hascriticalissues = true;
      });
    }
    setVehicleHasCriticalIssues(hascriticalissues);
  },[currentVehicleIssues]);

  useEffect(()=>{
 
    if(vehicle && vehicle.vehicleID ){

      if (vehicle.vehicleType) {
        getChecklists(vehicle.vehicleType)
          .then(response => {
            setChecklistData(response)
          })
      }

      setVehicleStatuses({
        goStatus : vehicle.goStatus,
        status : vehicle.status
      });

      let unsubscribeLatestVehicleChecklist = subscribeToLatestVehicleChecklist(setCurrentVehicleChecklist, vehicle.vehicleID);
      let unsubscribeLatestVehicleIssue = subscribeToVehicleIssues(setCurrenVehicleIssues,vehicle.vehicleID);

      return () => {
        unsubscribeLatestVehicleChecklist();
        unsubscribeLatestVehicleIssue();
      };

    }
  },[vehicle]);

  useEffect(()=>{

    if(user && user.userID && user.assignedVehicle !== ''){
   
      let unsubscribeToUserAssignedVehicle = subscribeToUserAssignedVehicle(user.assignedVehicle,setVehicle);

      return () => {
        unsubscribeToUserAssignedVehicle();
      };
      
    }else{
      setVehicleHasActiveChecklist(false); 
      setIsCurrentUserChecklist(false);
      setVehicle({});
    }
  },[/*user,vehicle*/user]);

  const updateShiftSchedule = async (user) => {
    return new Promise((resolve) => {
      if (user.isOnShift) {
        let todayDate = new Date();
        user.startShiftTime = todayDate;
        user.endShiftTime = '';
        user.currentSID = uuidv5([user.userID, todayDate.getTime()].join(''), uuidNamespace);
        resolve(user);
      }
      else {
        user.startShiftTime = user.startShiftTime ? user.startShiftTime : new Date();
        user.endShiftTime = new Date();
        setVehicleShiftLog(user);
        resolve(user);
      }
    });
  }

  const updateShift = async (user) => {
    user.isOnShift = !user.isOnShift;
    try {
      await updateShiftSchedule(user)
        .catch((error) => {
          throw Error(error);
        });

      let userIsOnShift = false;
      let currentUser = '';
      let goStatus = NOGO_STATUS;
      if(user.isOnShift){

        userIsOnShift = true;
        currentUser = user.userID;

      } else{

        currentUser = "";
        if(vehicleHasActiveChecklist){
          await updateOrphanedVehicleChecklist(currentVehicleChecklist[0].vehicleID);
          setVehicleHasActiveChecklist(false);  
          setIsCurrentUserChecklist(false);
        }      

      }   

      await updateUser(user)
      .then(() => {
        updateActiveUser(user);
        return user;
      })
      .then((userDetail) => userDetail.assignedVehicle
        && updateVehicle({
          vehicleID: userDetail.assignedVehicle,
          userStartShiftTime: userDetail.isOnShift ? userDetail.startShiftTime : '',
          userEndShiftTime: userDetail.isOnShift ? userDetail.endShiftTime : '',
          userIsOnShift: userDetail.isOnShift,
          currentUser: userDetail.isOnShift ? userDetail.userID : '',
          goStatus: goStatus,
          checkItemStatus: '',
          actionedBy: ''
        }))
        .catch((error) => {
          throw Error(error);
        });

      return user
    }
    catch (error) {
      console.log(`Failed udpating user session details, ${error}`);
    }
  };

  const endShiftBarrier = async(user, vehicle)=>{
    setIsModalShowing(true);
   
    if(user.assignedVehicle && user.assignedVehicle !== '' && vehicle && vehicle.vehicleID)
    {   
      if (user.isOnShift) {

        if(user.assignedVehicle !== vehicle.vehicleID){
          setModalContent({message: 'Please confirm with your supervisor that a vehicle is assigned', status: 'error',title: user.isOnShift ? 'End Shift' : 'Start Shift'});
        } 
        else{
          setModalContent({component: <ClosingHourModal title='Enter Closing Hours' updateShift={updateShift}  showModal={setIsModalShowing} currentvehicle={vehicle} />});
        }

      }
      else{      

        if(user.assignedVehicle !== vehicle.vehicleID){
          setModalContent({message: 'Please confirm with your supervisor that a vehicle is assigned', status: 'error',title: user.isOnShift ? 'End Shift' : 'Start Shift'});
        }
        else if(vehicle && vehicle.currentStatus === OUT_FOR_MAINTENANCE){
          setModalContent({message: 'Vehicle is out for maintenance', status: 'error',title: 'Start Shift'});
        }
        else if(vehicle.currentUser && vehicle.currentUser !== user.userID){
          setModalContent({message: 'This vehicle is currently in use by' + vehicle.currentUser, status: 'error',title: 'Start Shift'});
        }else if(vehicleHasCriticalIssues){
          setModalContent({message: 'The vehicle has critical Issues. Contact your supervisor' + vehicle.currentUser, status: 'error',title: 'Start Shift'});
        }
        else{
          setModalContent({component: <OpeningHourModal title='Enter Opening Hours' updateShift={updateShift}  showModal={setIsModalShowing} currentvehicle={vehicle} />});
        }

      }
    }else{
      setModalContent({message: 'Please confirm with your supervisor that a vehicle is assigned', status: 'error',title: user.isOnShift ? 'End Shift' : 'Start Shift'});
    }
  }

  const checklistModalContent = (user, vehicle, vehicleChecklist) => {
    const title = 'Accept Checklist';

    switch (true) {
      case (vehicle && vehicle.currentStatus === OUT_FOR_MAINTENANCE):
        return ({message: 'Vehicle is out for maintenance', status: 'error', title});
      case (!user.isOnShift):
        return ({message: 'Please begin your shift to accept checklist', status: 'error', title});
      case (vehicleHasCriticalIssues === true):
        return {message: 'The vehicle has critical Issues. Contact your supervisor', status: 'error', title};
      case (vehicleHasActiveChecklist && !isCurrentUserChecklist && vehicleChecklist.length > 0):
        return {message: 'This vehicle is currently in use by '+vehicleChecklist[0].addedByDriver.fullName+" ("+vehicleChecklist[0].addedByDriver.userID+")", status: 'error', title};
      case (vehicleHasActiveChecklist && isCurrentUserChecklist && vehicleChecklist.length && vehicleChecklist[0].checkItemStatus === PENDING):
        return {message: 'Waiting for Supervisor to approve checklist', status: 'error', title};
      case (vehicleHasActiveChecklist && isCurrentUserChecklist && vehicleChecklist.length && vehicleChecklist[0].checkItemStatus && vehicleChecklist[0].checkItemStatus === APPROVED):
        return {message: 'Checklist already processed by the supervisor, you can not accept checklist at this time.', status: 'error', title};
      default:
        return {message: 'Please confirm with your supervisor that a vehicle is assigned', status: 'error', title };
    }

  }

  const reportIssueModalContent = () => {
    const title = 'Report an Issue';
    switch (true) {
      case (user.isOnShift && user.assignedVehicle !== false && vehicleHasActiveChecklist && isCurrentUserChecklist):
        return { component: <ReportAProblem vehicle={vehicle} showModal={setIsModalShowing} />, title };
      case (!user.isOnShift):
        return { message: 'Please begin your shift to accept a vehicle', status: 'error', title };
      case (user.isOnShift && !vehicleHasActiveChecklist):
        return { message: 'Please complete a checklist before reporting an issue', status: 'error', title };
      default:
        return { message: 'Please confirm with your supervisor that a vehicle is assigned', status: 'error', title };
    }
  };

  const handleAcceptChecklist = () => {
    if(!vehicleHasCriticalIssues && user.isOnShift && user.assignedVehicle !== false && !vehicleHasActiveChecklist) {
      navigate('/operator/checklist');
    }else if(!vehicleHasCriticalIssues && user.isOnShift && user.assignedVehicle !== false && (vehicleHasActiveChecklist && isCurrentUserChecklist &&  currentVehicleChecklist[0].checkItemStatus === REJECTED )){
      navigate('/operator/checklist');
    } else {
      setIsModalShowing(true);
      setModalContent(checklistModalContent(user, vehicle, currentVehicleChecklist));
    }
  }

  const handleReportAnIssue = () => {
    setIsModalShowing(true);
    setModalContent(reportIssueModalContent());
  }

  const handleSubmitLoad = () => {
    setIsModalShowing(true);
    setModalContent({ component: <SubmitLoad showModal={setIsModalShowing} /> });
  }

  const sideNav = (
    <SideBarItems
      role={OPERATOR}
      user={user}
      handleShiftState={() => endShiftBarrier(user, vehicle)}
      handleAcceptChecklist={handleAcceptChecklist}
      handleReportAnIssue={(isModalShowing) => handleReportAnIssue(isModalShowing)}
      handleSubmitLoad={() => handleSubmitLoad}
    />
  );

  return (
    <SharedLayout role={OPERATOR} sideNav={sideNav} pageHeading={'Operator'}>
      <Router style={{width: '100%'}}>
        <Dashboard
          default
          path="/dashboard"
          updateShiftProperty={() => endShiftBarrier(user, vehicle)}
          changeStart={user.isOnShift ? 'End shift' : 'Start shift'}
          checklistBarrier={handleAcceptChecklist}
          reportBarrier={(isModalShowing) => handleReportAnIssue(isModalShowing)}
          showModal={setIsModalShowing}
          setModalContent={(component) => setModalContent({component})} 
          vehicleStatuses ={vehicleStatuses}
          currentVehicleChecklist={currentVehicleChecklist.length ? currentVehicleChecklist[0] : {}}
          isCurrentUserChecklist={isCurrentUserChecklist}
          vehicleHasActiveChecklist={vehicleHasActiveChecklist}
          vehicleHasCriticalIssues={vehicleHasCriticalIssues}
          currentVehicleIssues={currentVehicleIssues}
          vehicle={vehicle}
        />
        <Checklist path="/checklist" currentVehicleIssues={currentVehicleIssues} checklistData={checklistData} vehicle={vehicle} />
      </Router>
      <CModal show={isModalShowing} onClose={() => setIsModalShowing(!isModalShowing)} size={'lg'}>
        <ModalChildren isModalShowing={isModalShowing} hide={() => setIsModalShowing(false)} {...modalContent} />
      </CModal>
    </SharedLayout>
  );
};
