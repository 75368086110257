import React, {useContext} from 'react'
import { useForm } from "react-hook-form";
import Styles from './EditPartForm.module.scss';
import { updatePart } from '../../../../services/PartsService'
import {CModalBody, CModalFooter, CModalHeader, CModalTitle} from "@coreui/react";
import { UserContext } from "../../../../context/userContext";

const EditPartForm = (props) => {
    const {
        partID,
        partName 
    } = props.part;

    const { register, handleSubmit, errors } = useForm();
    const { toggleFullPageLoader } = useContext(UserContext);

    const updateCurrentPart = async (data) => {
        try {
            toggleFullPageLoader(true);
            props.hide();
            await updatePart(partID, {partName: data.partName});
        } finally {
            toggleFullPageLoader(false);
        }
    }
    
    return (
        <>
            <CModalHeader closeButton>
                <CModalTitle>Edit Part</CModalTitle>
            </CModalHeader>

            <form className="d-flex flex-column"  onSubmit={handleSubmit(updateCurrentPart)}>
                <CModalBody>

                    <div className={Styles.partForm}>

                        <label htmlFor="partName">Part Name :</label>
                        <input className={Styles.inputPrimary}
                            defaultValue={partName}
                            type="text"
                            id="partName"
                            name="partName"
                            placeholder="enter the part name"
                            ref={register({ required: true })} />
                            {errors.partName && <p> Part Name is required.</p>}

                    </div>
                </CModalBody>

                <CModalFooter>
                    <button className="btn btn-secondary" data-dismiss="modal" aria-label="Close" type="button" onClick={()=>(props.hide())}>Cancel</button>
                    <button className="btn btn-primary" type="submit">Update</button>
                </CModalFooter>
            </form>
            
        </>
    )
}

export default EditPartForm;
