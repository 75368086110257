import React from "react";
import {DailyReport} from "../DailyReport/DailyReport";

const HandoverReport = ( props ) => {

    const {user} = props;

    return (
        <>
            { !user.isOnShift &&
                <section>
                    <div className="alert alert-danger fade show">
                        Please begin your shift to proceed
                    </div>
                </section>
            }

            { user.isOnShift && 
                <DailyReport />
            }

        </>
    )
}

export default HandoverReport;