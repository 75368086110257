
module.exports = Object.freeze({
  AVAILABLE: 'Available',
  NOT_AVAILABLE: 'Not Available',
  GO_STATUS : 'Go',
  NOGO_STATUS : 'No go',
  GO_BUT_STATUS : 'Go, but',
  
  //currentStatus
  OUT_FOR_MAINTENANCE: "Out for Maintenance",
  PARKED: "Parked"
});