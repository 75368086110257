import React, {useState, useContext, useEffect} from 'react'
import {CCardText, CModalBody, CModalFooter, CModalHeader, CModalTitle } from "@coreui/react";
import EngineHours from '../EngineHours';
import { UserContext } from "../../context/userContext";
import toastService from "../../services/toastService";
import {getUser} from '../../services/UsersService';
import { updateVehicle } from '../../services/VehiclesService';
import {createVehicleEngineHours} from '../../services/VehicleEnginehoursService';
import {DateUtil} from '../../utilities/DateUtil';
import { createNewsItem } from '../../services/newsItemsService';

const OpeningHourModal = ({ title, message, showModal, updateShift, currentvehicle }) => {
    
    const { user, /*vehicle,*/ toggleFullPageLoader } = useContext(UserContext);
    const [openingHourVal, setOpeningHourVal] = useState(null);
    const lastClosingHour = parseInt(currentvehicle.engineHours && currentvehicle.engineHours.closingHours) ? currentvehicle.engineHours.closingHours : 0;
    const vehicle = currentvehicle;
    
    const logOpeningHour = async()=>{

        try {

            toggleFullPageLoader(true);

            if(user && vehicle && user.assignedVehicle !== vehicle.vehicleID){
                toggleFullPageLoader(false);
                showModal( false );
                toastService("Something went wrong while submitting opening hours, please try again or contact your supervisor.", 5000);
            }else{

            await updateShift( user );

            const openingHourSnippet = {
                openingHours: openingHourVal,
                openingHoursDetail: {
                    dateCreated: new Date(),
                    dateCreatedStr : DateUtil(new Date()),
                    addedByDriver: {
                        userID: user.userID,
                        fullName: user.fullNameStr,
                        userType: user.userType,
                    },
                    approvedBy: {},
                    type: 'openingHours',
                },
                closingHours: '',
                closingHoursDetail: {}
            };

            const engineHours = {
                ...vehicle.engineHours,
                ...openingHourSnippet
            }

            await createNewsItem({
                dateCreated: new Date(),
                dateCreatedStr: DateUtil( new Date() ),
                title: `Opening Hours Reported`,
                message: `Opening hours on ${vehicle.vehicleID} by ${user.fullNameStr} reported`,
                team: vehicle.currentTeam,
                type: "vehicleOpeningHourReported",
                userShiftId : user.currentSID,
                info: {
                    openingHours : openingHourVal,
                    driver: user.userID,
                    user : user,
                    vehicle: vehicle,
                    vehicleID: vehicle.vehicleID,
                },
                approvedBy: {}
            });

            let todayDate = new Date();
            let userStartShiftTime = todayDate;
            let userEndShiftTime = '';
            let userIsOnShift = true;
            let currentUser = user.userID;

            await updateVehicle({...vehicle, engineHours,userStartShiftTime,userEndShiftTime,userIsOnShift,currentUser});
        
            await createVehicleEngineHours( 
                vehicle.vehicleID,
                    {
                        openingHours : openingHourVal, 
                        ...openingHourSnippet.openingHoursDetail,
                        userShiftId : user.currentSID,
                    } 
            );             
            showModal( false );
            toggleFullPageLoader(false);
        }

        }catch (error) {
            console.error('catch error : logClosingHour', error);
        } finally {
            toggleFullPageLoader(false);
        }

    }

    useEffect(() => {
   
      }, [user])

    return (
        <>
            <CModalHeader closeButton>
                <CModalTitle>{title}</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <CCardText>
                                        
                    { (lastClosingHour > 0 ) && 
                        <>
                            Last closing hours for this vehicle - <strong>  ({lastClosingHour}) </strong>
                        </>
                    }

                </CCardText>
                <EngineHours action='opening' setResponseVal={setOpeningHourVal} />
            </CModalBody>
            <CModalFooter className="align-items-center justify-content-between">
                <button type={'button'} className={'btn btn-danger'} data-dismiss='modal' onClick={()=>(showModal(false))}>Close</button>

                { (!openingHourVal || openingHourVal < 1 )&&
                    <button type={'button'} className='btn btn-secondary disabled' data-dismiss='modal'>Submit &amp; Start shift</button>
                }

                { openingHourVal && parseFloat(openingHourVal) >= lastClosingHour &&
                    <button type={'button'} className={'btn btn-primary'} data-dismiss='modal' onClick={()=>{logOpeningHour()}}>Submit &amp; Start shift</button>
                }

            </CModalFooter>
        </>
    )
}

export default OpeningHourModal
