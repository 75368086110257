import React, { useState, useContext } from "react";
import Styles from './SharedLayout.module.scss';
import { UserContext } from "../../context/userContext";
import CIcon from "@coreui/icons-react";
import SideNav from "../SideNav";
import {
  CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CImg, CHeaderNav,
  CNavLink,
  CNavItem,
} from "@coreui/react";
import { freeSet } from "@coreui/icons";
import FullPageLoader from "../FullPageLoader";
import placeholderAvatar from "../../assets/images/profile-img-default.png";
import { cilEnvelopeOpen } from '@coreui/icons'

import AvatarDropdown from "../../components/AvatarDropdown"

const SharedLayout = (props) => {
  const { user, signOut } = useContext(UserContext);
  const { sideNav, sideNavHeading, pageHeading, role} = props;
  const [isDocked, setIsDocked] = useState(false);
  const [isMobileDocked, setMobileIsDocked] = useState(false);

  // 获取token的对象 - get token object
  const sessonKey = Object.keys(sessionStorage).filter(el => el?.includes('firebase:authUser'))[0]
  // 获取email中的userid - get user id from email
  const username = JSON.parse(sessionStorage.getItem(sessonKey))?.email.split('@')[0]

  // const avatars = {
  //     'operator': '6YQmQgcQ0VA',
  //     'supervisor': 'qzDF5PNEWKc',
  //     'maintenance': 'Q9_5wIRSJDs',
  //     'management': 'UazoQTbhrjM',
  // }

  // console.log('====================================');
  // console.log(props.children);
  // console.log('====================================');

  return (
    <div className={`c-app c-default-layout`}>
      <SideNav
        isDocked={isDocked}
        setIsDocked={setIsDocked}
        setMobileIsDocked={setMobileIsDocked}
        isMobileDocked={isMobileDocked}
        style={{ background: '#303c54 !important' }}
      >
        {sideNavHeading && <h2 className={Styles.heading}>{sideNavHeading}</h2>}
        {sideNav}
      </SideNav>

      <div className="c-wrapper" style={{ background: '#f2f3f5 !important' }}>
        <header className={`c-header ${Styles["header-config"]}`} >
          <button className="c-header-toggler ml-3 d-lg-down-none" style={{ height: 60 }} type="button" onClick={() => setIsDocked(!isDocked)}>
            <span className="c-header-toggler-icon"></span>
          </button>
          <button className="c-header-toggler ml-3 d-lg-none" type="button" onClick={() => setMobileIsDocked(!isMobileDocked)}>
            <span className="c-header-toggler-icon"></span>
          </button>
          <div className={`mr-auto c-header-nav ${Styles["header-nav"]}`}>
            <h2 className="m-0">{pageHeading}</h2>
          </div>
          {/* <CHeaderNav>
            <CNavItem>
              <CNavLink href="#">
                <CIcon icon={cilEnvelopeOpen} size="lg" color="#000"/>
                email
              </CNavLink>
            </CNavItem>
          </CHeaderNav> */}

          <div className={`px-3 c-header-nav ${Styles["header-nav"]}`}>
              <AvatarDropdown role={role}/>
                    </div>
                </header>

                <div className={Styles.divBody}>
                    <main className={Styles.main}>
                        {props.children}
                    </main>
                </div>
                <FullPageLoader />
        {/* FHY_TODO: 因为在menu中会标注版权信息，不在context在展示footer */}
        {/* <footer className="c-footer"></footer> */}
      </div>

    </div>
  )
}

export default SharedLayout
