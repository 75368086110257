export const checkList = [`
<svg width="64" height="64" viewBox="0 0 17 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.50007 2.24939C7.50007 2.05048 7.57909 1.85971 7.71974 1.71906C7.86039 1.57841 8.05116 1.49939 8.25007 
 1.49939H15.7501C15.949 1.49939 16.1397 1.57841 16.2804 1.71906C16.4211 1.85971 16.5001 2.05048 16.5001 2.24939C16.5001 2.4483 16.4211 2.63907 16.2804 2.77972C16.1397 2.92037 
 15.949 2.99939 15.7501 2.99939H8.25007C8.05116 2.99939 7.86039 2.92037 7.71974 2.77972C7.57909 2.63907 7.50007 2.4483 7.50007 2.24939ZM5.25607 0.968387C5.32591 1.03806 
 5.38133 1.12082 5.41914 1.21194C5.45695 1.30305 5.47641 1.40074 5.47641 1.49939C5.47641 1.59804 5.45695 1.69572 5.41914 1.78684C5.38133 1.87796 5.32591 1.96072 5.25607 
 2.03039L3.00607 4.28039C2.9364 4.35023 2.85364 4.40565 2.76252 4.44346C2.6714 4.48127 2.57372 4.50073 2.47507 4.50073C2.37642 4.50073 2.27874 4.48127 2.18762 4.44346C2.0965 
 4.40565 2.01374 4.35023 1.94407 4.28039L1.19407 3.53039C1.12434 3.46066 1.06902 3.37787 1.03128 3.28676C0.993545 3.19565 0.974121 3.098 0.974121 2.99939C0.974121 2.80022 1.05324 
 2.60922 1.19407 2.46839C1.3349 2.32756 1.52591 2.24844 1.72507 2.24844C1.92423 2.24844 2.11524 2.32756 2.25607 2.46839L2.47507 2.68889L4.19407 0.968387C4.26374 0.898542 4.3465 
 0.843128 4.43762 0.805318C4.52874 0.767508 4.62642 0.748047 4.72507 0.748047C4.82372 0.748047 4.9214 0.767508 5.01252 0.805318C5.10364 0.843128 5.1864 0.898542 5.25607 0.968387ZM7.50007 
 8.24939C7.50007 8.05048 7.57909 7.85971 7.71974 7.71906C7.86039 7.57841 8.05116 7.49939 8.25007 7.49939H15.7501C15.949 7.49939 16.1397 7.57841 16.2804 7.71906C16.4211 7.85971 16.5001 8.05048 
 16.5001 8.24939C16.5001 8.4483 16.4211 8.63907 16.2804 8.77972C16.1397 8.92037 15.949 8.99939 15.7501 8.99939H8.25007C8.05116 8.99939 7.86039 8.92037 7.71974 8.77972C7.57909 8.63907 7.50007 8.4483 
 7.50007 8.24939ZM5.25607 6.96839C5.32591 7.03806 5.38133 7.12082 5.41914 7.21194C5.45695 7.30306 5.47641 7.40074 5.47641 7.49939C5.47641 7.59804 5.45695 7.69572 5.41914 7.78684C5.38133 7.87796 5.32591 
 7.96072 5.25607 8.03039L3.00607 10.2804C2.9364 10.3502 2.85364 10.4056 2.76252 10.4435C2.6714 10.4813 2.57372 10.5007 2.47507 10.5007C2.37642 10.5007 2.27874 10.4813 2.18762 10.4435C2.0965 10.4056 
 2.01374 10.3502 1.94407 10.2804L1.19407 9.53039C1.12434 9.46066 1.06902 9.37787 1.03128 9.28676C0.993545 9.19565 0.974121 9.098 0.974121 8.99939C0.974121 8.90077 0.993545 8.80312 1.03128 
 8.71201C1.06902 8.6209 1.12434 8.53812 1.19407 8.46839C1.2638 8.39866 1.34658 8.34334 1.43769 8.3056C1.5288 8.26786 1.62645 8.24844 1.72507 8.24844C1.82368 8.24844 1.92133 8.26786 2.01244 
 8.3056C2.10355 8.34334 2.18634 8.39866 2.25607 8.46839L2.47507 8.68889L4.19407 6.96839C4.26374 6.89854 4.3465 6.84313 4.43762 6.80532C4.52874 6.76751 4.62642 6.74805 4.72507 6.74805C4.82372 
 6.74805 4.9214 6.76751 5.01252 6.80532C5.10364 6.84313 5.1864 6.89854 5.25607 6.96839Z" fill="white"/>
</svg>
`]