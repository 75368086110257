import {Card, Button, Table} from 'antd'
import {InfoCircleOutlined} from '@ant-design/icons'
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../context/userContext';
import { subscribeToAllVehicleEngineHours } from '../../../services/VehicleEnginehoursService';
import { DateUtil } from '../../../utilities/DateUtil';
import { groupBy } from 'lodash'
import getColumnSearchProps from "../../../components/TableColumnSearch"

export const VehicleEngineHours = () => {

  const { user } = useContext(UserContext);
  const [collectionEngineHours, setCollectionEngineHours] = useState([]);
  const [collectionFilteredEngineHours, setCollectionFilteredEngineHours] = useState([]);
  const [combinedCollectionEngineHours, setCombinedCollectionEngineHours] = useState([]);
  const [groupedCollectionEngineHours, setGroupedEngineHours] = useState([]);
  const [details, setDetails] = useState([]);

  const vehicleFieldSettings = [
    {
      key: 'dateCreated',
      dataIndex: 'dateCreated',
      title: 'Date Created (Opening Hours)',
      sorter: (a, b) => a.dateCreated - b.dateCreated,
      defaultSortOrder: 'descend',
      render: (_, record) => (
          <div>
            <span>{record.dateCreatedStr}</span>
            <Button icon={<InfoCircleOutlined/>} type={'link'} shape="circle" onClick={() => toggleDetails(record.id)}/>
          </div>
      )
    },
    {
      key: 'vehicleID',
      dataIndex: 'vehicleID',
      title: 'Vehicle ID',
      sorter: (a, b) => a.vehicleID.localeCompare(b.vehicleID),
      ...getColumnSearchProps("vehicleID", "Vehicle ID")
    },
    {
      key: 'addedBy',
      dataIndex: 'addedBy',
      title: 'Added By',
      ...getColumnSearchProps("addedBy", "Added By")
    },
    {
      key: 'userType',
      dataIndex: 'userType',
      title: 'User Type',
    },
    {
      key: 'openingHours',
      dataIndex: 'openingHours',
      title: 'Opening Hours',
      sorter: (a, b) => a.openingHours - b.openingHours,
    },
    {
      key: 'closingHours',
      dataIndex: 'closingHours',
      title: 'Closing Hours',
      sorter: (a, b) => a.closingHours - b.closingHours,
    },
  ];

  const legacyVehicleFieldSettings = [
    {
      key: 'dateCreated',
      dataIndex: 'dateCreated',
      title: 'Date Created',
      sorter: (a, b) => a.dateCreated - b.dateCreated,
      defaultSortOrder: 'descend',
    },
    {
      key: 'vehicleID',
      dataIndex: 'vehicleID',
      title: 'Vehicle ID',
      sorter: (a, b) => a.vehicleID.localeCompare(b.vehicleID),
      ...getColumnSearchProps("vehicleID", "Vehicle ID")
    },
    {
      key: 'addedBy',
      dataIndex: 'addedBy',
      title: 'Added By',
      ...getColumnSearchProps("addedBy", "Added By")
    },
    {
      key: 'userType',
      dataIndex: 'userType',
      title: 'User Type',
    },
    {
      key: 'openingHours',
      dataIndex: 'openingHours',
      title: 'Opening Hours',
      sorter: (a, b) => a.openingHours - b.openingHours,
    },
    {
      key: 'closingHours',
      dataIndex: 'closingHours',
      title: 'Closing Hours',
      sorter: (a, b) => a.closingHours - b.closingHours,
    },
  ];


  useEffect(() => {

    const subscribeHours = subscribeToAllVehicleEngineHours(setCollectionEngineHours);

    return () => (
      subscribeHours
    )

  }, [])


  useEffect(() => {

    if (collectionEngineHours) {

      const newSet = collectionEngineHours.filter((item) => {
        return (item.userShiftId && item.userShiftId !== '') ?? false;
      })

      const filterSet = collectionEngineHours.filter((item) => (!item.userShiftId && item.userShiftId === ''))

      let groupSet = groupBy(newSet, 'userShiftId');

      setGroupedEngineHours(groupSet);
      setCollectionFilteredEngineHours(filterSet);

    }

  }, [collectionEngineHours])

  useEffect(() => {

    if (groupedCollectionEngineHours) {

      let grpList = [];

      Object.values(groupedCollectionEngineHours).map((item) => (grpList.push({ ...item[1], ...item[0], details: item })))

      setCombinedCollectionEngineHours(grpList);

    }

  }, [collectionEngineHours, groupedCollectionEngineHours])

  const legacyEngineHoursReformat = (opening_hours) => {

    const { addedByDriver, dateCreated, openingHours, closingHours, type } = opening_hours

    return ({
      ...opening_hours,
      key: opening_hours.id,
      dateCreated: dateCreated.toDate().getTime(),
      dateCreatedStr: DateUtil(dateCreated.toDate()),
      openingHours: openingHours ?? '-',
      closingHours: closingHours ?? '-',
      type: type ?? '',
      addedBy: (addedByDriver && addedByDriver.fullName) ? addedByDriver.fullName : '-',
      userType: (addedByDriver && addedByDriver.userType) ? addedByDriver.userType : '-',
    })

  }

  const engineHoursReformat = (opening_hours) => {

    const { addedByDriver, openingHours, closingHours } = opening_hours

    return ({
      ...opening_hours,
      key: opening_hours.id,
      _classes: '',
      // dateCreated : dateCreated.toDate().getTime(),
      // dateCreatedStr : DateUtil(dateCreated.toDate()),
      openingHours: openingHours ?? '-',
      closingHours: closingHours ?? '-',
      // type: type ?? '',
      addedBy: (addedByDriver && addedByDriver.fullName) ? addedByDriver.fullName : '-',
      userType: (addedByDriver && addedByDriver.userType) ? addedByDriver.userType : '-',
    })

  }

  const toggleDetails = (index) => {
    const position = details.indexOf(index)
    let newDetails = details.slice()
    if (position !== -1) {
      newDetails.splice(position, 1)
    } else {
      newDetails = [...details, index]
    }
    setDetails(newDetails)
  }

  // subscribeToAllVehicleEngineHours
  return (
    <>
      {!user.isOnShift &&
        <section>
          <div className="alert alert-danger fade show">
            Please begin your shift to proceed
          </div>
        </section>
      }

      {user.isOnShift &&
        collectionEngineHours.length > 0 &&
        <section>
          <Card title='Vehicle Engine Hours' bodyStyle={{padding: 0}}>
            <Table
                columns={vehicleFieldSettings}
                sortDirections={['ascend', 'descend', 'ascend']}
                dataSource={combinedCollectionEngineHours.map(engineHoursReformat)}
                pagination={{size: 'small', defaultPageSize: 20}}
                scroll={{x:true}}
                expandable={{
                  showExpandColumn: false,
                  expandedRowKeys: details,
                  expandedRowRender: (item, index) => (
                        <div className='p-3 bg-info d-flex justify-content-center align-items-center'>
                          <table className="table table-light rounded-lg m-0" role="table">
                            <thead>
                            <tr>
                              <th>Date</th>
                              <th>Type</th>
                              <th>hours</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                              item.details.sort((a, b) => {
                                if (a.type > b.type) {
                                  return -1;
                                }
                                if (b.type > a.type) {
                                  return 1;
                                }
                                return 0;
                              }).map((det, idx) => (
                                  <tr className="" key={idx}>
                                    <td>{DateUtil(det.dateCreated.toDate())}</td>
                                    <td>{det.type}</td>
                                    <td>{det.openingHours ?? det.closingHours ?? '-'}</td>
                                  </tr>
                              ))
                            }
                            </tbody>
                          </table>
                        </div>
                  )
                }}
            />
          </Card>
          <Card title='Legacy Engine Hours' bodyStyle={{padding: 0}}>
            <Table
                columns={legacyVehicleFieldSettings}
                sortDirections={['ascend', 'descend', 'ascend']}
                dataSource={collectionFilteredEngineHours.map(legacyEngineHoursReformat)}
                pagination={{size: 'small', defaultPageSize: 20}}
                scroll={{x:true}}
            />
          </Card>
        </section>
      }
    </>
  )

};

export default VehicleEngineHours;
