import React, { useState, useContext } from "react";
import styles from "./ChecklistReviewModal.module.scss";
import { sortBy } from "lodash";
import showToast from "../../../../services/toastService";
import {createChecklistJobs} from "../../../../services/MaintenanceJobsService";
import {updateVehicle } from "../../../../services/VehiclesService";
import { createNewsItem } from "../../../../services/newsItemsService";
import {DateUtil} from "../../../../utilities/DateUtil";
import {CCardText, CModalHeader, CModalTitle, CModalBody, CModalFooter } from "@coreui/react";
import { UserContext } from "../../../../context/userContext";
import { getUser } from "../../../../services/UsersService";
import { getShiftVehicleEngineHours } from "../../../../services/VehicleEnginehoursService";
import {  NOGO_STATUS} from "../../../../enums/VehicleStatuses";
import { APPROVED, REJECTED } from "../../../../enums/VehicleChecklistStatuses";
import { updateVehicleChecklist } from "../../../../services/VehiclesChecklistService";

const ChecklistReviewModal = (props) => {
    const { toggleFullPageLoader } = useContext(UserContext);
    const { user, toggle , vehicleChecklist, filteredVehicleAdditionalTextArrData,vehicleItems,type,vehicleChecklistId } = props;
    const vehicleID = vehicleChecklist && vehicleChecklist.vehicleID ? vehicleChecklist.vehicleID : "";
    const [, setIsActive] = useState(true);
    const [driverName, setDriverName] = useState('');
    const [openingHour, setOpeningHour] = useState(0);
    const [closingHour, setClosingHour] = useState(0);
    let additionalInfo, filteredTextArr,title,message;
    let info,filteredIssues = {};

    if( vehicleID ){ 

        if(type === "vehiclechecklistcomplete"){

          title = "Vehicle Checklist Complete";
          message = vehicleID + " - checklist complete";
          info = {vehicle: vehicleChecklist.vehicleID, driver: vehicleChecklist.addedByDriver.userID, vehicleID: vehicleID, goStatus: vehicleChecklist.goStatus }
          
          if(vehicleChecklist && vehicleChecklist.checkItems && vehicleChecklist.checkItems.length){                         
            filteredIssues = vehicleChecklist.checkItems;
            filteredIssues = sortBy( filteredIssues , ['classType' ,'issue']);
          }

          if( filteredVehicleAdditionalTextArrData ) {
            filteredTextArr = filteredVehicleAdditionalTextArrData.filter(textItem => (textItem.vehicleID === vehicleID && textItem.detail))
            filteredTextArr = sortBy(filteredTextArr, 'classType')
          }
        }      
    }

    if( vehicleChecklist && vehicleChecklist.usershiftId ){
        const getShiftHours = getShiftVehicleEngineHours(vehicleChecklist.usershiftId)

        getShiftHours.then(result =>{

          const openingHourResult = result.filter( item => item.type === 'openingHours' );
          const closingHourResult = result.filter( item => item.type === 'closingHours' );

          openingHourResult.map((engineHour)=>(setOpeningHour(engineHour.openingHours)))
          closingHourResult.map((engineHour)=>(setClosingHour(engineHour.closingHours)))

        })
    }

    const approvalChecklist = ( data ) => {
    return (

          <tr key={data.issueID} className={styles.issueItem}>
              <td>{data.classType}</td>
              <td>{data.issue}</td>
              <td>{data.additionalDetails}</td>
              <td width={'12%'}>{ data.photo ? <img className={'border rounded'} alt="" src={data.photo} style={{width:'100%', height: 'auto'}} /> : ''}</td>
          </tr>

      )
    }

    const additionalData = ( data ) => {

           if( !['opening_hours'].includes(data.label) ){

             return (
              <tr key={data.key} className={data.key}>
                  <td>{ ['opening_hours'].includes(data.label) ? ' - ':data.classType}</td>
                  <td>{data.label}</td>
                  <td>{data.detail}</td>
              </tr>
             )
           }
    }

    const preventChecklistDuplicateAction = async (vehicleChecklist) => {
      if (vehicleChecklist && [APPROVED, REJECTED].indexOf(vehicleChecklist.checkItemStatus) > -1) {
        toggle();
        showToast("Cannot action this checklist because it was already actioned.", 5000, 'background-color: red; color: white');
        return true;
      }
    };

    const approveVehicleChecklist = async () => {

        try {

          if (await preventChecklistDuplicateAction(vehicleChecklist)) {
            return;
          }

          toggleFullPageLoader(true);
          setIsActive(false );

          let checkItemStatus = APPROVED;
          let supervisorCheckItemApproved = true;
          let driver = info.driver;
          let vehicle = info.vehicle;
          let usershiftId = (vehicleChecklist && vehicleChecklist.usershiftId) ? vehicleChecklist.usershiftId : "";
          let team = vehicleChecklist.currentTeam;

          let checkItems = vehicleChecklist.checkItems.map( item =>{
            return { ...item, supervisorCheckItemApproved }
          });       
   
          let updatedVehicleChecklist = {...vehicleChecklist};     
          updatedVehicleChecklist.checkItemStatus = checkItemStatus;
          updatedVehicleChecklist.checkItems = checkItems;
          updatedVehicleChecklist.actionedBy = {
            fullName: user.fullNameStr,
            userID: user.userID,
            userType: user.userType,
            dateActioned: new Date(),
            dateActionedStr: DateUtil(new Date())
          }                   
 
          await updateVehicleChecklist( vehicleChecklistId,updatedVehicleChecklist);
          await createChecklistJobs(updatedVehicleChecklist);

          if(filteredIssues && filteredIssues.length === 0){
          }else{
            await createNewsItem({
              title: `Vehicle checklist approved`,
              message: `${vehicle} - checklist approved by - ${user.fullNameStr}`,
              team: team,
              type: "vehicleChecklistApproved",
              isImportant: false,
              seenBy: [],
              info:{
                vehicle: vehicle,
                driver: driver,
                vehicleChecklistId:vehicleChecklist.vehicleChecklistId,
              },
              dateCreated: new Date()
            });
          }

          
          for (let i = 0; i<filteredIssues.length; i++) {
            const issue = filteredIssues[i];
            let letters = issue.classType.split("");
            letters[0] = letters[0].toUpperCase();
            letters.splice(5, 0, " ");
            let title = letters.join("");      
            await createNewsItem({
              title: `${title} issue raised`,
              message: `${issue.issue} reported on ${vehicle}`,
              team: team,
              type: "maintenanceRaised",
              isImportant: false,
              seenBy: [],
              info:{
                vehicle: vehicle,
                driver: driver,
                faultClass: issue.classType,
                issue: issue.issue,
                faultDescription: issue.additionalDetails,
                vehicleChecklistId:vehicleChecklist.vehicleChecklistId,
                usershiftId: usershiftId
              },
              dateCreated: new Date()
            });
          }       
          toggle();
        } finally {
          toggleFullPageLoader(false);
        }
    };

    const rejectVehicleChecklist = async () => {
        try {

          let legacyVehicleID = info.vehicle;
          legacyVehicleID = legacyVehicleID.split('-');
            
          let vehicleID = info.vehicleID ? info.vehicleID : legacyVehicleID[ legacyVehicleID.length - 1 ];

          toggleFullPageLoader(true);

          let goStatus = info.status;
          let checkItemStatus =  REJECTED;
          let actionedBy = user.userID;
          let team = vehicleChecklist.currentTeam;
          let driver = info.driver;

          setIsActive(false);
          
          let checkItemAdditionalDetails = {};
          const vehicleUpdateData = { vehicleID, goStatus , checkItemStatus, actionedBy, checkItemAdditionalDetails };

          let updatedVehicleChecklist = {...vehicleChecklist}
          updatedVehicleChecklist.checkItemStatus = checkItemStatus;
          updatedVehicleChecklist.actionedBy = {
            action: checkItemStatus,
            fullName: user.fullNameStr,
            userID: user.userID,
            userType: user.userType,
            dateActioned: new Date(),
            dateActionedStr: DateUtil(new Date())
          }
          
          await updateVehicle(  vehicleUpdateData );
          await updateVehicleChecklist( vehicleChecklistId,updatedVehicleChecklist);        
          await createNewsItem({
            title: `Vehicle checklist rejected`,
            message: `${vehicleID} - checklist rejected by - ${user.fullNameStr}`,
            team: team,
            type: "vehicleChecklistRejected",
            isImportant: false,
            seenBy: [],
            info:{
              vehicle: vehicleID,
              driver: driver,
              vehicleChecklistId:vehicleChecklist.vehicleChecklistId,
            },
            dateCreated: new Date()
          });

          toggle();
        } finally {
          toggleFullPageLoader(false);
        }
    };

    const ctaButtonWording =  filteredIssues && filteredIssues.length > 0  ? "Send to Maintenance": "Approve checklist"
      
    const getUserDetails = async ( driverId )=>{
      
      const details = await getUser( driverId );
      return details;
    }

    const getUserFullnameStr = ( driver )=>{
      
      getUserDetails( driver )
      .then((driverItem)=>{
        setDriverName( driverItem.fullNameStr ?? driver);
      });

      return driverName;

    }

    let itemStatus = '';
    if( vehicleChecklist && vehicleChecklist.checkItemStatus )
      itemStatus = vehicleChecklist.checkItemStatus;

    switch (type) {

        case "vehiclechecklistcomplete" :
          additionalInfo = (
              <>
                <p>Vehicle: {info["vehicle"]}</p>
                <p>Driver: { getUserFullnameStr(info["driver"]) }</p>
                
                { (openingHour > 0 ) &&
                  <p>Opening Hours : { openingHour }</p>
                }
                
                { (closingHour > 0) &&  (closingHour > openingHour) &&
                  <p>Closing Hours : { closingHour }</p>
                }

                <div className="table-responsive">
                    { itemStatus === "Pending" && filteredIssues && filteredIssues.length > 0 &&
                        
                          <table className='table'>
                              <thead key="issue-titles">
                              <tr>
                                  <th>Class Type</th>
                                  <th>Issue</th>
                                  <th>Additional Details</th>
                              </tr>
                              </thead>

                              <tbody>
                                {filteredIssues.map(approvalChecklist )}
                              </tbody>

                          </table>
                    }

                    { itemStatus === "Pending" && filteredVehicleAdditionalTextArrData && filteredVehicleAdditionalTextArrData.length && filteredTextArr && filteredTextArr.length > 0 &&

                        <div className="table-responsive py-3">
                            <h3 className="mt-2 mb-4"> Additional comments</h3>
                            <table className='table'>
                                <thead key="msg-titles">
                                <tr>
                                    <th>Class Type</th>
                                    <th>Item</th>
                                    <th>Details</th>
                                </tr>
                                </thead>

                                <tbody>
                                    {filteredTextArr.map(additionalData)}
                                </tbody>

                            </table>
                        </div>
                    }

                    { filteredIssues && (filteredIssues.length < 1 || !filteredIssues.length) && info && itemStatus !== APPROVED && itemStatus !== REJECTED && info.goStatus === NOGO_STATUS &&

                        <div className="d-flex justify-content-center align-content-center p-2">
                            No issues recorded
                        </div>
                    }

                    {
                        info && itemStatus !== 'Approved' && itemStatus !== 'Rejected' && info.goStatus === NOGO_STATUS &&
                        <div className='d-flex justify-content-between'>
                            <button className={styles.btnSecondary} onClick={() => {
                                rejectVehicleChecklist();
                            }}> Reject
                            </button>
                            <button className={styles.btnPrimary} onClick={() => {
                                approveVehicleChecklist();
                            }}> {ctaButtonWording}
                            </button>
                        </div>
                    }

                    { itemStatus === 'Approved' &&

                        <div className="d-flex justify-content-center align-content-center p-2 alert-success">
                            Issue already Approved
                        </div>
                    }

                    { itemStatus === 'Rejected' &&

                        <div className="d-flex justify-content-center align-content-center p-2 alert-danger">
                            Issue Rejected
                        </div>
                    }
                </div>
              </>
            )
          break;

        default:
        break;
  }

  return (
    <>

        <CModalHeader closeButton>
            <CModalTitle>{title}</CModalTitle>
        </CModalHeader>
        <CModalBody>
            <CCardText tag={'div'}>
                <div className={''}>
                    {/*<p className="time">{time}</p> Todo: Fix issue of date now displaying   */}
                    <p className="message">{message}</p>
                    <div className={styles.additionalInfoContainer}>{additionalInfo}</div>
                </div>
            </CCardText>
        </CModalBody>
        <CModalFooter className="d-flex justify-content-between">
            <button className="btn btn-danger" type="button" data-dismiss='modal' onClick={()=>{toggle(false)}}>Cancel</button>
        </CModalFooter>

    </>
  );
};

export default ChecklistReviewModal;