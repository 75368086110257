import React from "react";
import { useForm } from "react-hook-form";
import {CModalBody, CModalHeader, CModalTitle, CModalFooter} from "@coreui/react";

const ReportsForm = (props) => {
  const { showModal, reportName, generateReportFn } = props;
  const { register, handleSubmit, formState } = useForm({mode: "onChange"});

  const submitForm = async (data) => {
    const reportDate = data['report-date'];
    generateReportFn(new Date(reportDate));
    showModal(false)
  };

  // Get the maximum allowed date for the report
  const getMaxDate = () => {
    const today = new Date();
    const todayDate = today.getDate();
    return `${today.getFullYear()}-0${today.getMonth() + 1}-${todayDate < 10 ? '0' + todayDate : todayDate}`;
  };

  return (
    <>
      <CModalHeader closeButton>
        <CModalTitle>Download {reportName}</CModalTitle>
      </CModalHeader>
      <form onSubmit={handleSubmit(submitForm)}>

        <CModalBody>
          <div className="d-flex flex-column">
            <div className="form-group">
              <label htmlFor="report-date">Choose Date</label>
              <input className="form-control" max={getMaxDate()} type="date" name="report-date" ref={register({ required: true })} placeholder="Pick date..." />
            </div>
          </div>
        </CModalBody>

        <CModalFooter className="d-flex justify-content-between">
          <button className="btn btn-danger" type="button" data-dismiss='modal' onClick={()=>{showModal(false)}}>Cancel</button>
          <button className="btn btn-primary" type="submit" disabled={!formState.isValid}>Download</button>
        </CModalFooter>

      </form>
    </>
  );
};

export default ReportsForm;
