import React, { useState, useEffect } from 'react';
import IncidentTable from "../../../components/IncidentTable/IncidentTable";
import { getIncidents } from '../../../services/IncidentService';
export const Incidents = (props) => {

    const { user } = props;
    const [incidentCollection, setIncidentCollection] = useState([]);

    useEffect(() => {
        getIncidents().then(setIncidentCollection);

    }, [incidentCollection]);

    return (
        <>
            { !user.isOnShift &&
                <section>
                    <div className="alert alert-danger fade show">
                        Please begin your shift to proceed
                    </div>
                </section>
            }
            {  user.isOnShift  &&
                <section>
                <IncidentTable incidentCollection={incidentCollection} user={user} role={'supervisor'}>

                  

                </IncidentTable>
                </section>
            }
        </>
    )

}

export default Incidents;
