export const exclamationCircle = [`
<svg width="64" height="64" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1_840)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 22.5C15.2848 22.5 17.9555 21.3938 19.9246 19.4246C21.8938 17.4555 23 14.7848 23 12C23 9.21523 21.8938 6.54451 19.9246 4.57538C17.9555 2.60625 15.2848 1.5 12.5 1.5C9.71523 1.5 7.04451 2.60625 5.07538 4.57538C3.10625 6.54451 2 9.21523 2 12C2 14.7848 3.10625 17.4555 5.07538 19.4246C7.04451 21.3938 9.71523 22.5 12.5 22.5ZM12.5 24C15.6826 24 18.7348 22.7357 20.9853 20.4853C23.2357 18.2348 24.5 15.1826 24.5 12C24.5 8.8174 23.2357 5.76516 20.9853 3.51472C18.7348 1.26428 15.6826 0 12.5 0C9.3174 0 6.26516 1.26428 4.01472 3.51472C1.76428 5.76516 0.5 8.8174 0.5 12C0.5 15.1826 1.76428 18.2348 4.01472 20.4853C6.26516 22.7357 9.3174 24 12.5 24Z" fill="#F2C800"/>
<path d="M11.0029 16.4997C11.0029 16.3027 11.0417 16.1077 11.1171 15.9257C11.1925 15.7437 11.303 15.5783 11.4423 15.439C11.5816 15.2997 11.7469 15.1892 11.9289 15.1139C12.1109 15.0385 12.3059 14.9997 12.5029 14.9997C12.6999 14.9997 12.895 15.0385 13.077 15.1139C13.2589 15.1892 13.4243 15.2997 13.5636 15.439C13.7029 15.5783 13.8134 15.7437 13.8887 15.9257C13.9641 16.1077 14.0029 16.3027 14.0029 16.4997C14.0029 16.8975 13.8449 17.279 13.5636 17.5603C13.2823 17.8417 12.9008 17.9997 12.5029 17.9997C12.1051 17.9997 11.7236 17.8417 11.4423 17.5603C11.161 17.279 11.0029 16.8975 11.0029 16.4997ZM11.1499 7.49219C11.13 7.30295 11.15 7.11163 11.2087 6.93063C11.2675 6.74963 11.3636 6.58301 11.4909 6.44157C11.6182 6.30013 11.7738 6.18703 11.9476 6.10961C12.1215 6.03219 12.3096 5.99219 12.4999 5.99219C12.6902 5.99219 12.8784 6.03219 13.0522 6.10961C13.226 6.18703 13.3817 6.30013 13.509 6.44157C13.6362 6.58301 13.7324 6.74963 13.7911 6.93063C13.8499 7.11163 13.8699 7.30295 13.8499 7.49219L13.3249 12.7527C13.3073 12.9593 13.2127 13.1519 13.06 13.2921C12.9072 13.4324 12.7073 13.5103 12.4999 13.5103C12.2925 13.5103 12.0927 13.4324 11.9399 13.2921C11.7871 13.1519 11.6926 12.9593 11.6749 12.7527L11.1499 7.49219Z" fill="#F2C800"/>
</g>
<defs>
<clipPath id="clip0_1_840">
<rect width="24" height="24" fill="white" transform="translate(0.5)"/>
</clipPath>
</defs>
</svg>
`]