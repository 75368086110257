import React, { useState, useEffect } from "react";
import { subscribeToVehicles } from '../../../services/VehiclesService'
import { subscribeToTeams } from "../../../services/TeamsService";
import { CCardHeader, CCard, CCardBody, CModal } from "@coreui/react";
import { CIcon } from "@coreui/icons-react";
import { freeSet } from '@coreui/icons';
import ReportsForm from './ReportsForm';
import {matchDate} from '../../../utilities/DateUtil';
import { downloadReportUtil } from "../../../utilities/DownloadReportUtil";

export const Reports = ( props ) => {
  
  const {user} = props;
  const [maintenanceJobs, setMaintenanceJobs] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [teams, setTeams] = useState([]);
  const [modalContent, setModalContent] = useState(null);
  const [ isShowing, setShowModal ] = useState(false);

  const mapVehicleIssuesToMaintenanceJobs = (vehicles = []) => {
    let jobsArr = [];

    vehicles.forEach(vehicle => {
      (vehicle.checkItems || []).forEach((checkItem) => {
        if (checkItem.maintenanceSignoff === false && checkItem.supervisorCheckItemApproved === true ) {
          checkItem.teamName = vehicle.currentTeam || '';
          jobsArr.push({
            machineType: vehicle.vehicleType || '',
            vinNumber: vehicle.vin || '',
            model: vehicle.model || '',
            brand: vehicle.make || '',
            teamName: vehicle.currentTeam || '',
            status: vehicle.status,
            comment: checkItem.additionalDetails || '',
            currentHours: Math.abs(
              Math.round((new Date() - new Date(checkItem.dateCreated)) / (1000 * 60 * 60))
            ),
            createdAt: new Date(checkItem.dateCreated)
          })
        }
      });
    });

    return jobsArr;
  };

  const attachJobSites = (jobs = []) => {
    return jobs.map(job => {
      const teamFound = teams.find(team => team.teamName === job.teamName);

      if (teamFound) {
        job.site = teamFound.site;
      }

      return job;
    });
  };

  useEffect(() => {
    subscribeToVehicles(setVehicles);
    subscribeToTeams(setTeams);
  }, []);

  useEffect(() => {
    if (vehicles.length > 0 && teams.length > 0) {

      Promise.resolve(vehicles)
        .then(mapVehicleIssuesToMaintenanceJobs)
        .then(attachJobSites)
        .then(jobsWithSiteName => {
          setMaintenanceJobs(jobsWithSiteName);
        })
        .catch(err => {
          console.error('Error while processing vehicle issues', err);
        })
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicles, teams]);

  const toggle = () => {
    setShowModal(!isShowing);
  };

  const generateDailyMaintenanceReport = async (reportDate) => {

    const fields = [
      {
        label: 'MACHINE TYPE',
        value: 'machineType'
      },
      {
        label: 'BRAND',
        value: 'brand'
      },
      {
        label: 'MODEL',
        value: 'model'
      },
      {
        label: 'VIN NUMBER',
        value: 'vinNumber'
      },
      {
        label: 'PLANT NO',
        value: 'site'
      },
      {
        label: 'CURRENT HOURS',
        value: 'currentHours'
      },
      {
        label: 'STATUS',
        value: 'status'
      },
      {
        label: 'COMMENT',
        value: 'comment'
      }
    ];

    const filteredJobs = maintenanceJobs.filter((job) => matchDate(job.createdAt, reportDate));
    await downloadReportUtil( 'daily-maintenance-report', filteredJobs, fields, reportDate );

  };

  return (
    <>

      { !user.isOnShift && 
        <section>
          <div className="alert alert-danger fade show">
              Please begin your shift to proceed
          </div>
        </section>
      }

      { user.isOnShift && 
        <>
          <section>
            <CCard>
              <CCardHeader>
                Reports
              </CCardHeader>

              <CCardBody>

                <div className="table-responsive">
                  <table className='table'>
                    <thead>
                      <tr>
                        <th className="border-top-0">Report Name</th>
                        <th className="border-top-0">&nbsp;</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>Daily Maintenance Report</td>
                        <td>
                          <button className="btn btn-primary" onClick={() => {
                              setModalContent(<ReportsForm showModal={setShowModal} reportName="Daily Maintenance Report" generateReportFn={generateDailyMaintenanceReport}/>);
                              toggle();
                          }}>
                            <CIcon name={''} content={freeSet.cilCloudDownload} className={"mx-2"}/>Generate
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

              </CCardBody>
            </CCard>
          </section>
          <CModal show={isShowing} size='lg' onClose={() => {setShowModal(false);}}>
            {modalContent}
          </CModal>
        </>
      }

    </>
  )
};