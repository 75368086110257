import React, { useState } from 'react';
import EditSiteForm  from '../EditSiteForm';
import useModal from '../../../../components/Modal/useModal';
import { deleteSite } from '../../../../services/SitesService';
import {CModal} from "@coreui/react";
import {freeSet} from "@coreui/icons";
import CIcon from "@coreui/icons-react";


const SiteItem = (props) => {
    const {isShowing, toggle} = useModal();
    const site = props.site;

    const [isOpen, setIsOpen] = useState(false);
    const toggleStyles = isOpen ? "d-block" : "d-none";
    const toggleParentClass = isOpen ? "d-none" : "d-block";

    return (
        
        <tr key={site.siteID} >
            <td>{site.siteID}</td>
            <td>{site.siteName}</td>
            <td width={100}>
                <div className="position-relative">
                    <div className={`${toggleParentClass}`}>
                        <span className="d-inline p-2" onClick={toggle}><CIcon  content={freeSet.cilPencil} /></span>
                        <span className="d-inline p-2" onClick={() => setIsOpen(!isOpen)}><CIcon  content={freeSet.cilTrash} /></span>
                    </div>

                    <div className={`${toggleStyles}`}>
                        <button
                            className="btn btn-secondary mx-2 my-1"
                            onClick={() => setIsOpen(!isOpen)}
                        >Cancel
                        </button>
                        <button
                            className="btn btn-primary mx-2 my-1"
                            onClick={() => {
                                deleteSite(site);
                                setIsOpen(!isOpen);
                            }}
                        >Confirm
                        </button>
                    </div>

                    <CModal show={isShowing} size={'lg'} >
                        {<EditSiteForm site={site} hide={toggle} />}
                    </CModal>

                </div>
            </td>
        </tr>

    )
}

export default SiteItem;
