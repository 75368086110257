export const exclamationMark = [`
  <title>exclamation mark icon</title>
    <g>
        <path
        d='M2465 5113c-645-24-1252-289-1711-747-413-414-666-939-734-1526-15-122-15-438 0-560 68-587 321-1112 734-1526 361-361 811-602 1303-698 189-37 297-47 503-47 447 0 827 95 1215 305 669 361 1144 1004 1289 1743 37 189 47 297 47 503 0 447-95 827-305 1215-360 666-999 1140-1738 1289-183 36-421 56-603 49zm368-488c454-59 868-265 1197-595 330-329 536-743 595-1197 19-146 19-400 0-546-59-454-265-868-595-1197-329-330-743-536-1197-595-146-19-400-19-546 0-454 59-868 265-1197 595-330 329-536 743-595 1197-19 146-19 400 0 546 47 359 189 699 417 1000 80 105 270 295 375 375 297 225 639 369 989 416 137 19 417 19 557 1z'
        transform='matrix(.1 0 0 -.1 0 512)'
        ></path>
        <path
        d='M2454 3981c-108-28-186-99-204-185-7-35-10-291-8-787l3-734 23-45c45-91 151-142 292-142 114 0 188 27 252 92 36 38 49 59 58 101 8 39 10 258 8 789l-3 735-26 45c-34 57-51 72-119 106-46 23-69 28-150 30-52 2-109 0-126-5zM2509 1749c-98-15-182-78-229-171-26-52-30-72-30-138s4-86 30-138c33-65 76-109 143-144 35-19 58-23 137-23s102 4 137 23c67 35 110 79 143 144 26 52 30 72 30 138s-4 86-30 138c-63 125-193 193-331 171z'
        transform='matrix(.1 0 0 -.1 0 512)'
        ></path>
    </g>
`]
