import React, { useState, useEffect, useContext } from 'react'
import { useForm } from "react-hook-form";
import Styles from './EditUserForm.module.scss';
import { updateUser } from '../../../../services/UsersService';
import { getTeams } from '../../../../services/TeamsService'
import { CModalBody, CModalFooter, CModalHeader, CModalTitle } from "@coreui/react";
import { uniqBy, get } from 'lodash';
import { UserContext } from "../../../../context/userContext";

const EditUserForm = (props) => {
    const {
        userID,
        userType,
        currentTeam,
        currentSubTeam,
        fullNameStr,
        dateOfBirth
    } = props.user;
    let {
        currentTeamID,
        currentSubTeamID
    } = props.user;

    const { register, handleSubmit, errors, reset } = useForm();
    const { toggleFullPageLoader } = useContext(UserContext);

    const updateCurrentUser = async (data) => {
        try {
            toggleFullPageLoader(true);
            const currentTeam = teamsArr.find((team) => team.teamID === data.currentTeam) || {};
            const currentSubTeam = subTeamsArr.find((team) => team.teamID === data.currentSubTeam) || {};
            const updatedTeam = {
                userID: userID,
                userType: data.userType,
                currentTeam: get(currentTeam, 'teamName', ''),
                currentSubTeam: get(currentSubTeam, 'subTeamName', ''),
                currentSubTeamName: get(currentSubTeam, 'subTeamName', ''),
                currentTeamID: get(currentTeam, 'teamID', ''),
                currentSubTeamID: get(currentSubTeam, 'teamID', ''),
                fullNameStr: data.fullNameStr,
                dateOfBirth: data.dateOfBirth
            };
            props.hide();
            await updateUser(updatedTeam);
        } finally {
            toggleFullPageLoader(false);
        }
    };

    const [teamsArr, setTeamsArr] = useState([]);
    const [subTeamsArr, setSubTeamsArr] = useState([]);

    useEffect(() => {
        getTeams().then(response => {
            setTeamsArr(uniqBy(response, 'teamName'));
            setSubTeamsArr(uniqBy(response, 'subTeamName'));
        })
    }, []);

    useEffect(() => {
        /**
         * This is meant to cater for historical users data. Old user data won't have currentTeamID and/or currentSubTeamID
         * So we use their current team names to find the team id to auto populate the form with the correct data
         */
        if (!currentTeamID) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            currentTeamID = get(teamsArr.find((team) => team.teamName === currentTeam), 'teamID', '');
        }

        if (!currentSubTeamID) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            currentSubTeamID = get(subTeamsArr.find((team) => team.subTeamName === currentSubTeam), 'teamID', '');
        }

        reset({
            currentTeam: currentTeamID,
            currentSubTeam: currentSubTeamID
        })
    }, [teamsArr, subTeamsArr]);

    return (

        <>
            <CModalHeader closeButton>
                <CModalTitle>Edit User</CModalTitle>
            </CModalHeader>

            <form className="d-flex flex-column" onSubmit={handleSubmit(updateCurrentUser)}>
                <CModalBody>
                    <div className={Styles.userForm}>
                        <label htmlFor="userType">Select User Type :</label>
                        <select
                            className={Styles.selectPrimary}
                            defaultValue={userType}
                            name="userType"
                            id="userType"
                            ref={register({ required: true })}>
                            <option value="">Select User Type :</option>
                            <option value="operator">Operator</option>
                            <option value="supervisor">Supervisor</option>
                            <option value="maintenance">Maintenance</option>
                            <option value="management">Management</option>
                            <option value="admin">Admin</option>
                        </select>
                        {errors.userType && <p>User Type is required.</p>}

                        <label htmlFor="currentTeam">Select Team :</label>
                        <select
                            className={Styles.selectPrimary}
                            defaultValue={currentTeamID}
                            name="currentTeam"
                            id="currentTeam"
                            ref={register()}>
                            <option value="">Select Team</option>
                            {teamsArr.map(({ teamName, teamID }) => <option key={teamID} value={teamID}>{teamName}</option>)}
                        </select>

                        <label htmlFor="currentSubTeam">Select Sub Team :</label>
                        <select
                            className={Styles.selectPrimary}
                            defaultValue={currentSubTeamID}
                            name="currentSubTeam"
                            id="currentSubTeam"
                            ref={register()}>
                            <option value="">Select Sub Team</option>
                            {subTeamsArr.map(({ subTeamName, teamID }) => <option key={teamID} value={teamID}>{subTeamName}</option>)}
                        </select>

                        <label htmlFor="fullName">Full Name :</label>
                        <input
                            className={Styles.inputPrimary}
                            defaultValue={fullNameStr}
                            type="text"
                            id="fullNameStr"
                            name="fullNameStr"
                            placeholder="enter user's full name"
                            ref={register({ required: true, minLength: 2 })} />
                        {errors.fullName && <p>FullName is required. Minimum length of 2 characters.</p>}

                        <label htmlFor="dateOfBirth">Date of Birth :</label>
                        <input
                            className={Styles.inputPrimary}
                            defaultValue={dateOfBirth}
                            type="date"
                            id="dateOfBirth"
                            name="dateOfBirth"
                            placeholder="enter user's Date of Birth"
                            ref={register} />
                        {errors.dateOfBirth && <p>Date of birth is required.</p>}

                    </div>

                </CModalBody>
                <CModalFooter>
                    <button className="btn btn-secondary" data-dismiss="modal" aria-label="Close" type="button" onClick={() => (props.hide())}>Cancel</button>
                    <button className="btn btn-primary" type="submit">Update</button>
                </CModalFooter>
            </form>


        </>
    )
}

export default EditUserForm;
