import React, { useState } from 'react';
import EditPartForm from '../EditPartForm';
import useModal from '../../../../components/Modal/useModal';
import { deletePart } from '../../../../services/PartsService';
import { CModal } from "@coreui/react";
import { freeSet } from "@coreui/icons";
import CIcon from "@coreui/icons-react";


const PartItem = (props) => {
  const { isShowing, toggle } = useModal();
  const part = props.part;

  const [isOpen, setIsOpen] = useState(false);
  const toggleStyles = isOpen ? "d-block" : "d-none";
  const toggleParentClass = isOpen ? "d-none" : "d-block";

  return (

    <tr key={part.partID} >
      <td>{part.partID}</td>
      <td>{part.partName}</td>
      <td width={100}>
        <div className="position-relative">
          <div className={`${toggleParentClass}`}>
            <span className="d-inline p-2" onClick={toggle}><CIcon content={freeSet.cilPencil} /></span>
            <span className="d-inline p-2" onClick={() => setIsOpen(!isOpen)}><CIcon content={freeSet.cilTrash} /></span>
          </div>

          <div className={`${toggleStyles}`}>
            <button
              className="btn btn-secondary mx-2 my-1"
              onClick={() => setIsOpen(!isOpen)}
            >Cancel
            </button>
            <button
              className="btn btn-primary mx-2 my-1"
              onClick={() => {
                deletePart(part);
                setIsOpen(!isOpen);
              }}
            >Confirm
            </button>
          </div>

          {/*<Modal innerComponent={<EditSiteForm site={site} hide={toggle}/>} isShowing={isShowing} hide={toggle} />*/}
          {/*<Modal innerComponent={<EditPartForm part={part} hide={toggle}/>} isShowing={isShowing} hide={toggle} />*/}
          <CModal show={isShowing} size={'lg'} >
            {<EditPartForm part={part} hide={toggle} />}
          </CModal>

        </div>
      </td>
    </tr>

  )
}

export default PartItem;
