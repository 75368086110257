import React, { useEffect, useState, useContext } from 'react';
import { useForm } from "react-hook-form";
import Styles from './CreateVehicleForm.module.scss';
import { createVehicle } from '../../../../services/VehiclesService'
import { getTeams } from '../../../../services/TeamsService';
import { CModalBody, CModalFooter, CModalHeader, CModalTitle } from "@coreui/react";
import { UserContext } from "../../../../context/userContext";

// ------ CLASSES ----- //

class Vehicle {
  constructor(vehicleData) {
    this.vehicleID = vehicleData.vehicleID;
    this.vehicleType = vehicleData.vehicleType;
    this.currentTeam = vehicleData.currentTeam;
    this.vin = vehicleData.vehicleVIN;
    this.licence = vehicleData.vehicleLicencePlate;
    this.year = vehicleData.vehicleYear;
    this.make = vehicleData.vehicleMake;
    this.model = vehicleData.vehicleModel;
    this.currentUser = null;
    this.checkItems = null;
    this.lastChecked = new Date();
    this.checkedLog = null;
    this.currentStatus = vehicleData.currentStatus;
    this.vehicleStatus = vehicleData.vehicleStatus;
  }
}

// ------ JSX ----- //

const CreateVehicleForm = (props) => {
  const { register, handleSubmit, errors, reset } = useForm();
  const { vehicleTypesArr, userInfos } = props;
  const { toggleFullPageLoader } = useContext(UserContext);

  const createNewVehicle = async (data) => {
    try {
      toggleFullPageLoader(true);
      props.hide();
      reset();
      await createVehicle(new Vehicle(data));
    } finally {
      toggleFullPageLoader(false);
    }
  };

  const validateVehicleYear = (year) => {
    // Validate that the vehicle year is in the past
    return Number(year) <= new Date().getFullYear();
  };

  const [teamNamesArr, setTeamNamesArr] = useState([null]);
  // eslint-disable-next-line no-unused-vars
  const [subTeamNamesArr, setSubTeamNamesArr] = useState([null]);

  useEffect(() => {
    getTeams().then(response => {
      setTeamNamesArr([...new Set(response.map(team => team.teamName))]);
      setSubTeamNamesArr([...new Set(response.map(team => team.subTeamName))]);
    });
  }, [])


  return (
    <>
      <CModalHeader closeButton>
        <CModalTitle>Create Vehicle</CModalTitle>
      </CModalHeader>

      <form className="d-flex flex-column" onSubmit={handleSubmit(createNewVehicle)}>
        <CModalBody>
          <div className={Styles.vehicleForm}>
            <label htmlFor="vehicleID">Enter the vehicle ID  :</label>
            <input className={Styles.inputPrimary}
              type="text"
              id="vehicleID"
              name="vehicleID"
              placeholder="eg: 001"
              ref={register({ required: true })} />
            {errors.vehicleID && <p>vehicleID is required.</p>}

            <label htmlFor="vehicleVIN">Enter the vehicle VIN  :</label>
            <input className={Styles.inputPrimary}
              type="text"
              id="vehicleVIN"
              name="vehicleVIN"
              placeholder="eg: 001"
              ref={register()}
            />

            <label htmlFor="vehicleLicencePlate">Enter the vehicle licence number  :</label>
            <input className={Styles.inputPrimary}
              type="text"
              id="vehicleLicencePlate"
              name="vehicleLicencePlate"
              placeholder="eg: CY 10"
              ref={register()}
            />

            <label htmlFor="vehicleYear">Enter the vehicle year  :</label>
            <input className={Styles.inputPrimary}
              type="text"
              id="vehicleYear"
              name="vehicleYear"
              placeholder="eg: 2015"
              ref={register({ pattern: /^(19|20)\d{2}$/, validate: validateVehicleYear })}
            />
            {errors.vehicleYear && errors.vehicleYear.type === 'pattern' && <p>Make sure vehicle year is valid.</p>}
            {errors.vehicleYear && errors.vehicleYear.type === 'validate' && <p>Vehicle year cannot be in the future.</p>}

            <label htmlFor="vehicleMake">Enter the vehicle make  :</label>
            <input className={Styles.inputPrimary}
              type="text"
              id="vehicleMake"
              name="vehicleMake"
              placeholder="eg: Toyota"
              ref={register()}
            />

            <label htmlFor="vehicleModel">Enter the vehicle model  :</label>
            <input className={Styles.inputPrimary}
              type="text"
              id="vehicleModel"
              name="vehicleModel"
              placeholder="eg: Yaris"
              ref={register()}
            />

            <label htmlFor="vehicleType">Select the Vehicle Type:</label>
            <select className={Styles.selectPrimary}
              name="vehicleType"
              id="vehicleType"
              ref={register({ required: true })}>

              <option value="">Please Select option</option>
              {vehicleTypesArr.map((vehicle) => <option key={vehicle}>{vehicle}</option>)}
            </select>
            {errors.vehicleType && <p>vehicle type is required.</p>}

            <label htmlFor="currentTeam">Select Team :</label>
            <select
              className={Styles.selectPrimary}
              name="currentTeam"
              id="currentTeam"
              ref={register()}>
              <option value="">Select Team</option>
              {teamNamesArr.map((teamName) => <option key={teamName}>{teamName}</option>)}
            </select>

            {
              userInfos.userType !== 'supervisor' ? (
                <>
                  <label htmlFor="vehicleStatus">Select the vehicle status:</label>
                  <select className={Styles.selectPrimary}
                    name="vehicleStatus"
                    id="vehicleStatus"
                    ref={register({ required: true })}>
                    <option value="available">Available</option>
                    <option value="Not available">Not Available</option>
                  </select>

                  <label htmlFor="currentStatus">Select the current status:</label>
                  <select className={Styles.selectPrimary}
                    name="currentStatus"
                    id="currentStatus"
                    ref={register({ required: true })}>
                      <option value="Parked">Parked</option>
                    <option value="Out for Maintenance">Out for Maintenance</option>
                  </select>
                </>
              ) : null
            }

          </div>
        </CModalBody>

        <CModalFooter>
          <button className="btn btn-secondary" data-dismiss="modal" aria-label="Close" type="button" onClick={() => (props.hide())}>Cancel</button>
          <button className="btn btn-primary" type="submit">Update</button>
        </CModalFooter>

      </form>

    </>

  )
}

export default CreateVehicleForm;