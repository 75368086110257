import React, { useContext, useEffect } from 'react';
import { navigate } from "@reach/router";
import { UserContext } from "../../context/userContext"

const SupervisorPrivateRoute = (props) => {
  const { children } = props;
  const { user,uid } = useContext(UserContext);
  let pathway = children.props.location.pathname?.replaceAll('/', '');
  useEffect(() => {

    if (Object.keys(user).length && !pathway.includes(user.userType) && uid === user.authID) {
      alert("It appears you do not have permission to access this page");
      navigate(`/`);
    }

    return () => console.info('cleanup permissions')

  }, [pathway, user]);

  return <>{children}</>;
}

export default SupervisorPrivateRoute;