import React, {useState, useEffect, useContext} from "react";
import Styles from "./ManagementNewsfeed.module.scss";
import { getTeams } from "../../../services/TeamsService";
import { subscribeToUsers } from "../../../services/UsersService";
import { subscribeToVehicles, subscribeToVehiclesWithUsersAssigned } from "../../../services/VehiclesService";
import NewsTicker from "../../Supervisor/NewsTicker";
import { subscribeToNewsItems, subscribeToIncidents } from "../../../services/newsItemsService";
import { UserContext } from "../../../context/userContext";
import useModal from "../../../components/Modal/useModal";
import VehicleTable from "../../Supervisor/VehicleTable";
import UserTable from "../../Supervisor/UserTable";
import underscore from "underscore";
import {CModal} from "@coreui/react";

const ManagementNewsfeed = () => {

    const { user } = useContext(UserContext);
    const [mgtVehiclesArr, setVehiclesArr] = useState([]);
    const [filteredVehiclesArr, setFilteredVehiclesArr] = useState([]);
    const [incidentsArr, setIncidentsArr] = useState([]);

    const [teamToView, setTeamView] = useState("All");

    const [usersArr, setUsersArr] = useState([]);
    const [teamsAvailableToView, setTeamsAvailableToView] = useState([]);
    const [filteredUsersArr, setFilteredUsersArr] = useState([]);

    const [mgtNewsItemsArr, setNewsItemsArr] = useState([]);
    const [filteredNewsItemsArr, setFilteredNewsItemsArr] = useState([]);

    const [ vehicleChecksArray, setVehicleChecksArray ] = useState([])
    const [ filteredVehicleChecksArray, setFilteredVehicleChecksArray ] = useState([])
    const [ , setCheckItemsArr ] = useState([]);

    const getUniqueTeams = teamsArr => [...underscore.uniq(teamsArr.map(teamObj => teamObj.teamName)), 'All'];

    // modal
    const { isShowing, toggle: toggleNewsModal } = useModal();
    const [newsModalContent , setNewsModalContent] = useState(null);

    const handleTeamChange = e => {
        setTeamView(e.target.value)
    }

    useEffect(() => {
        getTeams()
        .then( getUniqueTeams )
        .then( filteredTeams => setTeamsAvailableToView( filteredTeams ));

        //subscribing to all vehicles as this doesnt have a teams filter on the service
        subscribeToVehiclesWithUsersAssigned(setVehiclesArr);
        subscribeToUsers(setUsersArr);
        subscribeToVehicles(setVehicleChecksArray);
        subscribeToIncidents(setIncidentsArr);

    }, [])

    useEffect(()=>{
        //refilter when teamToView Changes
        setFilteredVehiclesArr(mgtVehiclesArr.filter( teamToView==="All" ? () => true : vehicle => vehicle.currentTeam===teamToView));
        setFilteredUsersArr(usersArr.filter( teamToView==="All" ? () => true : user => user.currentTeam===teamToView));

        // subscribe to collections
        subscribeToNewsItems(setNewsItemsArr,teamToView);

    },[teamToView,mgtVehiclesArr,usersArr])

    useEffect(()=>{

        setFilteredVehiclesArr(mgtVehiclesArr.filter( teamToView === "All" ? () => true : vehicle => vehicle.currentTeam === teamToView ));
        setFilteredUsersArr(usersArr.filter( teamToView === "All" ? () => true : user => user.currentTeam === teamToView ));


        // subscribe to collections
        let unsubscribeNews = subscribeToNewsItems(setNewsItemsArr,teamToView);
        return () => {
            unsubscribeNews();
        }

    },[mgtVehiclesArr,usersArr, teamToView])

    useEffect(() => {
        setFilteredVehicleChecksArray(vehicleChecksArray.filter(vehicle => vehicle.currentTeam === user.currentTeam));
    }, [ user.currentTeam, vehicleChecksArray ])

    useEffect(() => {
        let newCheckItemsArr=[];
        filteredVehicleChecksArray.forEach(vehicle =>{
            if(vehicle.checkItems){
                vehicle.checkItems.forEach(checkItem => {
                    if(checkItem.maintenanceSignoff && !checkItem.supervisorSignoff){
                        newCheckItemsArr.push({...checkItem, vehicleType: vehicle.vehicleType, team: vehicle.currentTeam})
                    }
                })
            }
        })
        setCheckItemsArr(newCheckItemsArr)
    }, [filteredVehicleChecksArray])

    useEffect(() => {
        // filter out incidents news since those are rendered on their own section
        setFilteredNewsItemsArr(mgtNewsItemsArr.filter((newsItem) => newsItem.type !== 'supervisorIncident'));
    }, [mgtNewsItemsArr]);

    return (
            <div>
                {   incidentsArr.length > 0 &&
                <section>
                    <NewsTicker incidentsListing={true} newsItems={incidentsArr} setModalContent={setNewsModalContent} toggle={toggleNewsModal} user={user} vehicleItems={mgtVehiclesArr} >
                    </NewsTicker>
                </section>
                }
                <section>
                    {<NewsTicker newsItems={filteredNewsItemsArr} setModalContent={setNewsModalContent} toggle={toggleNewsModal}  user={user} vehicleItems={mgtVehiclesArr}>
                        <select className={`${Styles.selectTeam} ${Styles.selectPrimary}`} name="team" onChange={handleTeamChange}>
                            {teamsAvailableToView.map((team) => <option key={team} value={team} selected={teamToView === team}>{team}</option>)}
                        </select>
                    </NewsTicker>}
                </section>

                <section>
                    <VehicleTable filteredVehiclesArr={filteredVehiclesArr}/>
                </section>

                <section>
                    <UserTable filteredUsersArr={filteredUsersArr}/>
                </section>

                <CModal show={isShowing} onClose={toggleNewsModal} size={'lg'}>
                    {newsModalContent}
                </CModal>

            </div>
    )

}

export default ManagementNewsfeed;