import React from "react";
import Styles from "./NewsTicker.module.scss";
import {CCard, CCardBody, CCardHeader, CCardText, CDataTable, CButton  } from "@coreui/react";
import {DateUtil} from "../../../utilities/DateUtil";
import NewsItemModal from "./NewsItemModal";
import {sortBy} from "lodash";
import underscore from "underscore";
import OpsIcon from "../../../components/OpsIcon";

import { getSingleVehicleChecklist } from "../../../services/VehiclesChecklistService";
const NewsTicker = (props) => {
    
    const isIncidentsOnly = props.incidentsListing || false;
    const sectionHeading = props.title || false;
    const allNewsItems = props.newsItems;
    const {setModalContent, toggle , user, vehicleItems, filteredVehicleAdditionalTextArr  } = props;
    const newsitemsFieldSettings = [
      { key: 'timestamp', label: 'Timestamp', _classes: 'sorter-left' },
      { key: 'title', label: 'Category' , sorter: false },
      { key: 'message', label: 'Title',  sorter: false },
      { key: 'show_details', label: 'Message',  _style: { width: '1%' }, sorter: false, filter: false }
    ]
    const newsTickerSorting = {column: 'timestamp', desc: 'true'}

    const getNewstickerClass = ( newsItem ) => {

        const { info , type } = newsItem;

        let classToUse, filteredIssues = '';
        let hasClassAIssue = false ;
        let goStatus = info && info.status && info.status ?  info.status : false;

        if( info.vehicleID ) {
            let vehicleDetail = vehicleItems.find( citem => (citem.vehicleID === info.vehicleID) );
           
            if( vehicleItems && vehicleItems.length && vehicleDetail){
                filteredIssues = vehicleDetail.checkItems;
                filteredIssues = sortBy( filteredIssues , ['classType' ,'issue']);
                hasClassAIssue = underscore.contains( underscore.pluck(filteredIssues, 'classType'), "classA")
            }

        }

        switch ( type.toLowerCase() ){

            case 'supervisorincident':
                classToUse = 'danger';
            break;

            case 'maintenancecomplete':
                classToUse = 'icon-blue';
            break;

            case 'vehiclechecklistcomplete':

                if( goStatus && goStatus.toLowerCase() === 'no go' ){
                    classToUse = 'danger';
                }
                else if( goStatus && goStatus.toLowerCase() === 'go, but' ){
                    classToUse = 'warning';
                }
                else if( hasClassAIssue === false || (goStatus && goStatus.toLowerCase() === 'go') ){
                    classToUse = 'success';
                }
                else{
                    classToUse =  'icon-gray';
                }

                break;

            default:
                classToUse = 'icon-gray';

        }

        return classToUse;

    }

    const toggleDetails = async (index, newsItem) => {

        toggle(true);
        let vehicleChecklistData;
        let vehicleChecklistId;
        let filteredVehicleAdditionalTextArrData = filteredVehicleAdditionalTextArr;
        if(newsItem.type.toLowerCase() === "vehiclechecklistcomplete" && newsItem.checklistId){
  
            await getSingleVehicleChecklist(newsItem.checklistId).then(vehicleChecklist => {     
                vehicleChecklistId = newsItem.checklistId;
                vehicleChecklistData = vehicleChecklist;
                if(vehicleChecklist.checkItemAdditionalDetails){
                    let list = [];
                    let collection = vehicleChecklist.checkItemAdditionalDetails;

                    Object.keys(collection).forEach((item, itemIdx) => {
                        Object.keys(collection[item]).forEach((indItem, indItemIdx) => {
                            list.push({
                            vehicleID: vehicleChecklist.vehicleID,
                            classType: item,
                            key: [item, itemIdx, indItemIdx].join('-').toLowerCase(),
                            label: indItem,
                            detail: collection[item][indItem]
                            });
                        });
                    });
                    sortBy(list, 'classType');
                    filteredVehicleAdditionalTextArrData = list;
                }                   
            });
           
          }
          setModalContent(<NewsItemModal item={newsItem} filteredVehicleAdditionalTextArr={filteredVehicleAdditionalTextArrData} vehicleChecklistId={vehicleChecklistId} vehicleItems={vehicleItems} vehicleChecklist={vehicleChecklistData} user={user} toggle={toggle} />)
    }

    const reformatNewsItem =  ( newsItem ) => {

        return {
            ...newsItem,
            timestamp: (newsItem.dateCreated ? newsItem.dateCreated.toDate().getTime() : new Date().getTime() ),
            message: newsItem.message,
            title: newsItem.title,
            _classes : ''
        }

    }


  return (
    <>
        <CCard className={``}>
            <CCardHeader className={'d-flex align-items-center justify-content-between'}>
                {isIncidentsOnly ? 'Incidents' : sectionHeading ? sectionHeading :'Team Information Feed'}
                <div className={'card-header-actions'}>
                    {props.children}
                </div>
            </CCardHeader>
            <CCardBody className={""}>
                <CCardText tag={'div'}>
                    <CDataTable
                        items={allNewsItems.map( reformatNewsItem )}
                        fields={newsitemsFieldSettings}
                        addTableClasses={Styles.tableContainer}
                        itemsPerPage={10}
                        pagination={{ addListClass: Styles.paginationContainer }}
                        sorter
                        filter
                        sorterValue={newsTickerSorting}
                        responsive={true}
                        hover={true}
                        scopedSlots = {{
                            'timestamp' :
                                (item, index)=> (
                                     <td>{ DateUtil(item.dateCreated ? item.dateCreated.toDate().toString().substring(0, 21) : new Date().toString().substring(0, 21)) }</td>
                                ),
                            'show_details':
                                (sd_item, sd_index)=> (
                                        <td className="py-2">
                                            <CButton shape="square" size="sm" onClick={()=> {toggleDetails(sd_index, sd_item)}}>
                                                <OpsIcon type={sd_item.type} useClass={getNewstickerClass(sd_item)} item={sd_item} />
                                            </CButton>
                                        </td>
                                )

                        }}
                    />

                </CCardText>

            </CCardBody>
        </CCard>

    </>
  );
}

export default NewsTicker;
