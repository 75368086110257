import React, {useContext} from 'react'
import placeholderAvatar from "../../assets/images/profile-img-default.png";
import {CImg} from "@coreui/react";
import Styles from './AvatarDropdown.module.scss';

import {Dropdown, Menu, message} from 'antd'
import Icon from '@ant-design/icons'

import {SvgIcons} from "../../assets/icons/SvgIcon"
import {UserContext} from "../../context/userContext";
import {setVehicleShiftLog} from "../../services/VehiclesService";
import {updateUser} from "../../services/UsersService";
import AccessControl from "../AccessControl";
import {navigate} from "@reach/router";
import UserTypes from "../../enums/UserTypes";

const AvatarDropdown = (props) => {

    const {role} = props;
    const {SUPERVISOR} = UserTypes;

    const {user, signOut, updateActiveUser} = useContext(UserContext);
    // get token object
    const sessonKey = Object.keys(sessionStorage).filter(el => el?.includes('firebase:authUser'))[0]
    // get user id from email
    const username = JSON.parse(sessionStorage.getItem(sessonKey))?.email.split('@')[0]

    //minimum working hours
    const workingHours = 12;

    const updateSupervisorShiftProperty = () => {
        user.isOnShift = !user.isOnShift;

        if (user.isOnShift) {
            let todayDate = new Date();
            let estEndDate = new Date();
            estEndDate.setHours(estEndDate.getHours() + workingHours);

            user.startShiftTime = todayDate;
            user.endShiftTime = estEndDate;
        } else {
            user.startShiftTime = user.startShiftTime ? user.startShiftTime : new Date();
            user.endShiftTime = new Date();

            setVehicleShiftLog(user);
        }


        updateUser(user).then((response) => {
            updateActiveUser(user);
            return user;
        });
    };

    const supervisorBarrier = () => {
        if (!user.isOnShift) {
            let messageContent = 'Please begin your shift to proceed';
            message.error(messageContent)
            return false;
        }

        return true;
    };

    const checkoutVehicleClick = () => {
        if (supervisorBarrier()) {
            if (user.assignedVehicle) {
                navigate('/supervisor/checklist');
            } else {
                message.error('No vehicle assigned, click Reassign Vehicles to assign yourself a vehicle');
            }
        }
    }

    const renderMenu = (isStart) => (
        <Menu>
            <div className={Styles.userInfo}>
                <div>{user.fullNameStr}</div>
                <div className={Styles.userId}>User ID: {user.userID ?? username}</div>
            </div>
            {
                !isStart &&
                <AccessControl allowed={[SUPERVISOR]} role={role}>
                    <Menu.Divider/>
                    <Menu.Item key={"StartShift"} icon={<Icon component={SvgIcons.ClockSvg}/>} onClick={updateSupervisorShiftProperty}>
                        Start Shift
                    </Menu.Item>
                </AccessControl>
            }
            {
                isStart &&
                <AccessControl allowed={[SUPERVISOR]} role={role}>
                    <Menu.Divider/>
                    <Menu.Item key={"EndShift"} icon={<Icon component={SvgIcons.ClockSvg} style={{color: 'red'}}/>} onClick={updateSupervisorShiftProperty}>
                        <span className={Styles.endShit}>End Shift</span>
                    </Menu.Item>
                </AccessControl>
            }
            <AccessControl allowed={[SUPERVISOR]} role={role}>
                <Menu.Divider/>
                <Menu.Item key={"CheckoutVehicle"} icon={<Icon component={SvgIcons.CheckListSvg}/>} onClick={checkoutVehicleClick}>
                    Checkout Vehicle
                </Menu.Item>
            </AccessControl>
            <Menu.Divider/>
            <Menu.Item key={"Logout"} icon={<Icon component={SvgIcons.LogOutSvg}/>} onClick={signOut}>
                Logout
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={() => renderMenu(user.isOnShift)} trigger={['click']}>
            <CImg src={placeholderAvatar} fluid shape={"rounded"}
                  className="rounded-circle m-3 shadow-sm border border-white" height={40}/>
        </Dropdown>
    )
}

export default AvatarDropdown
