import { firestore } from "../firebase";
import { DateUtil } from "../utilities/DateUtil";


const getAllVehicleEngineHours  = async ( operationalState ) => {

    const response = await firestore.collection("vehicleEngineHours").get();

    let result = response.docs.map(document => ({ ...document.data(), id: document.id }));

    if(operationalState){
        operationalState( result );
    }

    return result;
}

const subscribeToAllVehicleEngineHours = async ( subsriptionState ) => {

    return firestore.collection("vehicleEngineHours")
    .onSnapshot(snapshot => {
        let resultDoc = snapshot.docs.map(document => ({ ...document.data(), id: document.id }));
        subsriptionState(resultDoc);
    });

}

const subscribeVehicleEngineHours  = ( vehicleID, setState ) => {
    return firestore.collection("vehicleEngineHours")
        .where('vehicleID', '==', vehicleID)
        .onSnapshot(response => setState(response.docs.map(document => ({...document.data(), id: document.id}))));
}

const subscribeToVehicleEngineHours = async (subsriptionState, vehicleID ) => {

    return firestore.collection("vehicleEngineHours")
    .where('vehicleID', '==', vehicleID)
    .onSnapshot(snapshot => {
        let resultDoc = snapshot.docs.map(document => ({ ...document.data(), id: document.id }));
        subsriptionState(resultDoc);
    });

}

const createVehicleEngineHours  = (vehicleID, engineHours) => {

    const newEntry = {
        ...engineHours,
        vehicleID : vehicleID,
        dateCreated: new Date(),
        dateCreatedStr: DateUtil(new Date())
    }

    // console.log('createVehicleEngineHours', newEntry );
    return firestore.collection("vehicleEngineHours").add(newEntry);

}

const updateVehicleEngineHours = (entry, vehicleID, engineHours ) => {
    // vehicleID, engineHours 

    return firestore.collection("vehicleEngineHours")
    .doc(entry.id)
    .set({ ...engineHours, vehicleID, date_modified: new Date() }, {merge: true});

}

const deleteVehicleEngineHours = async(vehicleID) => {

    await firestore.collection("vehicleEngineHours")
    .where('vehicleID', '==', vehicleID)
    .get()
    .then((res)=>{
        
        res.forEach(entry => {
            return firestore
                .collection("vehicleEngineHours")
                .doc(entry.id)
                .delete()
        })

    });

}

const getShiftVehicleEngineHours  = async ( shiftId ) => {

    const response = await firestore.collection("vehicleEngineHours")
        .where('userShiftId', '==', shiftId)
        .get();

     return response.docs.map(document => ({ ...document.data(), id: document.id }));

}


export { getAllVehicleEngineHours, subscribeVehicleEngineHours, subscribeToAllVehicleEngineHours, subscribeToVehicleEngineHours,
     createVehicleEngineHours, updateVehicleEngineHours, deleteVehicleEngineHours, getShiftVehicleEngineHours }
