import React, { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { updateIncident } from "../../services/IncidentService";
import { CModalBody, CModalHeader, CModalTitle, CModalFooter } from "@coreui/react";
import Styles from './CloseIncidentForm.module.scss';
import { UserContext } from "../../context/userContext";
import { DateUtil } from "../../utilities/DateUtil";
import { CCollapse } from '@coreui/react';
const CloseIncidentForm = (props) => {
    const { register: registerClose, handleSubmit: handleSubmitClose, errors: errorsClose, reset: resetClose } = useForm();
    const { user, showModal } = props;
    const { incident } = props;
    const { toggleFullPageLoader } = useContext(UserContext);
    const [logInfoVisible, setlogInfoVisible] = useState(false)
    const CloseIncident = async (data) => {

        try {
            toggleFullPageLoader(true);
            //We added the original incident as it's own child element on the reformatted incident parameters from the cdatatable - the alternative is fetching the single incident by the ID 
            let updatedincident = incident.oincident;
            const closeIncidentObj = {
                name: data.name,
                classification: data.cbClassification,
                investigationComplete: data.investigationComplete,
                severity: data.severity,
                repeatProbability: data.repeatProbability,
                rootCause: data.rootCause,
                actionTaken: data.actionTaken,
                closedByUser: {
                    fullName: user.fullNameStr,
                    userID: user.userID,
                    userType: user.userType
                },
                dateClosed: new Date(),
                dateClosedStr: DateUtil(new Date())
            }
            updatedincident.status = "Closed";
            updatedincident.closeInfo = closeIncidentObj;
            await updateIncident(updatedincident);
            showModal(false);
            resetClose({});
        } catch (error) {
            console.error(error);
        }
        finally {
            toggleFullPageLoader(false);
        }

        return
    }

    const getLogInfoAccordionIcn = () => {
        if (logInfoVisible) {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="c-icon c-icon-1xl" role="img">
                    <path fill="var(--ci-primary-color, currentColor)" d="M496,400.357H16.333V363.908L256.047,96,496,365.81Zm-440.708-32H455.441L255.975,144.07Z" class="ci-primary"></path>
                </svg>
            );
        }
        else {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="c-icon c-icon-1xl" role="img">
                    <path fill="var(--ci-primary-color, currentColor)" d="M256.286,408.357,16.333,138.548V104H496v36.45ZM56.892,136,256.358,360.287,457.042,136Z" class="ci-primary"></path>
                </svg>
            );
        }
    };
    return (
        <>
            <CModalHeader closeButton>
                <CModalTitle>Close an Incident</CModalTitle>
            </CModalHeader>
            <form className="" onSubmit={handleSubmitClose(CloseIncident)}>
                <CModalBody>
       
                        <div className="d-flex flex-column card">
                        <div className={Styles.cardheader}>
                            <div className={Styles.accordionheader} onClick={() => setlogInfoVisible(!logInfoVisible)}>
                                Log Incident Summary
                                {getLogInfoAccordionIcn()}                                 
                                </div>
                            </div>
                        <CCollapse className={(logInfoVisible ? Styles.cardbody + " show" : Styles.cardbody )}>
                            <div className="row">
                                <div className="col-md-2"><label>Title</label></div>
                                <div className="col-12 col-md-9"><label>{incident.title} </label></div>
                            </div>
                            <div className="row">
                                <div className="col-md-2"><label>Classification</label></div>
                                <div className="col-12 col-md-9"><label>{incident.classification.join(",")}</label></div>
                            </div>
                            {incident.message && <div className="row">
                                <div className="col-md-2"><label>Message</label></div>
                                <div className="col-12 col-md-9"><label>{incident.message}</label></div>
                            </div>}
                            <div className="row">
                                <div className="col-md-2"><label>Logged Date</label></div>
                                <div className="col-12 col-md-9"><label>{incident.dateCreatedFormatted}</label></div>
                            </div>
                            <div className="row">
                                <div className="col-md-2"><label>Logged By</label></div>
                                <div className="col-12 col-md-9"><label>{incident.oincident.loggedByUser.fullName}</label></div>
                            </div>
                         </CCollapse>
                    </div>
                    <div className="d-flex flex-column">
                        <div className="row">
                            <div className="h6" style={{ paddingLeft: '1em', marginTop: '1em', marginBottom: '0.5em' }}>Close Incident Information</div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="title">Name</label>
                            <input ref={registerClose({ required: true })} className="form-control" type="text" id="name" name="name" placeholder="Name..." />
                            {errorsClose.name && <p className={Styles.validation}>Name is required.</p>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="title">Investigation Complete</label>
                            <select ref={registerClose({ required: true })} className="form-control" id="investigationComplete" name="investigationComplete"><option value="">Please select</option><option value="Yes">Yes</option><option value="No">No</option></select>
                            {errorsClose.investigationComplete && <p className={Styles.validation}>This field is required.</p>}
                        </div>
                        <div>
                            <label htmlFor="title">Classification</label>
                        </div>
                        <div className="form-group">
                            <div class={"custom-control custom-checkbox custom-control-inline col-md-3 "+Styles.modalcheckboxgroup}>
                                <input ref={registerClose} class="form-check-input" type="checkbox" name="cbClassification" value="Near Miss" /><label class="form-check-label mx-2">Near Miss</label>
                            </div>
                            <div class={"custom-control custom-checkbox custom-control-inline col-md-3 "+Styles.modalcheckboxgroup}>
                                <input ref={registerClose} class="form-check-input" type="checkbox" name="cbClassification" value="First aid case" /><label class="form-check-label mx-2">First aid case</label>
                            </div>
                            <div class={"custom-control custom-checkbox custom-control-inline col-md-4 "+Styles.modalcheckboxgroup}>
                                <input ref={registerClose} class="form-check-input" type="checkbox" name="cbClassification"value="Medical treatment case" /><label class="form-check-label mx-2">Medical treatment case</label>
                            </div>
                        </div>
                        <div className="form-group">
                            <div class={"custom-control custom-checkbox custom-control-inline col-md-3 "+Styles.modalcheckboxgroup}>
                                <input ref={registerClose} class="form-check-input" type="checkbox" name="cbClassification" value="Lost time Injury" /><label class="form-check-label mx-2">Lost time Injury</label>
                            </div>
                            <div class={"custom-control custom-checkbox custom-control-inline col-md-3 "+Styles.modalcheckboxgroup}>
                                <input ref={registerClose} class="form-check-input" type="checkbox" name="cbClassification" value="Fatality" /><label class="form-check-label mx-2">Fatality</label>
                            </div>
                            <div class={"custom-control custom-checkbox custom-control-inline col-md-4 "+Styles.modalcheckboxgroup}>
                                <input ref={registerClose} class="form-check-input" type="checkbox" name="cbClassification" value="Machine Damage" /><label class="form-check-label mx-2">Machine Damage</label>
                            </div>
                        </div>

                        <div className="form-group">
                            <label htmlFor="title">Severity</label>
                            <div class="custom-checkbox">
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input ref={registerClose} class="form-check-input" type="radio" name="severity" value="1" /><label class="form-check-label mx-2">1</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input ref={registerClose} class="form-check-input" type="radio" name="severity" value="2" /><label class="form-check-label mx-2">2</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input ref={registerClose} class="form-check-input" type="radio" name="severity" value="3" /><label class="form-check-label mx-2">3</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input ref={registerClose} class="form-check-input" type="radio" name="severity" value="4" /><label class="form-check-label mx-2">4</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input ref={registerClose} class="form-check-input" type="radio" name="severity" value="5" /><label class="form-check-label mx-2">5</label>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="title">Probability of Repeat</label>
                            <div class="custom-checkbox">
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input ref={registerClose} class="form-check-input" type="radio" name="repeatProbability" value="1" /><label class="form-check-label mx-2">1</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input ref={registerClose} class="form-check-input" type="radio" name="repeatProbability" value="2" /><label class="form-check-label mx-2">2</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input ref={registerClose} class="form-check-input" type="radio" name="repeatProbability" value="3" /><label class="form-check-label mx-2">3</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input ref={registerClose} class="form-check-input" type="radio" name="repeatProbability" value="4" /><label class="form-check-label mx-2">4</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input ref={registerClose} class="form-check-input" type="radio" name="repeatProbability" value="5" /><label class="form-check-label mx-2">5</label>
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <label htmlFor="message">Root Cause</label>
                            <textarea ref={registerClose} className="form-control" type="textarea" id="rootCause" name="rootCause" placeholder="Root cause..." rows="2" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="message">Action taken</label>
                            <textarea ref={registerClose} className="form-control" type="textarea" name="actionTaken" placeholder="Action taken..." rows="2" />
                        </div>
                    </div>

                </CModalBody>
                <CModalFooter className="d-flex justify-content-between">
                    <button className="btn btn-danger" type="button" data-dismiss='modal' onClick={() => showModal(false)}>Cancel</button>
                    <button className="btn btn-primary" type="submit">Submit</button>
                </CModalFooter>

            </form>
        </>
    );
}

export default CloseIncidentForm;
