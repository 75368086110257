export const truckicon = [`
  <title>tow truck icon</title>
  <g>
        <g>
            <path d="m19.985 37.502c-.389 0-.759-.229-.92-.609l-5.987-14.095c-.217-.508.021-1.096.529-1.311.504-.215 1.096.021 1.311.529l5.987 14.095c.217.508-.021 1.095-.529 1.311-.127.055-.26.08-.391.08z"
                  fill="#ccd1d9"/>
        </g>
        <path d="m32.01 29.499v7h-9v-6l-8.99-8.09-11.01-9.91v-4z" fill="#e6e9ed"/>
        <g>
            <path d="m22.987 37.502c-.553 0-1-.447-1-1v-6.001c0-.553.447-1 1-1s1 .447 1 1v6.001c0 .553-.447 1-1 1z"
                  fill="#e6e9ed"/>
        </g>
        <g>
            <path d="m31.989 37.502c-.553 0-1-.447-1-1v-7.001c0-.553.447-1 1-1s1 .447 1 1v7.001c0 .553-.447 1-1 1z"
                  fill="#e6e9ed"/>
        </g>
        <g>
            <path d="m22.987 31.501c-.238 0-.478-.085-.669-.257l-19.993-18.002c-.41-.37-.443-1.002-.074-1.412.369-.409 1.002-.442 1.412-.074l19.993 18.002c.41.37.443 1.002.074 1.412-.197.22-.469.331-.743.331z"
                  fill="#e6e9ed"/>
        </g>
        <g>
            <path d="m31.988 30.501c-.203 0-.408-.062-.586-.189l-28.995-21.004c-.447-.325-.547-.95-.222-1.397.322-.447.947-.549 1.396-.225l28.995 21.004c.447.324.547.949.223 1.396-.195.271-.501.415-.811.415z"
                  fill="#e6e9ed"/>
        </g>
        <g>
            <path d="m35.992 42.533h-17.008c-.553 0-1-.447-1-1v-5.031c0-.553.447-1 1-1h17.008c.553 0 1 .447 1 1v5.031c0 .553-.447 1-1 1zm-16.008-2h15.008v-3.031h-15.008z"
                  fill="#656d78"/>
        </g>
        <g>
            <path d="m47.02 34.509h11l-3-6h-8zm16 0v9.99h-10.99c-2.979 0-5.439 2.16-5.91 5h-.01-3.09v-8-12.99-4h15z"
                  fill="#ed5565"/>
        </g>
        <g>
            <path d="m36.01 36.499v5h-17.01v-5h1.01 3 9z" fill="#656d78"/>
        </g>
        <g>
            <path d="m42.02 28.509c.55 0 1 .45 1 1v11.991c0 .55-.45 1-1 1h-2c-.55 0-1-.45-1-1v-11.991c0-.55.45-1 1-1z"
                  fill="#656d78"/>
        </g>
        <g>
            <path d="m55.02 28.509 3 6h-11v-6z" fill="#5d9cec"/>
        </g>
        <g>
            <path d="m63.02 44.499v3.01h-5.79c-1.04-1.8-2.979-3.01-5.2-3.01z" fill="#e6e9ed"/>
        </g>
        <g>
            <path d="m39.02 41.499h4v8h-10.08c-.47-2.84-2.939-5-5.91-5-2.979 0-5.439 2.16-5.91 5h-.01-2.15-.021c-.47-2.84-2.93-5-5.91-5-2.97 0-5.439 2.16-5.91 5h-5.109l3.01-8h13.98v.029h17.01v-.029z"
                  fill="#e6e9ed"/>
        </g>
        <g>
            <path d="m63.002 48.505c-.553 0-1-.447-1-1v-12.995c0-.553.447-1 1-1s1 .447 1 1v12.995c0 .553-.447 1-1 1z"
                  fill="#ed5565"/>
        </g>
        <g>
            <path d="m63.003 35.51c-.367 0-.721-.202-.896-.553l-5-10.002c-.247-.494-.047-1.095.447-1.342.493-.247 1.096-.047 1.342.447l5 10.002c.247.494.047 1.095-.447 1.342-.143.072-.296.106-.446.106z"
                  fill="#ed5565"/>
        </g>
        <g>
            <path d="m63.002 45.503h-10.996c-.553 0-1-.447-1-1s.447-1 1-1h10.996c.553 0 1 .447 1 1s-.447 1-1 1z"
                  fill="#e6e9ed"/>
        </g>
        <g>
            <path d="m63.002 48.505h-5.797c-.553 0-1-.447-1-1s.447-1 1-1h5.797c.553 0 1 .447 1 1s-.447 1-1 1z"
                  fill="#e6e9ed"/>
        </g>
        <g>
            <path d="m58.002 25.508h-14.999c-.553 0-1-.448-1-1s.447-1 1-1h14.999c.553 0 1 .448 1 1s-.447 1-1 1z"
                  fill="#ed5565"/>
        </g>
        <path d="m44.003 24.508c0-.552-.448-1-1-1-.553 0-1 .448-1 1l.002 24.993h2z" fill="#ed5565"/>
        <g>
            <path d="m58.023 35.51c-.005.001-.012.001-.02 0h-10.998c-.553 0-1-.447-1-1v-6c0-.552.447-1 1-1h7.997c.379 0 .725.214.895.553l2.921 5.839c.13.169.206.38.206.608-.001.553-.448 1-1.001 1zm-10.018-2h8.381l-2.002-4h-6.379z"
                  fill="#5d9cec"/>
        </g>
        <g>
            <path d="m47.999 38.508h-1c-.553 0-1-.447-1-1s.447-1 1-1h1c.553 0 1 .447 1 1s-.447 1-1 1z" fill="#da4453"/>
        </g>
        <g>
            <path d="m2.998 32.503c-.552 0-1-.447-1-1l-.004-23.006c0-.553.447-1 1-1 .552 0 1 .447 1 1l.004 23.006c0 .553-.447 1-1 1z"
                  fill="#545c66"/>
        </g>
        <g>
            <path d="m2.998 37.505c-1.654 0-3-1.347-3-3.001 0-.553.447-1 1-1s1 .447 1 1c0 .552.448 1.001 1 1.001s1-.449 1-1.001c0-1.343-1.438-2.103-1.453-2.109-.488-.253-.685-.854-.436-1.343.25-.49.845-.689 1.336-.443.104.053 2.553 1.308 2.553 3.896 0 1.653-1.346 3-3 3z"
                  fill="#545c66"/>
        </g>
        <g>
            <path d="m3.998 30.504h-2c-.553 0-1-.448-1-1s.447-1 1-1h2c.553 0 1 .448 1 1s-.447 1-1 1z" fill="#656d78"/>
        </g>
        <path d="m60.908 43.503h3.094v4.002h-3.094z" fill="#e6e9ed"/>
        <g fill="#656d78">
            <path d="m13.029 43.503c-3.217 0-5.893 2.34-6.402 5.417-.064.347-.098.715-.098 1.083 0 3.597 2.915 6.5 6.5 6.5 3.597 0 6.5-2.903 6.5-6.5 0-.368-.031-.736-.098-1.083-.508-3.077-3.173-5.417-6.402-5.417z"/>
            <path d="m33.432 48.92c-.509-3.077-3.185-5.417-6.402-5.417-3.228 0-5.893 2.34-6.402 5.417-.064.347-.098.715-.098 1.083 0 3.597 2.904 6.5 6.5 6.5 3.586 0 6.5-2.903 6.5-6.5-.001-.368-.032-.736-.098-1.083z"/>
        </g>
        <g>
            <path d="m57.663 46.764c.552.954.866 2.059.866 3.239 0 3.597-2.914 6.5-6.5 6.5-3.596 0-6.5-2.903-6.5-6.5 0-.368.033-.736.098-1.083.51-3.077 3.175-5.417 6.402-5.417 2.407 0 4.507 1.31 5.634 3.261z"
                  fill="#656d78"/>
        </g>
        <g>
            <path d="m54.02 50.003c0-1.1-.89-2-2-2-1.1 0-2 .9-2 2 0 1.109.9 2 2 2 1.11 0 2-.891 2-2z" fill="#e6e9ed"/>
        </g>
        <g>
            <path d="m27.029 48.003c1.101 0 2 .9 2 2 0 1.109-.899 2-2 2-1.109 0-2-.891-2-2 0-1.1.891-2 2-2z"
                  fill="#e6e9ed"/>
        </g>
        <g>
            <path d="m13.029 48.003c1.11 0 2 .9 2 2 0 1.109-.89 2-2 2-1.1 0-2-.891-2-2 0-1.1.901-2 2-2z"
                  fill="#e6e9ed"/>
        </g>
    </g>
`]
