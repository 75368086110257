export const reportAnIssue = ['512 512',`
  <title>report an issue icon</title>
    <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="256" x2="256" y1="512" y2="0">
        <stop offset="0" stop-color="#fd3a84"/>
        <stop offset="1" stop-color="#ffa68d"/>
    </linearGradient>
    <g>
        <g>
            <path d="m256 91c-24.813 0-45 20.187-45 45v120c0 24.813 20.187 45 45 45s45-20.187 45-45v-120c0-24.813-20.187-45-45-45zm15 165c0 8.271-6.729 15-15 15s-15-6.729-15-15v-120c0-8.271 6.729-15 15-15s15 6.729 15 15zm-15 75c-24.813 0-45 20.187-45 45s20.187 45 45 45 45-20.187 45-45-20.187-45-45-45zm0 60c-8.271 0-15-6.729-15-15s6.729-15 15-15 15 6.729 15 15-6.729 15-15 15zm251.606-245.432-141.173-141.174c-2.813-2.813-6.628-4.394-10.607-4.394h-199.651c-3.979 0-7.794 1.58-10.606 4.394l-141.175 141.174c-2.814 2.813-4.394 6.628-4.394 10.606v199.651c0 3.978 1.58 7.793 4.394 10.606l141.175 141.174c2.813 2.813 6.628 4.394 10.606 4.394h199.651c3.979 0 7.794-1.581 10.606-4.394l141.174-141.174c2.813-2.813 4.394-6.628 4.394-10.606v-199.651c0-3.978-1.58-7.793-4.394-10.606zm-25.606 204.044-132.387 132.388h-187.225l-132.388-132.388v-187.224l132.388-132.388h187.226l132.386 132.388z"
                  fill="url(#SVGID_1_)"/>
        </g>
    </g>
`]
