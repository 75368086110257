import React, {useContext} from 'react'
import { useForm } from "react-hook-form";
import Styles from './CreateSiteForm.module.scss';
import { createSite } from '../../../../services/SitesService'
import {CModalBody, CModalFooter, CModalHeader, CModalTitle} from "@coreui/react";
import { UserContext } from "../../../../context/userContext";

// ------ CLASSES ----- //

class Site {
    constructor( siteID, siteName) {
        this.siteID = siteID;
        this.siteName = siteName;
    }
}

// ------ JSX ----- //

const CreateSiteForm = (props) => {
    const { register, handleSubmit, errors, reset } = useForm();
    const { toggleFullPageLoader } = useContext(UserContext);

    const createNewSite = async (data) => {
        try {
            toggleFullPageLoader(true);
            props.hide();
            reset();
            await createSite(new Site(data.siteID, data.siteName));
        } finally {
            toggleFullPageLoader(false);
        }
    }
    
    return (
        <>
            <CModalHeader closeButton>
                <CModalTitle>Create Site</CModalTitle>
            </CModalHeader>

            <form className="d-flex flex-column" onSubmit={handleSubmit(createNewSite)}>
                <CModalBody>
                    <div className={Styles.siteForm}>

                        <label htmlFor="siteID">Site ID :</label>
                        <input className={Styles.inputPrimary}
                            type="text"
                            id="siteID"
                            name="siteID"
                            placeholder="enter the site ID"
                            ref={register({ required: true })} />
                            {errors.siteID && <p>Site ID is required. Must be unique.</p>}

                        <label htmlFor="siteName">Site Name :</label>
                        <input className={Styles.inputPrimary}
                            type="text"
                            id="siteName"
                            name="siteName"
                            placeholder="enter the site name"
                            ref={register({ required: true, minLength: 2 })} />
                            {errors.siteName && <p>Site name is required. Minimum length of 2 characters.</p>}

                    </div>
                </CModalBody>

                <CModalFooter>
                    <button className="btn btn-secondary" data-dismiss="modal" aria-label="Close" type="button" onClick={()=>(props.hide())}>Cancel</button>
                    <button className="btn btn-primary" type="submit">Update</button>
                </CModalFooter>

            </form>

        </>
);
}

export default CreateSiteForm;