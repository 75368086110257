import React from "react";
import {Card, Table, Button} from 'antd'
import {DateUtil} from "../../../utilities/DateUtil";
import { getSingleVehicleChecklist } from "../../../services/VehiclesChecklistService";
import {sortBy} from "lodash";
import ChecklistReviewModal from "../ChecklistReview/ChecklistReviewModal";
const CheckList = (props) => {

    const sectionHeading = props.title || false;
    const {vehicleChecklists,  extra, setModalContent, toggle , user, vehicleItems  } = props;

    const vehicleFieldSettings = [
        {
            key: 'timestamp',
            dataIndex: 'timestamp',
            title: 'Date Created',
            sorter: (a, b) => a.timestamp - b.timestamp,
            defaultSortOrder: 'descend',
            render: (value, record) => DateUtil(record.dateCreated ? record.dateCreated.toDate().toString().substring(0, 21) : new Date().toString().substring(0, 21))
        },
        {
            key: 'title',
            dataIndex: 'title',
            title: 'Category',
        },
        {
            key: 'message',
            dataIndex: 'message',
            title: 'Vehicle',
        },
        {
            key: 'action',
            dataIndex: 'action',
            title: 'action',
            render: (_, record) => <Button type='primary' onClick={() => toggleDetails(record)}>Open</Button>
        },
    ]

    const toggleDetails = async (vehicleChecklist) => {
        toggle(true);
        let filteredVehicleAdditionalTextArrData = {};
        let vehicleChecklistOrig = {};

            await getSingleVehicleChecklist(vehicleChecklist.vehicleChecklistId).then(vehicleChecklist => {  
                vehicleChecklistOrig = vehicleChecklist;
                if(vehicleChecklist.checkItemAdditionalDetails){
                    let list = [];
                    let collection = vehicleChecklist.checkItemAdditionalDetails;
                    Object.keys(collection).forEach((item, itemIdx) => {
                        Object.keys(collection[item]).forEach((indItem, indItemIdx) => {
                            list.push({
                            vehicleID: vehicleChecklist.vehicleID,
                            classType: item,
                            key: [item, itemIdx, indItemIdx].join('-').toLowerCase(),
                            label: indItem,
                            detail: collection[item][indItem]
                            });
                        });
                    });
                    sortBy(list, 'classType');
                    filteredVehicleAdditionalTextArrData = list;
                }                 
            });    
                    
          setModalContent(<ChecklistReviewModal vehicleChecklist={vehicleChecklistOrig} vehicleChecklistId={vehicleChecklist.vehicleChecklistId} type={"vehiclechecklistcomplete"} user={user} toggle={toggle} filteredVehicleAdditionalTextArrData={filteredVehicleAdditionalTextArrData} vehicleItems={vehicleItems} />)
    }
    const vehicleChecklistReformat = (vehicleChecklist) => {
        let category = "Vehicle Checklist Complete";
        let type = "vehiclechecklistcomplete";
        return ({
            dateCreated: vehicleChecklist.dateCreated,
            time: vehicleChecklist.dateCreated.toDate().getTime(),
            timestamp: (vehicleChecklist.dateCreated ? vehicleChecklist.dateCreated.toDate().getTime() : new Date().getTime() ),
            vehicle: vehicleChecklist.vehicleID,
            message: vehicleChecklist.vehicleID,
            title: category,
            type: type,
            status: vehicleChecklist.checkItemStatus,
            vehicleChecklistId: vehicleChecklist.id,
        })
    }

    return (
        <Card title={sectionHeading} bodyStyle={{padding: 0}} extra={extra}>
            <Table
                columns={vehicleFieldSettings}
                sortDirections={['ascend', 'descend', 'ascend']}
                dataSource={vehicleChecklists.map(vehicleChecklistReformat)}
                pagination={{size: 'small'}}
                scroll={{x:true}}
            />

        </Card>
    );
};

export default CheckList;
