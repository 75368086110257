import React, { useState, useEffect } from "react";
// import MaintenanceListItem from "../MaintenanceListItem";
import Styles from "./MaintenanceList.module.scss";
import { subscribeToMaintenanceJobs } from '../../../services/MaintenanceJobsService'
import { subscribeToTeams } from "../../../services/TeamsService";
import { CCard, CCardBody, CCardHeader, CCardText } from "@coreui/react";
import MaintenanceTable from '../MaintenanceTable';

const MaintenanceList = () => {

    const [jobsCollection, setJobsCollection] = useState([]);
    const [filteredJobsArr, setFilteredJobsArr] = useState([]);
    const [maintenanceJobs, setMaintenanceJobs] = useState([]);
    const [teamCollection, setTeams] = useState([]);
    const [vehicleIDArr, setVehicleIDArr] = useState([null]);
    const [selectedVehicle, setSelectedVehicle] = useState('');
    const [selectedState, setSelectedState] = useState('');

    const filterJobs = (vehicleID) => {
        if (vehicleID) {
            setFilteredJobsArr(jobsCollection.filter((job) => job.vehicleID === vehicleID))
        } else {
            setFilteredJobsArr(jobsCollection)
        }  
    }

    const filterStatus = (maintenanceSignoff) => {
      if (maintenanceSignoff) {
  
        if (maintenanceSignoff === "unclaimed") {
          setFilteredJobsArr(jobsCollection.filter((job) => job.assignedMaintenance === '' && job.maintenanceSignoff === false && !job.maintenanceRejectionReason));
        }
        else if (maintenanceSignoff === "inprogress") {
          setFilteredJobsArr(jobsCollection.filter((job) => job.assignedMaintenance !== '' && job.maintenanceSignoff === false && !job.maintenanceRejectionReason));
        }
        else if (maintenanceSignoff === "rejected") {
          setFilteredJobsArr(jobsCollection.filter((job) => job.assignedMaintenance === '' && job.maintenanceSignoff === false && job.maintenanceRejectionReason));
        } else {
          setFilteredJobsArr(jobsCollection);
        }
      } else {
        setFilteredJobsArr(jobsCollection);
      }
    };

    useEffect(() => {
        
        const unsubscribetoMaintenanceJobs = subscribeToMaintenanceJobs(setMaintenanceJobs);
        const unsubscribeTeams = subscribeToTeams(setTeams);

        //Use effect clean ups
        return () => {
            unsubscribeTeams();
            unsubscribetoMaintenanceJobs();
        }
    }, []);

    const mapMaintenanceJobs = (maintenanceJobs) => {
      let jobsArr = [];
      if( maintenanceJobs && maintenanceJobs.length ){
        maintenanceJobs.forEach(maintenanceJob => {
          if (maintenanceJob.maintenanceSignoff === false ) {
            jobsArr.push(maintenanceJob);
          }             
        });
      }
      return jobsArr;
    };

    const attachJobSites = filteredJobList => {

      if (filteredJobList && filteredJobList.length) {
        return filteredJobList.map(job => {  
          const teamFound = teamCollection.find(team => team.teamName === job.vehicleTeam);  
          if (teamFound) {
            job.site = teamFound.site;
          }  
          return job;  
        })  
      }  
      return []
    };

    useEffect(() => {
      if (jobsCollection) {
        setVehicleIDArr([...new Set(jobsCollection.map(job => job.vehicleID))])
      }
    }, [jobsCollection]);

    useEffect(() => {
      filterJobs(selectedVehicle);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedVehicle])

    useEffect(() => {
        if (maintenanceJobs.length > 0 && teamCollection.length > 0) {          
            Promise.resolve(maintenanceJobs)
                .then(mapMaintenanceJobs)
                .then(attachJobSites)
                .then(jobsWithSiteName => {
                    setJobsCollection(jobsWithSiteName);
                    setFilteredJobsArr(jobsWithSiteName);

                    if(selectedVehicle){
                        filterJobs(selectedVehicle);
                    }
                   
                })
                .catch(err => {
                    console.error('error', err)
                })
        }else{
          setJobsCollection([])
          setFilteredJobsArr([]);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [maintenanceJobs,teamCollection]);

    useEffect(() => {
      filterStatus(selectedState);  
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedState])

    return (
        <div className={''}>
            <CCard className={``}>
                <CCardHeader className={'d-flex align-items-center justify-content-between'}>
                    <div className={''}>Outstanding Jobs</div>
                    <div className={'card-header-actions col-md-4 col-7 p-0 d-md-flex'}>
                        <select className={'custom-select card-header-action col-12 col-md-6 mr-md-2 my-1'} onChange={(event) => setSelectedVehicle(event.target.value)}>
                        <option value="">All Vehicles</option>
                            {vehicleIDArr ? vehicleIDArr.map((vehicleID) => <option key={vehicleID} value={vehicleID}>{vehicleID}</option>) : null}
                        </select>
                        <select className={'custom-select card-header-action col-md-6 col-12 my-1'} onChange={(event) => setSelectedState(event.target.value)}>
                            <option value="">All States</option>
                            <option value="unclaimed">Unclaimed</option>
                            <option value="inprogress">In Progress</option>
                            <option value="rejected">Rejected</option>
                        </select>
                    </div>
         
                </CCardHeader>

                <CCardBody className={`${Styles.jobsListSection}`}>

                    <CCardText tag={'div'}>
                        <div className={`position-relative table-responsive`}>
                            <MaintenanceTable jobsCollection={filteredJobsArr} />
                        </div>

                    </CCardText>

                </CCardBody>
            </CCard>
        </div>
    )

}

export default MaintenanceList;
