const checklistData = {
    "ADT": {
        classA: {
            "Head lights": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FHeadlights.png?alt=media&token=059c6317-bcac-4128-9c90-84e6937087f1" ,
            "Indicator/rear/brake lights": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FRear%20lights.png?alt=media&token=11bbfab6-2e31-4f46-b6be-524addc05a90" ,
            "Hooter": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FHooter.png?alt=media&token=017f6674-c65b-4f36-a6bd-c42239866d88" , 
            "Reverse hooter": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FReverse%20hooter.png?alt=media&token=55c29d3a-0cf0-4548-81b6-f1261fa806c9" ,
            "Seat belts": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSeat%20belts.png?alt=media&token=3b624fd8-c12d-4a45-8441-a2250ef74dc0" , 
            "Seats": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSeats.png?alt=media&token=a5db2a95-881b-4839-9713-e5726f1666bc",
            "Two-way radio": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FTwo%20way%20radio.png?alt=media&token=7c661eb5-01a8-44bb-a513-1b2a2b98ba2b" , 
            "Mirrors": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FMirrors.png?alt=media&token=02ed44ff-a292-41e0-af24-5e188766919a" ,
            "Levers & joysticks": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FLevers%20%26%20joysticks.png?alt=media&token=2d376604-a898-4915-9021-e78825fa7675",
            "Steer control": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSteering.png?alt=media&token=78bbb22e-8142-44f2-8aa9-7325859e6451" ,
            "Oil/fuel leaks": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FOil%20leak.png?alt=media&token=2d443594-f759-413a-956a-2aec978595b4",
            "Fluid level": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFluid%20images.png?alt=media&token=75a339f6-e178-4eff-8d02-5fffaa51b739",
            "Fire extinguisher": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFire%20extinguisher.png?alt=media&token=9c364d87-a260-4f7d-bf5b-38a6cf60857a" , 
            "Windscreen wipers": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FWindscreen%20wipers.png?alt=media&token=371a7e0a-3302-4f61-a28a-3faac4844633",
            "Windscreen condition": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FWindscreen.png?alt=media&token=4e488be5-932f-4d26-ac84-14e1b1c1dacd",
            "Brakes tested": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBrakes.png?alt=media&token=c35871de-2292-4e3c-86d8-0599d4a32b8e" ,
            "Isolation point": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FIsolation%20point.png?alt=media&token=66d56be2-bf90-4e17-817d-fbee2f3ea201" ,
            "Tyre condition": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FTyres.png?alt=media&token=e86b0300-1409-40a5-b6a5-5f51d95819d3" ,
            "Wheel nuts": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FWheel%20nuts.png?alt=media&token=6276be15-c7bc-4795-84a9-7c80dbd6d3b0" , 
            "Air conditioner": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FAir%20conditioner.png?alt=media&token=b2c68ea5-5bb5-4b66-b44a-311617723521" , 
            "Gauges": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FGauges.png?alt=media&token=efba8ff9-7321-42d4-87d4-a3c57b234a86",
            "Steps/three-point contact": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSteps.png?alt=media&token=8091d69c-4539-4d25-be1a-bfac93eeb7af" , 
            "Strobe light": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FStrobe%20light.png?alt=media&token=800346f1-2b02-4765-8a4e-a6f98ec0755b" , 
            "Safety guards": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FEquipment%20safety%20guards.png?alt=media&token=ac8e4d00-680c-4444-a1a2-37be71096bba",
            "Stop blocks": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FStop%20blocks.png?alt=media&token=23f443fa-7624-4795-8220-8edb35705b35" , 
            "Reflective tape": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FReflective%20tape.jpeg?alt=media&token=ffa8f998-227f-4f41-be9e-eeaa67ad7ac6",
            "Quarterly tagged": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FQuarterly%20tagged.png?alt=media&token=57ccdb57-a537-4125-9929-f514c0820771",
            "Door seals" : "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FDoor%20seals.png?alt=media&token=45091388-acc8-4e78-81d2-e88a8465988b",
            "Operator licence": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FOperator%20license.png?alt=media&token=75b2b887-8384-487b-946a-2d251e586089", 
            "Door latches": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FDoor%20latches.png?alt=media&token=8b7d8bcf-15f7-4aaf-bd31-3d61c0d86c9e"
        },
        classB: {
            "Cab clean": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FCab%20clean.png?alt=media&token=f3dfba64-d8ff-4769-81c1-0b8ac0f64487",
            "Rear/side windows": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FRear%3Aside%20windows.png?alt=media&token=54724ea8-a728-4831-9429-ee0078a30a40" , 
            "ID number": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FID%20number.png?alt=media&token=abe0388d-b63f-4e2b-b8a0-4b81d328c5fb",
            "Wiring": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FWiring.png?alt=media&token=5b91c44a-3edb-4a3d-bc43-261d98b8928b",
            "Cab/bonnet stay": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FCab%3Abonnet%20stay.png?alt=media&token=154e60c5-3642-44f7-9858-b7ed7cac4eb4"
        },
    }, 
    
    "AWT":{
        classA: {
            "Operator licence": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FOperator%20license.png?alt=media&token=75b2b887-8384-487b-946a-2d251e586089", 
            "Pins in position and locked": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FPins.jpeg?alt=media&token=64d829c3-e5f1-463f-aa32-cd7ee057f85f" ,
            "Fire extinguisher": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFire%20extinguisher.png?alt=media&token=9c364d87-a260-4f7d-bf5b-38a6cf60857a" , 
            "Hand rails": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FHand%20rails.png?alt=media&token=678b0c5a-7470-469d-800d-ecafe8d6547b" ,
            "Doors/handles": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FDoor%20seals.png?alt=media&token=45091388-acc8-4e78-81d2-e88a8465988b" ,
            "Stop blocks": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FStop%20blocks.png?alt=media&token=23f443fa-7624-4795-8220-8edb35705b35" , 
            "Wheel nuts": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FWheel%20nuts.png?alt=media&token=6276be15-c7bc-4795-84a9-7c80dbd6d3b0" , 
            "Seat belts": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSeat%20belts.png?alt=media&token=3b624fd8-c12d-4a45-8441-a2250ef74dc0" , 
            "Levers & joysticks": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FLevers%20%26%20joysticks.png?alt=media&token=2d376604-a898-4915-9021-e78825fa7675",
            "Steer control": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSteering.png?alt=media&token=78bbb22e-8142-44f2-8aa9-7325859e6451" ,
            "Hooter": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FHooter.png?alt=media&token=017f6674-c65b-4f36-a6bd-c42239866d88" , 
            "Reverse hooter": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FReverse%20hooter.png?alt=media&token=55c29d3a-0cf0-4548-81b6-f1261fa806c9" ,
            "Head lights": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FHeadlights.png?alt=media&token=059c6317-bcac-4128-9c90-84e6937087f1" ,
            "Strobe light": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FStrobe%20light.png?alt=media&token=800346f1-2b02-4765-8a4e-a6f98ec0755b" , 
            "Indicator/rear/brake lights": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FRear%20lights.png?alt=media&token=11bbfab6-2e31-4f46-b6be-524addc05a90" ,
            "Brake test service brake": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBrakes.png?alt=media&token=c35871de-2292-4e3c-86d8-0599d4a32b8e" ,
            "Brake test park brake": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBrakes.png?alt=media&token=c35871de-2292-4e3c-86d8-0599d4a32b8e" ,
            "Air conditioner": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FAir%20conditioner.png?alt=media&token=b2c68ea5-5bb5-4b66-b44a-311617723521" , 
            "Oil level": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFluid%20images.png?alt=media&token=75a339f6-e178-4eff-8d02-5fffaa51b739" ,
            "(Red permit area) Brake test ramp when entering pit": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBrakes.png?alt=media&token=c35871de-2292-4e3c-86d8-0599d4a32b8e" ,
            "(Red permit area) Flag": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFlag.png?alt=media&token=9bc16ad5-163b-4ff0-919e-a66be0670f17" ,
            "(Red permit area) Radio two way": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FTwo%20way%20radio.png?alt=media&token=7c661eb5-01a8-44bb-a513-1b2a2b98ba2b" , 
        },
        classB: {
            "Dashboard instruments": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FGauges.png?alt=media&token=efba8ff9-7321-42d4-87d4-a3c57b234a86" ,
            "Oil/fuel leaks": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FOil%20leak.png?alt=media&token=2d443594-f759-413a-956a-2aec978595b4",
            "Windows": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FWindscreen.png?alt=media&token=4e488be5-932f-4d26-ac84-14e1b1c1dacd" ,
            "Tyre condition": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FTyres.png?alt=media&token=e86b0300-1409-40a5-b6a5-5f51d95819d3" ,
            "Steps": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSteps.png?alt=media&token=8091d69c-4539-4d25-be1a-bfac93eeb7af" , 
            "Seats": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSeats.png?alt=media&token=a5db2a95-881b-4839-9713-e5726f1666bc",
            "Screen wiper": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FWindscreen%20wipers.png?alt=media&token=371a7e0a-3302-4f61-a28a-3faac4844633" ,
            "Mirrors": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FMirrors.png?alt=media&token=02ed44ff-a292-41e0-af24-5e188766919a" ,
            "Coolant level": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFluid%20images.png?alt=media&token=75a339f6-e178-4eff-8d02-5fffaa51b739" ,
            "Radiator not clogged": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FRadiator.png?alt=media&token=4065d278-9625-46d8-93aa-d99028b0dd9b" ,
            "Hydraulic oil level": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFluid%20images.png?alt=media&token=75a339f6-e178-4eff-8d02-5fffaa51b739" ,
            "Fuel level": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFluid%20images.png?alt=media&token=75a339f6-e178-4eff-8d02-5fffaa51b739" ,
            "(Red permit area) Reflective tape condition": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FReflective%20tape.jpeg?alt=media&token=ffa8f998-227f-4f41-be9e-eeaa67ad7ac6",
        },
        classC: {
            "New bump marks": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBump%20marks.png?alt=media&token=d714ffa6-eaf5-47a8-96c3-1ab5e7f8e434" 
        },
    },

    "DB": {
        classA: {
            "Operator licence": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FOperator%20license.png?alt=media&token=75b2b887-8384-487b-946a-2d251e586089", 
            "Wheel nuts": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FWheel%20nuts.png?alt=media&token=6276be15-c7bc-4795-84a9-7c80dbd6d3b0" , 
            "Pins in position and locked": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FPins.jpeg?alt=media&token=64d829c3-e5f1-463f-aa32-cd7ee057f85f" ,
            "Fire extinguisher": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFire%20extinguisher.png?alt=media&token=9c364d87-a260-4f7d-bf5b-38a6cf60857a" , 
            "Stop blocks": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FStop%20blocks.png?alt=media&token=23f443fa-7624-4795-8220-8edb35705b35" , 
            "Emergency triangle excluding red permit area": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FTriangles.png?alt=media&token=c7797281-e817-4cb2-b745-92c83d084185" , 
            "Seat belts": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSeat%20belts.png?alt=media&token=3b624fd8-c12d-4a45-8441-a2250ef74dc0" , 
            "Levers & joysticks": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FLevers%20%26%20joysticks.png?alt=media&token=2d376604-a898-4915-9021-e78825fa7675",
            "Steer control": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSteering.png?alt=media&token=78bbb22e-8142-44f2-8aa9-7325859e6451" ,
            "Hooter": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FHooter.png?alt=media&token=017f6674-c65b-4f36-a6bd-c42239866d88" , 
            "Reverse hooter": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FReverse%20hooter.png?alt=media&token=55c29d3a-0cf0-4548-81b6-f1261fa806c9" ,
            "Head lights": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FHeadlights.png?alt=media&token=059c6317-bcac-4128-9c90-84e6937087f1" ,
            "Strobe light": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FStrobe%20light.png?alt=media&token=800346f1-2b02-4765-8a4e-a6f98ec0755b" , 
            "Indicator/rear/brake lights": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FRear%20lights.png?alt=media&token=11bbfab6-2e31-4f46-b6be-524addc05a90" ,
            "Brake test service brake": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBrakes.png?alt=media&token=c35871de-2292-4e3c-86d8-0599d4a32b8e" ,
            "PPE": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FPPE.jpeg?alt=media&token=46c07362-6cf9-4187-8f09-04f66a333797" ,
            "Isolation point": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FIsolation%20point.png?alt=media&token=66d56be2-bf90-4e17-817d-fbee2f3ea201" ,
            "Brake test park brake": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBrakes.png?alt=media&token=c35871de-2292-4e3c-86d8-0599d4a32b8e" ,
            "Doors/handles": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FDoor%20seals.png?alt=media&token=45091388-acc8-4e78-81d2-e88a8465988b" ,
            "Air conditioner": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FAir%20conditioner.png?alt=media&token=b2c68ea5-5bb5-4b66-b44a-311617723521" , 
            "Radio two way": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FTwo%20way%20radio.png?alt=media&token=7c661eb5-01a8-44bb-a513-1b2a2b98ba2b" , 
            "(Other attachments) Pumps": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FPump.jpeg?alt=media&token=9a1f3128-bc5e-434f-8f5c-3d74ef4055b2" ,
            "(Other attachments) Safety guards": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FEquipment%20safety%20guards.png?alt=media&token=ac8e4d00-680c-4444-a1a2-37be71096bba",
            "(Other attachments) Hose connectors/fittings": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FHose.jpeg?alt=media&token=28c8f67d-32f6-4396-90d5-c932bb31a20d" ,
            "(Other attachments) Brake test ramp when entering pit": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBrakes.png?alt=media&token=c35871de-2292-4e3c-86d8-0599d4a32b8e" ,
            "(Other attachments) Radio two way": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FTwo%20way%20radio.png?alt=media&token=7c661eb5-01a8-44bb-a513-1b2a2b98ba2b" , 
            "(Other attachments) Flags": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFlag.png?alt=media&token=9bc16ad5-163b-4ff0-919e-a66be0670f17" ,
        },
        classB: {
            "Oil/fuel leaks": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FOil%20leak.png?alt=media&token=2d443594-f759-413a-956a-2aec978595b4",
            "Mirrors": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FMirrors.png?alt=media&token=02ed44ff-a292-41e0-af24-5e188766919a" ,
            "Windows": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FWindscreen.png?alt=media&token=4e488be5-932f-4d26-ac84-14e1b1c1dacd" ,
            "Tyre condition": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FTyres.png?alt=media&token=e86b0300-1409-40a5-b6a5-5f51d95819d3" ,
            "Seats": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSeats.png?alt=media&token=a5db2a95-881b-4839-9713-e5726f1666bc",
            "Steps": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSteps.png?alt=media&token=8091d69c-4539-4d25-be1a-bfac93eeb7af" , 
            "Screen wipers": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FWindscreen%20wipers.png?alt=media&token=371a7e0a-3302-4f61-a28a-3faac4844633" ,
            "Oil level": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFluid%20images.png?alt=media&token=75a339f6-e178-4eff-8d02-5fffaa51b739" ,
            "Dashboard indicators/instruments": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FGauges.png?alt=media&token=efba8ff9-7321-42d4-87d4-a3c57b234a86" ,
            "Hydraulic oil": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFluid%20images.png?alt=media&token=75a339f6-e178-4eff-8d02-5fffaa51b739" , 
            "Coolant level": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFluid%20images.png?alt=media&token=75a339f6-e178-4eff-8d02-5fffaa51b739" ,
            "Radiator not clogged": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FRadiator.png?alt=media&token=4065d278-9625-46d8-93aa-d99028b0dd9b" ,
            "PTO": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FPTO.jpeg?alt=media&token=d2e84e7e-6bde-4dad-ab0d-3dc1c3299c3c"
        },
        classC: {
            "New bump marks": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBump%20marks.png?alt=media&token=d714ffa6-eaf5-47a8-96c3-1ab5e7f8e434" 
        },
    },
    
    "Drills": {
        classA: {
            "Operator licence": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FOperator%20license.png?alt=media&token=75b2b887-8384-487b-946a-2d251e586089", 
            "Fire extinguisher": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFire%20extinguisher.png?alt=media&token=9c364d87-a260-4f7d-bf5b-38a6cf60857a" , 
            "Front and rear lights": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FRear%20lights.png?alt=media&token=11bbfab6-2e31-4f46-b6be-524addc05a90" ,
            "Strobe light": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FStrobe%20light.png?alt=media&token=800346f1-2b02-4765-8a4e-a6f98ec0755b" , 
            "Tram hooter": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FHooter.png?alt=media&token=017f6674-c65b-4f36-a6bd-c42239866d88" ,
            "Radio two way": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FTwo%20way%20radio.png?alt=media&token=7c661eb5-01a8-44bb-a513-1b2a2b98ba2b" ,
            "Dashboard warning lights" : "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FGauges.png?alt=media&token=efba8ff9-7321-42d4-87d4-a3c57b234a86" ,
            "Hooter": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FHooter.png?alt=media&token=017f6674-c65b-4f36-a6bd-c42239866d88" , 
            "Drifter slides": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FDrifter%20slides.png?alt=media&token=625b742a-6a15-4880-8134-bfba6ba6fea1" ,
            "Drifter cable": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FDrifter%20cable.png?alt=media&token=58b0bbb7-6214-4151-bd8a-4fca9c4ce181" ,
            "Seat belts": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSeat%20belts.png?alt=media&token=3b624fd8-c12d-4a45-8441-a2250ef74dc0" , 
            "Key control": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FKey%20control.png?alt=media&token=56e5c02c-bd71-41e6-a3ce-2c24ab526f75" ,
            "Controls": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FLevers%20%26%20joysticks.png?alt=media&token=2d376604-a898-4915-9021-e78825fa7675" ,
            "Emergency stop condition": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FEmergency%20stop.png?alt=media&token=c248c482-609f-40bd-8571-7cd3c7372016" ,
            "Boom condition": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBoom%20condition.png?alt=media&token=858937ed-1ab5-4e03-aa8d-852f9381259a" , 
            "Isolation point": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FIsolation%20point.png?alt=media&token=66d56be2-bf90-4e17-817d-fbee2f3ea201" ,
            "Hoses": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FHose.jpeg?alt=media&token=28c8f67d-32f6-4396-90d5-c932bb31a20d" , 
            "Isolator valves": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FValve.jpeg?alt=media&token=15c07dc1-af3d-4f59-87e2-b5c66931c301" ,
            "Safety guards": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FEquipment%20safety%20guards.png?alt=media&token=ac8e4d00-680c-4444-a1a2-37be71096bba",
            "Air conditioner": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FAir%20conditioner.png?alt=media&token=b2c68ea5-5bb5-4b66-b44a-311617723521" , 
            "Doors and handles": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FDoor%20seals.png?alt=media&token=45091388-acc8-4e78-81d2-e88a8465988b" ,
            "Dust collection": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FDust%20collector.jpeg?alt=media&token=43214e6d-8d6e-438e-a5b2-53a8e4eec675" ,
        },
        classB: {
            "Mirrors": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FMirrors.png?alt=media&token=02ed44ff-a292-41e0-af24-5e188766919a" ,
            "Oil/fuel leaks": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FOil%20leak.png?alt=media&token=2d443594-f759-413a-956a-2aec978595b4",
            "Seat": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSeats.png?alt=media&token=a5db2a95-881b-4839-9713-e5726f1666bc" ,
            "Steps": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSteps.png?alt=media&token=8091d69c-4539-4d25-be1a-bfac93eeb7af" , 
            "Reflective tape condition": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FReflective%20tape.jpeg?alt=media&token=ffa8f998-227f-4f41-be9e-eeaa67ad7ac6",
            "Tracks and sprockets": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FTracks.png?alt=media&token=3029cbc1-d9c9-4d7b-8b6e-bfcce436a73d" ,
            "Windows and wipers": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FWindscreen%20wipers.png?alt=media&token=371a7e0a-3302-4f61-a28a-3faac4844633" ,
            "Cylinders": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FCylinders.png?alt=media&token=76a3f1e7-ef80-4d57-8b57-02a27f15567b" , 
            "Tow bar and pin": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FTow%20bar.png?alt=media&token=912a14df-dd11-401c-9b47-dae94b35054f" ,
            "Carousel": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FCarousel.jpeg?alt=media&token=bb3bde48-c361-45fe-9210-d9af2c7d2df4"
        },
        classC: {
        "New bump marks": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBump%20marks.png?alt=media&token=d714ffa6-eaf5-47a8-96c3-1ab5e7f8e434",
        "Fuel level": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFluid%20images.png?alt=media&token=75a339f6-e178-4eff-8d02-5fffaa51b739"
        }
    }, 

    "Excavator": {
        classA: {
            "Operator licence": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FOperator%20license.png?alt=media&token=75b2b887-8384-487b-946a-2d251e586089", 
            "Fire extinguisher": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFire%20extinguisher.png?alt=media&token=9c364d87-a260-4f7d-bf5b-38a6cf60857a" , 
            "Seat belts": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSeat%20belts.png?alt=media&token=3b624fd8-c12d-4a45-8441-a2250ef74dc0" , 
            "Head lights": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FHeadlights.png?alt=media&token=059c6317-bcac-4128-9c90-84e6937087f1" ,
            "Strobe light": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FStrobe%20light.png?alt=media&token=800346f1-2b02-4765-8a4e-a6f98ec0755b" , 
            "Area lights": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FHeadlights.png?alt=media&token=059c6317-bcac-4128-9c90-84e6937087f1" ,
            "Dashboard warning lights": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FGauges.png?alt=media&token=efba8ff9-7321-42d4-87d4-a3c57b234a86" ,
            "Hooter": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FHooter.png?alt=media&token=017f6674-c65b-4f36-a6bd-c42239866d88" , 
            "Travel alarm": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FTravel%20alarm.png?alt=media&token=8a47f605-6d1c-4e10-b38c-d93c5b89aa60" ,
            "Swing brakes": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBrakes.png?alt=media&token=c35871de-2292-4e3c-86d8-0599d4a32b8e" ,
            "Controls": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FLevers%20%26%20joysticks.png?alt=media&token=2d376604-a898-4915-9021-e78825fa7675" , 
            "All pipes": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FPipe.png?alt=media&token=e677e45a-8c1e-4048-8dee-0bb0a63a4820" , 
            "Mirrors": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FMirrors.png?alt=media&token=02ed44ff-a292-41e0-af24-5e188766919a" ,
            "Radio two way": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FTwo%20way%20radio.png?alt=media&token=7c661eb5-01a8-44bb-a513-1b2a2b98ba2b" , 
            "Isolation point": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FIsolation%20point.png?alt=media&token=66d56be2-bf90-4e17-817d-fbee2f3ea201" ,
            "Key control": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FKey%20control.png?alt=media&token=56e5c02c-bd71-41e6-a3ce-2c24ab526f75" , 
            "Emergency stop condition": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FEmergency%20stop.png?alt=media&token=c248c482-609f-40bd-8571-7cd3c7372016" ,
            "Air conditioner": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FAir%20conditioner.png?alt=media&token=b2c68ea5-5bb5-4b66-b44a-311617723521" , 
            "Doors and handles": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FDoor%20seals.png?alt=media&token=45091388-acc8-4e78-81d2-e88a8465988b"  
        },
        classB: {
            "Steps": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSteps.png?alt=media&token=8091d69c-4539-4d25-be1a-bfac93eeb7af" , 
            "Hand grips": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FHand%20grips.png?alt=media&token=a18f2002-ff4d-4fec-aaa8-3437827f283c" ,
            "Windows": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FWindscreen.png?alt=media&token=4e488be5-932f-4d26-ac84-14e1b1c1dacd" ,
            "Seats": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSeats.png?alt=media&token=a5db2a95-881b-4839-9713-e5726f1666bc",
            "Oil/fuel leaks": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FOil%20leak.png?alt=media&token=2d443594-f759-413a-956a-2aec978595b4",
            "Tracks": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FTracks.png?alt=media&token=3029cbc1-d9c9-4d7b-8b6e-bfcce436a73d" ,
            "Reflective tape condition": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FReflective%20tape.jpeg?alt=media&token=ffa8f998-227f-4f41-be9e-eeaa67ad7ac6",
            "Bucket": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBucket.png?alt=media&token=081ce8a2-33aa-4b44-b0da-58829f20a129" ,
            "Rollers": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FRollers.png?alt=media&token=74fb4408-00f8-416e-ad41-00b45f86c1a2"
        }, 
        classC: {
            "New bump marks": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBump%20marks.png?alt=media&token=d714ffa6-eaf5-47a8-96c3-1ab5e7f8e434" 
        },
    },

    "FEL": {
        classA: {
            "Operator licence": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FOperator%20license.png?alt=media&token=75b2b887-8384-487b-946a-2d251e586089", 
            "Stop blocks": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FStop%20blocks.png?alt=media&token=23f443fa-7624-4795-8220-8edb35705b35" , 
            "Fire extinguisher": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFire%20extinguisher.png?alt=media&token=9c364d87-a260-4f7d-bf5b-38a6cf60857a" , 
            "Seat belts": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSeat%20belts.png?alt=media&token=3b624fd8-c12d-4a45-8441-a2250ef74dc0" , 
            "Head lights": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FHeadlights.png?alt=media&token=059c6317-bcac-4128-9c90-84e6937087f1" ,
            "Hazard lights": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FStrobe%20light.png?alt=media&token=800346f1-2b02-4765-8a4e-a6f98ec0755b" , 
            "Access lights": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FAccess%20lights.png?alt=media&token=2daff19f-e8e2-4b0f-961c-d4873715515e" , 
            "Indicator/rear/brake lights": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FRear%20lights.png?alt=media&token=11bbfab6-2e31-4f46-b6be-524addc05a90" ,
            "Mirrors": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FMirrors.png?alt=media&token=02ed44ff-a292-41e0-af24-5e188766919a" ,
            "Dashboard warning lights": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FGauges.png?alt=media&token=efba8ff9-7321-42d4-87d4-a3c57b234a86" , 
            "Hooter": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FHooter.png?alt=media&token=017f6674-c65b-4f36-a6bd-c42239866d88" , 
            "Reverse hooter": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FReverse%20hooter.png?alt=media&token=55c29d3a-0cf0-4548-81b6-f1261fa806c9" , 
            "Steer control": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSteering.png?alt=media&token=78bbb22e-8142-44f2-8aa9-7325859e6451" ,
            "Brakes tested": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBrakes.png?alt=media&token=c35871de-2292-4e3c-86d8-0599d4a32b8e" ,
            "Key control/proxy": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FKey%20control.png?alt=media&token=56e5c02c-bd71-41e6-a3ce-2c24ab526f75" ,
            "Gauges": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FGauges.png?alt=media&token=efba8ff9-7321-42d4-87d4-a3c57b234a86",
            "Tyre condition": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FTyres.png?alt=media&token=e86b0300-1409-40a5-b6a5-5f51d95819d3" ,
            "Controls":"https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FLevers%20%26%20joysticks.png?alt=media&token=2d376604-a898-4915-9021-e78825fa7675" , 
            "Brake test ramp when entering pit": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBrakes.png?alt=media&token=c35871de-2292-4e3c-86d8-0599d4a32b8e" ,
            "Strobe light": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FStrobe%20light.png?alt=media&token=800346f1-2b02-4765-8a4e-a6f98ec0755b" , 
            "Radio two way": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FTwo%20way%20radio.png?alt=media&token=7c661eb5-01a8-44bb-a513-1b2a2b98ba2b" , 
            "Isolation point": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FIsolation%20point.png?alt=media&token=66d56be2-bf90-4e17-817d-fbee2f3ea201" ,
            "Air conditioner": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FAir%20conditioner.png?alt=media&token=b2c68ea5-5bb5-4b66-b44a-311617723521" , 
            "Doors and handles": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FDoor%20seals.png?alt=media&token=45091388-acc8-4e78-81d2-e88a8465988b" 
        },
        
        classB: {
            "Hand grips": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FHand%20grips.png?alt=media&token=a18f2002-ff4d-4fec-aaa8-3437827f283c" ,
            "Hand rails": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FHand%20rails.png?alt=media&token=678b0c5a-7470-469d-800d-ecafe8d6547b" ,
            "Screen wipers": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FWindscreen%20wipers.png?alt=media&token=371a7e0a-3302-4f61-a28a-3faac4844633" ,
            "Fuel level": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFluid%20images.png?alt=media&token=75a339f6-e178-4eff-8d02-5fffaa51b739" ,
            "All pipes": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FPipe.png?alt=media&token=e677e45a-8c1e-4048-8dee-0bb0a63a4820" ,
            "Seats": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSeats.png?alt=media&token=a5db2a95-881b-4839-9713-e5726f1666bc",
            "Oil/fuel leaks": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FOil%20leak.png?alt=media&token=2d443594-f759-413a-956a-2aec978595b4",
            "Windows": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FWindscreen.png?alt=media&token=4e488be5-932f-4d26-ac84-14e1b1c1dacd" ,
            "Steps": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSteps.png?alt=media&token=8091d69c-4539-4d25-be1a-bfac93eeb7af" , 
            "Reflective tape condition": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FReflective%20tape.jpeg?alt=media&token=ffa8f998-227f-4f41-be9e-eeaa67ad7ac6",
            "Bucket": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBucket.png?alt=media&token=081ce8a2-33aa-4b44-b0da-58829f20a129"
        },
        
        classC: {
            "Towbar and pin": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FTow%20bar.png?alt=media&token=912a14df-dd11-401c-9b47-dae94b35054f" ,
            "New bump marks": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBump%20marks.png?alt=media&token=d714ffa6-eaf5-47a8-96c3-1ab5e7f8e434"
        }
    },
    
    "Forklift": {
        classA: {
            "Operator licence": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FOperator%20license.png?alt=media&token=75b2b887-8384-487b-946a-2d251e586089", 
            "Stop blocks": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FStop%20blocks.png?alt=media&token=23f443fa-7624-4795-8220-8edb35705b35" , 
            "Wheel nuts": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FWheel%20nuts.png?alt=media&token=6276be15-c7bc-4795-84a9-7c80dbd6d3b0" , 
            "Fork condition": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFork.png?alt=media&token=d5ca2545-c272-4034-8ae4-ff50c1d10249",
            "Lifting chain condition": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSafety%20chain.png?alt=media&token=ccfaf893-265e-44e2-95ac-cbe159447ea6",
            "Pins in position and locked": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FPins.jpeg?alt=media&token=64d829c3-e5f1-463f-aa32-cd7ee057f85f" ,
            "Seat belts": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSeat%20belts.png?alt=media&token=3b624fd8-c12d-4a45-8441-a2250ef74dc0" , 
            "Emergency triangle": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FTriangles.png?alt=media&token=c7797281-e817-4cb2-b745-92c83d084185",
            "Controls": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FLevers%20%26%20joysticks.png?alt=media&token=2d376604-a898-4915-9021-e78825fa7675",
            "Hooter": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FHooter.png?alt=media&token=017f6674-c65b-4f36-a6bd-c42239866d88" , 
            "Reverse hooter": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FReverse%20hooter.png?alt=media&token=55c29d3a-0cf0-4548-81b6-f1261fa806c9" ,
            "Head lights": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FHeadlights.png?alt=media&token=059c6317-bcac-4128-9c90-84e6937087f1" ,
            "Strobe light": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FStrobe%20light.png?alt=media&token=800346f1-2b02-4765-8a4e-a6f98ec0755b" , 
            "Indicator/rear/brake lights": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FRear%20lights.png?alt=media&token=11bbfab6-2e31-4f46-b6be-524addc05a90" ,
            "Brake test (park brake)": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBrakes.png?alt=media&token=c35871de-2292-4e3c-86d8-0599d4a32b8e",
            "Brake test (service brake)": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBrakes.png?alt=media&token=c35871de-2292-4e3c-86d8-0599d4a32b8e",
            "Fire extinguisher": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFire%20extinguisher.png?alt=media&token=9c364d87-a260-4f7d-bf5b-38a6cf60857a" , 
        },
        
        classB: {
            "Tyre condition": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FTyres.png?alt=media&token=e86b0300-1409-40a5-b6a5-5f51d95819d3" ,
            "Dashboard indicators": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FGauges.png?alt=media&token=efba8ff9-7321-42d4-87d4-a3c57b234a86",
            "Mirrors": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FMirrors.png?alt=media&token=02ed44ff-a292-41e0-af24-5e188766919a" ,
            "Seats": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSeats.png?alt=media&token=a5db2a95-881b-4839-9713-e5726f1666bc",
            "Oil/fuel leaks": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FOil%20leak.png?alt=media&token=2d443594-f759-413a-956a-2aec978595b4",
            "Hydraulic oil": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFluid%20images.png?alt=media&token=75a339f6-e178-4eff-8d02-5fffaa51b739",
            "Oil level": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFluid%20images.png?alt=media&token=75a339f6-e178-4eff-8d02-5fffaa51b739",
            "Coolant level": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFluid%20images.png?alt=media&token=75a339f6-e178-4eff-8d02-5fffaa51b739",
            "Radiator not clogged": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FRadiator.png?alt=media&token=4065d278-9625-46d8-93aa-d99028b0dd9b",
        },

        classC: {
            "New bump marks": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBump%20marks.png?alt=media&token=d714ffa6-eaf5-47a8-96c3-1ab5e7f8e434"
        }
    },
    
    "Grader": {
        classA: {
            "Operator licence": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FOperator%20license.png?alt=media&token=75b2b887-8384-487b-946a-2d251e586089", 
            "Seat belts": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSeat%20belts.png?alt=media&token=3b624fd8-c12d-4a45-8441-a2250ef74dc0" , 
            "Fire extinguisher": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFire%20extinguisher.png?alt=media&token=9c364d87-a260-4f7d-bf5b-38a6cf60857a" , 
            "Head lights": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FHeadlights.png?alt=media&token=059c6317-bcac-4128-9c90-84e6937087f1" ,
            "Strobe light": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FStrobe%20light.png?alt=media&token=800346f1-2b02-4765-8a4e-a6f98ec0755b" , 
            "Indicator/rear/brake lights": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FRear%20lights.png?alt=media&token=11bbfab6-2e31-4f46-b6be-524addc05a90" ,
            "Hazard lights": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FStrobe%20light.png?alt=media&token=800346f1-2b02-4765-8a4e-a6f98ec0755b",
            "Mirrors": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FMirrors.png?alt=media&token=02ed44ff-a292-41e0-af24-5e188766919a" ,
            "Dashboard warning lights": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FGauges.png?alt=media&token=efba8ff9-7321-42d4-87d4-a3c57b234a86",
            "Hooter": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FHooter.png?alt=media&token=017f6674-c65b-4f36-a6bd-c42239866d88" , 
            "Reverse hooter": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FReverse%20hooter.png?alt=media&token=55c29d3a-0cf0-4548-81b6-f1261fa806c9" ,
            "Gauges": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FGauges.png?alt=media&token=efba8ff9-7321-42d4-87d4-a3c57b234a86",
            "Steer control": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSteering.png?alt=media&token=78bbb22e-8142-44f2-8aa9-7325859e6451" ,
            "Brakes tested": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBrakes.png?alt=media&token=c35871de-2292-4e3c-86d8-0599d4a32b8e" ,
            "Controls": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FLevers%20%26%20joysticks.png?alt=media&token=2d376604-a898-4915-9021-e78825fa7675",
            "Isolation point": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FIsolation%20point.png?alt=media&token=66d56be2-bf90-4e17-817d-fbee2f3ea201" ,
            "Key control": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FKey%20control.png?alt=media&token=56e5c02c-bd71-41e6-a3ce-2c24ab526f75",
            "Radio two way": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FTwo%20way%20radio.png?alt=media&token=7c661eb5-01a8-44bb-a513-1b2a2b98ba2b" , 
            "Air conditioner": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FAir%20conditioner.png?alt=media&token=b2c68ea5-5bb5-4b66-b44a-311617723521" , 
            "Doors & handles": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FDoor%20seals.png?alt=media&token=45091388-acc8-4e78-81d2-e88a8465988b",    
        },
        
        classB: {
            "Tyre condition": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FTyres.png?alt=media&token=e86b0300-1409-40a5-b6a5-5f51d95819d3" ,
            "Hand grips": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FHand%20grips.png?alt=media&token=a18f2002-ff4d-4fec-aaa8-3437827f283c",
            "Screen wiper": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FWindscreen%20wipers.png?alt=media&token=371a7e0a-3302-4f61-a28a-3faac4844633",
            "Fuel level": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFluid%20images.png?alt=media&token=75a339f6-e178-4eff-8d02-5fffaa51b739",
            "All pipes": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FPipe.png?alt=media&token=e677e45a-8c1e-4048-8dee-0bb0a63a4820",
            "Oil/fuel leaks": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FOil%20leak.png?alt=media&token=2d443594-f759-413a-956a-2aec978595b4",
            "Windows": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FWindscreen.png?alt=media&token=4e488be5-932f-4d26-ac84-14e1b1c1dacd",
            "Seats": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSeats.png?alt=media&token=a5db2a95-881b-4839-9713-e5726f1666bc",
            "Steps": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSteps.png?alt=media&token=8091d69c-4539-4d25-be1a-bfac93eeb7af" , 
            "Reflective tape condition": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FReflective%20tape.jpeg?alt=media&token=ffa8f998-227f-4f41-be9e-eeaa67ad7ac6",
            "Blade & ripper": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBlade%20%26%20ripper.png?alt=media&token=4f11ef23-6632-4201-baab-d9c8e0b91e27"  
        },

        classC: {
            "Hour meter": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FHour%20meter.png?alt=media&token=907175cb-4255-40ba-ad98-faa55421412c",
            "New bump marks": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBump%20marks.png?alt=media&token=d714ffa6-eaf5-47a8-96c3-1ab5e7f8e434"
        }
    },
    
    "LDV": {
        classA: {
            "Operator licence": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FOperator%20license.png?alt=media&token=75b2b887-8384-487b-946a-2d251e586089", 
            "Wheel nuts": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FWheel%20nuts.png?alt=media&token=6276be15-c7bc-4795-84a9-7c80dbd6d3b0" , 
            "Fire extinguisher": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFire%20extinguisher.png?alt=media&token=9c364d87-a260-4f7d-bf5b-38a6cf60857a" , 
            "Emergency triangle": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FTriangles.png?alt=media&token=c7797281-e817-4cb2-b745-92c83d084185",
            "Seat belts": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSeat%20belts.png?alt=media&token=3b624fd8-c12d-4a45-8441-a2250ef74dc0" , 
            "Hooter": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FHooter.png?alt=media&token=017f6674-c65b-4f36-a6bd-c42239866d88" , 
            "Reverse hooter": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FReverse%20hooter.png?alt=media&token=55c29d3a-0cf0-4548-81b6-f1261fa806c9" ,
            "Head lights": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FHeadlights.png?alt=media&token=059c6317-bcac-4128-9c90-84e6937087f1" ,
            "Indicator/rear/brake lights": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FRear%20lights.png?alt=media&token=11bbfab6-2e31-4f46-b6be-524addc05a90" ,
            "Brake test (foot brake)": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBrakes.png?alt=media&token=c35871de-2292-4e3c-86d8-0599d4a32b8e",
            "Brake test (emergency brake)": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBrakes.png?alt=media&token=c35871de-2292-4e3c-86d8-0599d4a32b8e",
            "(Red permit area) Brake test ramp when entering pit": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBrakes.png?alt=media&token=c35871de-2292-4e3c-86d8-0599d4a32b8e",
            "(Red permit area) Radio two way": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FTwo%20way%20radio.png?alt=media&token=7c661eb5-01a8-44bb-a513-1b2a2b98ba2b" , 
            "(Red permit area) Flag": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFlag.png?alt=media&token=9bc16ad5-163b-4ff0-919e-a66be0670f17",
            "(Red permit area) Strobe light": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FStrobe%20light.png?alt=media&token=800346f1-2b02-4765-8a4e-a6f98ec0755b" , 
            "(Towing a trailer) Wheel nuts": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FWheel%20nuts.png?alt=media&token=6276be15-c7bc-4795-84a9-7c80dbd6d3b0" , 
            "(Towing a trailer) Stop blocks": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FStop%20blocks.png?alt=media&token=23f443fa-7624-4795-8220-8edb35705b35" , 
            "(Towing a trailer) Safety chain/pin": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSafety%20chain.png?alt=media&token=ccfaf893-265e-44e2-95ac-cbe159447ea6",
            "(Towing a trailer) Tow bar pin": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FTow%20bar.png?alt=media&token=912a14df-dd11-401c-9b47-dae94b35054f",
            "(Towing a trailer) Indicator/rear/brake lights": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FRear%20lights.png?alt=media&token=11bbfab6-2e31-4f46-b6be-524addc05a90" ,
        },
        
        classB: {
            "Oil/fuel leaks": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FOil%20leak.png?alt=media&token=2d443594-f759-413a-956a-2aec978595b4",
            "Tyre condition": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FTyres.png?alt=media&token=e86b0300-1409-40a5-b6a5-5f51d95819d3" ,
            "Dashboard indicators": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FGauges.png?alt=media&token=efba8ff9-7321-42d4-87d4-a3c57b234a86",
            "Windows": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FWindscreen.png?alt=media&token=4e488be5-932f-4d26-ac84-14e1b1c1dacd",
            "Mirrors": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FMirrors.png?alt=media&token=02ed44ff-a292-41e0-af24-5e188766919a" ,
            "Seats": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSeats.png?alt=media&token=a5db2a95-881b-4839-9713-e5726f1666bc",
            "Screen wiper": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FWindscreen%20wipers.png?alt=media&token=371a7e0a-3302-4f61-a28a-3faac4844633",
            "(Red permit area) Reflective tape condition": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FReflective%20tape.jpeg?alt=media&token=ffa8f998-227f-4f41-be9e-eeaa67ad7ac6",
            "(Towing a trailer) Tyre condition": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FTyres.png?alt=media&token=e86b0300-1409-40a5-b6a5-5f51d95819d3" ,
            "(Towing a trailer) Reflective tape condition": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FReflective%20tape.jpeg?alt=media&token=ffa8f998-227f-4f41-be9e-eeaa67ad7ac6",
            "(Weekly inspection) Oil level":  "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFluid%20images.png?alt=media&token=75a339f6-e178-4eff-8d02-5fffaa51b739",
            "(Weekly inspection) Coolant level": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFluid%20images.png?alt=media&token=75a339f6-e178-4eff-8d02-5fffaa51b739",
            "(Weekly inspection) Fluid level": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFluid%20images.png?alt=media&token=75a339f6-e178-4eff-8d02-5fffaa51b739",
            "(Weekly inspection) Screen wiper level": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FWindscreen%20wipers.png?alt=media&token=371a7e0a-3302-4f61-a28a-3faac4844633",
            "(Weekly inspection) Radiator not clogged": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FRadiator.png?alt=media&token=4065d278-9625-46d8-93aa-d99028b0dd9b"
        },   
    },
    
    "SRV Water Bowser": {
        classA: {
            "Operator licence": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FOperator%20license.png?alt=media&token=75b2b887-8384-487b-946a-2d251e586089", 
            "Wheel nuts": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FWheel%20nuts.png?alt=media&token=6276be15-c7bc-4795-84a9-7c80dbd6d3b0" , 
            "Pins in position and locked": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FPins.jpeg?alt=media&token=64d829c3-e5f1-463f-aa32-cd7ee057f85f" ,
            "Fire extinguisher": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFire%20extinguisher.png?alt=media&token=9c364d87-a260-4f7d-bf5b-38a6cf60857a" , 
            "Stop blocks": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FStop%20blocks.png?alt=media&token=23f443fa-7624-4795-8220-8edb35705b35" , 
            "Emergency triangle": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FTriangles.png?alt=media&token=c7797281-e817-4cb2-b745-92c83d084185",
            "Seat belts": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSeat%20belts.png?alt=media&token=3b624fd8-c12d-4a45-8441-a2250ef74dc0" , 
            "Controls": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FLevers%20%26%20joysticks.png?alt=media&token=2d376604-a898-4915-9021-e78825fa7675",
            "Hooter": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FHooter.png?alt=media&token=017f6674-c65b-4f36-a6bd-c42239866d88" , 
            "Reverse hooter": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FReverse%20hooter.png?alt=media&token=55c29d3a-0cf0-4548-81b6-f1261fa806c9" ,
            "Head lights": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FHeadlights.png?alt=media&token=059c6317-bcac-4128-9c90-84e6937087f1" ,
            "Strobe light": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FStrobe%20light.png?alt=media&token=800346f1-2b02-4765-8a4e-a6f98ec0755b" , 
            "Indicator/rear/brake lights": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FRear%20lights.png?alt=media&token=11bbfab6-2e31-4f46-b6be-524addc05a90" ,
            "Brake test (service brake)": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBrakes.png?alt=media&token=c35871de-2292-4e3c-86d8-0599d4a32b8e",
            "Brake test (park brake)": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBrakes.png?alt=media&token=c35871de-2292-4e3c-86d8-0599d4a32b8e",
            "Doors & handles": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FDoor%20seals.png?alt=media&token=45091388-acc8-4e78-81d2-e88a8465988b",
            "Air conditioner": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FAir%20conditioner.png?alt=media&token=b2c68ea5-5bb5-4b66-b44a-311617723521" , 
            "(Red permit area) Brake test ramp when entering pit": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBrakes.png?alt=media&token=c35871de-2292-4e3c-86d8-0599d4a32b8e",
            "(Red permit area) Radio two way": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FTwo%20way%20radio.png?alt=media&token=7c661eb5-01a8-44bb-a513-1b2a2b98ba2b" , 
            "(Red permit area) Flag": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFlag.png?alt=media&token=9bc16ad5-163b-4ff0-919e-a66be0670f17"
        },
        classB: {
            "Oil level": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFluid%20images.png?alt=media&token=75a339f6-e178-4eff-8d02-5fffaa51b739",
            "Oil/fuel leaks": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FOil%20leak.png?alt=media&token=2d443594-f759-413a-956a-2aec978595b4",
            "Mirrors": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FMirrors.png?alt=media&token=02ed44ff-a292-41e0-af24-5e188766919a" ,
            "Windows": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FWindscreen.png?alt=media&token=4e488be5-932f-4d26-ac84-14e1b1c1dacd",
            "Tyre condition": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FTyres.png?alt=media&token=e86b0300-1409-40a5-b6a5-5f51d95819d3" ,
            "Seats": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSeats.png?alt=media&token=a5db2a95-881b-4839-9713-e5726f1666bc",
            "Steps": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSteps.png?alt=media&token=8091d69c-4539-4d25-be1a-bfac93eeb7af" , 
            "Screen wiper": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FWindscreen%20wipers.png?alt=media&token=371a7e0a-3302-4f61-a28a-3faac4844633",
            "Safety guards": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FEquipment%20safety%20guards.png?alt=media&token=ac8e4d00-680c-4444-a1a2-37be71096bba",
            "Dashboard indicators": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FGauges.png?alt=media&token=efba8ff9-7321-42d4-87d4-a3c57b234a86",
            "Hydraulic oil": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFluid%20images.png?alt=media&token=75a339f6-e178-4eff-8d02-5fffaa51b739",
            "Coolant level": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFluid%20images.png?alt=media&token=75a339f6-e178-4eff-8d02-5fffaa51b739",
            "Radiator not clogged": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FRadiator.png?alt=media&token=4065d278-9625-46d8-93aa-d99028b0dd9b", 
            "(Other attachments) Pumps": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FPump.jpeg?alt=media&token=9a1f3128-bc5e-434f-8f5c-3d74ef4055b2",
            "(Other attachments) Safety guards": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FEquipment%20safety%20guards.png?alt=media&token=ac8e4d00-680c-4444-a1a2-37be71096bba",
            "(Other attachments) Hose connectors & fittings": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FHose.jpeg?alt=media&token=28c8f67d-32f6-4396-90d5-c932bb31a20d",
            "(Red permit area) Reflective tape": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FReflective%20tape.jpeg?alt=media&token=ffa8f998-227f-4f41-be9e-eeaa67ad7ac6",
        },

        classC: {
            "New bump marks": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBump%20marks.png?alt=media&token=d714ffa6-eaf5-47a8-96c3-1ab5e7f8e434"
        },
    },
    
    "Track Dozer": {
        classA: {
            "Operator licence": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FOperator%20license.png?alt=media&token=75b2b887-8384-487b-946a-2d251e586089", 
            "Seat belts": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSeat%20belts.png?alt=media&token=3b624fd8-c12d-4a45-8441-a2250ef74dc0" , 
            "Fire extinguisher": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFire%20extinguisher.png?alt=media&token=9c364d87-a260-4f7d-bf5b-38a6cf60857a" , 
            "Head lights": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FHeadlights.png?alt=media&token=059c6317-bcac-4128-9c90-84e6937087f1" ,
            "Strobe lights": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FStrobe%20light.png?alt=media&token=800346f1-2b02-4765-8a4e-a6f98ec0755b" , 
            "Access lights": "imhttps://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FAccess%20lights.png?alt=media&token=2daff19f-e8e2-4b0f-961c-d4873715515eg",
            "Rear lights": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FRear%20lights.png?alt=media&token=11bbfab6-2e31-4f46-b6be-524addc05a90",
            "Mirrors": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FMirrors.png?alt=media&token=02ed44ff-a292-41e0-af24-5e188766919a" ,
            "Dashboard warning lights": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FGauges.png?alt=media&token=efba8ff9-7321-42d4-87d4-a3c57b234a86",
            "Hooter": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FHooter.png?alt=media&token=017f6674-c65b-4f36-a6bd-c42239866d88" , 
            "Reverse hooter": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FReverse%20hooter.png?alt=media&token=55c29d3a-0cf0-4548-81b6-f1261fa806c9" ,
            "Steer control": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSteering.png?alt=media&token=78bbb22e-8142-44f2-8aa9-7325859e6451" ,
            "Brakes tested": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBrakes.png?alt=media&token=c35871de-2292-4e3c-86d8-0599d4a32b8e" ,
            "Controls": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FLevers%20%26%20joysticks.png?alt=media&token=2d376604-a898-4915-9021-e78825fa7675",
            "Gauges": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FGauges.png?alt=media&token=efba8ff9-7321-42d4-87d4-a3c57b234a86",
            "Isolation point": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FIsolation%20point.png?alt=media&token=66d56be2-bf90-4e17-817d-fbee2f3ea201" ,
            "Key control": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FKey%20control.png?alt=media&token=56e5c02c-bd71-41e6-a3ce-2c24ab526f75",
            "Air conditioner": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FAir%20conditioner.png?alt=media&token=b2c68ea5-5bb5-4b66-b44a-311617723521" , 
            "Doors & handles": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FDoor%20seals.png?alt=media&token=45091388-acc8-4e78-81d2-e88a8465988b",
            "Safety guards": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FEquipment%20safety%20guards.png?alt=media&token=ac8e4d00-680c-4444-a1a2-37be71096bba",
            "Radio two way": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FTwo%20way%20radio.png?alt=media&token=7c661eb5-01a8-44bb-a513-1b2a2b98ba2b" , 
        },
        
        classB: {
            "Screen wiper": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FWindscreen%20wipers.png?alt=media&token=371a7e0a-3302-4f61-a28a-3faac4844633",
            "Fuel level": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFluid%20images.png?alt=media&token=75a339f6-e178-4eff-8d02-5fffaa51b739",
            "All pipes": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FPipe.png?alt=media&token=e677e45a-8c1e-4048-8dee-0bb0a63a4820",
            "Seats": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSeats.png?alt=media&token=a5db2a95-881b-4839-9713-e5726f1666bc",
            "Windows": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FWindscreen.png?alt=media&token=4e488be5-932f-4d26-ac84-14e1b1c1dacd",
            "Steps": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSteps.png?alt=media&token=8091d69c-4539-4d25-be1a-bfac93eeb7af" , 
            "Oil/fuel leaks": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FOil%20leak.png?alt=media&token=2d443594-f759-413a-956a-2aec978595b4",
            "Tracks": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FTracks.png?alt=media&token=3029cbc1-d9c9-4d7b-8b6e-bfcce436a73d",
            "Hand grips": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FHand%20grips.png?alt=media&token=a18f2002-ff4d-4fec-aaa8-3437827f283c",
            "Reflective tape condition": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FReflective%20tape.jpeg?alt=media&token=ffa8f998-227f-4f41-be9e-eeaa67ad7ac6",
            "Blade ripper": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBlade%20%26%20ripper.png?alt=media&token=4f11ef23-6632-4201-baab-d9c8e0b91e27"   
        },

        classC: {
            "New bump marks": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBump%20marks.png?alt=media&token=d714ffa6-eaf5-47a8-96c3-1ab5e7f8e434"
        }
    },

    "RDT": {
        classA: {
            "Operator licence": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FOperator%20license.png?alt=media&token=75b2b887-8384-487b-946a-2d251e586089", 
            "Stop blocks": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FStop%20blocks.png?alt=media&token=23f443fa-7624-4795-8220-8edb35705b35" , 
            "Fire extinguisher": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFire%20extinguisher.png?alt=media&token=9c364d87-a260-4f7d-bf5b-38a6cf60857a" , 
            "Seat belts": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSeat%20belts.png?alt=media&token=3b624fd8-c12d-4a45-8441-a2250ef74dc0" , 
            "Head lights": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FHeadlights.png?alt=media&token=059c6317-bcac-4128-9c90-84e6937087f1" ,
            "Indicator/rear/brake lights": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FRear%20lights.png?alt=media&token=11bbfab6-2e31-4f46-b6be-524addc05a90" ,
            "Key control/proxy": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FKey%20control.png?alt=media&token=56e5c02c-bd71-41e6-a3ce-2c24ab526f75",
            "Emergency stop condition": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FEmergency%20stop.png?alt=media&token=c248c482-609f-40bd-8571-7cd3c7372016",
            "Mirrors": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FMirrors.png?alt=media&token=02ed44ff-a292-41e0-af24-5e188766919a" ,
            "Dashboard warning lights": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FGauges.png?alt=media&token=efba8ff9-7321-42d4-87d4-a3c57b234a86",
            "Dump body up buzzer": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FDump%20buzzer.png?alt=media&token=5696c04b-73c6-47be-b253-e59e17c90f34",
            "Hooter": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FHooter.png?alt=media&token=017f6674-c65b-4f36-a6bd-c42239866d88" , 
            "Reverse hooter": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FReverse%20hooter.png?alt=media&token=55c29d3a-0cf0-4548-81b6-f1261fa806c9" ,
            "Directional hooter": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FHooter.png?alt=media&token=017f6674-c65b-4f36-a6bd-c42239866d88",
            "Steer control": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSteering.png?alt=media&token=78bbb22e-8142-44f2-8aa9-7325859e6451" ,
            "Brakes tested": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBrakes.png?alt=media&token=c35871de-2292-4e3c-86d8-0599d4a32b8e" ,
            "Gauges": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FGauges.png?alt=media&token=efba8ff9-7321-42d4-87d4-a3c57b234a86",
            "Tyre condition": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FTyres.png?alt=media&token=e86b0300-1409-40a5-b6a5-5f51d95819d3" ,
            "Controls": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FLevers%20%26%20joysticks.png?alt=media&token=2d376604-a898-4915-9021-e78825fa7675",
            "Radio two way": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FTwo%20way%20radio.png?alt=media&token=7c661eb5-01a8-44bb-a513-1b2a2b98ba2b" , 
            "Air conditioner": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FAir%20conditioner.png?alt=media&token=b2c68ea5-5bb5-4b66-b44a-311617723521" , 
            "Doors & handles": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FDoor%20seals.png?alt=media&token=45091388-acc8-4e78-81d2-e88a8465988b"
        },
        
        classB: {
            "Hand rails & kick plates": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FHand%20rails.png?alt=media&token=678b0c5a-7470-469d-800d-ecafe8d6547b",
            "Access light": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FAccess%20lights.png?alt=media&token=2daff19f-e8e2-4b0f-961c-d4873715515e",
            "Screen wiper": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FWindscreen%20wipers.png?alt=media&token=371a7e0a-3302-4f61-a28a-3faac4844633",
            "Oil/fuel leaks": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FOil%20leak.png?alt=media&token=2d443594-f759-413a-956a-2aec978595b4",
            "All pipes": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FPipe.png?alt=media&token=e677e45a-8c1e-4048-8dee-0bb0a63a4820",
            "Windows": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FWindscreen.png?alt=media&token=4e488be5-932f-4d26-ac84-14e1b1c1dacd",
            "Seats": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSeats.png?alt=media&token=a5db2a95-881b-4839-9713-e5726f1666bc",
            "Reflective tape condition": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FReflective%20tape.jpeg?alt=media&token=ffa8f998-227f-4f41-be9e-eeaa67ad7ac6",
            "Steps": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSteps.png?alt=media&token=8091d69c-4539-4d25-be1a-bfac93eeb7af" , 
        },

        classC: {
            "Hour meter": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FHour%20meter.png?alt=media&token=907175cb-4255-40ba-ad98-faa55421412c",
            "New bump marks": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBump%20marks.png?alt=media&token=d714ffa6-eaf5-47a8-96c3-1ab5e7f8e434"
        }
    },

    "Truck Mounted Crane": {
        classA: {
            "Operator licence": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FOperator%20license.png?alt=media&token=75b2b887-8384-487b-946a-2d251e586089", 
            "Wheel nuts": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FWheel%20nuts.png?alt=media&token=6276be15-c7bc-4795-84a9-7c80dbd6d3b0" , 
            "Pins in position and locked": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FPins.jpeg?alt=media&token=64d829c3-e5f1-463f-aa32-cd7ee057f85f" ,
            "Fire extinguisher": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFire%20extinguisher.png?alt=media&token=9c364d87-a260-4f7d-bf5b-38a6cf60857a" , 
            "Stop blocks": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FStop%20blocks.png?alt=media&token=23f443fa-7624-4795-8220-8edb35705b35" , 
            "Emergency triangle": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FTriangles.png?alt=media&token=c7797281-e817-4cb2-b745-92c83d084185",
            "Seat belts": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSeat%20belts.png?alt=media&token=3b624fd8-c12d-4a45-8441-a2250ef74dc0" , 
            "Controls": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FLevers%20%26%20joysticks.png?alt=media&token=2d376604-a898-4915-9021-e78825fa7675",
            "Hooter": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FHooter.png?alt=media&token=017f6674-c65b-4f36-a6bd-c42239866d88" , 
            "Reverse hooter": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FReverse%20hooter.png?alt=media&token=55c29d3a-0cf0-4548-81b6-f1261fa806c9" ,
            "Head lights": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FHeadlights.png?alt=media&token=059c6317-bcac-4128-9c90-84e6937087f1" ,
            "Strobe light": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FStrobe%20light.png?alt=media&token=800346f1-2b02-4765-8a4e-a6f98ec0755b" , 
            "Indicator/rear/brake lights": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FRear%20lights.png?alt=media&token=11bbfab6-2e31-4f46-b6be-524addc05a90" ,
            "Brake test (service brake)": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBrakes.png?alt=media&token=c35871de-2292-4e3c-86d8-0599d4a32b8e",
            "Brake test (park brake)": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBrakes.png?alt=media&token=c35871de-2292-4e3c-86d8-0599d4a32b8e",
            "Doors & handles": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FDoor%20seals.png?alt=media&token=45091388-acc8-4e78-81d2-e88a8465988b",
            "(Other attachments) Hook safety latch": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FHook%20latch.png?alt=media&token=c562846d-eef0-4389-ac77-f92249bd816c",
            "(Other attachments) Outriggers": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FOutriggers.png?alt=media&token=e32cd5a9-8184-4615-8d85-b00552286912",
            "(Other attachments) Pins in position and locked": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FPins.jpeg?alt=media&token=64d829c3-e5f1-463f-aa32-cd7ee057f85f",
            "(Other attachments) Levers & joysticks": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FLevers%20%26%20joysticks.png?alt=media&token=2d376604-a898-4915-9021-e78825fa7675",
            "(Red permit area) Brake test ramp when entering pit": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBrakes.png?alt=media&token=c35871de-2292-4e3c-86d8-0599d4a32b8e",
            "(Red permit area) Radio two way": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FTwo%20way%20radio.png?alt=media&token=7c661eb5-01a8-44bb-a513-1b2a2b98ba2b" , 
            "(Red permit area) Flag": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFlag.png?alt=media&token=9bc16ad5-163b-4ff0-919e-a66be0670f17"
        },
        classB: {
            "Tyre condition": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FTyres.png?alt=media&token=e86b0300-1409-40a5-b6a5-5f51d95819d3" ,
            "Dashboard indicators": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FGauges.png?alt=media&token=efba8ff9-7321-42d4-87d4-a3c57b234a86",
            "Oil/fuel leaks": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FOil%20leak.png?alt=media&token=2d443594-f759-413a-956a-2aec978595b4",
            "Mirrors": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FMirrors.png?alt=media&token=02ed44ff-a292-41e0-af24-5e188766919a" ,
            "Windows": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FWindscreen.png?alt=media&token=4e488be5-932f-4d26-ac84-14e1b1c1dacd",
            "New bump marks": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBump%20marks.png?alt=media&token=d714ffa6-eaf5-47a8-96c3-1ab5e7f8e434",
            "Seats": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSeats.png?alt=media&token=a5db2a95-881b-4839-9713-e5726f1666bc",
            "Steps": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSteps.png?alt=media&token=8091d69c-4539-4d25-be1a-bfac93eeb7af" , 
            "Screen wiper": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FWindscreen%20wipers.png?alt=media&token=371a7e0a-3302-4f61-a28a-3faac4844633",
            "Air conditioner": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FAir%20conditioner.png?alt=media&token=b2c68ea5-5bb5-4b66-b44a-311617723521" , 
            "Load chart": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FLoad%20chart.png?alt=media&token=fb03798f-f987-4304-b04e-1765150054b8",
            "Oil level": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFluid%20images.png?alt=media&token=75a339f6-e178-4eff-8d02-5fffaa51b739",
            "Coolant level": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFluid%20images.png?alt=media&token=75a339f6-e178-4eff-8d02-5fffaa51b739",
            "Radiator not clogged": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FRadiator.png?alt=media&token=4065d278-9625-46d8-93aa-d99028b0dd9b",
            "Hydraulic oil": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFluid%20images.png?alt=media&token=75a339f6-e178-4eff-8d02-5fffaa51b739",
            "(Red permit area) Reflective tape": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FReflective%20tape.jpeg?alt=media&token=ffa8f998-227f-4f41-be9e-eeaa67ad7ac6",
        },

        classC: {
            "Steps": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSteps.png?alt=media&token=8091d69c-4539-4d25-be1a-bfac93eeb7af" , 
            "Recent body damage": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBump%20marks.png?alt=media&token=d714ffa6-eaf5-47a8-96c3-1ab5e7f8e434"
        },
    },

    "HRO": {
        classA:{
            "Safety glasses": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FPPE.jpeg?alt=media&token=46c07362-6cf9-4187-8f09-04f66a333797" ,
            "Gloves": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FPPE.jpeg?alt=media&token=46c07362-6cf9-4187-8f09-04f66a333797" ,
            "Hard hat": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FPPE.jpeg?alt=media&token=46c07362-6cf9-4187-8f09-04f66a333797" ,
            "Overall": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FPPE.jpeg?alt=media&token=46c07362-6cf9-4187-8f09-04f66a333797" ,
            "Safety shoes": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FPPE.jpeg?alt=media&token=46c07362-6cf9-4187-8f09-04f66a333797" ,
            "Reflective vest": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FPPE.jpeg?alt=media&token=46c07362-6cf9-4187-8f09-04f66a333797" ,
            "Dust mask": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FPPE.jpeg?alt=media&token=46c07362-6cf9-4187-8f09-04f66a333797" ,
            "Ear plugs": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FPPE.jpeg?alt=media&token=46c07362-6cf9-4187-8f09-04f66a333797" ,
            "Engine oil": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFluid%20images.png?alt=media&token=75a339f6-e178-4eff-8d02-5fffaa51b739" ,
            "Compressor oil": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFluid%20images.png?alt=media&token=75a339f6-e178-4eff-8d02-5fffaa51b739" ,
            "Hydraulic oil": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFluid%20images.png?alt=media&token=75a339f6-e178-4eff-8d02-5fffaa51b739" ,
            "Lubricator oil": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFluid%20images.png?alt=media&token=75a339f6-e178-4eff-8d02-5fffaa51b739" ,
            "Radiator water": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FRadiator.png?alt=media&token=4065d278-9625-46d8-93aa-d99028b0dd9b" ,
            "Shank Adapter thread": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FShank%20adapter.jpeg?alt=media&token=a286bbaf-1f57-4f9a-afa7-fce8f57afd4a" , 
            "Fire extinguisher": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFire%20extinguisher.png?alt=media&token=9c364d87-a260-4f7d-bf5b-38a6cf60857a" , 
            "Rod changer secure":"https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FRod%20changer.png?alt=media&token=f9d193bc-10d4-41e5-9f54-881e1a58b0f7"
        },  
        classB: {
            "Hooter": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FHooter.png?alt=media&token=017f6674-c65b-4f36-a6bd-c42239866d88" ,
            "Reverse alarm": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FTravel%20alarm.png?alt=media&token=8a47f605-6d1c-4e10-b38c-d93c5b89aa60" ,
            "Seats": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSeats.png?alt=media&token=a5db2a95-881b-4839-9713-e5726f1666bc" ,
            "Lights": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FRear%20lights.png?alt=media&token=11bbfab6-2e31-4f46-b6be-524addc05a90" ,
            "Air conditioner": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FAir%20conditioner.png?alt=media&token=b2c68ea5-5bb5-4b66-b44a-311617723521" ,
            "Cooler and radiator": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FRadiator.png?alt=media&token=4065d278-9625-46d8-93aa-d99028b0dd9b" ,
            "Gauges": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FGauges.png?alt=media&token=efba8ff9-7321-42d4-87d4-a3c57b234a86",
            "Battery" : "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBattery.png?alt=media&token=b3465ab8-67b4-4700-a2f4-2ad95ffd3b77" ,
            "Central levers": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FLevers%20%26%20joysticks.png?alt=media&token=2d376604-a898-4915-9021-e78825fa7675" ,
            "Undercarriage track chain": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FTracks.png?alt=media&token=3029cbc1-d9c9-4d7b-8b6e-bfcce436a73d" ,
            "Undercarriage oil leaks": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFluid%20images.png?alt=media&token=75a339f6-e178-4eff-8d02-5fffaa51b739" ,
            "Safety guards": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FEquipment%20safety%20guards.png?alt=media&token=ac8e4d00-680c-4444-a1a2-37be71096bba", 
            "Air hoses": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FHose.jpeg?alt=media&token=28c8f67d-32f6-4396-90d5-c932bb31a20d" ,
            "Hydraulic hoses": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FHose.jpeg?alt=media&token=28c8f67d-32f6-4396-90d5-c932bb31a20d" ,
            "Dust collector": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FDust%20collector.jpeg?alt=media&token=43214e6d-8d6e-438e-a5b2-53a8e4eec675" ,
            "V-belts": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FV%20belts.png?alt=media&token=9d6542a4-28a2-40db-b50b-8b69fd377ffd" ,
            "Mast": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FMast.png?alt=media&token=355eb92d-3470-4c5f-98f2-15a33a7e5da5" ,
            "Rod changer": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FRod%20changer.png?alt=media&token=f9d193bc-10d4-41e5-9f54-881e1a58b0f7"
        }, 
        classC: {
            "Mast - drifter slides": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FDrifter%20slides.png?alt=media&token=625b742a-6a15-4880-8134-bfba6ba6fea1" ,
            "Mast - jawliners": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FMast.png?alt=media&token=355eb92d-3470-4c5f-98f2-15a33a7e5da5" ,
            "Mast - dust hood": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FDust%20collector.jpeg?alt=media&token=43214e6d-8d6e-438e-a5b2-53a8e4eec675" ,
            "Mast - hydraulic cyclinders": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FMast.png?alt=media&token=355eb92d-3470-4c5f-98f2-15a33a7e5da5" , 
            "Operator cab windscreens": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FWindscreen%20wipers.png?alt=media&token=371a7e0a-3302-4f61-a28a-3faac4844633"
        },
    },
    
    "Bus": {
        classA:{
            "Operator license": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FOperator%20license.png?alt=media&token=75b2b887-8384-487b-946a-2d251e586089" , 
            "Number plate and lights": "imhttps://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FNumber%20plate.jpeg?alt=media&token=e107beb4-babd-41ab-8cc5-8cc13970388eg" ,
            "Windscreen and wipers": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FWindscreen%20wipers.png?alt=media&token=371a7e0a-3302-4f61-a28a-3faac4844633" ,
            "Interior lights": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FStrobe%20light.png?alt=media&token=800346f1-2b02-4765-8a4e-a6f98ec0755b" ,
            "Fuel gauge": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FGauges.png?alt=media&token=efba8ff9-7321-42d4-87d4-a3c57b234a86" ,
            "Seat condition": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSeats.png?alt=media&token=a5db2a95-881b-4839-9713-e5726f1666bc" ,
            "Seat belts": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSeat%20belts.png?alt=media&token=3b624fd8-c12d-4a45-8441-a2250ef74dc0" ,
            "Steering": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSteering.png?alt=media&token=78bbb22e-8142-44f2-8aa9-7325859e6451" ,
            "All light fittings and reflectors": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FRear%20lights.png?alt=media&token=11bbfab6-2e31-4f46-b6be-524addc05a90" ,
            "Headlights": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FHeadlights.png?alt=media&token=059c6317-bcac-4128-9c90-84e6937087f1" , 
            "Indicator/rear/brake lights": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FRear%20lights.png?alt=media&token=11bbfab6-2e31-4f46-b6be-524addc05a90" ,
            "Hazard lights": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FStrobe%20light.png?alt=media&token=800346f1-2b02-4765-8a4e-a6f98ec0755b" , 
            "Hooter": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FHooter.png?alt=media&token=017f6674-c65b-4f36-a6bd-c42239866d88" ,
            "Reverse alarm": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FTravel%20alarm.png?alt=media&token=8a47f605-6d1c-4e10-b38c-d93c5b89aa60" , 
            "Danger triangles": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FTriangles.png?alt=media&token=c7797281-e817-4cb2-b745-92c83d084185" ,
            "Fire extinguisher": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFire%20extinguisher.png?alt=media&token=9c364d87-a260-4f7d-bf5b-38a6cf60857a" , 
            "Service brake test": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBrakes.png?alt=media&token=c35871de-2292-4e3c-86d8-0599d4a32b8e" , 
            "Park brake test": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBrakes.png?alt=media&token=c35871de-2292-4e3c-86d8-0599d4a32b8e" , 
            "Emergency brakes test": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBrakes.png?alt=media&token=c35871de-2292-4e3c-86d8-0599d4a32b8e" ,
            "Tyre condition": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FTyres.png?alt=media&token=e86b0300-1409-40a5-b6a5-5f51d95819d3" ,
            "Rim and components": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FWheel%20nuts.png?alt=media&token=6276be15-c7bc-4795-84a9-7c80dbd6d3b0" , 
            "Wheel nuts": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FWheel%20nuts.png?alt=media&token=6276be15-c7bc-4795-84a9-7c80dbd6d3b0" , 
            "Stop blocks": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FStop%20blocks.png?alt=media&token=23f443fa-7624-4795-8220-8edb35705b35" , 
            "Engine oil level": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFluid%20images.png?alt=media&token=75a339f6-e178-4eff-8d02-5fffaa51b739" ,
            "Fluid level": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFluid%20images.png?alt=media&token=75a339f6-e178-4eff-8d02-5fffaa51b739",
            "Hydraulic oil level": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFluid%20images.png?alt=media&token=75a339f6-e178-4eff-8d02-5fffaa51b739" ,
            "Steering oil level": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFluid%20images.png?alt=media&token=75a339f6-e178-4eff-8d02-5fffaa51b739" ,
            "Transmission oil level": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFluid%20images.png?alt=media&token=75a339f6-e178-4eff-8d02-5fffaa51b739" ,
            "Radiator water level and leaks": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFluid%20images.png?alt=media&token=75a339f6-e178-4eff-8d02-5fffaa51b739" ,
            "Oil/fuel leaks": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FOil%20leak.png?alt=media&token=2d443594-f759-413a-956a-2aec978595b4",
            "(At night) Visible reflector tape": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FReflective%20tape.jpeg?alt=media&token=ffa8f998-227f-4f41-be9e-eeaa67ad7ac6",
        }, 
    classB: {
        "Rear window and side windows": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FRear%3Aside%20windows.png?alt=media&token=54724ea8-a728-4831-9429-ee0078a30a40" ,
        "Gauges": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FGauges.png?alt=media&token=efba8ff9-7321-42d4-87d4-a3c57b234a86",
        "Mirrors": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FMirrors.png?alt=media&token=02ed44ff-a292-41e0-af24-5e188766919a" ,
        "Cab clean": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FCab%20clean.png?alt=media&token=f3dfba64-d8ff-4769-81c1-0b8ac0f64487" , 
        "Wheel spanner, jack and lever": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FLevers%20%26%20joysticks.png?alt=media&token=2d376604-a898-4915-9021-e78825fa7675" ,
        "Spare wheel condition": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FTyres.png?alt=media&token=e86b0300-1409-40a5-b6a5-5f51d95819d3" ,
        "Body parts and hinges": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBump%20marks.png?alt=media&token=d714ffa6-eaf5-47a8-96c3-1ab5e7f8e434" ,
        "V-belts": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FV%20belts.png?alt=media&token=9d6542a4-28a2-40db-b50b-8b69fd377ffd" ,
        "Air conditioner": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FAir%20conditioner.png?alt=media&token=b2c68ea5-5bb5-4b66-b44a-311617723521" , 
        "Drain air reservoir": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FAir%20conditioner.png?alt=media&token=b2c68ea5-5bb5-4b66-b44a-311617723521" ,
        "Battery clamps and terminals condition": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBattery.png?alt=media&token=b3465ab8-67b4-4700-a2f4-2ad95ffd3b77" ,
        "Steps and handrails": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSteps.png?alt=media&token=8091d69c-4539-4d25-be1a-bfac93eeb7af"
    } ,
},

    "TLB": {
        classA: {
            "Operator licence": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FOperator%20license.png?alt=media&token=75b2b887-8384-487b-946a-2d251e586089", 
            "Stop blocks": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FStop%20blocks.png?alt=media&token=23f443fa-7624-4795-8220-8edb35705b35" , 
            "Indicator/rear/brake lights": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FRear%20lights.png?alt=media&token=11bbfab6-2e31-4f46-b6be-524addc05a90" ,
            "Strobe light": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FStrobe%20light.png?alt=media&token=800346f1-2b02-4765-8a4e-a6f98ec0755b" , 
            "Mirrors": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FMirrors.png?alt=media&token=02ed44ff-a292-41e0-af24-5e188766919a" ,
            "Dashboard warning lights": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FGauges.png?alt=media&token=efba8ff9-7321-42d4-87d4-a3c57b234a86",
            "Hooter": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FHooter.png?alt=media&token=017f6674-c65b-4f36-a6bd-c42239866d88" , 
            "Reverse hooter": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FReverse%20hooter.png?alt=media&token=55c29d3a-0cf0-4548-81b6-f1261fa806c9" ,
            "Steer control": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSteering.png?alt=media&token=78bbb22e-8142-44f2-8aa9-7325859e6451" ,
            "Brakes tested": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBrakes.png?alt=media&token=c35871de-2292-4e3c-86d8-0599d4a32b8e" ,
            "Key control/proxy": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FKey%20control.png?alt=media&token=56e5c02c-bd71-41e6-a3ce-2c24ab526f75",
            "Fire extinguisher": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFire%20extinguisher.png?alt=media&token=9c364d87-a260-4f7d-bf5b-38a6cf60857a" , 
            "Gauges": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FGauges.png?alt=media&token=efba8ff9-7321-42d4-87d4-a3c57b234a86",
            "Wheel nuts": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FWheel%20nuts.png?alt=media&token=6276be15-c7bc-4795-84a9-7c80dbd6d3b0" , 
            "Tyre condition": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FTyres.png?alt=media&token=e86b0300-1409-40a5-b6a5-5f51d95819d3" ,
            "Controls": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FLevers%20%26%20joysticks.png?alt=media&token=2d376604-a898-4915-9021-e78825fa7675",
            "Park brake": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBrakes.png?alt=media&token=c35871de-2292-4e3c-86d8-0599d4a32b8e",
            "Isolation point": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FIsolation%20point.png?alt=media&token=66d56be2-bf90-4e17-817d-fbee2f3ea201" ,
            "Air conditioner": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FAir%20conditioner.png?alt=media&token=b2c68ea5-5bb5-4b66-b44a-311617723521" , 
            "Radio two way": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FTwo%20way%20radio.png?alt=media&token=7c661eb5-01a8-44bb-a513-1b2a2b98ba2b" , 
        },
        classB: {
            "Digging boom assembly": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBoom%20condition.png?alt=media&token=858937ed-1ab5-4e03-aa8d-852f9381259a",
            "Bucket and cutting edges": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBucket.png?alt=media&token=081ce8a2-33aa-4b44-b0da-58829f20a129",
            "Oil/fuel leaks": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FOil%20leak.png?alt=media&token=2d443594-f759-413a-956a-2aec978595b4",
            "Windows": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FWindscreen.png?alt=media&token=4e488be5-932f-4d26-ac84-14e1b1c1dacd",
            "Outriggers": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FOutriggers.png?alt=media&token=e32cd5a9-8184-4615-8d85-b00552286912",
            "New bump marks": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBump%20marks.png?alt=media&token=d714ffa6-eaf5-47a8-96c3-1ab5e7f8e434",
            "Tow bar & pin": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FTow%20bar.png?alt=media&token=912a14df-dd11-401c-9b47-dae94b35054f",
            "Reflective tape condition": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FReflective%20tape.jpeg?alt=media&token=ffa8f998-227f-4f41-be9e-eeaa67ad7ac6",
            "All pipes": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FPipe.png?alt=media&token=e677e45a-8c1e-4048-8dee-0bb0a63a4820",
            "Screen wiper": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FWindscreen%20wipers.png?alt=media&token=371a7e0a-3302-4f61-a28a-3faac4844633",
            "Fuel level": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FFluid%20images.png?alt=media&token=75a339f6-e178-4eff-8d02-5fffaa51b739",
            "Doors & handles": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FDoor%20seals.png?alt=media&token=45091388-acc8-4e78-81d2-e88a8465988b",
            "Seats": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSeats.png?alt=media&token=a5db2a95-881b-4839-9713-e5726f1666bc",
            "Steps": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FSteps.png?alt=media&token=8091d69c-4539-4d25-be1a-bfac93eeb7af" , 
            "Rear lights": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FRear%20lights.png?alt=media&token=11bbfab6-2e31-4f46-b6be-524addc05a90",
        },
        classC: {
            "Recent body damage": "https://firebasestorage.googleapis.com/v0/b/shiftreporter-3420e.appspot.com/o/images%2FBump%20marks.png?alt=media&token=d714ffa6-eaf5-47a8-96c3-1ab5e7f8e434"
        }
    }
}

export default checklistData;