import React, { useState, useEffect } from 'react'
import { freeSet } from "@coreui/icons";
import { CButton } from "@coreui/react";
import CIcon from "@coreui/icons-react";

const EngineHours = (props) => {

  const { action, setResponseVal } = props;

  const odoParams = {
    'opening': {
      title: 'Opening Hours',
      id: 'opening_hours'
    },
    'closing': {
      title: 'Closing Hours',
      id: 'closing_hours'
    }
  };

  const [sliderVal, setSliderVal] = useState({
    odo_1: '',
    odo_2: '',
    odo_3: '',
    odo_4: '',
    odo_5: '',
    odo_6: '',
    odo_7: 0,

  });

  const [sliderFormattedVal, setFormattedSliderVal] = useState();
  const [odoFormattedVal, setOdoFormattedVal] = useState();
  // eslint-disable-next-line no-unused-vars
  const [showOdoSummary, setOdoSummary] = useState(true);

  const rangeSliderChange = (event, val) => {

    let newItem = { ...sliderVal };
    newItem[event] = val;

    setSliderVal(newItem);
  }

  const inc = (element) => {

    let el = document.querySelector(`[name="${element}"]`);

    if (isNaN(parseInt(el.value)) || parseInt(el.value) > 8) {
      el.value = 0;
    } else {
      el.value = parseInt(el.value) + 1;
    }

    rangeSliderChange(element, el.value);

  }

  const dec = (element) => {

    let el = document.querySelector(`[name="${element}"]`);

    if (parseInt(el.value) > 0) {
      el.value = parseInt(el.value) - 1;
      rangeSliderChange(element, el.value);

    }

  }


  const resetValues = () => {

    let newCleanHolder = {};
    let resetKeys = Object.keys(sliderVal);

    resetKeys.forEach((item) => {
      newCleanHolder[item] = '';
      let elItem = document.querySelector(`[name="${item}"]`);
      elItem.value = '-';
    })

    setSliderVal(newCleanHolder);
    setFormattedSliderVal('');
    setOdoFormattedVal('');

  }

  useEffect(() => {

    if (setResponseVal) {
      setResponseVal(odoFormattedVal);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [odoFormattedVal])

  useEffect(() => {

    let text = [];
    let odometerDigits = [];
    let odometer = sliderVal;
    let odomoterKeys = Object.keys(odometer);

    odomoterKeys.forEach((item, idx) => {

      if (odometer[item]) {

        if (item === 'odo_7') {

          text.push(`<div class='p-1 my-0'>
                        <strong>, ${odometer[item]}</strong>
                    </div>`);

          odometerDigits.push('.');
          odometerDigits.push(odometer[item]);

        }
        else {

          text.push(`<div class='p-1 my-0'>
                        <strong>${odometer[item]}</strong>
                    </div>`);

          odometerDigits.push(odometer[item]);

        }

      }

    })


    if (odometerDigits && odometerDigits.length) {
      setOdoFormattedVal(odometerDigits.join(''));
    }

    if (text && text.length) {
      setFormattedSliderVal(text.join(''));
    }

  }, [sliderVal])

  return (
    <div className="mt-5 mb-3 d-flex flex-column justify-content-between align-items-center">

      <input type="hidden" className="form-range col-4 my-3" name={odoParams[action].id} id={odoParams[action].id} defaultValue={odoFormattedVal} />

      <div className="d-flex justify-content-sm-between justify-content-center btn-square btn bg-gradient-dark px-0 mb-1 col-12 col-md-12 col-lg-10">
        <div className="d-flex justify-content-sm-center justify-content-between flex-center align-items-center">

          <div className="d-flex flex-column justify-content-between mx-0 p-1 p-md-0 mx-md-2 col-sm ">
            <button className="btn btn-square m-0 btn-dark  p-1 p-md-2" type="button" aria-expanded="false" aria-haspopup="true" aria-label="Dropdown toggle" onClick={() => { inc('odo_1') }}>
              <CIcon content={freeSet.cilCaretTop} size={'xl'} className=""></CIcon>
            </button>
            <input type="text" name="odo_1" className="odometer-digit text-white mx-0 my-2 form-control p-0 text-center" readOnly={true} defaultValue="-" onChange={rangeSliderChange} />
            <button className="btn btn-square m-0 btn-dark  p-1 p-md-2 " type="button" aria-expanded="false" aria-haspopup="true" aria-label="Dropdown toggle" onClick={() => { dec('odo_1') }}>
              <CIcon content={freeSet.cilCaretBottom} size={'xl'}></CIcon>
            </button>
          </div>
          <div className="d-flex flex-column justify-content-between mx-0 p-1 p-md-0 mx-md-2 col-sm ">
            <button className="btn btn-square m-0 btn-dark  p-1 p-md-2" type="button" aria-expanded="false" aria-haspopup="true" aria-label="Dropdown toggle" onClick={() => { inc('odo_2') }}>
              <CIcon content={freeSet.cilCaretTop} size={'xl'} className=""></CIcon>
            </button>
            <input type="text" name="odo_2" className="odometer-digit text-white mx-0 my-2 form-control p-0 text-center" readOnly={true} defaultValue="-" onChange={rangeSliderChange} />
            <button className="btn btn-square m-0 btn-dark   p-1 p-md-2" type="button" aria-expanded="false" aria-haspopup="true" aria-label="Dropdown toggle" onClick={() => { dec('odo_2') }}>
              <CIcon content={freeSet.cilCaretBottom} size={'xl'}></CIcon>
            </button>
          </div>
          <div className="d-flex flex-column justify-content-between mx-0 p-1 p-md-0 mx-md-2 col-sm ">
            <button className="btn btn-square m-0 btn-dark  p-1 p-md-2" type="button" aria-expanded="false" aria-haspopup="true" aria-label="Dropdown toggle" onClick={() => { inc('odo_3') }}>
              <CIcon content={freeSet.cilCaretTop} size={'xl'} className=""></CIcon>
            </button>
            <input type="text" name="odo_3" className="odometer-digit text-white mx-0 my-2 form-control p-0 text-center" readOnly={true} defaultValue="-" onChange={rangeSliderChange} />
            <button className="btn btn-square m-0 btn-dark  p-1 p-md-2 " type="button" aria-expanded="false" aria-haspopup="true" aria-label="Dropdown toggle" onClick={() => { dec('odo_3') }}>
              <CIcon content={freeSet.cilCaretBottom} size={'xl'}></CIcon>
            </button>
          </div>
          <div className="d-flex flex-column justify-content-between mx-0 p-1 p-md-0 mx-md-2 col-sm ">
            <button className="btn btn-square m-0 btn-dark  p-1 p-md-2" type="button" aria-expanded="false" aria-haspopup="true" aria-label="Dropdown toggle" onClick={() => { inc('odo_4') }}>
              <CIcon content={freeSet.cilCaretTop} size={'xl'} className=""></CIcon>
            </button>
            <input type="text" name="odo_4" className="odometer-digit text-white mx-0 my-2 form-control p-0 text-center" readOnly={true} defaultValue="-" onChange={rangeSliderChange} />
            <button className="btn btn-square m-0 btn-dark  p-1 p-md-2 " type="button" aria-expanded="false" aria-haspopup="true" aria-label="Dropdown toggle" onClick={() => { dec('odo_4') }}>
              <CIcon content={freeSet.cilCaretBottom} size={'xl'}></CIcon>
            </button>
          </div>
          <div className="d-flex flex-column justify-content-between mx-0 p-1 p-md-0 mx-md-2 col-sm ">
            <button className="btn btn-square m-0 btn-dark  p-1 p-md-2" type="button" aria-expanded="false" aria-haspopup="true" aria-label="Dropdown toggle" onClick={() => { inc('odo_5') }}>
              <CIcon content={freeSet.cilCaretTop} size={'xl'} className=""></CIcon>
            </button>
            <input type="text" name="odo_5" className="odometer-digit text-white mx-0 my-2 form-control p-0 text-center" readOnly={true} defaultValue="-" onChange={rangeSliderChange} />
            <button className="btn btn-square m-0 btn-dark  p-1 p-md-2 " type="button" aria-expanded="false" aria-haspopup="true" aria-label="Dropdown toggle" onClick={() => { dec('odo_5') }}>
              <CIcon content={freeSet.cilCaretBottom} size={'xl'}></CIcon>
            </button>
          </div>
          <div className="d-flex flex-column justify-content-between mx-0 p-1 p-md-0 mx-md-2 col-sm ">
            <button className="btn btn-square m-0 btn-dark  p-1 p-md-2" type="button" aria-expanded="false" aria-haspopup="true" aria-label="Dropdown toggle" onClick={() => { inc('odo_6') }}>
              <CIcon content={freeSet.cilCaretTop} size={'xl'} className=""></CIcon>
            </button>
            <input type="text" name="odo_6" className="odometer-digit text-white mx-0 my-2 form-control p-0 text-center" readOnly={true} defaultValue="-" onChange={rangeSliderChange} />
            <button className="btn btn-square m-0 btn-dark  p-1 p-md-2 " type="button" aria-expanded="false" aria-haspopup="true" aria-label="Dropdown toggle" onClick={() => { dec('odo_6') }}>
              <CIcon content={freeSet.cilCaretBottom} size={'xl'}></CIcon>
            </button>
          </div>

          <div className="d-flex flex-column justify-content-between mx-1">
            <div className="h1 text-white">,</div>
          </div>
          <div className="d-flex flex-column justify-content-between mx-0 p-1 p-md-0 mx-md-2 col-sm ">
            <button className="btn btn-square m-0 btn-light  p-1 p-md-2" type="button" aria-expanded="false" aria-haspopup="true" aria-label="Dropdown toggle" onClick={() => { inc('odo_7') }}>
              <CIcon content={freeSet.cilCaretTop} size={'xl'} className=""></CIcon>
            </button>
            <input type="text" name="odo_7" className="odometer-digit text-white mx-0 my-2 form-control p-0 text-center" readOnly={true} defaultValue="-" onChange={rangeSliderChange} />
            <button className="btn btn-square m-0 btn-light  p-1 p-md-2" type="button" aria-expanded="false" aria-haspopup="true" aria-label="Dropdown toggle" onClick={() => { dec('odo_7') }}>
              <CIcon content={freeSet.cilCaretBottom} size={'xl'}></CIcon>
            </button>
          </div>


        </div>
      </div>

      {showOdoSummary &&

        <div className='col-12 col-md-12 col-lg-10 mb-4 d-flex justify-content-between align-items-center p-0'>
          <div className="d-flex p-0" dangerouslySetInnerHTML={{ __html: sliderFormattedVal }} />
          <div className="p-0 d-flex justify-content-center align-items-center">
            <CButton size="sm" className="btn-square btn-danger mx-0 text-uppercase" onClick={() => { resetValues() }}>
              <CIcon size="sm" content={freeSet.cilSync} className="mr-1" />Reset
            </CButton>
          </div>
        </div>

      }

    </div>
  )

}

export default EngineHours
