import React, {useState} from 'react'
import {updateChecklist} from "../../../../services/ChecklistsService";
import {freeSet} from "@coreui/icons";
import CIcon from "@coreui/icons-react";

const ChecklistViewItem = ( props ) => {

    const { item, itemDetails, vehicleType, issueClass, checklistCollection } = props

    const [isDeleteOpen, setDeleteOpen] = useState(false);
    const toggleStyles = isDeleteOpen ? "d-block" : "d-none";
    const toggleParentClass = isDeleteOpen ? "d-none" : "d-block";

    const [isEditOpen, setEditOpen] = useState(false);
    const toggleEditStyles = isEditOpen ? "d-none" : "d-none";

    const editChecklistItem = ( item )  => {

        console.log('edit item', item, vehicleType, issueClass, checklistCollection )

    }

    const getChecklistMediaItem = ( item )  => {

        const filteredList = checklistCollection.filter( (vehType )=>{

            return vehType.id === vehicleType

        } );

        let filteredListChecklist = filteredList[0][issueClass];

        return filteredListChecklist[item]

    }

    const deleteChecklistItem = ( item )  => {

        const filteredList = checklistCollection.filter( (vehType )=>{

            return vehType.id === vehicleType

        } );

        let filteredListChecklist = filteredList[0][issueClass];

        delete filteredListChecklist[item];

        // console.log('delete result >> ',filteredListChecklist )

       updateChecklist( checklistCollection );

    }
    
    return (
        <>
            <tr>
                {/*<td>*/}
                {/*    {vehicleType}*/}
                {/*</td>*/}
                <td>
                    {issueClass}
                </td>
                <td>
                    {item}
                </td>
                <td className="text-capitalize">
                    {itemDetails?.assignedRole}
                </td>
                <td width={100}>
                    <div className="position-relative">

                        <div className={`${toggleParentClass} text-center`}>
                            {/*<span className="d-inline p-2 faux-link" onClick={()=>{ setEditOpen(!isEditOpen) }}><CIcon  content={freeSet.cilPencil} /></span>*/}
                            <span className="d-inline p-2 faux-link" onClick={ ()=>{ setDeleteOpen(!isDeleteOpen); } }><CIcon  content={freeSet.cilTrash} /></span>
                        </div>

                        <div className={`${toggleStyles}`}>
                            <button
                                className="btn btn-secondary mx-2 my-1"
                                onClick={()=>{
                                    setDeleteOpen(!isDeleteOpen);
                                }}
                            >Cancel
                            </button>
                            <button
                                className="btn btn-primary mx-2 my-1"
                                onClick={() => {
                                    deleteChecklistItem( item );
                                    setDeleteOpen(!isDeleteOpen);
                                }}
                            >Confirm
                            </button>
                        </div>
                    </div>
                </td>
            </tr>

            <tr className={toggleEditStyles}>

                <td colSpan={3}>

                    <div className="d-flex py-2 justify-content-center align-items-center">
                        <input className="form-control col mx-1" name="checklist-class" defaultValue={issueClass}  readOnly={true} />

                        <input name={'checklist-title'} className="form-control col mx-1" type="text" defaultValue={item} />

                        <input name={'checklist-icon'} className="form-control col mx-1" type="text" defaultValue={getChecklistMediaItem(item)}  />

                        <input name={'checklist-control'} className="form-control col mx-1" type="hidden" defaultValue={item}  />
                    </div>

                    <div className="position-relative">

                        <div className={``}>
                            <button
                                className="btn btn-secondary mx-2 my-1"
                                onClick={()=>{
                                    setEditOpen(!isEditOpen);
                                }}
                            >Cancel
                            </button>
                            <button
                                className="btn btn-primary mx-2 my-1"
                                onClick={() => {
                                    editChecklistItem( item );
                                    setEditOpen(!isEditOpen);
                                }}
                            >Confirm
                            </button>
                        </div>

                    </div>

                </td>

            </tr>

        </>
    )

}

export default ChecklistViewItem
