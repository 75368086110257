import React, {useContext, useEffect, useState} from "react";
import Styles from "./VehicleHistory.module.scss";
import {Card, Tag, Table, Select, Button, Space, Badge} from 'antd'
import {getColumnSearchProps} from "../../../components/TableColumnSearch/TableColumnSearch";
import {navigate} from "@reach/router";
import {UserContext} from "../../../context/userContext";
import {getVehicles, subscribeToVehicles} from '../../../services/VehiclesService'
import {getOperators} from '../../../services/UsersService'
import UserTypes  from '../../../enums/UserTypes';

const VehicleHistory = (props) => {

    const { user } = useContext(UserContext)
    const badgeColor = '#32323299'
    const [vehicleList, setVehicleList] = useState([])
    const [vehicleListLoading, setVehicleListLoading] = useState(false)

    
    useEffect(() => {
        let team = user.currentTeam;
        if(user.userType === UserTypes.SUPERVISOR || user.userType === UserTypes.MANAGEMENT || user.userType === UserTypes.ADMIN){
            team = 'All'
        }
        else{
            team = user.currentTeam || 'All'
        }
        setVehicleListLoading(true)
        subscribeToVehicles((result) => {
            if(result.length > 0) {
                setVehicleList(result.filter(
                    team === 'All' ? () => true : (vehicle) => vehicle.currentTeam === team
                ))
            }
            setVehicleListLoading(false)
        })
    }, [])

    const [selectValue, setSelectValue] = useState('All')

    const vehicleFieldSettings = [
        {
            key: 'vehicleName',
            dataIndex: 'vehicleName',
            title: 'Vehicle  Name',
            sorter: (a, b) => a.vehicleName.localeCompare(b.vehicleName),
        },
        {
            key: 'vehicleType',
            dataIndex: 'vehicleType',
            title: 'Vehicle Type',
            ...getColumnSearchProps("vehicleType", "Vehicle Type"),
        },
        {
            key: 'vin',
            dataIndex: 'vin',
            title: 'VIN Number',
        },
        {
            key: 'currentStatus',
            dataIndex: 'currentStatus',
            title: 'Current Status',
            ...getColumnSearchProps("status", "Current Status"),
            render: (text, record) => {
                if(text === 'On Shift'){
                    return <Tag color={"green"}>{text}</Tag>
                }
                else if (text === 'Parked'){
                    return <Tag color={"blue"}>{text}</Tag>
                }
                else if (text === 'Out for Service'){
                    return <Tag color={'red'}>{text}</Tag>
                }
                else if(text){
                    return <Tag>{text}</Tag>
                }
                else{
                    return '-'
                }
            }
        },
        {
            key: 'closingHours',
            dataIndex: 'closingHours',
            title: 'Vehicle Hours',
            render: (_, record) => record.engineHours ? record.engineHours.closingHours ?? '-' : '-'
        },
        {
            key: 'action',
            dataIndex: 'action',
            title: 'action',
            render: (text, record) => <Button onClick={() => {
                navigate(`/${user.userType}/vehicleHistory/checklistHistory/${record.vehicleID}`)
            }}>{'View Info'}</Button>
        },
    ]

    const vehicleReformat = (vehicle) => {

        let vehicleCurrentStatus = vehicle.currentStatus;
        if(vehicle.currentStatus === "Parked"){
            if(vehicle.userIsOnShift){
                vehicleCurrentStatus = "On Shift"
            }
        }

        return ({
            key: vehicle.vehicleID,
            vehicleID: vehicle.vehicleID,
            vehicleName: vehicle.vehicleID,
            vehicleType: vehicle.vehicleType,
            vin: vehicle.vin,
            currentStatus: vehicleCurrentStatus,
            engineHours: vehicle.engineHours,
        })
    }

    const selectRender =
        <Select value={selectValue} onChange={(value) => setSelectValue(value)}>
            <Select.Option key={'Assigned'} value={'Assigned'}>Assigned</Select.Option>
            <Select.Option key={'Unassigned'} value={'Unassigned'}>Unassigned</Select.Option>
            <Select.Option key={'Archived'} value={'Archived'}>Archived</Select.Option>
            <Select.Option key={'All'} value={'All'}>All</Select.Option>
        </Select>

    return (
        <Card title={'Vehicles'} bodyStyle={{padding: 0}} extra={selectRender}>
            <Space size={16} className={Styles.countArea}>
                <span>
                    <Badge color={badgeColor} text="Total vehicles"/>
                    <span className={Styles.countValue}>{vehicleList.length}</span>
                </span>
                <span>
                    <Badge color={badgeColor} text="Parked"/>
                    <span className={Styles.countValue}>{vehicleList.filter(item => item.currentStatus === 'Parked' && !item.userIsOnShift).length}</span>
                </span>
                <span>
                    <Badge color={badgeColor} text="On Shift"/>
                    <span className={Styles.countValue}>{vehicleList.filter(item => item.currentStatus === 'Parked' && item.userIsOnShift).length}</span>
                </span>
                <span>
                    <Badge color={badgeColor} text="Out for Maintenance"/>
                <span className={Styles.countValue}>{vehicleList.filter(item => item.currentStatus === 'Out for Maintenance').length}</span>
                </span>
            </Space>
            <Table
                loading={vehicleListLoading}
                columns={vehicleFieldSettings}
                sortDirections={['ascend', 'descend', 'ascend']}
                dataSource={vehicleList.map(vehicleReformat)}
                pagination={{size: 'small', defaultPageSize: 30}}
                scroll={{x:true}}
            />
        </Card>
    );
};

export default VehicleHistory;
