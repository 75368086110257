import React, { useState, useEffect } from 'react';
import IncidentTable from "../../../components/IncidentTable/IncidentTable";
import { getIncidents } from '../../../services/IncidentService';
export const Incidents = (props) => {

    const { user } = props;
    const [incidentCollection, setIncidentCollection] = useState([]);

    useEffect(() => {
        getIncidents().then(setIncidentCollection);
    }, [incidentCollection]);

    return (
        <>
            
            {  
                <section>
                 <IncidentTable incidentCollection={incidentCollection}  user={user} role={'management'}>
                

                </IncidentTable>       
                </section>

          
            }
        </>
    )

}

export default Incidents;
