import React, { useState, useEffect, useContext } from 'react';
import useModal from '../../../components/Modal/useModal';
import CreateVehicleForm from './CreateVehicleForm';
import VehicleItem from './VehicleItem';
import { UserContext } from '../../../context/userContext';
import { getVehicles, getVehicleTypes, subscribeToVehicles, getVehiclesWithUsersAssigned } from '../../../services/VehiclesService';
import { CCard, CCardBody, CCardHeader, CCardText, CModal } from "@coreui/react";

const ManageVehicles = () => {
  const { user } = useContext(UserContext);
  const [userInfos, setUserInfos] = useState({});
  const { isShowing, toggle } = useModal();
  const [vehiclesArr, setVehiclesArr] = useState([]);
  const [filteredVehiclesArr, setFilteredVehiclesArr] = useState([]);
  const [vehicleTypesArr, setVehicleTypesArr] = useState([
    "Articulated Water Truck",
    "Diesel Bowser",
    "Drills",
    "Excavator",
    "Fel",
    "Forklift",
    "Grader",
    "Ldv",
    "Srv Water Bowser",
    "Track Dozer",
    "Rdt",
    "Truck Mounted Crane",
    "Tlb",
    "Lighting Vehicle",
    "Hydraulic Rig Operator",
    "Loader",
    "Haul Truck",
    "Bus"
  ]);


  useEffect(() => {
    if (Object.keys(user).length) setUserInfos(user)
  }, [user]);


  useEffect(() => {
    getVehiclesWithUsersAssigned().then(response => {
      setFilteredVehiclesArr(response);
    });
  }, [vehiclesArr]);

  useEffect(() => {
    getVehicles().then(setVehiclesArr);
    getVehicleTypes().then(setVehicleTypesArr);
    subscribeToVehicles(setVehiclesArr);
  }, [])

  const filterVehicles = (vehicleType) => {
    if (vehicleType) {
      setFilteredVehiclesArr(vehiclesArr.filter((vehicle) => vehicle.vehicleType === vehicleType))
    } else {
      setFilteredVehiclesArr(vehiclesArr)
    }
  };

  return (
    <>
      <CCard className={``}>
        <CCardHeader className={'d-flex align-items-center justify-content-between'}>
          Vehicles
          <div className={'card-header-actions align-items-center justify-content-center d-flex'}>
            <div className={'p-2'}>
              {["maintenance", "supervisor"].includes(userInfos.userType)
                ? null
                : <button className="btn-primary btn" onClick={toggle}>Create New Vehicle</button>}
              <CModal show={isShowing} size={'lg'} > {<CreateVehicleForm vehicleTypesArr={vehicleTypesArr} hide={toggle} userInfos={userInfos} />} </CModal>
            </div>
            <div className={'p-2'}>
              <select className="selectPrimary custom-select" onChange={(event) => filterVehicles(event.target.value)}>
                <option value="">All Vehicle Types</option>
                {vehicleTypesArr.map((vehicle) => <option key={vehicle}>{vehicle}</option>)}
              </select>
            </div>
          </div>

        </CCardHeader>
        <CCardBody className="">
          <CCardText tag={'div'}>
            <div className={`position-relative table-responsive`}>
              <table className={`table table-hover`} >
                <thead key="titles" className="">
                  <tr>
                    <th className="border-top-0">Vehicle ID</th>
                    <th className="border-top-0">Vehicle Type</th>
                    <th className="border-top-0">Vehicle Status</th>
                    <th className="border-top-0">Current Status</th>
                    <th className="border-top-0">Current Team</th>
                    <th className="border-top-0">Current User</th>
                    {userInfos.userType !== "supervisor"
                      ? <th className="border-top-0">Action</th>
                      : null
                    }
                  </tr>
                </thead>
                <tbody>
                  {filteredVehiclesArr.map((vehicle) => <VehicleItem key={vehicle.vehicleID} vehicle={vehicle} userType={userInfos.userType} />)}
                </tbody>
              </table>
            </div>
          </CCardText>
        </CCardBody>
      </CCard>
    </>
  )
}

export default ManageVehicles;