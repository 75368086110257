import React, {useContext, useEffect, useState} from 'react';
import {Card, Table, Space, Button, Modal, Input} from "antd";
import {CloseOutlined, CheckOutlined} from '@ant-design/icons'
import {cloneDeep, isUndefined} from "lodash";
import {getMaintenanceJob,updateMaintenanceJob} from "../../../services/MaintenanceJobsService";
import showToast from "../../../services/toastService";
import {createNewsItem} from "../../../services/newsItemsService";
import {UserContext} from "../../../context/userContext";
import {APPROVED,REJECTED,CLOSED,OPEN} from "../../../enums/MaintenanceJobsStatuses";
export const SignOffMaintenance = (props) => {

    const {toggleFullPageLoader} = useContext(UserContext);
    const {showModal, user,maintenanceJobsArr } = props;

    // Logic for getting check items is in supervisor so supervisor knows the number of check items to show in the notification bubble
    // Really this should all be in context 🤷‍♀️

    const [currentCheckItems, setCurrentCheckItems] = useState([...maintenanceJobsArr])
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [rejectionReason, setRejectionReason] = useState('')

    const msgNoIssues = <h3>There are currently no maintenance issues to sign off</h3>;

    const onReasonInputChange = (elReason)=>{
        setRejectionReason(elReason.target.value);
    }

    const onSubmit = async (approved, checkItem) => {
        try {
            toggleFullPageLoader(true);
            //removing added field before putting into firestore
            const job = await getMaintenanceJob(checkItem.id);

            if(job && job.issueID){

                if (job && job.status === APPROVED) {
                  showToast("Cannot sign off this maintenance issue because it was already actioned.", 5000, 'background-color: red; color: white');
                  setIsModalVisible(false);
                  toggleFullPageLoader(false);
                  return false;
                }


                let jobUpdate = job;
                jobUpdate.assignedMaintenance = '';
                jobUpdate.maintenanceSignedOffBy = user.userID;
                jobUpdate.supervisorSignoff = approved;
                jobUpdate.maintenanceSignoff = approved ? job.maintenanceSignoff : false;
                jobUpdate.jobStatus = approved ? APPROVED : REJECTED;
                jobUpdate.status = approved ? CLOSED : OPEN;

                if (rejectionReason) {
                  jobUpdate.maintenanceRejectionReason = rejectionReason;
                }

                await updateMaintenanceJob(checkItem.id, jobUpdate);

                //raise a news item
                const status = approved ? "Approved" : "Rejected";

                await createNewsItem({
                    title: `Maintenance ${status}`,
                    message: `Maintenance for ${checkItem.issue} on ${checkItem.vehicleID} was ${status}`,
                    team: checkItem.vehicleTeam,
                    type: "maintenanceSuperviorSignoff",
                    isImportant: false,
                    seenBy: [],
                    info: {
                        vehicleID: checkItem.vehicleID,
                        faultClass: checkItem.classType,
                        faultName: checkItem.issue,
                        completedBy: checkItem.assignedMaintenance,
                        issueID: checkItem.issueID
                    },
                    dateCreated: new Date()
                });

          }

            setIsModalVisible(false);

        } finally {
            toggleFullPageLoader(false);
        }
    };

    const columns = [
        {
            key: 'mechanic',
            dataIndex: 'mechanic',
            title: 'Mechanic',
            render: (_, record) => record.maintenanceSignoff.completedBy || ''
        },
        {
            key: 'vehicle',
            dataIndex: 'vehicle',
            title: 'Vehicle',
            render: (_, record) => `${record.vehicleType}-${record.vehicleID}`
        },
        {
            key: 'issue',
            dataIndex: 'issue',
            title: 'Issue',
        },
        {
            key: 'classType',
            dataIndex: 'classType',
            title: 'Class',
        },
        {
            key: 'action',
            dataIndex: 'action',
            title: 'Action',
            render: (_, record) => (
                <Space size={"large"}>
                    <Button type={"primary"} shape="circle" icon={<CheckOutlined/>}
                        onClick={() => onSubmit(true, record)}
                    />
                    <Button danger shape="circle" icon={<CloseOutlined/>} onClick={() => {
                        setCurrentCheckItems(record)
                        setIsModalVisible(true)
                    }}/>
                </Space>
            )
        },
    ]

    return (
        <Card title={'Sign off Maintenance'} bodyStyle={{padding: 0}}>
            {
                !user.isOnShift &&
                <section>
                    <div className="alert alert-danger fade show">
                        Please begin your shift to proceed
                    </div>
                </section>
            }
            {
                user.isOnShift && maintenanceJobsArr.length === 0 &&
                msgNoIssues
            }
            {
                user.isOnShift && maintenanceJobsArr.length > 0 &&
                <Table
                    columns={columns}
                    dataSource={maintenanceJobsArr}
                    scroll={{x:true}}
                    rowKey={"maintenance-signoff-table"}
                />
            }
            <Modal title="Rejection reason:" visible={isModalVisible} destroyOnClose={true}
                   onOk={() => onSubmit(false, currentCheckItems)}
                   onCancel={() => setIsModalVisible(false)}>
                <Input placeholder={'Please enter rejection reason.'} onChange={onReasonInputChange}/>
            </Modal>
        </Card>
    )
}

export default SignOffMaintenance;
