export const maintananceApproveIcon = ['512.004 512.004', `
 <title>maintanence icon</title>
 <g>
        <path d="m186.526 377.196 200.67-200.67c32.81 12.855 71.538 6.046 98.047-20.464 26.502-26.502 33.312-65.231 20.464-98.047l-46.294 46.294-40.793-10.925-10.925-40.793 46.294-46.294c-32.817-12.848-71.545-6.039-98.047 20.464-26.509 26.509-33.319 65.238-20.464 98.047l-200.67 200.67c-35.299-14.878-77.619-7.941-106.377 20.817-37.908 37.908-37.908 99.37 0 137.278s99.37 37.908 137.278 0c28.758-28.758 35.695-71.079 20.817-106.377zm-115.315 63.597c-14.284-14.284-14.277-37.441 0-51.718 14.284-14.284 37.434-14.283 51.718 0s14.284 37.434 0 51.718c-14.277 14.276-37.434 14.283-51.718 0z"
              fill="#60e2f4"/>
        <path d="m165.709 483.573c-37.908 37.908-99.37 37.908-137.278 0l42.78-42.78c14.284 14.284 37.441 14.277 51.718 0 14.284-14.284 14.283-37.434 0-51.718l295.69-295.691 40.793 10.925 46.294-46.294c12.848 32.817 6.039 71.545-20.464 98.047-26.509 26.509-65.238 33.319-98.047 20.464l-200.67 200.67c14.879 35.298 7.942 77.619-20.816 106.377z"
              fill="#29c5ec"/>
        <path d="m372.279 351.065-276.104-276.104 19.014-19.014-70.979-55.946-44.209 44.208 55.947 70.98 19.014-19.015 276.104 276.104z"
              fill="#cddbf9"/>
        <path d="m361.672 361.672-339.567-339.567-22.104 22.104 55.947 70.98 19.014-19.015 276.104 276.104z"
              fill="#f3f6ff"/>
        <path d="m304.439 376.96 51.718 51.717 22.465-.304 50.098-50.098-.042-22.119-51.718-51.717z" fill="#3c4a83"/>
        <path d="m304.439 376.96 51.718 51.717 22.465-.304 24.876-24.876-62.798-62.798z" fill="#48588b"/>
        <path d="m356.157 428.677 68.299 68.299c10.02 10.02 23.144 15.026 36.268 15.026s26.248-5.006 36.26-15.019c20.025-20.025 20.025-52.496-.007-72.528l-68.299-68.299z"
              fill="#ea0016"/>
        <path d="m356.157 428.677 68.299 68.299c10.02 10.02 23.144 15.026 36.268 15.026s26.248-5.006 36.26-15.019l-104.567-104.566z"
              fill="#ff641a"/>
    </g>`]
