import React, { useState, useEffect, useContext } from 'react';
import { useForm } from "react-hook-form";
import Styles from './CreateUserForm.module.scss';
import { createAuthUser, createUser } from '../../../../services/UsersService'
import { getTeams } from '../../../../services/TeamsService';
import { CModalBody, CModalFooter, CModalHeader, CModalTitle } from "@coreui/react";
import { uniqBy, get } from 'lodash';
import { UserContext } from "../../../../context/userContext";

// ------ CLASSES ----- //

class User {
    constructor(userID, userType, fullName, dateOfBirth) {
        this.userID = userID;
        this.userType = userType;
        this.fullNameStr = fullName;
        this.dateOfBirth = dateOfBirth;
    }
}
class Supervisor extends User {
    constructor(userID, userType, fullName, dateOfBirth, currentTeam, currentSubTeam) {
        super(userID, userType, fullName, dateOfBirth);
        this.currentTeam = get(currentTeam, 'teamName', '');
        this.currentSubTeamName = get(currentSubTeam, 'subTeamName', '');
        this.currentSubTeam = get(currentSubTeam, 'subTeamName', '');
        this.currentTeamID = get(currentTeam, 'teamID', '');
        this.currentSubTeamID = get(currentSubTeam, 'teamID', '');
        this.isOnShift = false;
    }
}
class Operator extends Supervisor {
    constructor(userID, userType, fullName, dateOfBirth, currentTeam, currentSubTeam, isOnShift) {
        super(userID, userType, fullName, dateOfBirth, currentTeam, currentSubTeam, isOnShift);
        this.assignedVehicle = "";
    }
}

// ------ JSX ----- //

const defaultUserPassword = 'Core2025';

const CreateUserForm = (props) => {
    const { register, handleSubmit, errors, reset } = useForm();

    const [teamsArr, setTeamsArr] = useState([]);
    const [subTeamsArr, setSubTeamsArr] = useState([]);
    const { toggleFullPageLoader } = useContext(UserContext);

    useEffect(() => {
        getTeams().then(response => {
            setTeamsArr(uniqBy(response, 'teamName'));
            setSubTeamsArr(uniqBy(response, 'subTeamName'));
        });
    }, []);

    const createNewUser = async (data) => {
        const currentTeam = teamsArr.find((team) => team.teamID === data.currentTeam) || {};
        const currentSubTeam = subTeamsArr.find((team) => team.teamID === data.currentSubTeam) || {};
        const userPassword = data.userPassword ?? defaultUserPassword

        try {

            toggleFullPageLoader(true);

            switch (data.userType) {
                case "operator":
                    await createUser(new Operator(data.userID, data.userType, data.fullName, data.dateOfBirth, currentTeam, currentSubTeam));
                    await createAuthUser(data.userID, userPassword);
                    break;

                case "supervisor":
                    await createUser(new Supervisor(data.userID, data.userType, data.fullName, data.dateOfBirth, currentTeam, currentSubTeam));
                    await createAuthUser(data.userID, userPassword);
                    break;

                case "maintenance":
                    await createUser(new User(data.userID, data.userType, data.fullName, data.dateOfBirth));
                    await createAuthUser(data.userID, userPassword);
                    break;

                case "management":
                    await createUser(new User(data.userID, data.userType, data.fullName, data.dateOfBirth));
                    await createAuthUser(data.userID, userPassword);
                    break;
                case "admin":
                    await createUser(new User(data.userID, data.userType, data.fullName, data.dateOfBirth));
                    await createAuthUser(data.userID, userPassword);
                    break;

                default: /* do nothing; */
                    break;
            }

            props.hide();
            reset({});
        } catch (error) {
            console.error(error);
        }
        finally {
            toggleFullPageLoader(false);
        }

        return

    }

    return (

        <>
            <CModalHeader closeButton>
                <CModalTitle>Create User</CModalTitle>
            </CModalHeader>

            <form className="d-flex flex-column" onSubmit={handleSubmit(createNewUser)}>
                <CModalBody>
                    <div className={Styles.userForm}>

                        <label htmlFor="userID">User ID# :</label>
                        <input
                            className={Styles.inputPrimary}
                            type="text"
                            id="userID"
                            name="userID"
                            placeholder="eg: MINE123456"
                            ref={register({ required: true })} />
                        {errors.userID && <p>UserID is required.</p>}

                        <label htmlFor="userType">Select User Type :</label>
                        <select
                            className={Styles.selectPrimary}
                            name="userType"
                            id="userType"
                            ref={register({ required: true })}>
                            <option value="">Select User Type :</option>
                            <option value="operator">Operator</option>
                            <option value="supervisor">Supervisor</option>
                            <option value="maintenance">Maintenance</option>
                            <option value="management">Management</option>
                            <option value="admin">Admin</option>
                        </select>
                        {errors.userType && <p>User Type is required.</p>}

                        <label htmlFor="currentTeam">Select Team :</label>
                        <select
                            className={Styles.selectPrimary}
                            name="currentTeam"
                            id="currentTeam"
                            ref={register()}>
                            <option value="">Select Team</option>
                            {teamsArr.map(({ teamName, teamID }) => <option key={teamID} value={teamID}>{teamName}</option>)}

                        </select>


                        <label htmlFor="currentSubTeam">Select Sub Team :</label>
                        <select
                            className={Styles.selectPrimary}
                            name="currentSubTeam"
                            id="currentSubTeam"
                            ref={register()}>
                            <option value="">Select Sub Team</option>
                            {subTeamsArr.map(({ subTeamName, teamID }) => <option key={teamID} value={teamID}>{subTeamName}</option>)}

                        </select>

                        <label htmlFor="fullName">Full Name :</label>
                        <input
                            className={Styles.inputPrimary}
                            type="text"
                            id="fullName"
                            name="fullName"
                            placeholder="enter user's full name"
                            ref={register({ required: true, minLength: 2 })} />
                        {errors.fullName && <p>FullName is required. Minimum length of 2 characters.</p>}

                        <label htmlFor="dateOfBirth">Date of Birth :</label>
                        <input
                            className={Styles.inputPrimary}
                            type="date"
                            id="dateOfBirth"
                            name="dateOfBirth"
                            placeholder="enter user's Date of Birth"
                            ref={register} />
                        {errors.dateOfBirth && <p>Date of birth is required.</p>}


                        <label htmlFor="userPassword">Enter User's Password :</label>
                        <input
                            className={Styles.inputPrimary}
                            type="text"
                            id="userPassword"
                            name="userPassword"
                            placeholder="Enter user's password"
                            ref={register({ required: true })} />
                        {errors.userPassword && <p>User's password is required.</p>}

                    </div>
                </CModalBody>
                <CModalFooter>
                    <button className="btn btn-secondary" data-dismiss="modal" aria-label="Close" type="button" onClick={() => (props.hide())} >Cancel</button>
                    <button className="btn btn-primary" type="submit">Create</button>
                </CModalFooter>
            </form>

        </>
    );
}

export default CreateUserForm;