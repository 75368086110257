import React, {useContext} from 'react'
import { useForm } from "react-hook-form";
import Styles from './EditSiteForm.module.scss';
import { updateSite } from '../../../../services/SitesService'
import {CModalBody, CModalFooter, CModalHeader, CModalTitle} from "@coreui/react";
import { UserContext } from "../../../../context/userContext";

const EditSiteForm = (props) => {
    const {
        siteID,
        siteName
    } = props.site;

    const { register, handleSubmit, errors } = useForm();
    const { toggleFullPageLoader } = useContext(UserContext);

    const updateCurrentSite = async (data) => {
        try {
            toggleFullPageLoader(true);
            const updatedSite = {
                siteID : siteID,
                siteName : data.siteName
            };
            props.hide();
            await updateSite(updatedSite);
        } finally {
            toggleFullPageLoader(false);
        }
    }
    
    return (
        <>
            <CModalHeader closeButton>
                <CModalTitle>Edit Site</CModalTitle>
            </CModalHeader>

            <form className="d-flex flex-column" onSubmit={handleSubmit(updateCurrentSite)}>
                <CModalBody>

                    <div className={Styles.siteForm}>

                        <label htmlFor="siteName">Site Name :</label>
                        <input className={Styles.inputPrimary}
                               defaultValue={siteName}
                               type="text"
                               id="siteName"
                               name="siteName"
                               placeholder="enter the siteName"
                               ref={register({ required: true })} />
                        {errors.siteName && <p> Site Name is required.</p>}

                    </div>

                </CModalBody>

                <CModalFooter>
                    <button className="btn btn-secondary" data-dismiss="modal" aria-label="Close" type="button" onClick={()=>(props.hide())}>Cancel</button>
                    <button className="btn btn-primary" type="submit">Update</button>
                </CModalFooter>

            </form>

        </>
    )
}

export default EditSiteForm;
