
import {parse} from 'json2csv';
import toastService from "../services/toastService";
import {getFormattedDate} from '../utilities/DateUtil';

const downloadReportUtil = async( reportName, reportData, fields, optionalDate = null )=>{

    try {
      
        const reportDate = optionalDate ? optionalDate : new Date();
        const dwnReportName = [ reportName, getFormattedDate( reportDate ) ].join('-');
        const csv = parse(reportData, {fields});
        reportAutoDownloadUtil(csv, dwnReportName);

      } catch (err) {
        toastService("Report generation failed, please try again.", 5000);
      }

}

const reportAutoDownloadUtil = ( csvContent, reportName) => {

    const a = document.createElement("a");
    const file = new Blob([csvContent], {type: "text/csv;charset=utf-8;"});
    a.href = URL.createObjectURL(file);
    a.download = `${reportName}`;
    a.click();
  
};
  
export { downloadReportUtil, reportAutoDownloadUtil  }