import React, { useContext, useState } from "react";
import { UserContext } from "../../../context/userContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faExclamation, faCheck, faUser } from '@fortawesome/free-solid-svg-icons';

const SignIn = () => {
    const { signIn } = useContext(UserContext);
    const [signInStatus, setSignInStatus] = useState('inert');
    const statusAttributeMap = {
      inert: { icon: faUser, spin: false }, 
      active: { icon: faSpinner, spin: true  }, 
      success: { icon: faCheck, spin: false },
      warning: { icon: faExclamation, spin: false }, 
      error: { icon: faExclamation, spin: false } 
    };

    const signInHandler = async (event) => {
      event.preventDefault();
      setSignInStatus('active');
      await signIn(document.getElementById('signInID').value, document.getElementById('signInPw').value)
        .then(() => setSignInStatus('success'))
        .catch(() => setSignInStatus('error'));
    }

    return (
        <form className="">
            <label className="">Username/email</label>
            <input placeholder="i.e 763489" id="signInID" required className="form-control" onFocus={() => setSignInStatus('inert')}/>

            <label className="pt-2">Password</label>
            <input type="password" id="signInPw" placeholder="******" required className="form-control" onFocus={() => setSignInStatus('inert')} />

            <button className="btn btn-primary mt-4 d-inline-flex flex-row justify-content-between" style={{ minWidth: '100px' }} value="login" disabled={signInStatus === 'active'} onClick={signInHandler}>
              <FontAwesomeIcon className={`${signInStatus} mt-1`} {...statusAttributeMap[signInStatus]} size="1x" />
              <span>{'SIGN IN'}</span>
            </button>
        </form>
    )
}

export default SignIn
