import React, { useState, useContext } from "react";
import Styles from "../Maintenance.module.scss";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getMaintenanceJob,updateMaintenanceJob } from "../../../services/MaintenanceJobsService";
import { UserContext } from "../../../context/userContext";
import MaintenanceReportForm from "../../../components/MaintenanceReportForm/MaintenanceReportForm";
import Modal from "../../../components/Modal";
import useModal from "../../../components/Modal/useModal";
import {CDataTable} from '@coreui/react';

const MaintenanceTable = (props) => {
    const {user, toggleFullPageLoader} = useContext(UserContext);

    const { jobsCollection } = props;
    const {isShowing, toggle} = useModal();

    const [ feedbackDetails, setFeedbackDetails] = useState([]);
    const [ preloadReportFormVal, setPreloadReportFormVal] = useState([]);

    const jobFieldSettings = [
      { key: 'site', label: 'Site', sorter: false, _classes: 'col-1'},
      { key: 'date', label: 'Date', sorter: false, _classes: 'col-2'},
      { key: 'vehicleID', label: 'Vehicle ID', sorter: false, _classes: 'col-2'},
      { key: 'classType', label: 'Class', _classes: 'sorter-left'},
      { key: 'issue', label: 'Issue', _classes: 'sorter-left'},
      { key: 'assignedMaintenance', label: 'Assigned', _classes: 'col-1 sorter-left'},
      { key: 'photo', label: 'Photo', sorter: false, _classes: 'text-center' },
      { key: 'maintenanceSignoff', label: 'Status',  _classes: 'sorter-left' },
      { key: 'action', label: 'Action', sorter: false, _classes: 'text-center'},
    ];

    const jobsSorting = {column: 'classType', asc: 'true'}

    const jobReformat = (job) => {

      const {classType} = job;
      let colorClass = '';
      let status = 'Unclaimed';
      switch (true){

        case classType === 'classA':
          colorClass = Styles.classA;
        break;

        case classType === 'classB':
          colorClass = Styles.classB;
        break;

        case classType === 'classC':
          colorClass = Styles.classC;
        break;

        default:
          colorClass = Styles.classError

      }

      if (job.assignedMaintenance !== '') {
        if ((job.maintenanceSignoff === false && job.maintenanceRejectionReason)) 
            status = 'Rejected';
        else
            status = 'In Progress';            
      }
      else if (job.assignedMaintenance === '') {
        if (job.maintenanceRejectionReason)
            status = "Rejected";
        else
            status = 'Unclaimed';
      }

      return {
        ...job,
        colorClass,
          //date: DateUtil(job.dateCreated),
        date: job.dateCreatedStr,
        maintenanceSignoff: status
      }
    }

    const handleClaimJob = async (item) => {
        try {
          toggleFullPageLoader(true);
          const job = await getMaintenanceJob(item.id);
          if(job && job.issueID){
            let jobUpdate = job;
            jobUpdate.assignedMaintenance = user.userID;
            updateMaintenanceJob(item.id, jobUpdate);            
          }
        } finally {
          toggleFullPageLoader(false);
        }
    };

    const releaseJob = async (item) => {
        try {
          toggleFullPageLoader(true);
          const job = await getMaintenanceJob(item.id);
          if(job && job.issueID){
            let jobUpdate = job;
            jobUpdate.assignedMaintenance = "";
            updateMaintenanceJob(item.id, jobUpdate);
          }
        } finally {
          toggleFullPageLoader(false);
        }
    };

    const feedbackButton = (jobIdx) =>(
        <button className="btn btn-warning btn-sm my-sm-1 text-white col-12" onClick={()=>toggleFeedbackDetails(jobIdx)}>
          {feedbackDetails.includes(jobIdx) ? 'Hide Feedback' : 'Show Feedback'}
        </button>
    );

    const getCallToActionButtons = (item, itemIdx) => {

      if (item.assignedMaintenance) {
        if (item.assignedMaintenance === user.userID) {
          return (
              <div className="d-flex col-12 flex-column align-items-center">
                <button className="btn btn-primary btn-sm m-sm-1 col-12" onClick={()=>toggleReportForm(item)}>Complete Job</button>
                <button className="btn btn-info btn-sm m-sm-1 col-12" onClick={()=>releaseJob(item)}>Unclaim Job</button>
                {item.maintenanceRejectionReason && feedbackButton(itemIdx)}
              </div>
          );
        } else {
          return (
              <div className="d-flex col-12 flex-column align-items-center">
                <button className="btn btn-infobtn-sm m-sm-1 col-12" onClick={()=> releaseJob(item)}>Unclaim Job</button>
                {item.maintenanceRejectionReason && feedbackButton(itemIdx)}
              </div>
          );
        }
      }

      return (
          <div className="d-flex col-12 flex-column align-items-center">
            <button className="btn btn-primary btn-sm m-sm-1 col-12" onClick={() => handleClaimJob(item)}>Claim Job</button>
            {item.maintenanceRejectionReason && feedbackButton(itemIdx)}
          </div>
      );

    };
    const getJobStatus = (item, index) => {
        if (item.assignedMaintenance !== '') {
            if ((item.maintenanceSignoff === false && item.maintenanceRejectionReason)) {
                return (
                    <span style={{ fontSize: '100%', fontWeight: 'normal' }} class="badge cbadge badge-danger">Rejected</span>
                );
            } else {
                return (
                    <span style={{ fontSize: '100%', fontWeight: 'normal'  }} class="badge cbadge badge-success">In Progress</span>
                );
            }            
        }
        else if (item.assignedMaintenance === '') {
            if (item.maintenanceRejectionReason) {
                return (                    
                    <span style={{ fontSize: '100%', fontWeight: 'normal' }} class="badge cbadge badge-danger">Rejected</span>
                );
            } else {
                return (
                    <span style={{ fontSize: '100%', fontWeight: 'normal' }} class="badge cbadge badge-warning text-white">Unclaimed</span>
                );
            }
        }
    };

    const toggleFeedbackDetails = (index) => {
      const position = feedbackDetails.indexOf(index)
      let newDetails = feedbackDetails.slice()
      if (position !== -1) {
        newDetails.splice(position, 1)
      } else {
        newDetails = [...feedbackDetails, index]
      }
      setFeedbackDetails(newDetails)
    }

    const toggleReportForm = (item) => {
      const {id, issue, vehicleID} = item;
      setPreloadReportFormVal({
        'id': id,
        'vehicleID': vehicleID,
        'vehicleissue': issue
      });
      toggle();
    }

    return (
        <>
          <CDataTable
            items={jobsCollection.map(jobReformat)}
            fields={jobFieldSettings}
            itemsPerPage={10}
            sorter
            responsive={true}
            sorterValue={jobsSorting}
            hover={true}
            scopedSlots = {{
              'classType':
                  (item) => (
                      <td className='col-1'>
                        <FontAwesomeIcon className={`${item.colorClass} mx-1`} icon={faExclamationTriangle}/> {item.classType}
                      </td>
                  ),
              'action':
                  (item, index) => (
                      <td className='col-2'>
                        {getCallToActionButtons(item, index )}
                      </td>
                  ),
              'photo':
                  (item) =>(
                      <td className='col-1'>
                        { item.photo ? <img className={'border rounded w-100 h-auto'} src={item.photo} alt='' /> : ''}
                      </td>
                  ),
              'details':
                  (item, index ) => (
                    <>
                      { feedbackDetails.includes(index) && item.maintenanceRejectionReason &&
                        <div className="alert alert-info fade show my-2">
                          <div className="alert-heading h5"> Supervisor Feedback </div>
                          <div className="text-body">
                            {item.maintenanceRejectionReason}
                          </div>
                        </div>
                      }
                    </>
                    ),
                'maintenanceSignoff':
                    (item, index) => (
                        <td className='col-1'>
                            {getJobStatus(item, index)}
                        </td>
                    ),
            }}
          />

          <Modal innerComponent={<MaintenanceReportForm preloadedValues={preloadReportFormVal} hide={toggle} user={user}/>} isShowing={isShowing} hide={toggle} />
        </>
    )
}

export default MaintenanceTable;
