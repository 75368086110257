import React, {useEffect, useState, useContext} from 'react'
import {useForm, Controller} from "react-hook-form";
import Styles from "./MaintenanceReportForm.module.scss";
import {createNewsItem} from "../../services/newsItemsService";
import { getMaintenanceJob,updateMaintenanceJob } from "../../services/MaintenanceJobsService";
import { COMPLETE  } from "../../enums/MaintenanceJobsStatuses";
import {getParts} from "../../services/PartsService";
import { UserContext } from "../../context/userContext";
import Creatable  from "react-select";

const MaintenanceReportForm = (props) => {

    const { preloadedValues, hide , user } = props;
    const [vehicleID, setVehicleID] = useState(preloadedValues.vehicleID);
    const jobId = preloadedValues.id;
    const [vehicleIssue, setVehicleIssue] = useState(preloadedValues.vehicleissue);
    const [fixTime, setFixTime] = useState("");
    const [rootCause, setRootCause] = useState("");
    const [otherInfo, setOtherInfo] = useState("");
    const {toggleFullPageLoader} = useContext(UserContext);
    const [partNamesCollection, setPartNamesArr] = useState([]);
    const { maintenanceReport, handleSubmit, control, errors } = useForm({
        defaultValues: preloadedValues
    });

    const submitReport = async ( data ) => {

        let partsItemsUsed = []
        data.partsused.forEach( part => {
            partsItemsUsed.push(part.value);
            //if its a new part, create a part
            if( part.__isNew__ ){}
        });

        const reportObj = {
            "completedBy" : user.userID,
            "vehicleIssue": vehicleIssue,
            "fixTime": fixTime,
            "partsUsed": partsItemsUsed,
            "rootCause": rootCause,
            "otherInfo": otherInfo
        };

        try {
            toggleFullPageLoader(true);

            const job = await getMaintenanceJob(jobId);
            if(job && job.issueID){

              let jobUpdate = job;
              let completedByUser = user.userID || job.assignedMaintenance;
              jobUpdate.assignedMaintenance = user.userID;
              jobUpdate.maintenanceSignoff = reportObj  
              jobUpdate.jobStatus =  COMPLETE;     

              await updateMaintenanceJob(jobId, jobUpdate);

              await createNewsItem({
                title: "Maintenance Completed",
                message: `${job.issue} on ${job.vehicleID} by ${ completedByUser }`,
                team: job.currentTeam,
                type: "maintenanceComplete",
                isImportant: false,
                seenBy: [],
                info: {
                    vehicle: job.vehicleID,
                    faultClass: job.classType,
                    faultName: job.issue,
                    completedBy: completedByUser
                },
                dateCreated: new Date()
              });
              //close modal
              hide();
            }else{
                alert("An error has occurred while processing this request. Get intouch with the system admin");
            }

        } finally {
            toggleFullPageLoader(false);
        }
    };

    useEffect(() => {

        getParts().then( response => {
            setPartNamesArr([...new Set(response.map(part => ({value : part.partName, label: part.partName})))]);
        });

    }, [])

    return (
        <div className={Styles.repairFormContainer}>

            <h1 className={Styles.header}>Repair Report for Vehicle ID  : {vehicleID} </h1>

            <form className={Styles.maintenanceReport} onSubmit={handleSubmit( submitReport )}>

                <label htmlFor="vehicleid"> Vehicle ID </label>
                <input ref={maintenanceReport} disabled={!!vehicleID} placeholder={vehicleID ?? "Vehicle ID" } name="vehicleid" onChange={(e) => setVehicleID(e.target.value)} value={vehicleID ? vehicleID : "" } />

                <label htmlFor="vehicleissue"> Vehicle Issue</label>
                <input ref={maintenanceReport} disabled={!!vehicleIssue}  placeholder={ vehicleIssue ?? "Vehicle Issue" } name="vehicleissue" onChange={(e) => setVehicleIssue(e.target.value)} value={ vehicleIssue ?? "" }/>

                <label htmlFor="fixtime">Time Taken to Fix (minutes)</label>
                <input ref={maintenanceReport} placeholder="Time taken to fix" name="fixtime" onChange={(e) => setFixTime(e.target.value)} />

                <label htmlFor="rootcause">Root Cause</label>
                <input ref={maintenanceReport} placeholder="Root cause" name="rootcause" onChange={(e) => setRootCause(e.target.value)} required={true} />

                <label htmlFor="partsused" className={Styles.fullwidth}>Parts Used</label>

                <Controller isMulti
                    closeMenuOnSelect={false}
                    className="col col-12 py-1 px-0"
                    name="partsused"
                    id="partsused"
                    options={partNamesCollection}
                    as={Creatable}
                    control={control}
                    rules={{ required: true }}
                />

                {errors['partsused'] && <span className="error">Parts used is required.</span>}

                <textarea ref={maintenanceReport} cols="30" rows="15" placeholder="Vehicle Fix and other information" name="otherinfo"  onChange={(e) => setOtherInfo(e.target.value)} required={true}/>

                <button className={`button ${Styles.btnSecondary}`} type="button" onClick={hide}>Cancel</button>
                <button className={`button ${Styles.btnPrimary}`} type="submit">Submit</button>

            </form>

        </div>
    )
}

export default MaintenanceReportForm
