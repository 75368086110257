import React from 'react';
import SignIn from "./SignIn";
import styles from "../Login/Login.module.scss";
import CIcon from "@coreui/icons-react";

const Login = () => {

    return (
        <div className="container-fluid vh-100 p-0 overflow-hidden">

            <main className={`d-flex vh-100` }>
                <div className={`left w-50 flex-fill bg-gradient-light p-4`}>
                    <div className="d-flex justify-content-center flex-column h-100 align-items-center">
                        <div className="mb-2 mb-md-4">
                            <CIcon name={"logo"} src={require( `../../assets/images/opscore-logo.png` )} className={``} height="140" />
                        </div>
                        <div className="col col-sm-8 col-lg-4">
                            <SignIn />
                        </div>
                    </div>

                    <footer className='mt-n4 mx-n4 bg-gradient-light text-center py-3 d-md-down-none shadow'>
                        <b>OpsCORE</b> © Copyright {new Date().getFullYear()}. All rights reserved.
                    </footer>

                </div>
                <div className={`right w-50 bg-transparent d-md-down-none align-items-center ${styles.loginContainerBG}`}>&nbsp;</div>
            </main>

        </div>
    )
}

export default Login;
