import React, {useEffect, useState, Fragment, useContext} from "react";
import Styles from "./AssignmentHistory.module.scss";
import {PageHeader, Card, Tag, Table, Button, Divider, Spin, Descriptions, Empty} from 'antd'
import {subscribeVehicleByVehicleID} from "../../../../services/VehiclesService";
import {subscribeVehicleEngineHours} from "../../../../services/VehicleEnginehoursService";
import IndividualAssignment from "./IndividualAssignment";
import InspectionHistory from "../ChecklistHistory/InspectionHistory";
import moment from 'moment'
import VehiclePageHeader from "../VehiclePageHeader";
import {AVAILABLE} from "../../../../enums/VehicleStatuses";
import {groupBy} from "lodash";

const AssignmentHistory = (props) => {

    const {vehicleID} = props;
    const [vehicleInfo, setVehicleInfo] = useState({})
    const [assignmentList, setAssignmentList] = useState([])
    const [vehicleInfoLoading, setVehicleInfoLoading] = useState(false)
    const [assignmentListLoading, setAssignmentListLoading] = useState(false)
    const [assignmentDetail, setAssignmentDetail] = useState({
        detail: {},
        visible: false
    })

    useEffect(() => {
        setVehicleInfoLoading(true)
        setAssignmentListLoading(true)

        subscribeVehicleByVehicleID(vehicleID, (result) => {
            if(result.length > 0){
                setVehicleInfo(result[0])
            }
            setVehicleInfoLoading(false)
        })

        subscribeVehicleEngineHours(vehicleID, (items)=>{
            const newSet = items.filter((item) => {
                return (item.userShiftId && item.userShiftId !== '') ?? false;
            })
            let groupSet = groupBy(newSet, 'userShiftId');

            let grpList = [];

            Object.values(groupSet).map((item) => (grpList.push({ ...item[1], ...item[0], details: item })))

            setAssignmentList(grpList);

            setAssignmentListLoading(false)
        })
    }, [])

    const assignmentListField = [
        {
            key: 'user',
            dataIndex: 'user',
            title: 'User',
            render: (_, record) => record.addedByDriver?.fullName,
            sorter: (a, b) => a.addedByDriver?.fullName.localeCompare(b.addedByDriver?.fullName),
        },
        {
            key: 'userShiftId',
            dataIndex: 'userShiftId',
            title: 'Shift',
        },
        {
            key: 'startTime',
            dataIndex: 'startTimeStr',
            title: 'Start time',
            sorter: (a, b) => a.startTime - b.startTime,
            defaultSortOrder: 'descend',
        },
        {
            key: 'endTime',
            dataIndex: 'endTimeStr',
            title: 'End Time',
            sorter: (a, b) => a.endTime - b.endTime,
        },
        {
            key: 'openingHours',
            dataIndex: 'openingHours',
            title: 'Opening hours',
            render: (value) => value ?? '-'
        },
        {
            key: 'closingHours',
            dataIndex: 'closingHours',
            title: 'Closing hours',
            render: (value) => value ?? '-'
        },
        {
            key: 'action',
            dataIndex: 'action',
            title: 'action',
            render: (text, record) => <Button onClick={() => {
                setAssignmentDetail({
                    detail: record,
                    visible: true
                })
            }}>{'View Info'}</Button>
        },
    ]

    const assignmentReformat = (assignment) => {
        let startTimeStr = ''
        let endTimeStr = ''
        let startTime = ''
        let endTime = ''
        if(assignment?.details){
            let timeStr = '-'
            let time = 0
            if(assignment.details && assignment.details.length > 0){
                assignment.details.forEach(item => {
                    if(item.openingHours){
                        timeStr = item.dateCreatedStr
                        time = item.dateCreated
                    }
                })
            }
            startTimeStr = timeStr
            startTime = time
        }
        if(assignment?.details){
            let timeStr = '-'
            let time = 0
            if(assignment.details && assignment.details.length > 0){
                assignment.details.forEach(item => {
                    if(item.closingHours){
                        timeStr = item.dateCreatedStr
                        time = item.dateCreated
                    }
                })
            }
            endTimeStr = timeStr
            endTime = time
        }
        return ({
            ...assignment,
            startTime,
            endTime,
            startTimeStr,
            endTimeStr
        })
    }

    return (
        <Fragment>
            {
                !assignmentDetail.visible &&
                    <Fragment><Card bodyStyle={{padding: 0}}>
                        <VehiclePageHeader
                            title="Assignment History"
                            vehicleID={vehicleID}
                        />
                        <Divider className={Styles.headerDivider}/>
                        <Spin spinning={vehicleInfoLoading}>
                            <div className={Styles.vehicleInfo}>
                                <Descriptions title={vehicleInfo.vehicleID} column={1}>
                                    <Descriptions.Item label="Type">{vehicleInfo.vehicleType}</Descriptions.Item>
                                    <Descriptions.Item label="Current Team">{vehicleInfo.currentTeam}</Descriptions.Item>
                                    {vehicleInfo.userIsOnShift && <Descriptions.Item label="Current User">{vehicleInfo.currentUser}</Descriptions.Item>}
                                    <Descriptions.Item label="Current Status">{vehicleInfo.currentStatus === "Parked" && vehicleInfo.userIsOnShift ? "On Shift" : vehicleInfo.currentStatus}</Descriptions.Item>
                                    <Descriptions.Item label="Vehicle Status">{vehicleInfo?.vehicleStatus?.toLocaleLowerCase() === AVAILABLE.toLocaleLowerCase() ?
                                        <Tag color={"green"}>{vehicleInfo.vehicleStatus}</Tag> :
                                        <Tag color={"gray"}>{vehicleInfo.vehicleStatus}</Tag>}</Descriptions.Item>
                                </Descriptions>
                            </div>
                        </Spin>
                    </Card>
                        <Card title={'Assignment History'} bodyStyle={{padding: 0}}>
                            <Table
                                rowKey={'id'}
                                loading={assignmentListLoading}
                                columns={assignmentListField}
                                sortDirections={['ascend', 'descend', 'ascend']}

                                dataSource={assignmentList.map(assignmentReformat)}
                                pagination={{size: 'small', defaultPageSize: 30}}
                                scroll={{x:true}}
                            />
                        </Card>
                    </Fragment>
            }
            {
                assignmentDetail.visible &&
                    <IndividualAssignment
                        onClose={() => setAssignmentDetail({
                            visible: false
                        })}
                        vehicleInfo={vehicleInfo}
                        assignmentDetail={assignmentDetail.detail}
                    />
            }
        </Fragment>
    );
};

export default AssignmentHistory;
