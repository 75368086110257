import React from "react";
import Styles from "../Checklist.module.scss";
// import EngineHours from "../../EngineHours";

const ClassAChecks = (props) => {

    const { extraTextFields, setExtraTextFields, getTextFieldElements, setFailedElements, failObject, checklistData, vehicleType, nextHandler, backHandler,
        vehiclePreselected, vehicleStepCheckOptions} = props.propsMethods;
    const checkboxArr = Object.keys(checklistData.classA);
    checkboxArr.sort();

    const getChecklist = item => {
        
        let isSelected = !!vehiclePreselected(item)
        let isPassedSelection = vehicleStepCheckOptions(item, "classA");

        return (
            <div key={item} className={ isSelected ? Styles.inActive  : Styles.active}>
                <img src={checklistData.classA[item]?.iconUrl} alt={item} />
                <label htmlFor={item}>{item}</label>

                { !isSelected && !isPassedSelection &&
                    <input type="checkbox" id={item} name={item} value={item}/>
                }

                { !isSelected && isPassedSelection &&
                    <input type="checkbox" id={item}  name={item} defaultValue={item}  defaultChecked  />
                }

                { isSelected &&
                    <input type="checkbox" id={item}  name={item} defaultValue={item} readOnly checked={false} className={Styles.inActive}/>
                }

            </div>
        )

    }

    return (
        <>
            <h4>Class A Checks</h4>
            <article className={Styles.classChecks}>
                <form id="class-a-checks">
                    <div className={Styles.responses}>
                        {checkboxArr.map(getChecklist)}
                    </div>
                    <textarea className="form-control border-primary mt-5 mb-3 d-flex" name="additional_details" id="additional-details" placeholder="Please enter any additional details here..."  defaultValue={extraTextFields.classA.additional_details} />
                    <section className={Styles.navigation}>
                        <button onClick={() => { backHandler(); setFailedElements(failObject(vehicleType, "classA")); setExtraTextFields( getTextFieldElements('classA') ); }} className={Styles.btnSecondary}>Back</button>
                        <button type="button" className={Styles.btnPrimary} onClick={() => {nextHandler(); setFailedElements(failObject(vehicleType, "classA")); setExtraTextFields( getTextFieldElements('classA') ); }}>Next</button>
                    </section>
                </form>
            </article>
        </>
    )
}

export default ClassAChecks;