import React from 'react';
import CheckList from "../CheckList";
import {CCard, CCardHeader, CCardBody} from "@coreui/react";

const sectionTitle = 'Checklist Review';

export const ChecklistReview = (props) => {

    const { setModalContent, vehicleChecklists, setShowModal, user, vehiclesArr} = props;

    return (
        <>
            <section>
                {   user.isOnShift && vehicleChecklists.length > 0 &&
                        <CheckList
                            title={sectionTitle}
                            incidentsListing={false}
                            vehicleChecklists={vehicleChecklists}
                            setModalContent={setModalContent}
                            setShowModal={setShowModal}
                            toggle={setShowModal}
                            user={user}
                            vehicleItems={vehiclesArr}
                         />


                }

                { !user.isOnShift &&
                    <section>
                        <div className="alert alert-danger fade show">
                            Please begin your shift to proceed
                        </div>
                    </section>
                }

                { user.isOnShift && vehicleChecklists.length === 0 &&

                    <CCard className={``}>
                        <CCardHeader>
                            {sectionTitle}
                        </CCardHeader>

                        <CCardBody>

                            <div className="table-responsive">
                                <h3>There are currently no checklist items to review</h3>
                            </div>
                        </CCardBody>
                    </CCard>

                }
            </section>

        </>
    )

}

export default ChecklistReview;
