import { navigate } from "@reach/router";
import React, { createContext, useState, useEffect } from "react";
import firebase, { firestore } from "../firebase";
import { subscribeToUserVehicle } from "../services/VehiclesService"
import { getTeamSupervisor } from "../services/UsersService"
import { getTeamSiteName } from "../services/TeamsService";
import { getChecklists } from "../services/ChecklistsService";
import Modal from "../components/Modal";
import useModal from "../components/Modal/useModal";
import Error from "../components/Error";

export const UserContext = createContext({});

export const UserProvider = (props) => {

  const { fullstory } = props;
  const [user, setUser] = useState({});
  const [supervisor, setSupervisor] = useState({});
  const [checklistData, setChecklistData] = useState({});
  const [vehicle, setVehicle] = useState({});
  const [teamSiteName, setTeamSiteName] = useState({});
  const { isShowing, toggle } = useModal();
  const [modalContent, setModalContent] = useState(null);
  const [showFullPageLoader, toggleFullPageLoader] = useState(false);
  const [uid, setUid] = useState('');

  const updateActiveUser = (user) => {
    setUser(user);
  }

  const signUp = (username, password) => {
    let email;
    if (username.match(/@/)) {
      email = username;
    } else {
      email = `${username}@shiftreporter.com`;
    }
    firebase.auth().createUserWithEmailAndPassword(email, password)
      .then(response => {
        firestore
          .collection("users")
          .doc(username)
          .set({
            authID: response.user.uid
          }, { merge: true });
        setModalContent(<Error message={"Sign up successful. Please log in to continue"} hide={toggle} />);
        toggle();
      }).catch(function (error) {
        setModalContent(<Error message={error.message} hide={toggle} />);
        toggle();
      });
  }

  const signIn = (username, password) => (
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() =>
        firebase.auth().signInWithEmailAndPassword((username.match(/@/) ? username : `${username}@shiftreporter.com`), password)
          .then((authUser) => setCurrentUserDetails(authUser.user.uid))
          .catch((error) => {
            setModalContent(<Error message={error.message} hide={toggle} />);
            toggle();
            throw new Error(error);
          })));

  const signOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        navigate(`/`);
        setTimeout(() => setUser(null), 1000);
      })
      .catch((error) => {
        alert(error);
      });
  };

  const loadUserContent = async () => {
    toggleFullPageLoader(true);
    if (user.assignedVehicle && user.userType && user.userType !== "operator") {
      subscribeToUserVehicle(setVehicle, user.assignedVehicle)
    }

    if (user.currentTeam && user.currentSubTeam) {
      await getTeamSupervisor(user.currentTeam, user.currentSubTeam)
        .then(response => setSupervisor(response[0]));

      await getTeamSiteName(user.currentTeam, user.currentSubTeam)
        .then(response => setTeamSiteName(response[0]));
    }
    toggleFullPageLoader(false);
  }

  const initialLogin = () => {
    const currentLocation = window.location.pathname;
    if (user && user.userType && currentLocation === '/') {
      navigate(`/${user.userType}`)
    }

  }

  const setCurrentUserDetails = (uid) =>
    firestore.collection('users')
      .where('authID', '==', uid)
      .get()
      .then((collection) => collection.docs[0]
        ? collection.docs[0].ref.onSnapshot((user) => setUser(user.data()))
        : setUser({}));

  useEffect(() => {

    firebase.auth().onAuthStateChanged(async (user) => {

      if (user) {
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        setUid(uid);
        await setCurrentUserDetails(uid);

      } else {
        // User is signed out
        //console.info('current signed out onAuthStateChanged', user);
        navigate(`/`);
      }

    });

  }, [])

  useEffect(() => {

    if (user) {
      if(user.authID && uid && user.authID !== uid){
        setCurrentUserDetails(uid);
      }else{
        initialLogin();
        loadUserContent();
        fullstory.setUserVars({ ...user, displayName: user.fullNameStr });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (vehicle.vehicleType) {
      getChecklists(vehicle.vehicleType)
        .then(response => {
          setChecklistData(response)
        })
    }
  }, [vehicle])

  return (
    <UserContext.Provider value={{ user, signUp, signOut, signIn, vehicle, supervisor, teamSiteName, checklistData, showFullPageLoader, toggleFullPageLoader, updateActiveUser,uid }}>
      {props.children}
      <Modal innerComponent={modalContent} isShowing={isShowing} hide={toggle} />
    </UserContext.Provider>
  );
};
