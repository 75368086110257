import React, { useState, useEffect, useRef } from 'react';
import { Modal, Tag, Table, Space, Button, Popconfirm, Badge } from 'antd';
import { FormOutlined, DeleteOutlined } from '@ant-design/icons'
import 'antd/dist/antd.css';
import { CCard, CCardBody, CCardHeader } from '@coreui/react';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import moment from 'moment'
import { getShifts as serviceGetShifts, deleteShift as serviceDeleteShift } from '../services/ShiftsService';
import { getUsers } from '../services/ShiftsService';
import { getTeams } from '../services/TeamsService'
import { getWeekOfDate, getStartAndEndDatesForWeek } from '../utilities/DateUtil';
import EditShiftForm from './EditShiftForm';
import WeekPickerButton from './WeekPickerButton';

const DEFAULTTEXT = {
  ADD: 'Add Shift',
  UPDATE: 'Update Shift'
}

export default () => {
  const [date, setDate] = useState(new Date());
  const [status, setStatus] = useState('inert');
  const [shifts, setShifts] = useState([]);
  const [usersInfos, setUsersInfos] = useState([])
  const [teamsInfos, setTeamsInfos] = useState([])
  const [currentShiftParams, setCurrentShiftParams] = useState([])
  const shiftRef = useRef('ADD')

  useEffect(() => {
    Promise.all([getUsers(), getTeams()]).then(([users, teams]) => {
      setUsersInfos(users)
      setTeamsInfos(teams)
    })
  }, []);


  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  const getData = () => Promise.all([serviceGetShifts()])
    .then(([shifts]) => {
      const formatShift = shifts.map(el => {
        return {
          id: el.id,
          name: el.name,
          week: el.week,
          start: el.start,
          end: el.end,
          supervisor: el.supervisor,
          teams: el.teams,
          users: el.users,
          shiftStatus: el.shiftStatus
        }
      })
      setShifts(formatShift);
    });


  const deleteShift = (id) => {
    serviceDeleteShift(id).then(() => getData())
  }

  const updateShift = (record) => {
    shiftRef.current = 'UPDATE'
    const startAndEnd = [moment(record.start, 'HH:mm'), moment(record.end, 'HH:mm')]
    const newReaord = { ...record, startAndEnd }
    setCurrentShiftParams(newReaord)
    showModal()
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => (
        <>
          {record.shiftStatus
            ? <Badge status="processing" />
            : null}
          {name}
        </>
      )
    },
    { title: 'Start', dataIndex: 'start', key: 'start', },
    { title: 'End', dataIndex: 'end', key: 'end', },
    {
      title: 'Schedule',
      key: 'schedule',
      dataIndex: 'week',
      render: schedule => {
        return schedule?.map((el, index) => <Tag color="green" key={el + index} size="small">{el.substring(0, 3)}.</Tag>)
      }
    },
    {
      title: 'Supervisor',
      key: 'supervisor',
      dataIndex: 'supervisor',
      render: supervisor => {
        return supervisor?.map((el, index) => <Tag color={'blue'} key={el + index} size="small">{el}</Tag>)
      }
    },
    {
      title: 'Teams',
      key: 'teams',
      dataIndex: 'teams',
      width: 600,
      render: shiftTeams => {
        return shiftTeams?.map((el, index) => {
          const teamName = teamsInfos.filter(team => team.teamID === el)[0]?.teamName
          return <Tag color={'cyan'} key={el + index} size="small"> {teamName}</Tag>
        })
      }
    },
    {
      title: 'Users',
      key: 'users',
      dataIndex: 'users',
      render: users => {
        return users?.map((el, index) => <Tag color={'lime'} key={el + index} size="small">{el}</Tag>)
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'actions',
      render: (text, record) => {
        return (
          <Space size="middle">
            <FormOutlined style={{ color: '#0aa679', fontSize: 18, marginLeft: 20 }} onClick={() => updateShift(record)} />
            <Popconfirm placement="topLeft" title="Are you sure to delete?" onConfirm={() => deleteShift(record.id)} okText="Yes" cancelText="No">
              <DeleteOutlined style={{ color: '#f6394a', fontSize: 18, marginLeft: 20 }} />
            </Popconfirm>
          </Space>
        )
      },
    },
  ];

  // update data when date changed 
  useEffect(() => {
    const week = getWeekOfDate(date);
    const year = date.getFullYear();
    setStatus('active');
    getData(week, year)
      .then(() => {
        console.info(`Successfully retrieved schedules for week ${week} in year ${year}.`);
        setStatus('inert');
      })
      .catch((error) => {
        console.error(`Failed retrieving schedules for week ${week} in year ${year}, with error: `, error);
        setStatus('error');
      });
  }, [date]);

  return [
    <CCard key={'list-shifts-table'}>
      <CCardHeader className="d-flex align-items-center justify-content-between">
        <DatePicker
          className={'form-control'}
          selected={date}
          onChange={setDate}
          customInput={<WeekPickerButton status={status} week={getWeekOfDate(date)} year={date.getFullYear()} {...getStartAndEndDatesForWeek(date)} />} />
        <Button type='primary' style={{ width: '100px' }} onClick={() => {
          shiftRef.current = 'ADD'
          setCurrentShiftParams({})
          showModal()
        }}>Create</Button>
      </CCardHeader>
      <CCardBody>
        <Table columns={columns} dataSource={shifts} pagination={false} scroll={{ x: 765 }} />
      </CCardBody>
    </CCard>,
    <Modal title={DEFAULTTEXT[shiftRef.current]} width={660} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={null} destroyOnClose>
      <EditShiftForm
        usersInfos={usersInfos}
        teamsInfos={teamsInfos}
        handleOk={handleOk}
        getData={getData}
        currentShiftParams={currentShiftParams}
        status={shiftRef.current}
      />
    </Modal>
  ];
};
