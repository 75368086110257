import { firestore } from "./../firebase";

const createIncident = (incident) => {
    const newIncident = firestore.collection('incidents').doc();
    return newIncident.set({
        ...incident,
        incidentID: newIncident.id
    })
}

const updateIncident = (incident) => {
    return firestore.collection("incidents").doc(incident.incidentID).update({ ...incident });
}

const getIncidents = () => {
    return firestore
        .collection("incidents")     
        .orderBy("dateCreated", 'desc')
        .get()
        .then(response => response.docs.map(document => {
            return document.data();
        }
        ));
};

export { createIncident, updateIncident, getIncidents };


//Supervisor incidents should conform to this:
// }
//     title: null,                  //
//     message: null,                //
//     status: "Active"              //Open,Actioned, Closed, Archived
//     classification: []            //Near Miss, Machine Incident, Human/ Injury
//     severity: "0"                 // 0 = lowest; 5 = higest
//     isImportant: true,            //
//     seenBy: [],                   //Array of users who have viewed this
//     info: {}                      //additional info based on type. eg report numbers, vehicles
// }
//
/*
CLoseing Classifications
1. Near Miss
2. FAC - First aid case
3. MTC- Medical treatment case
4. LTI - Lost time Injury
5. FAT- Fatality
6. Machine Damage
 */