import React, { useState } from "react";
import Styles from "./IncidentTable.module.scss";
import { CModal } from "@coreui/react";
import {Card, Table, Tag, Popover} from 'antd'
import LogIncidentForm from '../LogIncidentForm/LogIncidentForm';
import IncidentClosingInfo from '../IncidentClosingInfo/IncidentClosingInfo';
import CloseIncidentForm from '../CloseIncidentForm/CloseIncidentForm';
import { MANAGEMENT } from "../../enums/UserTypes";
import { CLOSED, OPEN, ARCHIVED } from "../../enums/IncidentStatuses";
import {Button} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";
import {getColumnSearchProps} from "../TableColumnSearch/TableColumnSearch";

const IncidentTable = (props) => {

  const { user, incidentCollection, role } = props;
  const [isShowing, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const toggle = () => {
    setShowModal(!isShowing);
  };

  const incidentFieldSettings = [
    {
      key: 'dateCreated',
      index: 'dateCreated',
      title: 'Timestamp',
      sorter: (a, b) => a.dateCreated - b.dateCreated,
      defaultSortOrder: 'descend',
      render: (_, record) => (
          <div>
            <span>{record.dateCreatedFormatted}</span>
            <Popover placement="right" content={() => <div className={Styles.messageContent}>{record.message}</div>} title="Message" trigger="hover">
            <Button icon={<InfoCircleOutlined/>} type={'text'} shape="circle" />
            </Popover>
          </div>
      )
    },
    {
      key: 'title',
      index: 'title',
      title: 'Title',
      render: (text, record) => record.title,
      sorter: (a, b) => a.title.localeCompare(b.title),
      ...getColumnSearchProps("title", "Title")
    },
    {
      key: 'isImportant',
      index: 'isImportant',
      title: 'Important',
      render: (_, record) => record.isImportant
    },
    {
      key: 'classification',
      index: 'classification',
      title: 'Classification',
      render: (_, record) => record.classification,
      ...getColumnSearchProps("classification", "Classification")
    },
    {
      key: 'status',
      index: 'status',
      title: 'Status',
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (_, record) => getIncidentStatusBadges(record),
      filters: [
        {
          text: 'Open',
          value: OPEN
        },
        {
          text: 'Closed',
          value: CLOSED
        },
        {
          text: 'Archived',
          value: ARCHIVED
        }
      ],
      defaultFilteredValue: [OPEN, CLOSED],
      onFilter: (value, record) => record.status === value,
    },
    {
      key: 'action',
      dataIndex: 'action',
      title: 'action',
      render: (_, record) => getCallToActionButtons(record)
    },
  ];

  // const inc

  // const incidentSorting = { column: 'dateCreated', desc: false }

  const incidentReformat = (incident) => {
    let closeInfo = {};
    if (incident.status === CLOSED && incident.closeInfo) {
      closeInfo = incident.closeInfo;
    }
    let isImportant = incident.isImportant === true ? "Yes" : "No";
    return ({
      key: incident.incidentID,
      dateCreatedFormatted: incident.dateCreated.toDate().toString().substring(0, 21),
      dateCreated: incident.dateCreated.toDate().getTime(),
      title: incident.title,
      message: incident.message,
      status: incident.status,
      isImportant: isImportant,
      classification: incident.classification,
      incidentID: incident.incidentID,
      dateCreatedOrig: incident.dateCreated.toDate(),
      closeInfo: closeInfo,
      oincident: incident
    })
  }
  const toggleCloseIncidenForm = (incident) => {
    setModalContent(<CloseIncidentForm user={user} showModal={setShowModal} incident={incident} />)
    toggle();
  }
  const toggleViewCloseInfoForm = (incident) => {
    setModalContent(<IncidentClosingInfo user={user} showModal={setShowModal} incident={incident} />)
    toggle();
  }
  const toggleLogIncidentForm = () => {
    setModalContent(<LogIncidentForm user={user} showModal={setShowModal} />)
    toggle();
  }
  const getCallToActionButtons = (item) => {
    if (role === MANAGEMENT && item.status === OPEN) {
      return (
          <Button type='primary' onClick={() => toggleCloseIncidenForm(item)}>Close</Button>
      );
    }
    else if (item.status === CLOSED) {
      return (

          <Button type='primary' onClick={() => toggleViewCloseInfoForm(item)}>View</Button>
      );
    }
    return null;
  };
  const getIncidentStatusBadges = (item) => {

    if (item.status === OPEN) {
      const now = new Date();
      const diff = now.getTime() - item.dateCreatedOrig.getTime();
      let s = "Open";
      //if incident was opened in the last (24h = 86400000ms) - change status text to 'New'
      if (diff !== undefined && diff < 86400000) {
        s = "New";
      }
      if (item.isImportant === "No") {
        return (
            <Tag color={'green'}>{s}</Tag>
        );
      } else {
        return (
            <Tag color={'purple'}>{s}</Tag>
        );
      }
    }
    else if (item.status === ARCHIVED) {
      return (
          <Tag color={'orange'}>{item.status}</Tag>
      );
    }
    else {
      return (
          <Tag color={'blue'}>{item.status}</Tag>
      );
    }
  };

  // eslint-disable-next-line no-unused-vars
  let list;

  return (
    <>
      <Card title='Incidents' bodyStyle={{padding: 0}} extra={
        <Button type={"primary"} icon={<InfoCircleOutlined/>} onClick={() => toggleLogIncidentForm()}>Report An Incident</Button>
      }>
        <Table
            columns={incidentFieldSettings}
            sortDirections={['ascend', 'descend', 'ascend']}
            dataSource={incidentCollection.map(incidentReformat)}
            pagination={{size: 'small'}}
            scroll={{x:true}}
        />
      </Card>
      <CModal
        show={isShowing}
        size="lg"
        onClose={() => {
          setShowModal(false);
        }}
      >
        {modalContent}
      </CModal>
    </>
  );
};

export default IncidentTable;
