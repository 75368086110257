import React, { useState, useEffect } from 'react';
import useModal from '../../../components/Modal/useModal';
import CreateTeamForm from './CreateTeamForm';
import TeamItem from './TeamItem';
import { getTeams, subscribeToTeams } from '../../../services/TeamsService';
import {CCard, CCardBody, CCardHeader, CCardText, CModal} from "@coreui/react";

const ManageTeams = () => {
    const {isShowing, toggle} = useModal();
    const [teamsArr, setTeamsArr] = useState([]);
    const [teamNamesArr, setTeamNamesArr] = useState([null]);
    const [filteredTeamsArr, setFilteredTeamsArr] = useState([]);
    const [subTeam, setSubTeam] = useState([false]);

    useEffect(() => {
        getTeams().then(response => {
            setFilteredTeamsArr(response);
            setTeamsArr(response);
            setTeamNamesArr([...new Set(response.map(team => team.teamName))]);
        });
        return subscribeToTeams(setTeamsArr);
    }, [])

    useEffect(() => {
        if (teamsArr) {
            setFilteredTeamsArr(teamsArr);
            setTeamNamesArr([...new Set(teamsArr.map(team => team.teamName))])
        }
    }, [teamsArr])

    const filterTeams = (teamName) => {
        if (teamName) {
        setFilteredTeamsArr(teamsArr.filter((each) => each.teamName === teamName))
        } else {
        setFilteredTeamsArr(teamsArr)
        }
    };

    return (
        <>
            <CCard className={``}>
                <CCardHeader className={'d-flex align-items-center justify-content-between'}>
                    Teams
                    <div className={'card-header-actions align-items-center justify-content-center d-flex'}>
                        <div className={'p-2'}>
                            <button className="btn-primary btn" onClick={()=>{ setSubTeam(false); toggle() } }>Create New Parent Team</button>
                        </div>
                        <div className={'p-2'}>
                            <button className="btn-primary btn" onClick={()=>{ setSubTeam(true); toggle(); }}>Create Sub Team</button>
                        </div>
                        <div className={'p-2'}>
                            <select className="selectPrimary custom-select" onChange={(event) => filterTeams(event.target.value)}>
                                <option value="">All Teams</option>
                                {teamNamesArr ? teamNamesArr.map((team) => <option key={team} value={team}>{team}</option>) : null}
                            </select>
                        </div>
                    </div>

                </CCardHeader>

                <CCardBody className="">
                    <CCardText tag={'div'}>
                        <div className={`position-relative table-responsive`}>
                            <table className={`table table-hover`} >
                                <thead key="titles" className="">
                                <tr>
                                    <th className="border-top-0">ID</th>
                                    <th className="border-top-0">Site</th>
                                    <th className="border-top-0">Team Name</th>
                                    <th className="border-top-0">Sub Team Name</th>
                                    <th className="border-top-0">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {filteredTeamsArr.map((team) => <TeamItem key={team.teamID} team={team} />)}
                                </tbody>
                            </table>
                        </div>
                    </CCardText>
                </CCardBody>

            </CCard>

            <CModal show={isShowing} size={'lg'} >
                {<CreateTeamForm hide={toggle} subteam={subTeam} />}
            </CModal>

        </>
)
}

export default ManageTeams;
