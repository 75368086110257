import React, {useEffect, useState, Fragment, useContext, useRef} from "react";
import Styles from "./ChecklistHistory.module.scss";
import {Drawer, Card, Tag, Table, Button, Divider, Spin, Descriptions, Empty} from 'antd'
import {getColumnSearchProps} from "../../../../components/TableColumnSearch/TableColumnSearch";
import {subscribeVehicleChecklistsByVehicleId} from "../../../../services/VehiclesChecklistService";
import {subscribeVehicleByVehicleID} from "../../../../services/VehiclesService";
import { ACTIVE, OPEN, CLOSED } from "../../../../enums/VehicleChecklistStatuses";
import VehiclePageHeader from '../VehiclePageHeader'
import InspectionHistory from "./InspectionHistory";
import {AVAILABLE} from "../../../../enums/VehicleStatuses";
import {subscribeVehicleIssuesByVehicleID} from "../../../../services/MaintenanceJobsService";

const ChecklistHistory = (props) => {

    const {vehicleID} = props
    const [vehicleInfo, setVehicleInfo] = useState({})
    const [vehicleChecklist, setVehicleChecklist] = useState([])
    const [issues, setIssues] = useState([])
    const [vehicleInfoLoading, setVehicleInfoLoading] = useState(false)
    const [vehicleChecklistLoading, setVehicleChecklistLoading] = useState(false)
    const [issuesLoading, setIssuesLoading] = useState(false)
    const [vehicleCheckDetail, setVehicleCheckDetail] = useState({
        detail: {},
        visible: false,
        isPrint: false
    })

    useEffect(() => {
        setVehicleInfoLoading(true)
        setVehicleChecklistLoading(true)
        setIssuesLoading(true)

        subscribeVehicleByVehicleID(vehicleID, (result) => {
            if(result.length > 0){
                setVehicleInfo(result[0])
            }
            setVehicleInfoLoading(false)
        })

        subscribeVehicleChecklistsByVehicleId(vehicleID, (result) => {
            setVehicleChecklist(result)
            setVehicleChecklistLoading(false)
        })

        subscribeVehicleIssuesByVehicleID(vehicleID, 'Open' , (items)=>{
            setIssues(items)
            setIssuesLoading(false)
        })


    }, [])

    const vehicleChecklistField = [
        {
            key: 'submissionDate',
            dataIndex: 'submissionDate',
            title: 'Submission Date',
            sorter: (a, b) => a.dateCreated - b.dateCreated
        },
        {
            key: 'submittedBy',
            dataIndex: 'submittedBy',
            title: 'Submitted By',
            sorter: (a, b) => a.submittedBy?.localeCompare(b.submittedBy),
            ...getColumnSearchProps("submittedBy", "Submitted By"),
        },
        {
            key: 'status',
            dataIndex: 'status',
            title: 'Status',
                render: (text, record) => {
                if(text === CLOSED){
                    return <Tag color={"gray"}>{text}</Tag>
                }
                else if (text === OPEN){
                    return <Tag color={"blue"}>{text}</Tag>
                }
                else if (text === ACTIVE){
                    return <Tag color={'green'}>{text}</Tag>
                }
                else if(text){
                    return <Tag>{text}</Tag>
                }
            }
        },
        {
            key: 'approvedBy',
            dataIndex: 'approvedBy',
            title: 'Approved By',
            ...getColumnSearchProps("approvedBy", "Approved By"),
        },
        {
            key: 'failedItems',
            dataIndex: 'failedItems',
            title: 'Failed Items',
            render: (_, record) => {
                let count = 0;
                record.checkItems && record.checkItems.length && record.checkItems.map(item =>{
                        if(item.supervisorCheckItemApproved){
                            count++
                        }
                    }
                )
                return <Tag>{count}</Tag>
            }
        },
        {
            key: 'action',
            dataIndex: 'action',
            title: 'action',
            render: (_, record) =>
                <div>
                    <Button onClick={() => {
                        setVehicleCheckDetail({
                            detail: record,
                            visible: true
                        })
                    }} type={"link"}>View</Button>
                    <Divider type={"vertical"}/>
                    <Button type={"link"} onClick={() => {
                        setVehicleCheckDetail({
                            detail: record,
                            visible: true,
                            isPrint: true
                        })
                    }}>Print</Button>
                </div>,
        },
    ]

    const vehicleCheckListReformat = (check) => {
        return ({
            ...check,
            key: check.id,
            id: check.id,
            submissionDate: check.dateCreatedStr,
            dateCreated: check.dateCreated?.seconds,
            submittedBy: check.addedByDriver?.fullName,
            status: check.status,
            approvedBy: check.actionedBy?.fullName,
        })

    }

    return (
        <Fragment>
            {
                !vehicleCheckDetail.visible &&
                    <Fragment>
                        <Card bodyStyle={{padding: 0}}>
                            <VehiclePageHeader
                                title="Checklist History"
                                vehicleID={vehicleID}
                            />
                            <Divider className={Styles.headerDivider}/>
                            <Spin spinning={vehicleInfoLoading}>
                                <div className={Styles.vehicleInfo}>
                                    <Descriptions title={vehicleID} column={1}>
                                        <Descriptions.Item label="Type">{vehicleInfo.vehicleType}</Descriptions.Item>
                                        <Descriptions.Item label="Current Team">{vehicleInfo.currentTeam}</Descriptions.Item>
                                        {vehicleInfo.userIsOnShift && <Descriptions.Item label="Current User">{vehicleInfo.currentUser}</Descriptions.Item>}
                                        <Descriptions.Item label="Current Status">{vehicleInfo.currentStatus === "Parked" && vehicleInfo.userIsOnShift ? "On Shift" : vehicleInfo.currentStatus}</Descriptions.Item>
                                        <Descriptions.Item label="Vehicle Status">{vehicleInfo?.vehicleStatus?.toLocaleLowerCase() === AVAILABLE.toLocaleLowerCase() ?
                                            <Tag color={"green"}>{vehicleInfo.vehicleStatus}</Tag> :
                                            <Tag color={"gray"}>{vehicleInfo.vehicleStatus}</Tag>}</Descriptions.Item>
                                    </Descriptions>
                                </div>
                            </Spin>
                        </Card>
                        <Card title={`Open Issues ${issues.length}`} bodyStyle={{padding: 0}}>
                            <Spin spinning={issuesLoading}>
                                <div className={Styles.issueList}>
                                    {
                                        issues.length === 0 && <Empty/>
                                    }
                                    {
                                        issues.map(item =>
                                            <div className={Styles.issueItem} key={item.issueID}>
                                                <div className={Styles.issueTitle}>{item.issue}</div>
                                                <div className={Styles.issueMessage}>{`Reported By ${item.operatorFullName}`}</div>
                                                <div className={Styles.issueDate}>{item.dateCreatedStr}</div>
                                                <Divider/>
                                            </div>
                                        )
                                    }
                                </div>
                            </Spin>
                        </Card>
                        <Card title={'Checklist History'} bodyStyle={{padding: 0}}>
                            <Table
                                loading={vehicleChecklistLoading}
                                columns={vehicleChecklistField}
                                sortDirections={['ascend', 'descend', 'ascend']}
                                dataSource={vehicleChecklist.map(vehicleCheckListReformat)}
                                pagination={{size: 'small', defaultPageSize: 30}}
                                scroll={{x:true}}
                            />
                        </Card>
                    </Fragment>
            }
            {
                vehicleCheckDetail.visible &&
                <InspectionHistory
                    onClose={() => setVehicleCheckDetail({
                        visible: false
                    })}
                    isPrint={vehicleCheckDetail.isPrint}
                    vehicleID={vehicleID}
                    vehicleCheck={vehicleCheckDetail.detail}
                />
            }
        </Fragment>
    );
};

export default ChecklistHistory;
