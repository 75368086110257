import { firebaseAuth, firestore } from "../firebase";

const getUsers = () => {
  return firestore.collection("users")
    .get()
    .then(querySnapshot => {
      return querySnapshot.docs.map(doc => doc.data());
    });
}

const getUser = (userID) => {
    return firestore
        .collection("users")
        .where('userID', '==', userID)
        .get()
        .then(result => {
            return result.docs.map(doc => {
                return { ...doc.data(), id: doc.id }
            });
        })
        .then(list => list[0]);
}

const subscribeToUsers = (setState) => {
  return firestore.collection("users").onSnapshot(snapshot => setState(snapshot.docs.map(document => document.data())))
}
const createUser = (newUser) => firestore.collection("users").doc(newUser.userID).set({ ...newUser });

const createAuthUser = async (username, password) => {

  let email;

    if (username.match(/@/)) {
        email = username;
    } else {
        email = `${username}@shiftreporter.com`;
    }

    await firebaseAuth.auth().createUserWithEmailAndPassword(email, password)
        .then(response => {
            return firestore
                .collection("users")
                .doc(username)
                .set({ authID: response.user.uid }, { merge: true });

        })
        .catch((error) => {
            console.error(error.code, error.message)
        })


    return firebaseAuth.auth().signOut()
        .then(() => {
            console.log('Auth user signout')
        }).catch((err) => {
            console.error('Auth sign out error')
        })

}

// reset user password
const resetPassword = async ({ userID }) => {
    const email = userID.match(/@/) ? userID : `${userID}@shiftreporter.com`;
    await firebaseAuth.auth()
        .sendPasswordResetEmail(email)
        .then((res) => {
            console.log(res);
        }).catch(error => {
            console.log(error);
        })
}

const updateUser = (updatedUser) => {
    return firestore
        .collection("users")
        .doc(updatedUser.userID)
        .update({ ...updatedUser })
};

const deleteUser = (user) => firestore.collection("users").doc(user.userID).delete();

const getOperators = () => {
  return firestore
    .collection("users")
    .where('userType', '==', "operator")
    .get()
    .then(querySnapshot => {
      return querySnapshot.docs.map(doc => doc.data());
    });
}

const subscribeToOperators = (setState) => {
    return firestore
        .collection("users")
        .where('userType', '==', "operator")
        .onSnapshot(snapshot => setState(snapshot.docs.map(document => document.data())));
}

const getTeamSupervisor = (operatorTeam, operatorSubTeam) => {
    return firestore
        .collection("users")
        .where('userType', '==', "supervisor")
        .where("currentTeam", "==", `${operatorTeam}`)
        .where("currentSubTeam", "==", `${operatorSubTeam}`)
        .get()
        .then(querySnapshot => {
            return querySnapshot.docs.map(doc => doc.data());
        });
}

export { getUser, getUsers, subscribeToUsers, createUser, updateUser, deleteUser, resetPassword, getOperators, getTeamSupervisor, subscribeToOperators, createAuthUser };