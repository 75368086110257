import React, {useEffect, useState, Fragment, useContext} from "react";
import Styles from "./IndividualIssue.module.scss";
import {PageHeader, Card, Tag, Divider, Descriptions} from 'antd'
import {UserContext} from "../../../../../context/userContext";
import {AVAILABLE} from "../../../../../enums/VehicleStatuses";
import {getUser} from "../../../../../services/UsersService";

const IndividualIssue = (props) => {

    const {onClose, issuesDetail, vehicleInfo} = props
    const [mechanicFullName, setMechanicFullName] = useState('')

    let type = ''
    if(issuesDetail.type === 'reportedIssue'){
        type = 'Reported Issue'
    }
    else if(issuesDetail.type === 'checklistItem'){
        type = 'Checklist Item'
    }


    const getUserDetails = async( userID ) =>{
        return await getUser(userID).then(user => {
            return (user && user.fullNameStr) ? user.fullNameStr : userID;
        });
    }
    useEffect(() => {
        getUserDetails( issuesDetail.maintenanceSignoff.completedBy  ).then((user)=>{
            setMechanicFullName( user )
            }
        );
    },[])

    return (
        <Fragment>
            <Card bodyStyle={{padding: 0}}>
                <PageHeader
                    onBack={onClose}
                    title={'Individual Issue'}
                />
                <Divider className={Styles.headerDivider}/>
                <div className={Styles.vehicleInfo}>
                    <Descriptions title={vehicleInfo.id} column={1}>
                        <Descriptions.Item label="Vehicle">{vehicleInfo.vehicleID}</Descriptions.Item>
                        <Descriptions.Item label="Type">{vehicleInfo.vehicleType}</Descriptions.Item>
                        <Descriptions.Item label="Current Team">{vehicleInfo.currentTeam}</Descriptions.Item>
                        <Descriptions.Item label="Current Status">{vehicleInfo.currentStatus}</Descriptions.Item>
                        <Descriptions.Item label="Vehicle Status">{vehicleInfo?.vehicleStatus?.toLocaleLowerCase() === AVAILABLE.toLocaleLowerCase() ?
                            <Tag color={"green"}>{vehicleInfo.vehicleStatus}</Tag> :
                            <Tag color={"gray"}>{vehicleInfo.vehicleStatus}</Tag>}</Descriptions.Item>
                    </Descriptions>
                </div>
            </Card>
            <Card>
                <Descriptions title={'Details'} column={1}>
                    <Descriptions.Item label="Issue ID">{issuesDetail.issueID}</Descriptions.Item>
                    <Descriptions.Item label="Issue">{issuesDetail.issue}</Descriptions.Item>
                    <Descriptions.Item label="Summary">{issuesDetail.maintenanceSignoff?.otherInfo}</Descriptions.Item>
                    <Descriptions.Item label="Status">{issuesDetail.status}</Descriptions.Item>
                    <Descriptions.Item label="Reported by">{issuesDetail.operatorFullName}</Descriptions.Item>
                    <Descriptions.Item label="Assigned to">{mechanicFullName}</Descriptions.Item>
                    <Descriptions.Item label="Approved by">{issuesDetail.supervisorFullName}</Descriptions.Item>
                    <Descriptions.Item label="Source">{type}</Descriptions.Item>
                    <Descriptions.Item label="Job card Information">{
                        issuesDetail.maintenanceSignoff &&
                        <div>
                            <div>
                                <span>Fix Time: </span><span>{issuesDetail.maintenanceSignoff.fixTime}</span>
                            </div>
                            <div>
                            <span>Parts Used:</span><span>{issuesDetail.maintenanceSignoff.partsUsed && issuesDetail.maintenanceSignoff.partsUsed.length &&
                            issuesDetail.maintenanceSignoff.partsUsed.map(item => <span>{item}</span>)
                            }</span>
                            </div>
                            <div>
                            <span>Root Cause: </span><span>{issuesDetail.maintenanceSignoff.rootCause}</span>
                            </div>
                            <div>
                            <span>Other Info:</span><span>{issuesDetail.maintenanceSignoff.otherInfo}</span>
                            </div>
                        </div>
                    }</Descriptions.Item>
                </Descriptions>
            </Card>
        </Fragment>
    );
};

export default IndividualIssue;
