import React from "react";
import Styles from "./VehicleTable.module.scss";
import { CCard, CCardBody, CCardHeader, CCardText, CBadge, CDataTable } from "@coreui/react";
import underscore from "underscore";
import { AVAILABLE, NOT_AVAILABLE } from "../../../enums/VehicleStatuses";
const VehicleTable = (props) => {

  const { filteredVehiclesArr } = props;

  const vehicleFieldSettings = [
    { key: 'vehicleID', label: 'Vehicle ID', sorter: false },
    { key: 'vehicleType', label: 'Vehicle Type', _classes: 'sorter-left' },
    { key: 'vehicleStatus', label: 'Vehicle Status', _classes: 'sorter-left' },
    { key: 'status', label: 'Current Status', _classes: 'sorter-left' },
    { key: 'currentTeam', label: 'Current Team', sorter: false },
    { key: 'currentUser', label: 'Current User', sorter: false },
  ];

  const vehicleSorting = { column: 'status', asc: 'true' }

  const vehicleReformat = (vehicle) => {

    let vehicleClasses = {};
    let isUserOnShift = vehicle.userIsOnShift ? vehicle.userIsOnShift : false;
    let vehicleStatusText = vehicle.vehicleStatus ? vehicle.vehicleStatus : "-";
    let lowerVehicleStatus = vehicle?.vehicleStatus?.toLowerCase() ?? vehicle?.goStatus?.toLowerCase();
    let goStatusText = isUserOnShift && lowerVehicleStatus !== 'no go' ? "On Shift" : 'Parked';

    if (vehicle && vehicle.checkItems) {
      let vehicleIssues = vehicle.checkItems.filter((checkItem) => (checkItem.maintenanceSignoff === false && checkItem.supervisorCheckItemApproved === true))
      vehicleClasses = underscore.groupBy(vehicleIssues, 'classType')

    }

    let additionalClasses;
    if (vehicleStatusText.toLowerCase() === NOT_AVAILABLE.toLowerCase()) {
      additionalClasses = 'not-available';
    } else if (isUserOnShift && vehicleStatusText.toLowerCase() === AVAILABLE.toLowerCase()) {
      additionalClasses = 'on-shift-available';
    } else {
      additionalClasses = 'default-state'
    }

    return ({
      _classes: Styles[additionalClasses],
      vehicleID: vehicle.vehicleID,
      vehicleType: vehicle.vehicleType,
      vehicleStatus: vehicleStatusText,
      vehicleClasses: vehicleClasses,
      status: goStatusText,
      currentTeam: vehicle.currentTeam ? vehicle.currentTeam : "No team",
      currentUser: isUserOnShift ? (vehicle.user ? vehicle.user.fullNameStr : vehicle.currentUser) : '-'
    })
  }

  // eslint-disable-next-line no-unused-vars
  let list;

  return (
    <>

      <CCard>
        <CCardHeader className="d-flex align-items-center justify-content-between">
          Team Vehicles
          <div className="card-header-actions align-items-center justify-content-center d-flex">
            <div className={'p-2'}>Total</div>
            <div className={'badge badge-info p-3'}>{filteredVehiclesArr.length}</div>
            <div className={'p-2'}>Working</div>
            <div className={'badge badge-info p-3'}>{filteredVehiclesArr.filter(v => v.status === "Available").length}</div>
            <div className={'p-2'}>Break-down</div>
            <div className={'badge badge-info p-3'}>{filteredVehiclesArr.filter(v => v.status !== "Available").length}</div>
          </div>
        </CCardHeader>
        <CCardBody>
          <CCardText tag="div">

            <div className="position-relative table-responsive" ref={element => { list = element }}>

              <CDataTable items={filteredVehiclesArr.map(vehicleReformat)}
                fields={vehicleFieldSettings}
                addTableClasses={Styles.tableContainer}
                itemsPerPage={10}
                pagination={{ addListClass: Styles.paginationContainer }}
                sorter responsive={true}
                sorterValue={vehicleSorting}
                hover={true}
                scopedSlots={{
                  'vehicleStatus':
                    (item) => (
                      <td>
                        <div className="d-flex align-items-center">

                          <div className="flex-fill">
                            {item.vehicleStatus}
                          </div>

                          <div>

                            {item.vehicleClasses && item.vehicleClasses.classA &&
                              <CBadge color="danger" className="text-white p-2 mx-1 font-weight-normal">
                                Class A <span className="bg-white text-danger badge-pill mx-1">{item.vehicleClasses.classA.length}</span>
                              </CBadge>
                            }

                            {item.vehicleClasses && item.vehicleClasses.classB &&
                              <CBadge color="warning" className="text-white p-2 mx-1 font-weight-normal">
                                Class B <span className="bg-white text-warning badge-pill mx-1">{item.vehicleClasses.classB.length}</span>
                              </CBadge>
                            }

                            {item.vehicleClasses && item.vehicleClasses.classC &&
                              <CBadge color="info" className="text-white p-2 mx-1 font-weight-normal">
                                Class C <span className="bg-white text-info badge-pill mx-1">{item.vehicleClasses.classC.length}</span>
                              </CBadge>
                            }

                          </div>

                        </div>
                      </td>
                    ),
                }}
              />

            </div>
          </CCardText>
        </CCardBody>
      </CCard>

    </>
  );
};

export default VehicleTable;
