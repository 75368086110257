import React from "react";
import Styles from "./UserTable.module.scss";
import {DateUtil} from "../../../utilities/DateUtil";
import {Card, Badge, Space, Table} from 'antd'
import {getColumnSearchProps} from "../../../components/TableColumnSearch/TableColumnSearch";

const UserTable = (props) => {

    const userData = props.filteredUsersArr;
    const badgeColor = '#32323299'

    const userFieldSettings = [
        {
            key: 'name',
            dataIndex: 'name',
            title: 'Name',
            render: (text, record) => <span className={record._classes}>{text}</span>,
            ...getColumnSearchProps("name", "Name"),
        },
        {
            key: 'vehicle',
            dataIndex: 'vehicle',
            title: 'Assigned Vehicle',
            render: (text, record) => <span className={record._classes}>{text}</span>
        },
        {
            key: 'type',
            dataIndex: 'type',
            title: 'User Type',
            sorter: (a, b) => a.type.localeCompare(b.type),
            render: (text, record) => <span className={record._classes}>{text}</span>
        },
        {
            key: 'userID',
            dataIndex: 'userID',
            title: 'User ID',
            render: (text, record) => <span className={record._classes}>{text}</span>
        },
        {
            key: 'status',
            dataIndex: 'status',
            title: 'Status',
            sorter: (a, b) => a.status.localeCompare(b.status),
            defaultSortOrder: 'descend',
            render: (text, record) => <span className={record._classes}>{text}</span>
        },
        {
            key: 'shiftStart',
            dataIndex: 'shiftStart',
            title: 'Shift Start',
            render: (text, record) => <span className={record._classes}>{text}</span>
        },
        {
            key: 'shiftEnd',
            dataIndex: 'shiftEnd',
            title: 'Shift End',
            render: (text, record) => <span className={record._classes}>{text}</span>
        },
    ]

    const userReformat = (user) => {

        return (
            {
                key: user.userID,
                _classes : user.isOnShift ? Styles.onShift : Styles.unAvailable,
                name: user.fullNameStr,
                vehicle: user.assignedVehicle ? user.assignedVehicle : '',
                type: user.userType,
                userID: user.userID,
                isOnShift: user.isOnShift,
                status: user.isOnShift ? "On Shift" : "Not On Shift",
                shiftStart: user.startShiftTime ? DateUtil(user.startShiftTime.toDate()) : '',
                shiftEnd: user.endShiftTime && user.endShiftTime > user.startShiftTime ? DateUtil(user.endShiftTime.toDate()) : '',
            }
        )

    }

    return (
        <Card title={'Team Members'} bodyStyle={{padding: 0}} extra={(
            <Space size={16}>
                <span>
                    <Badge color={badgeColor} text="Total"/>
                    <span className={Styles.countValue}>{userData && userData.length}</span>
                </span>
                <span>
                    <Badge color={badgeColor} text="On Shift"/>
                    <span className={Styles.countValue}>{userData && userData.filter(user => user.isOnShift).length}</span>

                </span>
                <span>
                    <Badge color={badgeColor} text="Away"/>
                    <span className={Styles.countValue}>{userData && userData.filter(user => !user.isOnShift).length}</span>
                </span>
            </Space>
        )}>
            <Table
                columns={userFieldSettings}
                sortDirections={['ascend', 'descend', 'ascend']}
                dataSource={userData.map(userReformat)}
                pagination={{size: 'small'}}
                scroll={{x:true}}
            />

        </Card>
    );
};

export default UserTable;
