import firebase, { firestore } from "./../firebase";
import { v5 as uuidv5 } from "uuid";
import {chunk} from 'lodash';
import { DateUtil } from "../utilities/DateUtil";
import { getVehicleChecklists } from "./VehiclesChecklistService";
import { GetVehicleIssues } from "./MaintenanceJobsService";
import { APPROVED, PENDING } from "../enums/VehicleChecklistStatuses";
import { MAINTENANCE } from "../enums/MaintenanceJobsStatuses";
import { GO_BUT_STATUS, GO_STATUS, NOGO_STATUS } from "../enums/VehicleStatuses";

import {uniqBy} from 'lodash';

const uuidNamespace = '2b9459e8-2164-41e6-bfa5-a06232e5d059';

const getVehicles  = () => {
    return firestore.collection("vehicles").get().then(response => response.docs.map(document => ({...document.data(), id: document.id})));
}

const getVehiclesByIds = ( vehicleIDList = [] )=>{
    
    const collectionPath = firestore.collection("vehicles");
    let batches = [];

    while (vehicleIDList.length) {
      // firestore limits batches to 10
      const batch = vehicleIDList.splice(0, 10);

      // add the batch request to to a queue
      batches.push(
        new Promise(response => {
          collectionPath
            .where(
                "vehicleID",
              'in',
              [...batch]
            )
            .get()
            .then(results => response(results.docs.map(document => ({ ...document.data() , id: document.id}) )))
        })
      )
    }

    // after all of the data is fetched, return it
    return Promise.all(batches).then( content => content.flat() )

}

/**
 * For each vehicle attach user object if the vehicle is currently assigned to a user
 * Comment
 * @param {Array} vehicles - List of vehicles
 * @returns {Promise<Array>}
 * @private
 */
async function _attachUserObjectToVehiclesWithCurrentUsers(vehicles = []) {
  const vehiclesWithAssignedUsers = vehicles.filter((vehicle) => !!vehicle.currentUser);
  const userIds = vehiclesWithAssignedUsers.map((vehicle) => vehicle.currentUser);
  const chunks = chunk(userIds, 10);
  let users = [];
  for (let i = 0; i < chunks.length; i++) {
    const querySnapshot = await firestore.collection("users")
      .where('userID', 'in', chunks[i])
      .get();
    users = users.concat(querySnapshot.docs.map(doc => doc.data()));
  }

  vehicles.forEach((vehicle) => {
    const matchingUser = users.find((user) => user.userID === vehicle.currentUser);
    vehicle.user = matchingUser ? matchingUser : null;

    GetVehicleIssues( vehicle.vehicleID )
    .then((items)=>{
        vehicle.openIssues = items;
    })

  });
  return vehicles;
}

/**
 * Retrieve a list of vehicles with user object assigned to each vehicle that has a user assigned
 * @returns {Promise<Array>}
 */
const getVehiclesWithUsersAssigned = async () => {
  const response = await firestore.collection("vehicles").get();
  const vehicles = response.docs.map(document => document.data());  
  return await _attachUserObjectToVehiclesWithCurrentUsers(vehicles);
};

/**
 * Subscribe to vehicles collection changes and attach user object to vehicles with current user set
 *
 * @param {Function} setState - function used to schedule updates to the component state
 * @returns {Function}
 */
const subscribeToVehiclesWithUsersAssigned = (setState) => {
  return firestore.collection("vehicles").onSnapshot(async (snapshot) => {
    const vehicles = snapshot.docs.map(document => document.data());
    const updatedVehicles = await _attachUserObjectToVehiclesWithCurrentUsers(vehicles);
    setState(updatedVehicles);
  });
};




        const subscribeToUserAssignedVehicle = (vehicleID, setState) => {

            return firestore.collection("vehicles")
            .where("vehicleID", '==', vehicleID)
            .onSnapshot(snapshot =>
                {
        
                    let resultDoc = snapshot.docs.map(document => document.data())
                    resultDoc = resultDoc.length ? resultDoc[0] : null;
        
                    if( resultDoc ){
                        setState(resultDoc);
                    }
        
                }
            );
        };

// const subscribeToUserAssignedVehicle = (vehicleID, setState) => {

//     return firestore.collection("vehicles")
//     .onSnapshot(snapshot => setState(
//         snapshot
//         .docs
//         .map((document) => {

//             let vehicle = ({...document.data(), id: document.id});
//             //get checkitems
//             GetVehicleIssues( vehicle.vehicleID )
//             .then((items)=>{
//                 let _checkItems = [];            
//                 items.map( (item) =>_checkItems.push(...item.classType) )
//                 vehicle.openIssues = _checkItems;
//             })

//             return vehicle

//         } )
//     ));
// };

const getVehicleByID  = (vehicleID) => {
    return firestore.collection("vehicles").doc(vehicleID).get().then(doc => doc.data());
}


const getVehicleTypes = () => {
    return firestore
        .collection("checklistData")
        .get()
        .then(res => {
            return res.docs.map(doc => doc.id)
    });
}

//watches vehicles collection and updates the state whenever the db changes
const subscribeToVehicles = (setState) => {
    return firestore.collection("vehicles")
        .onSnapshot(snapshot => setState(
            snapshot
            .docs
            .map((document) => ({...document.data(), id: document.id}) )
        ));
}

const createVehicle  = (vehicle) => firestore.collection("vehicles").doc(vehicle.vehicleID).set({...vehicle});

const updateVehicle  = (vehicle) => {

    return firestore
        .collection("vehicles")
        .doc(vehicle.vehicleID)
        .set({...vehicle},{merge: true})
        .catch(err => console.error('updateVehicle Error', vehicle, err))

};

const deleteVehicle  = (vehicle) => firestore.collection("vehicles").doc(vehicle.vehicleID).delete();

const setVehicleIssues = (vehicle, vehicleData) => {
    //if gostatus is go, add timestamp
    return firestore
        .collection("vehicles")
        .doc(vehicle)
        .set(vehicleData, {merge: true})
        .catch(err => console.error('setVehicleIssues', err))
}

const setVehicleShiftLog = ( user ) => {

    let createdDate = new Date();

    let startShiftTime = user.startShiftTime || new Date();
    let hasStartShiftTime = !!user.startShiftTime;
    let endShiftTime = user.endShiftTime || new Date();
    let currentShiftId = user.currentSID ?? '';

    let vehicleShiftDetail = {
        created_date: createdDate,
        createdDateStr: DateUtil(createdDate),
        userID: user.userID,
        endShiftTime: endShiftTime,
        startShiftTime: startShiftTime,
        currentShiftId : currentShiftId
    };

    //for now we assume that the start date is unique per entry
    let startShiftConvertToDate = hasStartShiftTime ?  new Date(startShiftTime) : startShiftTime;
    let vehicleLogUUID = uuidv5([vehicleShiftDetail.userID, startShiftConvertToDate].join(''), uuidNamespace);//starttime + vid + uid

    if( user.assignedVehicle ) {

        vehicleShiftDetail = { ...vehicleShiftDetail, vehicleID: user.assignedVehicle };
        vehicleLogUUID = uuidv5([vehicleShiftDetail.vehicleID, vehicleShiftDetail.userID, startShiftConvertToDate].join(''), uuidNamespace);//starttime + vid + uid

    }

    return firestore.collection("vehicleShiftLogs")
        .doc( vehicleLogUUID )
        .set({...vehicleShiftDetail}, {merge : true})
}

const setVehicleStatus = ( vehicle, status ) => {

    firestore
        .collection("vehicles")
        .doc(vehicle.vehicleID)
        .set({
            status: status,
        }, {merge: true})

}

const refreshCheckItemIssues = ( issues ) => {

    let fatalClasslist =  false;
    let goStatus = GO_STATUS;

    //GO_BUT_STATUS, GO_STATUS, NOGO_STATUS

    if (issues.some(item => !item.supervisorSignoff &&  item.classType === "classA")) {
        goStatus = NOGO_STATUS;
        fatalClasslist = true;
    } else if (issues.some(item => !item.supervisorSignoff && item.classType === "classB") || issues.some(item => !item.supervisorSignoff && item.classType === "classC")) {
        goStatus = GO_BUT_STATUS;
    }

    return {
        status: fatalClasslist ? 'Not Available' : 'Available',
        goStatus: goStatus,
        checkItems: issues
    }

}

// toDo: 
// FirebaseError: Function DocumentReference.set() called with invalid data. Unsupported field value: undefined (found in document vehicles/003)
const subscribeVehicleByVehicleID = (vehicleID, setVehicle) => {
    return firestore
        .collection("vehicles")
        .where('vehicleID', '==', vehicleID)
        .onSnapshot(querySnapshot => {
            const data = querySnapshot.docs.map(doc => doc.data());
            setVehicle(data);
        });
    }

const getVehicleUserOnShift  = (vehicleID) => {

    return firestore
    .collection("users")
    .where('assignedVehicle', '==', vehicleID)
    .where('isOnShift', '==', true)
    .where('userType', '==', 'operator')
    .get()
    .then(querySnapshot => querySnapshot.docs.map(doc => doc.data()));
}

const subscribeToUserVehicleStatus = (setState, assignedVehicle ) => {

    return firestore.collection("vehicles")
        .where('vehicleID', '==', assignedVehicle)
        .onSnapshot(snapshot => {

                let resultDoc = snapshot.docs.map(document => document.data())
                resultDoc = resultDoc.length ? resultDoc[0] : null;

                let goStatus = resultDoc ? resultDoc.goStatus : null;
                let status= resultDoc ? resultDoc.status : null;

                let statuses = { goStatus, status };

                if( statuses ){

                    setState(statuses)

                }

            }
        );

}

const subscribeToUserVehicle = (setState, assignedVehicle ) => {

    return firestore.collection("vehicles")
        // .where('vehicleID', '==', '003' )
        .where('vehicleID', '==', assignedVehicle )
        .onSnapshot( async (snapshot) =>{

                let resultDoc = snapshot.docs.map(document => document.data())
                resultDoc = resultDoc.length ? resultDoc[0] : null;

                if( resultDoc ){
                    setState(resultDoc)
                    //get checkitems
                    
                    /*await getVehicleChecklists( assignedVehicle )
                        .then((items)=>{

                            let _checkItems = [];
                    
                            items.map( (item) =>_checkItems.push(...item.checkItems) )

                            resultDoc.checkItems = uniqBy(_checkItems, 'issueID')

                            setState(resultDoc)

                    })*/
                }


            }
        );

}

const updateVehicleIssues = (vehicle, issues) => {

    firestore
        .collection("vehicles")
        .doc(vehicle)
        .update({
            checkItems: firebase.firestore.FieldValue.arrayUnion({...issues})
        })

}

const updateVehicleIssue = async (issue) => {
    let response;
    try{
        const vehicle = (await firestore.collection("vehicles").doc(issue.vehicleID).get()).data();
        const oldCheckItems = vehicle.checkItems; 
        const newCheckItems = oldCheckItems.map(checkItem => {
            if(checkItem.issueID===issue.issueID){
                return issue;
            }else{
                return checkItem;
            }
        })

        const vehicleStatus = refreshCheckItemIssues( newCheckItems  );

        await firestore.collection("vehicles")
            .doc(issue.vehicleID)
            .update( { ...vehicleStatus } );

        response="OK"
    }catch(error){
        response = error;
    }finally{
        return response;
    }

}

const migrateVehicleChecklist = ()=>{

    firestore
    .collection("vehicles")
    .get().then(response => response.docs.map( document => {
            
            let vehicle = {...document.data(), id: document.id};
            
            let userShiftDate = new Date(vehicle.userStartShiftTime) ?? new Date(); 
            let vehicleChecklistUUID = uuidv5([vehicle.vehicleID, vehicle.currentUser, userShiftDate].join(''), uuidNamespace);//starttime + vid + uid

            let status = APPROVED;
        
            const issues = vehicle.checkItems ?? [];
        
            if( issues.length > 0  ){

                if (issues.some(item => item.supervisorSignoff && item.supervisorCheckItemApproved === true )) {
                    status = MAINTENANCE;
                } else if (issues.some(item => !item.supervisorSignoff && !item.supervisorCheckItemApproved === true )) {
                    status = PENDING;
                } else if (issues.some(item => item.supervisorSignoff === true && item.supervisorCheckItemApproved === true )) {
                    status = APPROVED;
                }

            }
            
            let checklistObj = {
                checklistID : vehicleChecklistUUID,
                checkItems : issues,
                vehicleID : vehicle.vehicleID,
                goStatus : vehicle.goStatus,
                additionalDetails : vehicle.checkItemAdditionalDetails ?? {},
                status : status,
                checkItemStatus : vehicle.checkItemStatus,
            };

            // firestore.collection("vehicleChecklists").add({...checklistObj});
         
            
            return true
        }
    ));


    // */

    /** 

        firestore
        .collection("vehicleChecklists")
        .get()
        .then(response => response.docs.map( document => {
            const vehicle = {...document.data(), id: document.id};
            // console.log(vehicle)
            
            let status = APPROVED;
        
            const issues = vehicle.checkItems;
        
            if( issues.length > 0  ){

                if (issues.some(item => item.supervisorSignoff && item.supervisorCheckItemApproved === true )) {
                    status = MAINTENANCE;
                } else if (issues.some(item => !item.supervisorSignoff && !item.supervisorCheckItemApproved === true )) {
                    status = PENDING;
                } else if (issues.some(item => item.supervisorSignoff === true && item.supervisorCheckItemApproved === true )) {
                    status = APPROVED;
                }

            }

            //other properties we actually want to remove from vehicle
            // const propsToRemove = {}
            
            console.log('testingun', {...vehicle, status} )
            firestore
                .collection("vehicleChecklists")
                .doc(vehicle.id)
                .set( {...vehicle, status}, { merge:true })

            return true

        }))
    
    */




}

export {
  getVehicles, getVehicleByID, updateVehicleIssue, getVehicleTypes, subscribeToVehicles, createVehicle, updateVehicle,
  setVehicleIssues, updateVehicleIssues, deleteVehicle, subscribeVehicleByVehicleID, setVehicleShiftLog, subscribeToUserVehicle,
  subscribeToUserVehicleStatus, setVehicleStatus, refreshCheckItemIssues, getVehiclesWithUsersAssigned,
  subscribeToVehiclesWithUsersAssigned, getVehiclesByIds, migrateVehicleChecklist,getVehicleUserOnShift,
  subscribeToUserAssignedVehicle
};

