import React, { useContext } from 'react'
import styles from './FullPageLoader.module.scss';
import loaderSvg from '../../assets/images/spining-loader.svg';
import { UserContext } from "../../context/userContext";

const FullPageLoader = () => {
  const { showFullPageLoader } = useContext(UserContext);
  if (!showFullPageLoader) {
    return (<></>)
  }

  return (
    <div className={`show modal overflow-auto d-flex justify-content-center align-items-center ${styles.loaderContainer}`} role="dialog">
      <div className={styles.loader}>
        <img src={loaderSvg} alt={'loader'} />
      </div>
    </div>
  )
};

export default FullPageLoader
