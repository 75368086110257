import React from "react";
import Styles from "../Checklist.module.scss";

const ClassBChecks = (props) => {
    
    const { extraTextFields, setExtraTextFields, getTextFieldElements, setFailedElements, failObject, checklistData, vehicleType, nextHandler, backHandler, vehiclePreselected, vehicleStepCheckOptions} = props.propsMethods;
    const checkboxArr = Object.keys(checklistData.classB);
    checkboxArr.sort();

    const getChecklist = item => {

        let isSelected = !!vehiclePreselected(item);
        let isPassedSelection = vehicleStepCheckOptions(item, "classB");

        return (
            <div key={item} className={ isSelected ? Styles.inActive  : Styles.active}>
                <img src={checklistData.classB[item]?.iconUrl} alt={item} />
                <label htmlFor={item}>{item}</label>

                { !isSelected && !isPassedSelection &&
                <input type="checkbox" id={item} name={item} value={item}/>
                }

                { !isSelected && isPassedSelection &&
                <input type="checkbox" id={item}  name={item} defaultValue={item}  defaultChecked  />
                }

                { isSelected &&
                <input type="checkbox" id={item}  name={item} defaultValue={item} readOnly checked={false} className={Styles.inActive}/>
                }

            </div>
        )

    }

    return (
        <>
            <h4>Class B Checks</h4>
            <article className={Styles.classChecks}>
                <form id="class-b-checks">
                    <div className={Styles.responses}>
                        {checkboxArr.map(getChecklist)}
                    </div>
                    <div className="mt-5 mb-3 d-flex">
                        <textarea className="form-control border-primary" name="class-b-comment" id="additional-details" placeholder="Please enter any additional details here..." defaultValue={extraTextFields.classB.additional_details} />
                    </div>
                    <section className={Styles.navigation}>
                        <button onClick={() => {backHandler(); setFailedElements(failObject(vehicleType, "classB")); setExtraTextFields( getTextFieldElements('classB') );}} className={Styles.btnSecondary}>Back</button>
                        <button type="button" className={Styles.btnPrimary} onClick={() => {nextHandler(); setFailedElements(failObject(vehicleType, "classB")); setExtraTextFields( getTextFieldElements('classB') ); }}>Next</button>
                    </section>
                </form>
            </article>
    </>
    )
}

export default ClassBChecks;