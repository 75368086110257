import React, { useState, useContext, useEffect } from 'react'
import { CCardText, CModalBody, CModalFooter, CModalHeader, CModalTitle } from "@coreui/react";
import EngineHours from '../EngineHours';
import { UserContext } from "../../context/userContext";
import { updateVehicle } from '../../services/VehiclesService';
import toastService from "../../services/toastService";
import { createVehicleEngineHours, getShiftVehicleEngineHours } from '../../services/VehicleEnginehoursService';
import { DateUtil } from '../../utilities/DateUtil';
import {createNewsItem} from '../../services/newsItemsService';

const ClosingHourModal = ({ title, message, showModal, updateShift,currentvehicle }) => {

  const { user, /*vehicle,*/ toggleFullPageLoader } = useContext(UserContext);
  const [closingHourVal, setClosingHourVal] = useState(null);
  const [openingHour, setOpeningHour] = useState(0);
  const [vehicle, setVehicle] = useState(currentvehicle);

  const logClosingHour = async () => {

    try {

      toggleFullPageLoader(true);

      if(user.assignedVehicle !== vehicle.vehicleID){
        toggleFullPageLoader(false);
        showModal( false );
        toastService("Something went wrong while submitting closing hours, please try again or contact your supervisor.", 5000);
    }else{

      const closingHourSnippet = {
        closingHours: closingHourVal,
        closingHoursDetail: {
          dateCreated: new Date(),
          dateCreatedStr: DateUtil(new Date()),
          addedByDriver: {
            userID: user.userID,
            fullName: user.fullNameStr,
            userType: user.userType,
          },
          approvedBy: {},
          type: 'closingHours',
        },
      };

      const engineHours = {
        ...vehicle.engineHours,
        ...closingHourSnippet
      }

      await createNewsItem({
        dateCreated: new Date(),
        dateCreatedStr: DateUtil(new Date()),
        title: `Closing Hours Reported`,
        message: `Closing hours on ${vehicle.vehicleID} by ${user.fullNameStr} reported`,
        team: vehicle.currentTeam,
        type: "vehicleClosingHourReported",
        userShiftId : user.currentSID,
        info: {
          closingHours : closingHourVal,
          driver: user.userID,
          user : user,
          vehicle: vehicle,
          vehicleID: vehicle.vehicleID
          },
        approvedBy: {}
        });
        
      await updateVehicle({ ...vehicle, engineHours });
      await createVehicleEngineHours(vehicle.vehicleID,{ closingHours: closingHourVal, ...closingHourSnippet.closingHoursDetail, userShiftId: user.currentSID });

      await updateShift({ ...user, currentSID: '' });

      toggleFullPageLoader(false);
      showModal(false);
    }

    } catch (error) {
      console.error('catch error : logClosingHour', error);
    } finally {
      toggleFullPageLoader(false);
    }

  }

  useEffect(() => {

    let mounted = true;

    if (user.currentSID) {

      const getShiftEngineHours = async () => {

        const getShiftOpeningHours = await getShiftVehicleEngineHours(user.currentSID);

        const engineHoursResult = getShiftOpeningHours.filter(item => item.type === 'openingHours')

        if (mounted) {
          // eslint-disable-next-line array-callback-return
          engineHoursResult.map((engineHour) => {
            setOpeningHour(engineHour.openingHours);
          })
        }

      }

      getShiftEngineHours();

      return () => { mounted = false; getShiftEngineHours(); };
    }

    return () => { mounted = false; };

  }, [user])

  return (
    <>
      <CModalHeader closeButton>
        <CModalTitle>{title}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CCardText>
          Closing hours required before ending shift
        </CCardText>
        <CCardText>
          <strong> Opening hours for this shift - ({openingHour}) </strong>
        </CCardText>
        <EngineHours action='closing' setResponseVal={setClosingHourVal} />
      </CModalBody>
      <CModalFooter className="align-items-center justify-content-between">
        <button type={'button'} className={'btn btn-danger'} data-dismiss='modal' onClick={() => (showModal(false))}>Close</button>

        {(!closingHourVal || closingHourVal < 1 || parseFloat(closingHourVal) < parseFloat(openingHour)) &&
          <button type='button' className='btn btn-secondary disabled' data-dismiss='modal'>Submit &amp; End shift</button>
        }

        {closingHourVal && parseFloat(closingHourVal) > 0 && parseFloat(closingHourVal) >= parseFloat(openingHour) &&
          <button type={'button'} className='btn btn-primary' data-dismiss='modal' onClick={() => { logClosingHour() }}>Submit &amp; End shift</button>
        }

      </CModalFooter>
    </>
  )
}

export default ClosingHourModal
