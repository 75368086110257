import firebase, { firestore } from "../firebase";
import { PENDING,APPROVED,ACTIVE, REJECTED, OPEN, CLOSED } from "../enums/VehicleChecklistStatuses";
import { AVAILABLE, GO_BUT_STATUS, GO_STATUS, NOGO_STATUS, NOT_AVAILABLE } from "../enums/VehicleStatuses";
import { CLASS_A, CLASS_B, CLASS_C } from "../enums/VehicleIssueClasses";

const getVehicleChecklists  = ( vehicle ) => {
    return firestore
        .collection("vehicleChecklists")
        .where("vehicleID", '==', vehicle)
        .where("vehicleID", 'in', [PENDING,APPROVED,REJECTED,NOT_AVAILABLE,AVAILABLE])//Todo: Review after discussing business rules
        .orderBy('dateCreated', 'desc')
        .get()
        .then(response => response.docs.map(document => { console.log(document.data(), document.id, "document"); return {...document.data(), id: document.id}}));
}



const subscribeVehicleChecklistsByVehicleId  = ( vehicleId, setVehicleChecklist ) => {
    return firestore
        .collection("vehicleChecklists")
        .where("vehicleID", '==', vehicleId)
        .orderBy('dateCreated', 'desc')
        .onSnapshot(response => setVehicleChecklist(response.docs.map(document => ({...document.data(), id: document.id}))));
}

const getSingleVehicleChecklist  = ( checklistid) => {

    return firestore.collection('vehicleChecklists').doc(checklistid).get()
    .then(snapshot => snapshot.data())

}

const getLatestVehicleChecklist  = (vehicleid) => {
    return firestore
    .collection("vehicleChecklists")
    .where("vehicleID", '==', vehicleid)
    .where("status", '==', OPEN)
    .orderBy('dateCreated', 'desc')
    .limit(1)
    .get()
    .then(response => response.docs.map(document => ({...document.data(), id: document.id})));   
}

//watches vehicles collection and updates the state whenever the db changes
const subscribeToLatestVehicleChecklist = (setState,vehicleid) => {
    return firestore
            .collection("vehicleChecklists")
            .where("vehicleID", '==', vehicleid)
            .where("status", '==', OPEN)
            .orderBy('dateCreated', 'desc')
            .onSnapshot(snapshot => setState(snapshot.docs.map(document => 
                ({...document.data(), id: document.id}))));
}
const subscribeToVehicleChecklistsInReview = (setState ) => {
    return firestore
            .collection("vehicleChecklists")
            .where("checkItemStatus", 'in', [PENDING])
            .where("status", '==', OPEN)
            .orderBy('dateCreated', 'desc')
            .onSnapshot(snapshot => setState(snapshot.docs.map(document => 
                ({...document.data(), id: document.id}))));
}
const subscribeToVehicleChecklistsInMaintenance = (setState ) => {
    return firestore
            .collection("vehicleChecklists")
            .where("checkItemStatus", '==', APPROVED)
            .where("status", '==', OPEN)
            .where("hasOpenIssues", '==',true)
            .onSnapshot(snapshot => setState(snapshot.docs.map(document => 
                ({...document.data(), id: document.id}))));
}
const createVehicleChecklist  = async(checklistData) => {
    
    let recordId = '';
    await firestore
        .collection("vehicleChecklists")
        .add(checklistData)
        .then((docRef) => {
            console.log("createVehicleChecklist doc written with ID: ", docRef.id);
            recordId = docRef.id;
            
        })
        .catch((error) => {
            console.error("createVehicleChecklist error : ", error );
        });

    return recordId;

};

const updateVehicleChecklist  = (vehicleChecklistId, vehicleChecklist) => firestore.collection("vehicleChecklists").doc(vehicleChecklistId).set({...vehicleChecklist}, {merge : true});

const updateOrphanedVehicleChecklist = async (vehicleId)=>{

    let vehicleChecklistCollection = [];

    await firestore
     .collection("vehicleChecklists")
     .where("status", "==", 'Open')
     .where("vehicleID", "==", vehicleId)
     .get()
     .then(result => {
        vehicleChecklistCollection = result.docs.map(doc => ( {...doc.data(), id: doc.id} ) );
       return result;
     })

     for (const checkList of vehicleChecklistCollection) {

            await firestore
            .collection("vehicleChecklists")
            .doc(checkList.id)
            .set({status:CLOSED}, {merge: true})

      }
}

const refreshChecklistItemIssues = ( issues ) => {
    
    let goStatus = GO_STATUS;
    let hasOpenIssues = false;

    if (issues.some(item => !item.supervisorSignoff &&  item.classType === CLASS_A)) {
        goStatus = NOGO_STATUS;
        hasOpenIssues = true;
    } else if (issues.some(item => !item.supervisorSignoff && item.classType === CLASS_B) || issues.some(item => !item.supervisorSignoff && item.classType === CLASS_C)) {
        goStatus = GO_BUT_STATUS;
        hasOpenIssues = true;
    }

    return {
        hasOpenIssues: hasOpenIssues,
        goStatus: goStatus,
        checkItems: issues
    }
    
}
const refreshVehicleItemIssues = ( issues ) => {
    
    let fatalClasslist =  false;
    let goStatus = GO_STATUS;

    if (issues.some(item => !item.supervisorSignoff &&  item.classType === CLASS_A)) {
        goStatus = NOGO_STATUS;
        fatalClasslist = true;
    } else if (issues.some(item => !item.supervisorSignoff && item.classType === CLASS_B) || issues.some(item => !item.supervisorSignoff && item.classType === CLASS_C)) {
        goStatus = GO_BUT_STATUS;
    }

    return {
        status: fatalClasslist ? NOT_AVAILABLE : AVAILABLE,
        goStatus: goStatus
    }
    
}

const updateVehicleChecklistIssues = (vehicle, issues) => {

    firestore
        .collection("vehicleChecklists")
        .doc(vehicle.id)
        .update({
            checkItems: firebase.firestore.FieldValue.arrayUnion({...issues})
        })

}

const updateVehicleChecklistIssue = async (vehicleChecklistId,issue) => {
    //Discuss: Not being used - business rules are able to be achieved without updating each individual checkitem in the vehicle checklist, there may be a case for it for reporting or to tighten some checks on the dashboard
    let response;
    try{
        const vehicleChecklist = (await firestore.collection("vehicleChecklists").doc(vehicleChecklistId).get()).data();
        const oldCheckItems = vehicleChecklist.checkItems; 
        const newCheckItems = oldCheckItems.map(checkItem => {
            if( checkItem.issueID === issue.issueID ){
                return issue;
            }else{
                return checkItem;
            }
        })

        const vehicleStatus = refreshVehicleItemIssues( newCheckItems  ); 
        const vehicleChecklistData = refreshChecklistItemIssues( newCheckItems  ); 

        await firestore.collection("vehicleChecklists")
            .doc(vehicleChecklistId)
            .update( { ...vehicleChecklistData } );      

        await firestore.collection("vehicles")
            .doc(issue.vehicleID)
            .update( { ...vehicleStatus } );

        response="OK"
    }catch(error){
        response = error;
    }finally{
        return response;
    }

}

const getVehicleCheckById  = ( id ) => {
    return firestore
        .collection("vehicleChecklists")
        .where(firebase.firestore.FieldPath.documentId(), '==', id)
        .get()
        .then(response => response.docs.map(document => ({...document.data(), id: document.id})));
}

export {getVehicleChecklists, subscribeVehicleChecklistsByVehicleId, subscribeToLatestVehicleChecklist,subscribeToVehicleChecklistsInMaintenance, createVehicleChecklist, refreshChecklistItemIssues,
updateVehicleChecklist, updateVehicleChecklistIssues, updateVehicleChecklistIssue, getSingleVehicleChecklist,getLatestVehicleChecklist,subscribeToVehicleChecklistsInReview,updateOrphanedVehicleChecklist,
    getVehicleCheckById
};

